export const COUNTRY_DETAILS_MAP = {
  AE: {
    priority: 1,
    packages: [
      {
        id: 0,
        process_name: 'UAE 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '60 days',
        visa_fees: 5400,
        service_charge: 1000,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'UAE 60 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '60 days',
        entry_validity: '60 days',
        visa_fees: 10200,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 2,
        process_name: 'UAE 90 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '90 days',
        entry_validity: '90 days',
        visa_fees: 41987,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 3,
        process_name: 'UAE 30 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '30 days',
        entry_validity: '60 days',
        visa_fees: 12500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 4,
        process_name: 'UAE 60 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '60 days',
        entry_validity: '60 days',
        visa_fees: 18000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 5,
        process_name: 'UAE 5 years Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 3,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '1825 days',
        entry_validity: '5 Years',
        visa_fees: 105000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'UAE',
    code: 'AE',
    hero_image_url:
      'https://images.unsplash.com/flagged/photo-1559717865-a99cac1c95d8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8VW5pdGVkJTIwQXJhYiUyMEVtaXJhdGVzfGVufDB8fDB8fHww',
    process: 'digital',
    // process_name: 'UAE 30 Days Single Entry E-Visa',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: 12,
    // eta_post_checkout_unit: 'hours',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 45,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '60 days',
    // govt_fees: 5950,
    // service_fees: 1200,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'UAE E-Visa',
        description:
          'A visa is required for you to enter the UAE. tryvisa provides a 30 day single entry UAE Visa. ',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'dropdown',
        required_doc: '',
        options: [
          {
            label: 'Services',
            value: 'services',
          },
          {
            label: 'Housewife',
            value: 'house wife',
          },
          {
            label: 'Child',
            value: 'none',
          },
          {
            label: 'Retired',
            value: 'retired',
          },
          {
            label: 'Business',
            value: 'business',
          },
          {
            label: 'Student / not allowed to work',
            value: 'student / not allowed to work',
          },
          {
            label: 'Medical doctors',
            value: 'medical doctors',
          },
          {
            label: 'Engineer',
            value: 'engineer',
          },
          {
            label: 'Architect',
            value: 'architect',
          },
          {
            label: 'Sales specialist',
            value: 'sales specialist',
          },
          {
            label: 'Soldier',
            value: 'soldier',
          },
          {
            label: 'Clinical scientist',
            value: 'clinical scientist',
          },
          {
            label: 'University professor',
            value: 'university professor',
          },
          {
            label: 'Teacher',
            value: 'teacher',
          },
          {
            label: 'Photographer',
            value: 'photographer',
          },
          {
            label: 'Pilot',
            value: 'pilot',
          },
          {
            label: 'Journalist',
            value: 'journalist',
          },
          {
            label: 'Flight attendant',
            value: 'flight attendant',
          },
          {
            label: 'Manager',
            value: 'manager',
          },
          {
            label: 'Senior Manager',
            value: 'senior manager',
          },
          {
            label: 'Vice President',
            value: 'vice president',
          },
          {
            label: 'Lawyer',
            value: 'lawyer',
          },
          {
            label: 'Director',
            value: 'director',
          },
          {
            label: 'Executive',
            value: 'executive',
          },
          {
            label: 'Associate officer',
            value: 'associate officer',
          },
          {
            label: 'Businesswoman',
            value: 'businesswoman',
          },
          {
            label: 'Secretary',
            value: 'secretary',
          },
          {
            label: 'Physician',
            value: 'physician',
          },
          {
            label: 'None',
            value: 'none',
          },
          {
            label: 'Sales Representative',
            value: 'sales representative',
          },
          {
            label: 'FINANCIAL ADVISOR',
            value: 'financial advisor',
          },
          {
            label: 'INSURANCE AGENT',
            value: 'insurance agent',
          },
          {
            label: 'financial consultant',
            value: 'financial consultant',
          },
          {
            label: 'BRANCH MANAGER',
            value: 'branch manager',
          },
          {
            label: 'ADVISOR',
            value: 'advisor',
          },
          {
            label: 'SOFTWARE ENGINEER',
            value: 'software engineer',
          },
          {
            label: 'NURSE',
            value: 'nurse',
          },
          {
            label: 'CHAIRMAN OF THE BOARD',
            value: 'chairman of the board',
          },
          {
            label: 'TRAVEL CONSULTANT',
            value: 'travel consultant',
          },
          {
            label: 'IT OPERATION MANAGER',
            value: 'it operation manager',
          },
          {
            label: 'Pharmacist',
            value: 'pharmacist',
          },
          {
            label: 'specialist',
            value: 'specialist',
          },
          {
            label: 'coordinatior',
            value: 'coordinatior',
          },
          {
            label: 'department head',
            value: 'department head',
          },
        ],
        description:
          'This is an optional occupation field. Most people use the default - Service. Occupation does not influence the decision of the visa.',
        label: "What is the traveler's occupation (optional)?",
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'occupation',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'USD',
        attributes: ['image_required'],
        only_b2b: true,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'USD',
        attributes: ['minor_guardian', 'image_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'USD',
        attributes: ['mothers_name_required', 'fathers_name_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 572,
              height: 436,
            },
            alt: 'UAE Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d6cb80c7-b854-430b-8582-47a9ee41e8d6_UAE+Ministry+of+Foreign+Affairs.png?auto=compress,format',
            id: 'ZdREMhEAACsAPkCx',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 404,
              height: 184,
            },
            alt: 'UAE MOFA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e0b0b285-555b-4d22-822b-7e06e4e6bf07_govUAE.png?auto=compress,format&rect=0,0,404,184&w=404&h=184',
            id: 'ZIK_pBAAACIAjaQL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 126,
              height: 80,
            },
            alt: 'UAE economy and tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ebf840c9-d1c3-4159-8ae2-23f0fc77f947_image+%281%29.png?auto=compress,format',
            id: 'ZK-XchIAACIAaHJG',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1263,
              height: 1588,
            },
            alt: 'UAE General Directorate of Residency and Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8223730b-abd3-4638-9d4b-57d95f96b4ea_UAE+General+Directorate+of+residency+and+foreign+affaris.png?auto=compress,format',
            id: 'ZdRMOBEAACgAPmST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 426,
            },
            alt: 'Etihad logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1b957a44-9a9c-43a2-8644-bf918157abc2_image+%282%29.png?auto=compress,format',
            id: 'ZK-XohIAACMAaHMj',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 5600,
              height: 3500,
            },
            alt: 'Emirates Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a2c9d4b4-10b9-4ba8-806d-ba13969e7e1b_image+%283%29.png?auto=compress,format',
            id: 'ZK-XoxIAACMAaHMo',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$1c2e4e9b-915b-49b4-b921-a8504b4fe091',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need to apply for a UAE tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents through tryvisa are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport-size photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a UAE visa from India without a sponsor?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is possible to apply for a UAE visa from India without a sponsor. Depending on the visa type and duration of stay, you can apply for a visa as a tourist or for other purposes. Ensure you meet the visa requirements and submit the necessary documents as per the guidelines provided on the official UAE visa website.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a multiple-entry UAE visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is possible to apply for a multiple-entry UAE visa. Multiple-entry visas allow you to enter and exit UAE multiple times within the visa validity period. This is particularly beneficial if you plan to make multiple trips to UAE within a specified duration.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my UAE visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and visa portals do not refund the fees, except for tryvisa. At tryvisa, you will receive a refund of up to ₹8000 or $100 when your visa is rejected. The refund does not include the service fee.',
              spans: [
                {
                  start: 213,
                  end: 219,
                  type: 'strong',
                },
                {
                  start: 240,
                  end: 247,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is a UAE visa open for Indians now?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is. Indian nationals can apply for a UAE visa at any time.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I get a UAE visa online in one day?',
          answer: [
            {
              type: 'paragraph',
              text: "No. Unfortunately, the tourist visa has a processing time of 2 days, which means you can't get it in one day.",
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is the UAE visa the same as a UAE visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes. There is actually no separate visa for UAE. You'll apply for a UAE visa, and from there, you can enter any emirate you want to.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a UAE tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a UAE tourist visa from India through the tryvisa app or the online portal.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time of a UAE tourist visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a UAE tourist visa application can vary depending on the type of visa and the processing option chosen. Generally, it takes around 2 business days for the visa to be processed. However, during peak seasons or due to unforeseen circumstances, the processing time may be longer.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a UAE visa if I have previously been denied entry to the UAE?',
          answer: [
            {
              type: 'paragraph',
              text: 'Previous denial of entry to the UAE may impact your eligibility for a visa. It is recommended to disclose any relevant information about past denials or immigration issues when applying for a UAE visa. The UAE authorities will consider your circumstances and make a decision based on the information provided.',
              spans: [],
            },
          ],
        },
        {
          question: 'Which UAE visas can I apply for online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for the UAE tourist visa online. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it hard to get a UAE tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes. However, if you apply through tryvisa, you’ll apply and get your visa without hassle.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is a bank statement required for a UAE visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'In some cases, you have to provide bank statements to show that you can financially support yourself during your stay in the UAE.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$242c661b-d26a-4d33-ba3a-22f6daa8c5d2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'United Arab Emirates',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 5400,
    // cost_max: 8460,
    // cost_min: 5852,
  },
  SG: {
    priority: 2,
    packages: [
      {
        id: 0,
        process_name: 'Singapore 90 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '90 days',
        entry_validity: 'Up to 2 years',
        visa_fees: 2800,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Singapore',
    code: 'SG',
    hero_image_url:
      'https://images.unsplash.com/photo-1525625293386-3f8f99389edd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8U2luZ2Fwb3JlfGVufDB8fDB8fHww',
    process: 'physical',
    // process_name: 'Visa',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: 4,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 120,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '30 days',
    // govt_fees: 1800,
    // service_fees: 850,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'e-Visa',
        description:
          'A Singpore visa is issued digitally. However, Singapore requires possession of your passport while it processes your visa. The passport has to be shipped to certain vendors authorized by the Immigration authorities of Singapore (ICA). tryvisa handles this for you.',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['employment_letter_required'],
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required', 'marital_status_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$3cfa0dc7-4975-4a9b-aa86-f98bff29c240',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How to ensure a successful Singapore visa for Indians online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'For a successful Singapore visa online application, follow the easy step-by-step guide on tryvisa, ensure you fill in accurate information, submit all the required documents, and pay the visa fee promptly.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens after my Singapore visa application has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply on tryvisa, your passport and visa will be sent to you, or if you applied for an e-visa, you will receive it digitally via e-mail.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the procedure to apply for a Singapore visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applicants are advised to submit their application in person through their nearest tryvisa application centre. If you cannot apply in person, you can apply through the tryvisa online portal. Start your online application through tryvisa, enter your travel dates, upload your documents, complete the visa application form, and pay the visa fee.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Singapore visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is safe to apply for a Singapore visa online. tryvisa uses a 256-bit Advanced Encryption Standard to keep your data safe and does not sell your data to third-party companies. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I track the status of my Singapore visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "Once you've submitted your Singapore visa online application through tryvisa, you can track its status by:",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Downloading the tryvisa app.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scroll down to your "Profile".',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'In your profile, click on your visa to see its current status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'You can also track your visa status using the government website.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What are the specifications for the passport-sized photos needed for the Singapore visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The passport-sized photos needed for the Singapore visa online application must be 35 x 45 mm, taken in colour, taken against a white background, and should show your full face without any headgear (unless you wear it for religious reasons).',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a Singapore visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Singapore tourist visa at least 30 days before your planned departure date to allow for sufficient processing time.",
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know when my Singapore visa online submission has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your Singapore visa online submission has been approved. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost to apply for a Singapore visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing fee to apply for a Singapore visa online is ₹ 1800 per application and ₹650 + 18% GST service fee per application. ',
              spans: [
                {
                  start: 58,
                  end: 65,
                  type: 'strong',
                },
                {
                  start: 86,
                  end: 112,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is there a fast-track service for Singapore visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: "Currently, there isn't a fast-track service for the Singapore visa online submission. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Singapore with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Singapore tourist visa does not allow you to work in the country.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the processing time for a Singapore tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your visa will be processed within 3-5 working days from the date of online submission.\n\n*This excludes the day of submission, weekends and public holidays.',
              spans: [
                {
                  start: 89,
                  end: 156,
                  type: 'em',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required for a Singapore visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "While not mandatory, it's recommended to have travel insurance for your trip to Singapore. It's not a requirement for the Singapore visa online application.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the minimum passport validity required for a Singapore visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months from the date of entry into Singapore for a successful Singapore visa online submission.',
              spans: [
                {
                  start: 43,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I apply for a Singapore visa online on behalf of a friend or family member?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Singapore visa online on behalf of a friend or family member. You will need to have the necessary documents and details to complete the application.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I cancel my Singapore visa online application after it has been submitted?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Singapore visa online application has been submitted, it cannot be cancelled. However, you can choose not to use the visa if your travel plans change.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I pay for my Singapore visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for your Singapore visa online application can be paid online through tryvisa using various payment methods, including credit/debit cards and certain mobile wallets.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a physical visa stamp for the Singapore e visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, the Singapore e-visa is an electronic visa, meaning there's no physical stamp.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0dccd815-820c-46e8-9faa-56afa0352b17',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Singapore',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2800,
    // cost_max: 3300,
    // cost_min: 3000,
  },
  TH: {
    priority: 3,
    visa_free_message: 'Visa Free till 11 November 2024',
    packages: [
      {
        id: 0,
        process_name: 'Thailand 30 days Single Entry Free Visa',
        supported: true,
        message: 'Visa Free till 11 November 2024',
        eta_post_checkout_duration: 5,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 3840,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Thailand 60 days Multiple Entry Free Visa',
        supported: true,
        message: 'Visa Free till 11 November 2024',
        eta_post_checkout_duration: 5,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '60 days',
        entry_validity: '180 days',
        visa_fees: 13000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Thailand',
    code: 'TH',
    hero_image_url:
      'https://images.unsplash.com/photo-1552465011-b4e21bf6e79a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8VGhhaWxhbmR8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    // process_name: 'Thailand E-Visa',
    supported: false,
    // message: null,
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Single Entry',
    // entry_length_stay: '15 days',
    // entry_validity: '15 days',
    // govt_fees: 0,
    // service_fees: 0,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Thailand E-Visa',
        description:
          'A visa is required for you to visit Thailand. Save time at immigration by getting a visa before you travel. ',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: '',
        description: ' ',
        label: 'What is the carrier code for your arrival flight from India to Thailand? (E.g. 6E)',
        required: true,
        key: 'arrival_flight_code',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: '',
        description: ' ',
        label:
          'What is the carrier code for your departure flight from Thailand to India? (E.g. 6E)',
        key: 'departure_flight_code',
        source_url: '',
        required: true,
        only_b2b: false,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'number',
        required_doc: '',
        description: ' ',
        label: 'What is the number of your arrival flight from India to Thailand? (E.g. 121)',
        only_b2b: false,
        source_url: '',
        key: 'arrival_flight_number',
        required: true,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'number',
        required_doc: '',
        description: ' ',
        label: 'What is the number of your departure flight from Thailand to India? (E.g. 121)',
        key: 'departure_flight_number',
        only_b2b: false,
        required: true,
        source_url: '',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'date',
        required_doc: '',
        description: ' ',
        label: 'What is your departure date for your flight to Thailand?',
        key: 'flight_date',
        only_b2b: false,
        source_url: '',
        required: true,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: '',
        description: ' ',
        label: 'What is the PNR for your arrival flight from India to Thailand?',
        only_b2b: false,
        required: true,
        key: 'arrival_flight_pnr',
        source_url: '',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: '',
        description: ' ',
        label: 'What is the PNR for your departure flight from Thailand to India? ',
        key: 'departure_flight_pnr',
        required: false,
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Tickets',
        description: 'Complete Flight Ticket',
        label: 'Complete_Flight_Ticket',
        key: 'Flights',
        source_url: '',
        only_b2b: true,
        required: true,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Reservations',
        description: 'Please Upload Your Hotel Reservations',
        label: 'Please Upload Your Hotel Reservations',
        required: true,
        key: 'Hotel_Reservation',
        only_b2b: true,
        source_url: '',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'fbi_background_check',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'identity',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        only_b2b: false,
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        currency: 'USD',
        attributes: ['image_required'],
        toggle: true,
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'passport_back',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: true,
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'shipping_labels',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: true,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'travel_insurance',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: true,
        family_enabled: false,
        amount: 0,
        chargable: false,
        currency: 'USD',
        toggle: false,
        attributes: ['use_surfer'],
        key: 'travel_itinerary',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'us_bank_statements',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1200,
            },
            alt: 'Thailand Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/55011aff-6f2b-47c7-b3af-4bbb9edb655d_1200px-Seal_of_the_Minister_of_Foreign_Affair_of_Thailand.svg.png?auto=compress,format',
            id: 'ZLEUQhIAACIAbtwy',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1174,
              height: 1544,
            },
            alt: 'Royal Thai Consulate General logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c25b8fa1-a688-4425-acee-527626ff3b22_image+%283%29.png?auto=compress,format',
            id: 'ZLEXqhIAACEAbuwl',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1228,
              height: 1228,
            },
            alt: 'Royal Thai Police logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5ee900e6-1f3c-4832-9ed6-b6dcc0381586_image.png?auto=compress,format',
            id: 'ZLEXqhIAACAAbuwm',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 316,
              height: 316,
            },
            alt: 'Tourism Authority of Thailand logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3928a48a-f56a-4def-9b3c-ab7e573a38a4_Thailand+tourism+board+logo.png?auto=compress,format',
            id: 'ZdWnTBEAACwARKrX',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 598,
            },
            alt: 'Shangri - LA hotel and resorts logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b129f299-f920-4290-b525-a222552a3d65_image+%282%29.png?auto=compress,format',
            id: 'ZLEXqBIAACEAbuwe',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$b15642d0-1505-4d28-8f82-9e21f5862e83',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Thailand e visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents you need are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A scanned copy of your passport's back page.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Thailand e-visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Thailand e-visa application can vary, but it typically takes around 2 days. tryvisa guarantees your money back if your visa takes longer than the estimated processing time.',
              spans: [
                {
                  start: 26,
                  end: 53,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/the-thailand-e-visa-on-arrival-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question:
            'Is it mandatory to provide a flight itinerary when applying for a Thailand e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is generally required to provide a flight itinerary when applying for a Thailand e-visa. The Thai authorities want to ensure that you have a confirmed plan to enter and exit the country within the visa validity period. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of a Thailand tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period of a Thailand tourist visa can vary. Generally, a single-entry tourist visa is valid for 15 days from the date of entry into Thailand. ',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I apply for a Thailand e-visa if I have previously been denied entry to Thailand?',
          answer: [
            {
              type: 'paragraph',
              text: 'Previous denial of entry to Thailand may impact your eligibility for a visa. It is advisable to disclose any relevant information about past denials or immigration issues when applying for a visa. The Thai authorities will consider your circumstances and make a decision based on the information provided.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an age limit for applying for a Thailand tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There is no specific age limit for applying for a Thailand tourist visa. Individuals of all ages can apply for a tourist visa as long as they fulfill the necessary requirements and provide the required documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Thailand e-visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Thailand e-visa application is rejected, review and fix the reasons that caused your visa to be denied. Once fixed, you can reapply for your visa. Remember, you will not receive a refund from the government or online visa portals except from tryvisa. tryvisa guarantees a refund of up to ₹8000 or $100 if your visa is rejected. This excludes the service fee.',
              spans: [
                {
                  start: 257,
                  end: 274,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Are there any specific health requirements or vaccinations needed for travel to Thailand?',
          answer: [
            {
              type: 'paragraph',
              text: 'While there are no mandatory vaccinations for entering Thailand, it is advisable to check with a healthcare professional or travel health clinic for any recommended vaccinations based on your health status and the areas you plan to visit. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it possible to convert a tourist visa to a work visa in Thailand?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is impossible to convert a tourist visa to a work visa in Thailand. If you wish to work in Thailand, you would need to exit the country and apply for an appropriate work visa from a Thai embassy or consulate in your home country or another eligible location.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Thailand e-visa if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Thailand e-visa if you have dual citizenship. However, it is important to apply using the passport that you intend to travel with. Ensure that you provide accurate information and use the appropriate passport details in your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Thailand e-visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Thailand e visa through the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Thailand tourist visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Thailand tourist visa online through tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I travel to Thailand with a copy of the e-visa, or do I need to carry the original?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advisable to carry the original e-visa when traveling to Thailand. While a digital or printed copy might be accepted in some cases, having the original e-visa document ensures a smoother entry process at immigration checkpoints.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Thailand e-visa is lost or damaged?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Thailand e-visa is lost or damaged, you can download a new copy from the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of a Thailand tourist visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa cost through tryvisa is INR 7 300. Please note that this visa fee is nonrefundable.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in Thailand on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can stay a maximum of 15 days with a Thai eVisa for Indians',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to multiple cities within Thailand with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, with a tourist visa to Thailand, you can travel to multiple cities within the country. The tourist visa allows you to explore various destinations and enjoy the cultural, historical, and natural attractions Thailand offers.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4785ba9d-cd79-40a5-98a5-5c5b4621aaa2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Thailand',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    price: 3840,
    // cost_max: 0,
    // cost_min: 0,
  },
  OM: {
    priority: 4,
    packages: [
      {
        id: 0,
        process_name: 'Oman 10 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '10 days',
        entry_validity: '90 days',
        visa_fees: 1950,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Oman 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 5300,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 2,
        process_name: 'Oman 90 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '90 days',
        entry_validity: '90 days',
        visa_fees: 53125,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Oman',
    code: 'OM',
    hero_image_url:
      'https://images.unsplash.com/photo-1599743777555-e362a2feab39?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8T21hbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'Oman 10 Day E-Visa ',
    supported: true,
    // message:
    //   'We do not currently support Oman visas\nYou can get a 26M Tourist Visit Visa (vailidty 30 days) on arrival at an airport if you have a valid Schengen visa or a valid visa to one of the following countries (USA, UK, Canada, Australia or Japan).',
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '10 days',
    // entry_validity: '90 days',
    // govt_fees: 3445,
    // service_fees: -3445,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Oman E-Visa',
        description:
          'The E-Visa is valid for 10 days. Overstaying on this visa will lead to severe penalties. ',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 275,
              height: 300,
            },
            alt: 'Royal Oman Police',
            copyright: null,
            url: 'https://images.prismic.io/atlys/79035ea0-38e8-4e29-85e1-dba77e6447ab_image+%285%29.png?auto=compress,format',
            id: 'ZK_YrRIAACEAaZPj',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 568,
              height: 440,
            },
            alt: 'Oman airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0efcdc3c-958f-4cd9-b500-1f9b4d48c289_Oman+airline..png?auto=compress,format',
            id: 'ZdXIxBEAACsARUJE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 225,
            },
            alt: 'Oman Ministry of foreign affairs logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0d38ce80-0a52-460e-aa34-f9c72fa7a762_Oman+ministry+of+foreign+affairs..png?auto=compress,format',
            id: 'ZdXJxhEAAC0ARUbS',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Oman Ministry of tourism',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8578f8b2-0390-4d67-9296-457dff959a6e_Oman+ministry+of+tourism.png?auto=compress,format',
            id: 'ZdXLuhEAACcARU94',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$dd0021c7-b606-4602-9a1a-55019387ed6f',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for an Oman visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The commonly required documents for an Oman visa application are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport should be valid for at least six months beyond your planned departure date from Oman. You must also have at least one blank page for the visa stamp.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Photograph: You need to provide a recent passport-sized color photograph with a white background.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance required to apply for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is highly recommended when applying for an Oman visa, though it may not be mandatory.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any restrictions on activities I can do on an Oman visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Oman visit visa is primarily for tourism purposes. Any kind of paid work without a proper work visa is prohibited.',
              spans: [],
            },
          ],
        },
        {
          question: 'What kind of photos should I submit for the Oman visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'You should submit a recent color photograph with a white background for your Oman visa application. On tryvisa, you can use the visa photo tool when you apply. It generates a perfect visa photo for free.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I make a family application for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: "A family application can be made for an Oman visa, On tryvisa, you can select the number of people you're applying for under one application.",
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if I make a mistake on my Oman visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you need to correct a mistake on your Oman visa application, please contact our support team as soon as possible to rectify the issue.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for an Oman visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: 'We recommend applying for your Oman visa at least two weeks before your intended travel date to account for any potential processing delays.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to book a flight before applying for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not mandatory, having a booked flight can increase the chances of your Oman visa application being approved.',
              spans: [],
            },
          ],
        },
        {
          question: 'Where can I find accurate Oman visa information?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can find all the relevant Oman visa information on our website. We provide updated and accurate details to ensure a smooth application process.',
              spans: [
                {
                  start: 30,
                  end: 39,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/oman-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my Oman visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and online portal do not refund the fees, except for tryvisa. At tryvisa, you will receive a maximum refund of ₹8000 or $100 when your visa is rejected. The refund excludes the service fee.',
              spans: [
                {
                  start: 215,
                  end: 220,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Oman with a visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, it is illegal to work in Oman with a visitor visa. You will need a proper work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the average processing time for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an Oman visa is 4 working days.',
              spans: [
                {
                  start: 40,
                  end: 54,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the price for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The price for an Oman visa is between INR 4,100 and INR 7,050. With tryvisa, there are no hidden fees. We ensure that you are fully aware of all costs associated with your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any specific health requirements to apply for an Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are no specific health requirements for most visitors. However, depending on your travel history, you might need to provide a yellow fever vaccination certificate.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check if my Oman visa application was successful?',
          answer: [
            {
              type: 'paragraph',
              text: 'Access your visa dashboard, where you can check the status of your Oman visa application. Click on the icon located at the top right corner of your dashboard to view your visa status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I visit all parts of Oman with a visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, an Oman visit visa allows you to travel throughout the country unless specified otherwise.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I receive my Oman visa after the application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After your Oman visa application is approved, the visa will be sent to you via email in PDF format.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check my Oman visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can check your Oman visa status online through our website by entering your application reference number.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it mandatory to print my Oman visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While the visa is linked to your passport electronically, you still need to print out a copy to show at customs once you arrive in Oman. We recommend that you print out not just one but several copies and put them in different bags just in case you lose your luggage.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$31062bf8-45b7-4e12-9f78-c42c81d7f3b8',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Oman',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 1950,
    // cost_max: 0,
    // cost_min: 0,
  },
  BD: {
    priority: 5,
    packages: [
      {
        id: 0,
        process_name: 'Bangladesh 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 1800,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Bangladesh 90 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '90 days',
        entry_validity: '180 days',
        visa_fees: 4500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 2,
        process_name: 'Bangladesh 90 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '90 days',
        entry_validity: '180 days',
        visa_fees: 2854,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 3,
        process_name: 'Bangladesh 180 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '180 days',
        entry_validity: '180 days',
        visa_fees: 3554,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 4,
        process_name: 'Bangladesh 365 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '365 days',
        entry_validity: '',
        visa_fees: 6354,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Bangladesh',
    code: 'BD',
    hero_image_url:
      'https://images.unsplash.com/photo-1624395149011-470cf6f6ec02?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8QmFuZ2xhZGVzaHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'Visa',
    supported: true,
    // message: 'Not Supported\nNot Supported',
    // eta_post_checkout_duration: 11,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 15,
    eta_pre_travel_unit: 'days',
    // entry_length_stay: '30 days',
    // entry_validity: '180 days',
    // govt_fees: 3500,
    // service_fees: -1570,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Sticker Visa',
        description:
          'The visa is a sticker visa, stamped into your passport. With tryvisa, you can avoid an in-person visit as tryvisa will submit the application on your behalf.',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: true,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: true,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 444,
              height: 222,
            },
            alt: 'Tourism Board of Bangladesh',
            copyright: null,
            url: 'https://images.prismic.io/atlys/f6b4ae90-7d95-4432-8e87-229b1facb26a_bangaldesh-tourism.png?auto=compress,format',
            id: 'ZN8YIRIAACcAvisK',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1500,
              height: 1500,
            },
            alt: 'Bangladesh Ministry of Foreign Affairs ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e0e29bd7-3025-499e-baad-0dac5e1478b5_Government_Seal_of_Bangladesh.svg.png?auto=compress,format',
            id: 'ZN8YNRIAACUAvitj',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 666,
              height: 375,
            },
            alt: 'Bangladesh Airlines logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c25c8790-e7f5-437e-b689-408edacb604a_Biman+Bangladesh+Airlines+Logo..png?auto=compress,format',
            id: 'ZdRwGhEAAC4APzZ-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$3cfa0dc7-4975-4a9b-aa86-f98bff29c240',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question:
            'What are the specifications for the passport-sized photos needed for the Bangladesh visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The passport-sized photos needed for the Bangladesh visa online application must be 35 x 45 mm, taken in colour, taken against a white background, and show your full face without any headgear (unless you wear it for religious reasons).',
              spans: [
                {
                  start: 84,
                  end: 94,
                  type: 'strong',
                },
                {
                  start: 102,
                  end: 111,
                  type: 'strong',
                },
                {
                  start: 129,
                  end: 145,
                  type: 'strong',
                },
                {
                  start: 161,
                  end: 170,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I track the status of my Bangladesh online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply through tryvisa, you can track your visa status by downloading the tryvisa app, going to your profile, and clicking on your visa to see its current status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'If you applied through a government channel, you can track your Bangladesh visa on the government website.',
              spans: [
                {
                  start: 53,
                  end: 79,
                  type: 'hyperlink',
                  data: {
                    id: 'ZPsOWhIAAPKldYH5',
                    type: 'article',
                    tags: ['BD', 'Asia', 'First-Time Travelers', 'Visa Essentials'],
                    lang: 'en-in',
                    slug: 'what-do-you-require-to-do-a-bangladesh-visa-check',
                    first_publication_date: '2023-09-08T12:37:53+0000',
                    last_publication_date: '2023-10-23T07:13:27+0000',
                    uid: 'bangladsh-visa-status-for-indians',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  start: 87,
                  end: 105,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.visa.gov.bd/ViewStatus.aspx',
                    target: '_blank',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way of getting a Bangladesh visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to apply for a Bangladesh visa is through tryvisa. You can complete the online application in under 10 minutes. Your visa will be processed once you submit the application and pay the fees. After your visa gets approved, you will receive your electronic visa.',
              spans: [
                {
                  start: 58,
                  end: 63,
                  type: 'hyperlink',
                  data: {
                    id: 'ZN8OdBIAACcAvgRs',
                    type: 'page',
                    tags: ['BD'],
                    lang: 'en-in',
                    slug: 'were-here-to-answer-all-your-questions',
                    first_publication_date: '2023-08-31T21:28:19+0000',
                    last_publication_date: '2024-02-20T09:26:15+0000',
                    uid: 'apply-bangladesh-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my Bangladesh visa submission is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is declined, you can submit another visa application. Before applying, double-check that all your documents are provided and the information is accurate.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '\nReapplying for your visa requires paying the visa fees again, as these fees are non-refundable through the government or visa portals. However, you can apply through tryvisa, which guarantees a refund of up to ₹8000 or $100 if your visa is rejected. Please be aware that this refund does not include any service fees.',
              spans: [
                {
                  start: 136,
                  end: 143,
                  type: 'strong',
                },
                {
                  start: 167,
                  end: 172,
                  type: 'strong',
                },
                {
                  start: 193,
                  end: 199,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for a Bangladesh visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advisable to apply for your Bangladesh visa 2 to 4 weeks before your planned travel to Bangladesh.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance required for a Bangladesh visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "While not mandatory, it's recommended to have travel insurance for your trip to Bangladesh. ",
              spans: [],
            },
          ],
        },
        {
          question: 'What happens after my Bangladesh visa online application has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Bangladesh online application has been approved, you will receive an email confirmation with your visa. You should print this out and carry it when you travel to Bangladesh.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can my Bangladesh tourist visa be extended?',
          answer: [
            {
              type: 'paragraph',
              text: 'yes, you can extend your Bangladesh tourist visa for an additional 30 days at the Department of Immigration and Passport in Bangladesh.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 67,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my Bangladesh visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are multiple factors that can cause a visa to be rejected. Here are some of the common factors that can cause your visa to be rejected:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'False documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not enough funds to cover your stay in Bangladesh.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Criminal record. (If your criminal record was a serious offence, there is a change your visa can be rejected)',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the Bangladesh visa price?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Bangladesh visa fee on tryvisa is ₹8500. ',
              spans: [
                {
                  start: 36,
                  end: 41,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Bangladesh with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Bangladesh tourist visa does not allow you to work in the country. If you wish to work in Bangladesh, you must first obtain a valid work visa for Bangladesh.',
              spans: [
                {
                  start: 138,
                  end: 162,
                  type: 'hyperlink',
                  data: {
                    id: 'ZPCKsxAAACQAzRO9',
                    type: 'article',
                    tags: ['BD', 'Asia', 'Visa Essentials'],
                    lang: 'en-in',
                    slug: 'do-indians-need-a-bangladesh-work-visa',
                    first_publication_date: '2023-08-31T12:43:56+0000',
                    last_publication_date: '2023-10-02T13:18:49+0000',
                    uid: 'bangladesh-work-visa-for-indian',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How long does the Bangladesh visa online application process take?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply through tryvisa, you can complete the online application process within 10 minutes. Once you have completed the application and paid the visa fees, your visa will be processed, which takes about 5-10 days.',
              spans: [
                {
                  start: 9,
                  end: 28,
                  type: 'hyperlink',
                  data: {
                    id: 'ZN8OdBIAACcAvgRs',
                    type: 'page',
                    tags: ['BD'],
                    lang: 'en-in',
                    slug: 'were-here-to-answer-all-your-questions',
                    first_publication_date: '2023-08-31T21:28:19+0000',
                    last_publication_date: '2024-02-20T09:26:15+0000',
                    uid: 'apply-bangladesh-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question:
            'What is the minimum passport validity required for a Bangladesh visa submission?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months from the date of entry into Bangladesh for a successful Bangladesh visa online submission.',
              spans: [
                {
                  start: 34,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I apply for a Bangladesh visa online on behalf of a friend or family member?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Bangladesh visa online on behalf of a friend or family member. You must have the necessary documents and details to complete the application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know when my Bangladesh visa online submission has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your Bangladesh visa online submission has been approved. ',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I pay for my Bangladesh visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for your Bangladesh visa application can be paid online through tryvisa using various payment methods, such as debit/credit card or an approved mobile wallet.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a physical visa stamp for the Bangladesh?',
          answer: [
            {
              type: 'paragraph',
              text: "No, the Bangladesh tourist visa is electronic, meaning there's no physical stamp.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Bangladesh visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply fast and hassle-free on the tryvisa online portal.',
              spans: [
                {
                  start: 42,
                  end: 61,
                  type: 'hyperlink',
                  data: {
                    id: 'ZN8OdBIAACcAvgRs',
                    type: 'page',
                    tags: ['BD'],
                    lang: 'en-in',
                    slug: 'were-here-to-answer-all-your-questions',
                    first_publication_date: '2023-08-31T21:28:19+0000',
                    last_publication_date: '2024-02-20T09:26:15+0000',
                    uid: 'apply-bangladesh-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0dccd815-820c-46e8-9faa-56afa0352b17',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Bangladesh',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 1800,
    // cost_max: 4050,
    // cost_min: 1180,
  },
  BH: {
    priority: 6,
    packages: [
      {
        id: 0,
        process_name: 'Bahrain 14 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '14 days',
        entry_validity: '30 days',
        visa_fees: 2500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Bahrain 30 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 5500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Bahrain',
    code: 'BH',
    hero_image_url:
      'https://images.unsplash.com/photo-1548755212-2b46ee259868?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8QmFocmFpbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'Bahrain E-Visa',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Tourist',
    // entry_length_stay: '14 days',
    // entry_validity: '90 days',
    // govt_fees: 2081,
    // service_fees: -2081,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          'A visa is required to visit Bahrain. This is single entry visa valid for 2 weeks',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '',
        description: 'Please upload your complete flight tickets',
        label: 'Flight Tickets',
        required: true,
        key: 'flight_ticket',
        only_b2b: true,
        source_url: '',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '',
        description: 'Please upload your hotel voucher',
        label: 'Hotel Details',
        key: 'Hotel',
        source_url: '',
        only_b2b: true,
        required: true,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 6 Months Bank Statements',
        description: 'Please upload 6 months Bank Statement minimum balance should be 1000 USD',
        label: 'Bank Statement',
        key: 'Bank_Statement',
        required: true,
        source_url: '',
        only_b2b: true,
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'appointment',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: true,
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'indonesia_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport_back',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'shipping_labels',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_insurance',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 235,
              height: 110,
            },
            alt: 'Bahrain government forum logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/679721f5-40de-481a-8747-a2f46615a791_image.png?auto=compress,format&rect=0,2,250,117&w=235&h=110',
            id: 'ZK-9iRIAACAAaSBK',
            edit: {
              x: 0,
              y: -1,
              zoom: 0.94,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 400,
              height: 504,
            },
            alt: 'Bahrain Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6dbb5efd-8615-4d4d-8f8d-bd44910b6aa7_Bahrain+Ministry+of+Foreign+Affairs.png?auto=compress,format',
            id: 'ZdSwThEAACwAQF0m',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 50,
            },
            alt: 'Bahrain Economic Development Board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/961009e7-60ab-42b1-9424-1e01a584d01d_image+%281%29.png?auto=compress,format',
            id: 'ZK-9iRIAACEAaSBJ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1136,
              height: 219,
            },
            alt: 'Bahrain International airport logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a0032646-d62c-4790-8737-a72dd98eb35e_Bahrain-International-Airport.png?auto=compress,format',
            id: 'ZdStgBEAACwAQFBW',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 220,
              height: 242,
            },
            alt: 'Bahrain coat of arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/285d3486-63cd-4a54-bf7d-a54dd177cc1f_image+%283%29.png?auto=compress,format',
            id: 'ZK-9pxIAACIAaSDk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 431,
            },
            alt: 'Gulf Air logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b8a26c0a-2d4b-43ca-a103-4275136b0100_image+%284%29.png?auto=compress,format',
            id: 'ZK--FBIAACIAaSLu',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$32deedcb-7806-439c-bf5a-d5c42bdad9f9',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need to apply for a Bahrain visit visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'For the required documents for Bahrain evisa, are a valid passport, a passport-size photo, and flight & hotel details.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check my Bahrain visit visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'Log in to your account using your username and password. Navigate to the "Applications" tab on the top menu. You can keep track of your Bahrain visa application status. The status ranges from "Submitted" to "Approved."',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the photo specifications for the Bahrain visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The photo for a Bahrain visa application should typically be a recent passport-size photograph with a white background. tryvisa has a photo visa tool that will ensure you meet the photo requirements.',
              spans: [
                {
                  start: 16,
                  end: 40,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/bahrain-evisa-for-indians',
                    target: '_blank',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'Can a Bahrain e-visa be used at any entry point into Bahrain?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Bahrain e-visa is typically valid at all international entry points into Bahrain. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are provided and you meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Instead, you can apply through tryvisa, where you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. This refund will not include the service fee.',
              spans: [
                {
                  start: 122,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 158,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'What if my Bahrain e-visa is not granted on time, and I have already planned my journey?',
          answer: [
            {
              type: 'paragraph',
              text: "If your Bahrain e-visa is not granted on time, it's recommended not to travel until approved. You might be denied entry into Bahrain without a valid visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'Is health insurance required for a Bahrain visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, it is not compulsory to have travel insurance to travel to Bahrain. However, it is always recommended that you have travel insurance just to safeguard yourself in the face of any trouble.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Bahrain visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, applying for a Bahrain visa online is safe, especially if you use tryvisa. tryvisa ensures always to protect your personal information when applying.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Bahrain e-visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Bahrain e-visa application is denied, you should receive an explanation. You can correct the issue and reapply or, in some cases, appeal the decision.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is biometric data required for the Bahrain visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Biometric data is not required for Bahrain visa applications on tryvisa. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the process for an online visa application to Bahrain?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Bahrain online visa application process is simple and efficient when you use tryvisa for your visa application. It involves filling out an application form, submitting the necessary documents, paying the visa fee, and then waiting for the visa approval.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Bahrain visa if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'The impact of a criminal record on your Bahrain visa application depends on the nature of the crime. Serious crimes may result in your visa application being denied.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to get a Bahrain visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The online processing time for a Bahrain visa takes 10 business days when you apply through tryvisa. Make sure to apply before your travel dates to accommodate any unexpected delays.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Bahrain visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Bahrain visa online. You can complete it quickly, easily, and efficiently from home. To apply for a Bahrain visit visa, you can submit an online application on tryvisa. You can fill in your application form, submit the required documents, pay visa fees, and submit.',
              spans: [],
            },
          ],
        },
        {
          question: "What's the price of a Bahrain tourist visa?",
          answer: [
            {
              type: 'paragraph',
              text: 'The price of a Bahrain tourist visa is ₹2,550. You can pay it on the online application portal with your debit or credit card, or approved mobile wallets. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to submit my passport for the Bahrain online visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'For an online visa application, you typically need to upload a scanned copy of your passport rather than submitting the physical document.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is a visa guarantee of entry into Bahrain?',
          answer: [
            {
              type: 'paragraph',
              text: "No, a visa does not guarantee entry into Bahrain. It's ultimately up to the immigration officers at the port of entry to allow or deny admission.",
              spans: [],
            },
          ],
        },
        {
          question: 'Do minors need a separate Bahrain visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, minors, even if traveling with their parents, On tryvisa, you can submit your family application. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the consequences of overstaying my Bahrain visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Overstaying your Bahrain visa can result in serious consequences, including fines, deportation, or a ban from re-entry. It's important to abide by the validity of your visa.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$5f184703-7a1f-47a1-bfc0-934841397df3',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Bahrain',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2500,
    // cost_max: 0,
    // cost_min: 0,
  },
  MY: {
    priority: 7,
    visa_free_message: 'Visa Free till 31 Dec 2024',
    packages: [
      {
        id: 0,
        process_name: 'Malaysia 30 days Single Entry Free Visa',
        supported: true,
        message: 'Visa Free till 31 Dec 2024',
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '30 days',
        visa_fees: 3300,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Malaysia',
    code: 'MY',
    hero_image_url:
      'https://images.unsplash.com/photo-1506929562872-bb421503ef21?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fE1hbGF5c2lhfGVufDB8fDB8fHww',
    process: 'digital',
    // process_name: 'Malaysia Electronic Travel Authorization',
    supported: false,
    // message:
    //   'Malaysia e-visas have been temporarily suspended \nWe will restart support for Malaysia once the Malaysian embassy opens up the processing of e-visas',
    // eta_post_checkout_duration: 6,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 3,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Single Entry',
    // entry_length_stay: '30 days',
    // entry_validity: '30 days',
    // govt_fees: 250,
    // service_fees: 1900,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Malaysia E-Visa',
        description:
          'A visa is required to visit Malaysia. This is an e-visa so you can get it directly on the tryvisa app without having to visit anywhere in-person. ',
      },
    ],
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'text',
        required_doc: 'Hotel Name',
        description: "Please share your Hotel/Accomodation place's Name",
        label: 'Hotel/Accomodation Name',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'hotel_name',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'covid_vaccine',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['departure_flight_required', 'return_flight_required'],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'identity',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'india_itr',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'indonesia_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'notary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'occupation',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'pan_card',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        currency: 'INR',
        toggle: true,
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['old_passport_number'],
        currency: 'INR',
        toggle: false,
        key: 'passport_back',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_lease_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_nif',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'shipping_labels',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_insurance',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1024,
              height: 768,
            },
            alt: 'Malaysia coat of arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/37a5e0e4-33bb-4a3a-b1a7-4b582c7844a2_Coat_of_arms_of_Malaysia.png?auto=compress,format',
            id: 'ZdW_4hEAACcARRjS',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1769,
              height: 2048,
            },
            alt: 'Malaysia Immigration Department logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/992f0984-c6c3-4f40-9bd8-3a32eb84a6bf_Logo_of_Department_of_Immigration_Malaysia.png?auto=compress,format',
            id: 'ZdXAvREAACsARRze',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 893,
              height: 279,
            },
            alt: 'Malaysia e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c0a839c2-7b1c-4dc1-9943-3b23119d9b99_Malaysia+e-visa+logo.png?auto=compress,format',
            id: 'ZdXBNxEAACwARR88',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 360,
              height: 360,
            },
            alt: 'Malaysia tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/03371eec-d29b-4f73-b75e-eccb218fe9aa_Malaysia_tourism_board_logo.png?auto=compress,format&rect=10,7,339,339&w=360&h=360',
            id: 'ZdXB_xEAACkARSLU',
            edit: {
              x: -10,
              y: -7,
              zoom: 1.062742796361443,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$6132cdfb-79e6-4e09-b737-e7cf2ea6c2b8',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for an online visa for Malaysia from India?',
          answer: [
            {
              type: 'paragraph',
              text: "When applying for the Malaysia eVisa, you'll need a valid passport, passport-sized photos, and flight & hotel details.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the Malaysia visa photo size requirement, and can I do it online for free?',
          answer: [
            {
              type: 'paragraph',
              text: 'The standard photo size is 35mm x 50mm. tryvisa will adjust your photo to meet the required photo size for free.',
              spans: [],
            },
          ],
        },
        {
          question: 'Who is a well-regarded Malaysia visa agent?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa is one of the numerous agents that provide visa services. With tryvisa handling your visa application, you can save precious time that would otherwise be spent on gathering documents and filling out forms. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I trust online Malaysia visa agents?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Malaysia visa agents such as tryvisa have a secure platform to protect your sensitive data during the application process. They comply with all data security laws to ensure your information is safe.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is the process to apply for an online visa for Malaysia from India straightforward?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the process is quite straightforward. tryvisa simplifies the visa application process, making it efficient and hassle-free.',
              spans: [],
            },
          ],
        },
        {
          question: 'Where can I get assistance to apply for a Malaysia tourist visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "tryvisa will assist you in reviewing your application to ensure it's correctly filled out before submission. This greatly reduces the chance of mistakes that might lead to application rejection.",
              spans: [],
            },
          ],
        },
        {
          question: 'What are the consequences of providing an incorrect Malaysia visa photo size?',
          answer: [
            {
              type: 'paragraph',
              text: 'Providing an incorrect photo size may lead to application delays or rejections. Always ensure your photo meets the stated requirements.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check the status of my Malaysia visa application online?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa updates you about your application status and helps you track your progress by sending timely messages and notifications. You can log into your online portal to stay up tp date with your application. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Malaysia visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'After visa rejection, you will receive the reasons for the rejection reasons. Fix these reasons and reapply for your visa. Unfortunately, you will lose the funds when applying through the government or online portals. However, through tryvisa, you will receive a refund on visa rejections. The refund will be up to ₹8000 or $100, excluding the service fees.',
              spans: [
                {
                  start: 218,
                  end: 225,
                  type: 'strong',
                },
                {
                  start: 235,
                  end: 240,
                  type: 'strong',
                },
                {
                  start: 261,
                  end: 267,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: "What's the fastest way to get an online visa for Malaysia from India?",
          answer: [
            {
              type: 'paragraph',
              text: 'The fastest way is often through an expedited service, available through certain visa agents or sometimes directly from the official immigration website.',
              spans: [],
            },
          ],
        },
        {
          question: "What's the easiest way to apply for a Malaysia visa?",
          answer: [
            {
              type: 'paragraph',
              text: 'Applying online is generally the easiest way. You can do this either through tryvisa or the official immigration website.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Malaysia visa online over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, online visa applications are generally available 24/7. However, processing times may still depend on business days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the common reasons for a Malaysia visa application rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'The common reasons for the Malaysia visa to be rejected could include',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect or incomplete documentation',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of a valid reason for travel',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Malaysia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for a Malaysian visa online. Ensure you have all the required documents. Download the App and fill out the application form.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the steps to apply for a Malaysia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Start by gathering your documents, completing the application form, paying the fees, and submitting the form online or at the visa center.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Malaysia visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply through tryvisa; ensure you have the necessary documents ready. You’ll complete your application in 5 minutes.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the fees when I apply for a Malaysia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Malaysia visa fee is INR 3,600, and tryvisa has no hidden fees. These costs are subject to change, and there might be visa processing fees for Indian citizens, so I recommend you check the visa fees before applying.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Malaysia tourist visa online from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for a Malaysia tourist visa online. Make sure to check the specific requirements for Indian nationals.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to apply for a Malaysia visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Malaysia processing time is 4 days. tryvisa efficiently handles fee payments as part of their visa processing services. This can save you time and effort.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$701803e7-d9ea-4579-89e5-9cc5d834d6c9',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Malaysia',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    price: 3300,
    // cost_max: 4460,
    // cost_min: 1400,
  },
  LK: {
    priority: 8,
    packages: [
      {
        id: 0,
        process_name: 'Sri Lanka 30 days Single Entry ETA',
        supported: true,
        message: null,
        eta_post_checkout_duration: 2,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 1400,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'ETA',
      },
    ],
    name: 'Sri Lanka',
    code: 'LK',
    hero_image_url:
      'https://images.unsplash.com/photo-1580910527739-556eb89f9d65?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8U3JpJTIwTGFua2F8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    // process_name: 'Sri Lanka Electronic Travel Authorization',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: 6,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '120 days',
    // govt_fees: 250,
    // service_fees: 2180,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'ETA',
        description: 'An Electronic Travel Authorization (E-visa) is required to visit Sri Lanka.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required', 'marital_status_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 188,
              height: 267,
            },
            alt: 'Sri Lanka Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a6509918-cc60-4e75-b132-76fa45933a58_Sri+Lanka+Ministry+of+foreign_affairs..png?auto=compress,format',
            id: 'ZdT7fBEAACcAQbOh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 5500,
              height: 3620,
            },
            alt: 'SriLankan Airlines',
            copyright: null,
            url: 'https://images.prismic.io/atlys/66a28a17-277f-4b4b-abad-cb1c0b5ed605_image+%286%29.png?auto=compress,format',
            id: 'ZK_xmBIAACEAafWs',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 225,
            },
            alt: 'Sri Lanka tourism development logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/87ff396a-5423-4b87-a9ea-6ae9179e9ed4_Sri+Lanka+Tourism+Board..png?auto=compress,format',
            id: 'ZdT8ohEAACkAQbkl',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$61ac877f-cb61-4ce2-be0a-54d6a8db0f77',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required for a Sri Lanka visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents required for a visa application include a valid passport and additional documents such as proof of accommodation and return flight tickets.',
              spans: [],
            },
          ],
        },
        {
          question:
            'How long does it take to get a visa for Sri Lanka after the application has been submitted?',
          answer: [
            {
              type: 'paragraph',
              text: 'Sri Lanka processed instantly on tryvisa. At tryvisa, we understand the value of time. Our team works diligently to process applications on time. We also offer expedited services for urgent visa needs.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to book my flight before applying for a Sri Lanka visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's not required, But having a confirmed flight booking can increase the chances of visa approval as it shows your intent to return.",
              spans: [],
            },
          ],
        },
        {
          question: 'How will I receive my Sri Lanka visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Sri Lanka visa application is approved, the e-visa will be sent to the email address you provided during the application process. You can also download the visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Sri Lanka with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a tourist visa does not permit you to work in Sri Lanka. If you intend to work, you must apply for a specific work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens after I submit my Sri Lanka visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "After submission, your application will be processed. Once you submit your application, we provide regular updates on the application status, ensuring you're never left in the dark about your visa processing.",
              spans: [],
            },
          ],
        },
        {
          question: 'What if my Sri Lanka visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Note that the refund excludes the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to print out my Sri Lanka e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, It is recommended that you print out your e-visa confirmation. While the Sri Lanka immigration authorities will have a record of your e-visa, having a printed copy can expedite your entry process.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the current Sri Lanka visa cost from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The cost of a Sri Lanka visa is INR 2,130. With tryvisa, there are no hidden charges. The Sri Lanka visa cost is clearly stated, and any additional charges for optional services are explicitly mentioned.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is submitting personal documents online for the Sri Lanka visa application safe?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, our online visa application portal is secure. We value your privacy and ensure that all your personal and sensitive documents are safe with us.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my Sri Lanka visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You should apply for your Sri Lanka visa at least two weeks before your intended travel date to account for any potential processing delays.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance necessary for a Sri Lanka visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Travel insurance isn't compulsory for a Sri Lanka visa, but it is highly recommended for any unforeseen medical or travel emergencies.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I start my Sri Lanka visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can start your Sri Lanka visa application through our secure online portal. Simply fill out the necessary information. tryvisa provides comprehensive information about the Sri Lanka visa cost, the application process, required documents, and more, helping you understand the process better.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to physically visit a visa agent for my Sri Lanka visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can conveniently apply for a Sri Lanka visa online through tryvisa. tryvisa provides a secure online platform where you can conveniently apply for your visa anywhere.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to submit my passport for the Sri Lanka visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "You don't need to submit your passport for the online visa application. However, you need a valid passport to apply for a visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I visit other countries and then return to Sri Lanka on the same visa?',
          answer: [
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'No, you can’t visit other countries with a Sri Lanka visa. You can look at this blog to see what you can do with the visa to Sri Lanka.',
              spans: [
                {
                  start: 117,
                  end: 135,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/sri-lanka-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question:
            "Are there any special requirements for children's Sri Lanka visa applications?",
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, children also require a visa to enter Sri Lanka. The application process is similar to adults, but additional documentation may be required, like a letter of consent from both parents.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the payment methods accepted for the Sri Lanka visa cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'We accept various payment methods, including credit cards, debit cards, and digital wallets.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the common reasons for a Sri Lanka visa application rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Common reasons for visa application rejections include incomplete applications or invalid travel documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'Where can I apply for a visa for Sri Lanka?',
          answer: [
            {
              type: 'paragraph',
              text: "You can apply for a Sri Lanka visa through our user-friendly online portal. It's designed to make your application process simple and quick. tryvisa offers trusted and reliable visa services for Sri Lanka.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$b40fa5f5-5be3-4bb7-aa29-eb0ed6b843c9',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Sri Lanka',
    purpose: 'tourism',
    // sticker_visa: false,
    // appointment: false,
    price: 1400,
    // cost_max: 2420,
    // cost_min: 1680,
  },
  KE: {
    priority: 9,
    packages: [
      {
        id: 0,
        process_name: 'Kenya 30 days Single Entry ETA',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 3100,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'ETA',
      },
    ],
    name: 'Kenya',
    code: 'KE',
    hero_image_url:
      'https://images.unsplash.com/photo-1611348524140-53c9a25263d6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8S2VueWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    // process_name: 'Kenya ETA',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: 5,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 2900,
    // service_fees: 2100,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          "An e-visa is required to visit, regardless of the duration. The e-visa is checked on departure, and you're required to get an e-visa before your trip. You cannot get an e-visa on arrival.",
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'notary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['validity_required', 'image_required'],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        key: 'passport_back',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'shipping_labels',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_insurance',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_itinerary',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 614,
              height: 607,
            },
            alt: 'Republic of Kenya logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8a961c6d-80ef-4f3a-848b-7962ab1ee81b_Kenya+Government.png?auto=compress,format',
            id: 'ZIrhwhAAACIAsswB',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 179,
              height: 169,
            },
            alt: 'Kenya coat of arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/daecfb42-a9b0-4b3a-af84-508300823959_Coat_of_arms_of_Kenya_%28Official%29.svg.png?auto=compress,format',
            id: 'ZIriIhAAACMAss2q',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 670,
              height: 372,
            },
            alt: 'Kenya Tourism Board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e66d112c-00ba-47db-8d49-98e3212b4413_Kenya-Tourism-Board.png?auto=compress,format',
            id: 'ZdRtnBEAACcAPyPO',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 438,
              height: 110,
            },
            alt: 'Kenya e-citizen logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6d5a5434-b0a2-4a6b-bb4f-00c813a67f45_Kenya+e-citizen+logo.png?auto=compress,format',
            id: 'ZdRvDBEAACgAPy_S',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 666,
              height: 375,
            },
            alt: 'Kenya airways logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6cc0fcc5-b909-40cd-800c-7b169fd07ab1_Kenya+Airways+logo..png?auto=compress,format',
            id: 'ZdXqhBEAACcARdx-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$99cd9924-4995-49ce-8716-5ac2557e933b',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Kenya visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When apply for your Kenya visa online, you only need the following documents:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A travel itinerary. If you don't have one, you can let tryvisa generate one for you.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I track the status of my Kenya visa?',
          answer: [
            {
              type: 'paragraph',
              text: "To track your visa status, go to the tryvisa app or online portal and go to your profile. There, you'll be able to see the status of your visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Kenya e-visa if I am not an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the Kenya e-visa is available to citizens of eligible countries, not limited to Indian citizens. It is advisable to check the official e-visa website of the Kenyan government to confirm the eligibility criteria for your specific nationality.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it necessary to provide a hard copy of the e-visa when traveling to Kenya?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, carrying a printout or a digital copy of the e-visa approval email is necessary. This will serve as proof of your valid visa and facilitate the immigration process upon arrival in Kenya.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for my child’s visa on their behalf?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can. If you choose to apply through tryvisa, you can apply for a visa for the whole family.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Note that the refund excludes the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You’ll receive your visa via the registered email. You can then download and print the visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it possible to apply for a Kenya e-visa if I am already in Kenya?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the Kenya e-visa must be obtained prior to your arrival in Kenya. It is not possible to apply for an e-visa once you are already in the country. Make sure to complete the e-visa application process and receive the e-visa approval before your trip to Kenya.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the processing time for a Kenya embassy visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "The processing time for a Kenya visa for Indians is 3 business days if you apply through tryvisa. tryvisa guarantees your money back if your visa isn't processed within the estimated processing time.",
              spans: [
                {
                  start: 26,
                  end: 48,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/kenya-e-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I apply for a Kenya e-visa if I am traveling for volunteer work or a charity project?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Kenya e-visa if you are traveling for volunteer work or a charity project. The e-visa covers various travel purposes, including tourism, business, and volunteer activities. Select the appropriate visa category and provide accurate information regarding your travel purpose during the application process.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What should I do if I need to change my travel dates after obtaining a Kenya visa?',
          answer: [
            {
              type: 'paragraph',
              text: "If you need to change your travel dates after obtaining a Kenya visa, you can contact tryvisa for some insight on what to do. However, it's possible that you'll have to apply for a new visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work or engage in employment activities with a Kenya tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Kenya tourist visa does not permit you to work or engage in employment activities in the country. If you intend to work in Kenya, you would need to apply for an appropriate work visa or permit through the relevant immigration authorities.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I apply for a Kenya e-visa if I have previously visited other countries with e-visa systems?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, having previously visited other countries with e-visa systems does not generally impact your eligibility for a Kenya e-visa. Each country has its own visa requirements and processes, and the eligibility for a Kenya e-visa is primarily based on your nationality. It is recommended to check the official e-visa website of Kenya for the most accurate and up-to-date information.',
              spans: [],
            },
          ],
        },
        {
          question:
            "Can I apply for a Kenya visa if I have a layover in Kenya but don't intend to leave the airport?",
          answer: [
            {
              type: 'paragraph',
              text: "If you have a layover in Kenya and don't intend to leave the airport, you generally do not need to apply for a Kenya visa. However, suppose you plan to exit the airport or have an extended layover that requires you to pass through immigration. In that case, it is advisable to check the specific visa requirements based on your nationality.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Do You Have Questions In Mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$a225d595-2b90-4f89-b7f3-ea912ca2aa60',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Kenya',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 3100,
    // cost_max: 5464,
    // cost_min: 4250,
  },
  ZA: {
    priority: 10,
    packages: [
      {
        id: 0,
        process_name: 'South Africa 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 6,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 4000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'South Africa',
    code: 'ZA',
    hero_image_url:
      'https://images.unsplash.com/photo-1484318571209-661cf29a69c3?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8U291dGglMjBBZnJpY2F8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    // process_name: 'South Africa E-Visa',
    supported: true,
    // message: "Currently unsupported\nWe're working on supporting more countries.",
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 120,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist Sticker Visa',
    // entry_length_stay: '30 days',
    // entry_validity: '30 days',
    // govt_fees: 4800,
    // service_fees: -4800,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa',
        description: 'A visa is required to enter South Africa',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 3 Months Bank Statments Stamped',
        description:
          'Please upload 3 Months Bank Statements Stamped and Signed by the Bank Manager with Minimum Balance of 5 Lakh Rupees, all pages stamped',
        label: '3 Months Bank Statement Stamped',
        key: 'Bank_Statements',
        source_url: '',
        only_b2b: true,
        required: true,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '1 Month Salary Slip',
        description: 'Please upload 1 month Salary Slip / GST Certificate',
        label: 'Salary Slip',
        required: true,
        source_url: '',
        only_b2b: true,
        key: 'Salary_Slip',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['employment_letter_required'],
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: true,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 390,
              height: 390,
            },
            alt: 'Ministry of foreign affairs for South Africa',
            copyright: null,
            url: 'https://images.prismic.io/atlys/025a232a-d594-4594-ac1a-4d0d4f20b3ff_Ministry+of+foreign+affairs+South+Africa..png?auto=compress,format',
            id: 'ZdUbSxEAACwAQkSZ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 300,
            },
            alt: 'Republic of South Africa Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2ddde274-4029-4980-a888-995f7d738c75_Republic+of+South+Africa..png?auto=compress,format',
            id: 'ZdUdpREAAC4AQk8l',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 475,
            },
            alt: 'South African Airways',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b5c4735b-8178-429e-9aba-9aa9db58ddd7_SAA_logo_%282019%29.svg.png?auto=compress,format',
            id: 'ZN9ifBIAACcAv3aB',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$96a69302-fe41-482a-82d1-84d86bd1d0e2',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the documents required for the South Africa Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying on tryvisa for your South Korea visa for Indians, the required documents include a valid passport, flight itinerary, and a passport-sized photo and other documents as stated by the tryvisa.',
              spans: [
                {
                  start: 95,
                  end: 127,
                  type: 'strong',
                },
                {
                  start: 149,
                  end: 155,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for a South Africa visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a South Africa visa for Indians, you require a travel insurance. But having travel insurance when visiting other countries is always a good idea.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I track my South Africa visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After submitting your South Africa visa application with tryvisa, you can track its status through tryvisa’s online portal. Simply visit the tryvisa app and click on the dashboard on the right-hand side. You will be able to check the status of your visa application.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it necessary to book a flight before applying for a South Africa visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: "While it is not always necessary, some visa applications require proof of return travel. It's recommended to check this requirement on the official South Korea visa online website or consult a South Africa visa agent.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in South Africa as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'The South Africa visa allows Indian citizens to stay in the country for up to 60 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure all your documents are correct and meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them, except tryvisa. Apply through tryvisa, where you are guaranteed a refund if your visa is denied. This refund excludes service fees and can be up to ₹8000 or $100.',
              spans: [
                {
                  start: 150,
                  end: 155,
                  type: 'strong',
                },
                {
                  start: 171,
                  end: 181,
                  type: 'strong',
                },
                {
                  start: 184,
                  end: 190,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a South Africa visa online from any country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the South Africa visa online system is made accessible by tryvisa. You can apply from anywhere in the country if you have a reliable internet connection and the necessary documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'How safe is it to apply for a South Africa visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "Applying for a South Africa visa online is safe if you use tryvisa’s visa portal or the government site. tryvisa’s website uses 256-bit Advanced Encryption Standard to ensure your data is protected from security breaches, and we do not sell your data to third-party companies. Plus, we've a strong logistics and supply chain services to keep your documents safe for handling and submissions.",
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a South Africa visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a South Africa visa at least one moth and 6 months before your intended travel date to allow ample time for processing. ",
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I travel to other countries from South Africa on a South Africa tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Japan tourist visa allows you to travel within South Africa only. If you wish to travel to other countries from South Korea, you will need the relevant visas for those countries.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I pay the fees for a South Africa e visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Fees for a South Africa visa online can be paid with a credit or debit card or a mobile wallet during the application process.',
              spans: [],
            },
          ],
        },
        {
          question:
            "What happens if my South Africa tourist visa expires while I'm in South Africa?",
          answer: [
            {
              type: 'paragraph',
              text: 'Suppose your South Africa tourist visa expires while in the country. You should contact the local immigration office immediately to avoid legal issues. They may grant a visa extension, or you may be required to leave the country.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What health precautions should I take before travelling to South Africa on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's advisable to check with your doctor or a travel health clinic about any recommended vaccinations or other health precautions before travelling to South Africa. Also, consider purchasing travel health insurance for your trip.",
              spans: [],
            },
          ],
        },
        {
          question: 'How Do I Get A Visa For South Africa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can get a South Africa visa digitally and the process is straightforward. Visit tryvisa’s online portal, complete the application form, upload the required documents, and submit. Then, make the necessary payments and wait for the visa approval.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in South Africa with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a South Africa tourist visa does not permit the holder to undertake paid work in South Africa. If you intend to work in South Africa, you must apply for a work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a South Africa visa online on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for digital South Africa on behalf of your family through tryvisa's website. Simply add the required information for your family members and submit it on the portal. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a visa upon arrival in South Africa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, South Africa does not offer visa-on-arrival for Indian citizens. It's essential to obtain a visa before travelling to the country.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$fed66994-9f4b-421f-9b86-027dc951d440',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'South Africa',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 4000,
    // cost_max: 0,
    // cost_min: 0,
  },
  RU: {
    priority: 11,
    packages: [
      {
        id: 0,
        process_name: 'Russia 16 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 6,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '16 days',
        entry_validity: '60 days',
        visa_fees: 5000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Russia 16 days Single Entry Stamp Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 6,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '16 days',
        entry_validity: 'As per Embassy',
        visa_fees: 12711,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 2,
        process_name: 'Russia 30 days Single Entry Stamp Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 6,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: 'As per Embassy',
        visa_fees: 16542,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
    ],
    name: 'Russia',
    code: 'RU',
    hero_image_url:
      'https://images.unsplash.com/photo-1547448415-e9f5b28e570d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8UnVzc2lhfGVufDB8fDB8fHww',
    process: 'digital',
    // process_name: 'Russia E-Visa',
    supported: true,
    // message: null,
    // eta_post_checkout_duration: 6,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Tourist',
    // entry_length_stay: '16 days',
    // entry_validity: '60 days',
    // govt_fees: 4894,
    // service_fees: 1536,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa',
        description: 'You can get an e-visa for Russia.',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1200,
            },
            alt: 'Emblem of the CEC of Russia logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e21c6233-4bfa-4e77-bcc0-1696c80146d7_image+%2812%29.png?auto=compress,format',
            id: 'ZNDtXhEAACIAf4VQ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 920,
              height: 600,
            },
            alt: 'Moscow State Institute of International Relations logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/72578488-c147-44b8-a6eb-d958963f444e_png-transparent-moscow-state-institute-of-international-relations-diplomatic-academy-of-the-ministry-of-foreign-affairs-of-the-russian-federation-organization-the-ministry-of-foreign-affairs-of-russia.png?auto=compress,format&rect=49,137,826,539&w=920&h=600',
            id: 'ZNDtbREAACMAf4WW',
            edit: {
              x: -54,
              y: -152,
              zoom: 1.1132287398576735,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3840,
              height: 2400,
            },
            alt: 'Aeroflot Russian Airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b2aaebc8-922f-4e83-9fda-5e040f8af7cf_image+%2813%29.png?auto=compress,format&rect=146,52,3578,2236&w=3840&h=2400',
            id: 'ZNDteREAACAAf4XP',
            edit: {
              x: -156.70980908250806,
              y: -55.81445254993438,
              zoom: 1.0733548567295073,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 637,
              height: 392,
            },
            alt: 'Russia Government logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/23503049-f5b1-4150-89e6-5ab997c5b549_Russi+Government+Logo.png?auto=compress,format',
            id: 'ZdSgQhEAACkAQBWh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$b15642d0-1505-4d28-8f82-9e21f5862e83',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Russia e visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents you need are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A scanned copy of your passport's back page.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Russia e-visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Russia e-visa application can vary, but it typically takes around 7 days. tryvisa guarantees your money back if your visa takes longer than the estimated processing time.',
              spans: [
                {
                  start: 92,
                  end: 98,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Is it mandatory to provide a flight itinerary when applying for a Russia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is generally required to provide a flight itinerary when applying for a Russia e-visa. The Russian authorities want to ensure that you have a confirmed plan to enter and exit the country within the visa validity period. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of a Russia tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period of a Russia tourist visa can vary. Generally, a single-entry tourist visa is valid for 60 days. The stay time is 16 days from the date of entry into Russia.',
              spans: [
                {
                  start: 107,
                  end: 114,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 140,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my Russia e-visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Russia e-visa application is rejected, review the reasons for the rejection and correct those reasons. Reapply for your visa once all the reasons are corrected. When applying through the government or online portals, you will not be refunded, which causes you to lose funds. However, on tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on denied visas. Please note this refund excludes any service fees.',
              spans: [
                {
                  start: 283,
                  end: 290,
                  type: 'strong',
                },
                {
                  start: 295,
                  end: 300,
                  type: 'strong',
                },
                {
                  start: 322,
                  end: 329,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is there an age limit for applying for a Russia tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There is no specific age limit for applying for a Russia tourist visa. Individuals of all ages can apply for a tourist visa as long as they fulfil the necessary requirements and provide the required documents.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Are there any specific health requirements or vaccinations needed for travel to Russia?',
          answer: [
            {
              type: 'paragraph',
              text: 'While there are no mandatory vaccinations for entering Russia, it is advisable to check with a healthcare professional or travel health clinic for any recommended vaccinations based on your health status and the areas you plan to visit. ',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Russia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Russia e visa through the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Russia tourist visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Russia tourist visa online through tryvisa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I travel to Russia with a copy of the e-visa, or do I need to carry the original?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advisable to carry the original e-visa when traveling to Russia. While a digital or printed copy might be accepted in some cases, having the original e-visa document ensures a smoother entry process at immigration checkpoints.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Russia e-visa is lost or damaged?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Russia e-visa is lost or damaged, you can download a new copy from the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of a Russia tourist visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa cost through tryvisa is ₹6700. Please note that this visa fee is non-refundable.',
              spans: [
                {
                  start: 31,
                  end: 36,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Russia on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can stay a maximum of 16 days with a Russia for Indians.',
              spans: [
                {
                  start: 26,
                  end: 33,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I travel to multiple cities within Russia with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, with a tourist visa to Russia, you can travel to multiple cities within the country. The tourist visa allows you to explore various destinations and enjoy the cultural, historical, and natural attractions Russia offers.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4785ba9d-cd79-40a5-98a5-5c5b4621aaa2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Russia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 5000,
    // cost_max: 8050,
    // cost_min: 5680,
  },
  EG: {
    priority: 12,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Egypt 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 7,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 2860,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Egypt 30 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 5,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 9400,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Egypt',
    code: 'EG',
    hero_image_url:
      'https://images.unsplash.com/photo-1553913861-c0fddf2619ee?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8RWd5cHR8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    // process_name: 'Egypt Single Entry E-Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 5,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 50,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 2350,
    // service_fees: 1600,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          "An e-visa is required to visit, regardless of the duration. The e-visa is checked on departure, and you're required to get an e-visa before your trip. You cannot get an e-visa on arrival.",
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'appointment',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'indonesia_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'occupation',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required', 'last_name_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'passport_back',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'photo',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_lease_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_bank_statements',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 780,
              height: 780,
            },
            alt: 'Egypt Tourism & Antiquities',
            copyright: null,
            url: 'https://images.prismic.io/atlys/7f15b53f-600b-4e3d-8f6f-0468ccab19e6_image+%282%29.png?auto=compress,format&rect=20,31,700,700&w=780&h=780',
            id: 'ZK-erBIAACMAaJKe',
            edit: {
              x: -22.29999999999996,
              y: -34.56499999999994,
              zoom: 1.114999999999998,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3000,
              height: 2000,
            },
            alt: 'Egypt Air',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c7412079-7951-48b8-ba4b-bff8cef02b3c_image+%281%29.png?auto=compress,format',
            id: 'ZK-e6xIAACEAaJO4',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 942,
            },
            alt: 'Air Arabia',
            copyright: null,
            url: 'https://images.prismic.io/atlys/69527721-299c-431b-953c-f156d2b3e5ae_image+%281%29.png?auto=compress,format',
            id: 'ZK-_2BIAACMAaSuk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 815,
            },
            alt: 'Egypt Ministry of Foreign Affairs logo\n',
            copyright: null,
            url: 'https://atlys.cdn.prismic.io/atlys/da40ffde-722e-4087-9a2a-42e05b78923c_Egypt+Ministry+of+Foreign+Affairs.svg',
            id: 'ZdNJqhIAACcA82YU',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 667,
              height: 374,
            },
            alt: 'Egypt e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4fcdea96-a0c4-4c93-910c-de088210d923_Egypt+e-visa+logo.png?auto=compress,format',
            id: 'ZdRPQhEAACgAPnIs',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$4f2fdf07-b269-4465-9b01-37727bae8e07',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required to apply for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents required to apply for an Egypt visa on tryvisa will only include a valid passport and a digital photo.',
              spans: [
                {
                  start: 78,
                  end: 93,
                  type: 'strong',
                },
                {
                  start: 100,
                  end: 113,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the required photo size for an Egypt visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The photo size required for an Egypt visa application is typically 4.5 cm x 4.5 cm.',
              spans: [
                {
                  start: 67,
                  end: 83,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for a visa before my intended travel dates?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply for a visa well before your intended travel dates, typically 7 days before your trip. This allows sufficient time for processing, document gathering, and any unforeseen delays.',
              spans: [
                {
                  start: 88,
                  end: 94,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Where can I download the Egypt visa application form?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Egypt visa application form can be sent to you via email on tryvisa or downloaded from the tryvisa app. You can check out the Egypt visa for Indians to find out more about the visa application form.',
              spans: [
                {
                  start: 126,
                  end: 148,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/egypt-e-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How do I track the status of my visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "You can track the status of your visa application by using the tracking number provided during the application submission process. You can check the official website of the relevant embassy/consulate for the tracking option. If you have applied through tryvisa, you‘ll receive updates on your emails and won't miss out on anything.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Egypt visa through a travel agency?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for an Egypt visa through an approved agency like tryvisa. We can assist you with the application process and provide guidance on the required documents and procedures.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I apply for an Egypt visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for an Egypt visa online through tryvisa in the comfort of your own home and the official e-visa portal the Egyptian government provides.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to submit biometric data for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, biometric data is not required for most Egypt visa applications. However, checking the latest requirements with the Egyptian embassy or consulate in your country is advisable before applying.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Egypt visa if I hold dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: "If you hold dual citizenship, you can choose to apply for an Egypt visa using either passport. However, it's important to note that you must use the same passport throughout your travel to Egypt that you used for your visa application.",
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide proof of accommodation for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, providing proof of accommodation is usually required for an Egypt visa application. This can include hotel reservations, a letter of invitation from a host in Egypt, or any other documentation demonstrating your planned accommodation arrangements.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Egypt visa if I am unemployed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Being unemployed does not necessarily disqualify you from applying for an Egypt visa. However, you may need to provide additional documentation to demonstrate your financial means, such as bank statements or sponsorship letters.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need a sponsor for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, you won’t need a sponsor when applying for the Tourist visa. Egypt's tourist visa application process does not require a sponsor, unlike some countries that require a sponsor. This means you can apply for the visa independently without needing a sponsor's support or involvement.",
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the visa fee for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for Egypt is ₹ 2,999. At tryvisa, we are committed to transparency and have no hidden fees. The visa fee covers the processing and administrative costs of obtaining your Egypt visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it mandatory to have travel insurance for an Egypt visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is not mandatory to have travel insurance for an Egypt visa; it is highly recommended. Travel insurance provides financial protection in case of unexpected events, such as medical emergencies, trip cancellations, or lost luggage.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process an Egypt visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for your visa application is 3-5 days when you apply for an Egypt visa through tryvisa. This processing time includes the period from when you submit your application to when you receive a decision on your visa.',
              spans: [
                {
                  start: 49,
                  end: 57,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'At tryvisa, we understand the importance of the efficiency and timely processing of visa applications.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to submit my original passport for a visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'With tryvisa, you won’t need to submit your original passport. You’ll only have to scan your passport to complete the application. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I submit my visa application by mail?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can apply for the Egypt visa online through tryvisa. You’ll only need to download the app and fill in your information. You’ll be able to complete the application in 10 minutes. ',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa promises to refund 100% of your visa fees up to ₹8000 in case your visa gets rejected. You will get the money using your original transacting method. Service fees will not be refunded. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Egypt visa if I hold a refugee travel document?',
          answer: [
            {
              type: 'paragraph',
              text: "Individuals holding a refugee travel document can apply for an Egypt visa. However, contacting your country's Egyptian embassy or consulate is important to confirm the specific requirements and procedures.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to Egypt if my passport is expiring soon?',
          answer: [
            {
              type: 'paragraph',
              text: "Most countries require your passport to be valid for at least six months beyond your intended departure date. Therefore, it's recommended to renew your passport before travelling to Egypt if it is expiring soon.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$c8e43bd7-90ea-4539-b181-1ec0cd32d809',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Egypt',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2860,
    // cost_max: 4470,
    // cost_min: 3200,
  },
  HK: {
    priority: 13,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Hong Kong 14 days Single Entry PAR',
        supported: true,
        message: 'VISA FREE',
        eta_post_checkout_duration: 1,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '14 days',
        entry_validity: '180 days',
        visa_fees: 500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'PAR',
      },
      {
        id: 1,
        process_name: 'Hong Kong 14 days Multiple Entry PAR',
        supported: true,
        message: 'VISA FREE',
        eta_post_checkout_duration: 1,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 1000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'PAR',
      },
    ],
    name: 'Hong Kong',
    code: 'HK',
    hero_image_url:
      'https://images.unsplash.com/photo-1707501151061-346995e8675b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8SG9uZyUyMEtvbmclMjBTQVIlMjBDaGluYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'Hong Kong E-Visa',
    // supported: false,
    // message: null,
    // eta_post_checkout_duration: 3,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Digital',
    // entry_length_stay: '14 days',
    // entry_validity: '180 days',
    // govt_fees: 375,
    // service_fees: 375,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['marital_status_required', 'validity_required', 'image_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 533,
            },
            alt: 'Hong Kong Airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/521818cc-4dd0-4c9d-9331-128b767ea230_Hong_Kong_Airlines_Logo.png?auto=compress,format',
            id: 'ZdRdsBEAAC0APrMI',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 440,
              height: 150,
            },
            alt: 'Hong Kong tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/fac32838-c156-4d78-ad35-289a493d6757_Hong+Kong+Tourism+Board.png?auto=compress,format',
            id: 'ZK-duhIAACMAaI5G',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 170,
            },
            alt: 'Hong Kong Police Force logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8c135d66-b95b-4fb2-b442-4344e5821ec5_Hong+Kong+Police+Force.png?auto=compress,format',
            id: 'ZK-eHxIAACMAaJAd',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1200,
            },
            alt: 'Hong Kong Regional emblem logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2b466838-1fd8-458f-ae35-e66cdc8b7fe3_Regional_Emblem_of_Hong_Kong.svg.png?auto=compress,format',
            id: 'ZdRfphEAACcAPrvp',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$e5b901d3-3224-4b54-a735-215e74e49940',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required to apply for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying online for a Hong Kong visa, you typically need to provide your passport details, proof of accommodation, and travel plans.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the photo requirements for the Hong Kong visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The photo submitted for your Hong Kong visa online application should be a recent color passport-size photo. Further specifications can be found on our website.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know the status of my Hong Kong visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After applying online for a Hong Kong visa, you can track the status of your application on our website.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Hong Kong visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Hong Kong visa takes about 4 to 6 weeks to process fully. At tryvisa, we understand that time is often of the essence, so we strive to process applications as quickly as possible without compromising on quality.',
              spans: [
                {
                  start: 53,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is applying for a Hong Kong visa in advance necessary?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, applying for a Hong Kong visa in advance is advisable to avoid delays in your travel plans. Apply for your Hong Kong visa online at least a few weeks before your planned travel date to allow for any unexpected delays in processing.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my Hong Kong visa once approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Hong Kong visa is approved, you will receive it digitally via email.',
              spans: [
                {
                  start: 68,
                  end: 77,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            "What should I do if my passport expires after I've applied for a Hong Kong visa online?",
          answer: [
            {
              type: 'paragraph',
              text: "If your passport expires after applying for a Hong Kong visa online, you'll need to get a new one and then apply for a new one.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Hong Kong visa online from anywhere?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, as long as you have internet access, you can apply for a Hong Kong visa online from anywhere in the world using tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, tryvisa takes data security seriously. Our platform uses advanced security measures to ensure your personal information is safe.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Hong Kong visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "If your Hong Kong visa application is rejected, you'll be notified why. You can then address the issue and reapply. Note that government and online portals do not provide a refund if your visa gets rejected. However, tryvisa guarantees a refund of up to ₹8000 ($100) on rejected visas. This refund excludes any service fees.",
              spans: [
                {
                  start: 208,
                  end: 215,
                  type: 'strong',
                },
                {
                  start: 236,
                  end: 242,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, it's possible to apply for a Hong Kong visa online. This method of application is convenient and time-saving. You can apply through tryvisa by completing the necessary details and submitting the required documents.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: "What's the easiest way to apply for a Hong Kong visa?",
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to apply for a Hong Kong visa is through the tryvisa with the online application process, which allows you to submit all necessary documents from the comfort of your home.',
              spans: [],
            },
          ],
        },
        {
          question:
            "What should I do if my passport expires after I've applied for a Hong Kong visa online?",
          answer: [
            {
              type: 'paragraph',
              text: "If your passport expires after applying for a Hong Kong visa online, you'll need to get a new one and then apply for a new one.",
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of a Hong Kong visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Hong Kong visa price is ₹1000. The visa fee is non-refundable. Ensure you have a credit or debit card to pay the visa fee. We ensure you are fully aware of all costs associated with the visa application.',
              spans: [
                {
                  start: 28,
                  end: 33,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required for a Hong Kong visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "While travel insurance may not be mandatory for a Hong Kong visa application, it's highly recommended for your safety and security during your travels.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Hong Kong visa online if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Individuals with dual citizenship can apply for a Hong Kong visa online. They should apply with the passport they intend to travel with.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a specific time to apply for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for a Hong Kong visa online at any time. The online application service is available 24/7.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Do I need a confirmed flight booking before applying for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'While it can be beneficial to have confirmed travel plans, it is not always required for a Hong Kong visa application. Specific requirements can be found on our website.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to mainland China with a Hong Kong visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Hong Kong visa does not grant access to mainland China. You would need to apply for a separate visa to travel there. You can check out the Hong Kong visa for Indians for what you can use for the visa. ',
              spans: [
                {
                  start: 141,
                  end: 167,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/hong-kong-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How can I prove my travel purpose when applying for a Hong Kong visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can prove your travel purpose when applying for a Hong Kong visa online by providing documentation such as hotel bookings, flight tickets, or a letter of invitation.',
              spans: [
                {
                  start: 110,
                  end: 125,
                  type: 'strong',
                },
                {
                  start: 127,
                  end: 141,
                  type: 'strong',
                },
                {
                  start: 148,
                  end: 168,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently asked questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$818313b3-603d-403a-935d-370629ad42e1',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Hong Kong SAR China',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 500,
    // cost_max: 1904,
    // cost_min: 0,
  },
  AZ: {
    priority: 14,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Azerbaijan 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 2650,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Azerbaijan 30 days Single Entry E-Visa (Express)',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 5686,
        service_charge: 500,
        speed: 'express',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Azerbaijan',
    code: 'AZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1596306499398-8d88944a5ec4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QXplcmJhaWphbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'ASAN E-Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 5,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    // entry_type: 'ASAN E-visa',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 2272,
    // service_fees: 410,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'ASAN E-Visa',
        description:
          'This is a single entry E-Visa that lets you visit Azerbaijan for a maximum of 30 days',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'appointment',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'fbi_background_check',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'flight_hotel_details',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'identity',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'pan_card',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'photo',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_lease_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 476,
              height: 524,
            },
            alt: 'Ministry of Foreign Affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e12596e1-2867-417a-9680-7fbcb534634c_Ministry_of_foreign_affairs_Azerbaijan.png?auto=compress,format',
            id: 'ZdO8QBEAACwAO8Lq',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 371,
              height: 377,
            },
            alt: 'Foreign Intelligence Service of Azerbaijan',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3b26faa6-3ccc-4858-ad5d-d7dcb14dd31b_Foreign+Intelligence+Service+of+Azerbaijan..png?auto=compress,format',
            id: 'ZdRMphEAAC4APmZ-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 608,
              height: 410,
            },
            alt: 'Azerbaijan e-visa portal ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6eddbad8-5ac2-45a7-9d96-e3983a7c2f3a_Azerbaijan_evisa.png?auto=compress,format',
            id: 'ZdROQBEAACsAPm2y',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 577,
              height: 433,
            },
            alt: 'Azerbaijan Airlines',
            copyright: null,
            url: 'https://images.prismic.io/atlys/534c7fe6-2233-4de2-8247-dda8470e126e_Azerbaijan+Airlines.png?auto=compress,format',
            id: 'ZdRP8xEAACcAPnVL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$6ab293be-9aa5-4c70-8b0f-c2df66679c88',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the documents required for an Azerbaijan tourist visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents required for an Azerbaijan tourist visa application may include a valid passport when you apply on tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any age restrictions for applying for an Azerbaijan e-Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are no specific age restrictions for applying for an Azerbaijan e-Visa. The general visa requirements apply to individuals of all ages.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan e-Visa if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, individuals with dual citizenship can apply for an Azerbaijan e-Visa. However, using the same passport for both the application and travel is important.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan e-Visa if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'A criminal record does not automatically disqualify you from applying for an Azerbaijan e-Visa. Each application is assessed individually.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need an invitation letter for an Azerbaijan tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Usually, an invitation letter is not required for an Azerbaijan tourist visa. However, it is recommended to have proof of accommodation and travel arrangements.',
              spans: [],
            },
          ],
        },
        {
          question:
            'How early should I apply for an Azerbaijan visa before my intended travel date?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply for an Azerbaijan visa at least a few weeks in advance to allow for sufficient processing time.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any additional fees for an Azerbaijan e-Visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-Visa application fee is the only fee required for an Azerbaijan e-Visa. tryvisa doesn’t have additional fees.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to submit my passport for an Azerbaijan e-Visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you do not need to submit your physical passport for an Azerbaijan e-Visa application. A scanned copy of your passport is usually sufficient.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it necessary to have a return ticket to apply for an Azerbaijan e-Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A return ticket is not mandatory for an Azerbaijan e-Visa application. However, it is advisable to have a confirmed travel itinerary.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for an Azerbaijan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for an Azerbaijan visa online, you can start your application on tryvisa, and we guarantee that you get your visa on time.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a visa to Azerbaijan if I reside in a different country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a visa to Azerbaijan from a different country if you meet the visa requirements and have the necessary documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to get an Azerbaijan e-Visa after applying online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an Azerbaijan e-Visa is usually within three working days.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan visa for tourism purposes?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Individuals visiting Azerbaijan for tourism can apply for the appropriate tourist visa, such as an e-Visa on tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Azerbaijan visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I track the status of my Azerbaijan e-Visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can track the status of your Azerbaijan e-Visa application online by using the application reference number.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the steps to apply for an Azerbaijan e-Visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The steps to apply for an Azerbaijan e-Visa online include filling out the application form, uploading supporting documents, and making the payment.',
              spans: [
                {
                  start: 26,
                  end: 43,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/azerbaijan-visa-for-indians',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan visa through a visa agency or travel agent?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for an Azerbaijan visa through tryvisa and complete the application in 10 minutes.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I use an Azerbaijan e-Visa for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Azerbaijan e-Visa is typically issued for single or multiple entries, depending on the type of visa you apply for.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan visa if I am visiting for tourism purposes?',
          answer: [
            {
              type: 'paragraph',
              text: 'Individuals visiting Azerbaijan for tourism can apply for the appropriate tourist visa, such as an e-Visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Azerbaijan visa if I am already in Azerbaijan?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, it is not possible to apply for an Azerbaijan visa if you are already in the country. You should apply for a visa before traveling.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$c61c690a-e854-4399-a376-e983fdbb9eb6',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Azerbaijan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2650,
    // cost_max: 4218,
    // cost_min: 1932,
  },
  VN: {
    priority: 15,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Vietnam 30 days Single Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '30 days',
        visa_fees: 2600,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
      {
        id: 1,
        process_name: 'Vietnam 90 days Multiple Entry E-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '90 days',
        entry_validity: '90 days',
        visa_fees: 5100,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'E-Visa',
      },
    ],
    name: 'Vietnam',
    code: 'VN',
    hero_image_url:
      'https://images.unsplash.com/photo-1583417319070-4a69db38a482?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8VmlldG5hbXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    // process_name: 'Vietnam E-Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 6,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '30 days',
    // govt_fees: 2150,
    // service_fees: 100,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Vietnam E-visa',
        description: 'An e-visa is required to visit, regardless of the duration. ',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'dropdown',
        required_doc: '',
        options: [
          {
            label: 'Tan Son Nhat Int Airport (Ho Chi Minh City)',
            value: 'STS',
          },
          {
            label: 'Phu Quoc International Airport',
            value: 'SPQ',
          },
          {
            label: 'Phu Bai Int Airport',
            value: 'SPB',
          },
          {
            label: 'Noi Bai Int Airport (Ha Noi)',
            value: 'SNB',
          },
          {
            label: 'Van Don Int Airport (Quang Ninh)',
            value: 'SVD',
          },
          {
            label: 'Can Tho International Airport',
            value: 'SCT',
          },
          {
            label: 'Cam Ranh Int Airport (Khanh Hoa)',
            value: 'SCR',
          },
          {
            label: 'Cat Bi Int Airport (Hai Phong)',
            value: 'SCB',
          },
          {
            label: 'Da Nang International Airport',
            value: 'SDN',
          },
        ],
        description: 'This is required on your visa, please provide accurate information',
        label: 'Which city (in Vietnam) are you arriving in?',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'port_of_entry',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'flight_hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'india_itr',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'indonesia_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'pan_card',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required', 'last_name_required'],
        currency: 'INR',
        toggle: true,
        source_url: '',
        only_b2b: false,
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_lease_details',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_insurance',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1080,
              height: 1080,
            },
            alt: 'Vietnamese Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e9fd77a5-7c6b-45bc-8ba5-ef8fd84352af_Vietnam+Ministry+of+Foreign+Affairs+logo.png?auto=compress,format&rect=119,106,830,830&w=1080&h=1080',
            id: 'ZdWtsxEAACkARMcx',
            edit: {
              x: -154,
              y: -137,
              zoom: 1.3014027371406571,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 378,
              height: 226,
            },
            alt: 'Vietnam tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/77142a92-74fe-45bf-a549-e8cc64755068_Vietnam+tourism+logo.png?auto=compress,format',
            id: 'ZdWt7hEAAC4ARMhQ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 574,
            },
            alt: 'Vietnam Airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a2a31736-d0f6-452f-9056-1597a3c999ed_Vietnam+Airline+logo.png?auto=compress,format',
            id: 'ZdWuCxEAACkARMjR',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1586,
              height: 769,
            },
            alt: 'VGP Vietnam government website logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a8a3b893-1f80-400c-b6ea-a5f29f14eecc_Vietnam.gov.vn+logo.png?auto=compress,format',
            id: 'ZdWuLxEAAC4ARMly',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$8085751f-629d-4174-9cb4-e269bf7391ff',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for a Vietnam visa for Indian passport holders?',
          answer: [
            {
              type: 'paragraph',
              text: 'The requirements for a Vietnam visa for Indian passport holders include a valid passport with a minimum of six months validity and your photo when you apply on tryvisa. ',
              spans: [
                {
                  start: 23,
                  end: 46,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/vietnam-evisa-for-indians',
                  },
                },
                {
                  start: 80,
                  end: 88,
                  type: 'strong',
                },
                {
                  start: 136,
                  end: 142,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa online as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, As an Indian citizen, you can apply for a Vietnam visa online. Using tryvisa for the online application process is convenient and allows you to submit your visa application from anywhere. We provide a user-friendly online platform to simplify the application process.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I pay for the Vietnam visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Vietnam visa for Indian price is ₹ 2,750 tryvisa has no hidden fees! You can also pay using your credit card.',
              spans: [
                {
                  start: 37,
                  end: 45,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the visa process for Indians traveling to Vietnam?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a Vietnam visa, Indians can apply online for an e-visa on tryvisa. To start the process, you have specific requirements and procedures. We provide detailed instructions on our website to guide you through the application process.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check the status of my Vietnam e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To check the status of your Vietnam e-visa, you can check the status on tryvisa. On tryvisa, you will receive updates notifications straight to your Email. ',
              spans: [
                {
                  start: 78,
                  end: 87,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does it take to process a Vietnam visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Vietnam visa application is 5-8 business days. Once you have submitted your visa application, it undergoes a thorough review by the immigration authorities. During this period, they verify the information provided, conduct any necessary checks, and decide to approve your visa.',
              spans: [
                {
                  start: 53,
                  end: 72,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Note that the refund does not include the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I apply for a Vietnam visa online as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a Vietnam visa online as an Indian citizen, you can download the tryvisa app, then start your application. You’ll complete the application process in 10 minutes. Just make sure to provide accurate information and follow the instructions carefully. Pay the visa fee, and we will process your application accordingly.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a Vietnam visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for a Vietnam visa at least 1-2 weeks before your planned travel date is recommended. This will allow enough processing time and ensure you have your visa before your departure.',
              spans: [
                {
                  start: 37,
                  end: 46,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Vietnam visa if you have dual citizenship. However, make sure to use the passport that you intend to travel with when applying for the visa. The visa will be linked to the passport you provide during the application process.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I use a Vietnam visa to visit multiple countries in the region?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Vietnam e-visa allows a single entry to Vietnam only. If you plan to visit multiple countries in the region, check the visa requirements for each country separately and obtain the necessary visas or permits.',
              spans: [
                {
                  start: 25,
                  end: 38,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I have previously been denied entry?',
          answer: [
            {
              type: 'paragraph',
              text: 'It may affect your visa application if you have previously been denied entry to Vietnam. We recommend providing accurate information about your previous visa denials during the application process. The immigration authorities will make a decision based on the provided information.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I am visiting for medical treatment?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Vietnam visa if you are visiting for medical treatment. Provide relevant medical documents, treatment plans, and a letter from the medical facility in Vietnam to support your visa application.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I apply for a Vietnam visa if I have already booked my flight and accommodation?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Vietnam visa even if you have already booked your flight and accommodation. In fact, applying for a visa before making any travel arrangements is recommended to ensure a smooth and hassle-free journey.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: "Can I apply for a Vietnam visa if I don't have a return ticket?",
          answer: [
            {
              type: 'paragraph',
              text: 'While a return ticket is not always a strict requirement for obtaining a Vietnam visa, it is advisable to have a confirmed return or onward ticket. Immigration officials may ask for proof of your intended departure from Vietnam during entry.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I use my Vietnam visa for entry through any airport or land border crossing?',
          answer: [
            {
              type: 'paragraph',
              text: 'The entry points for using a Vietnam visa may vary depending on your visa type. Some visas only allow entry through specific airports, while others are also valid for land border crossings. Check your visa type for the designated entry points.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide a travel itinerary when applying for a Vietnam visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Providing a travel itinerary is not a strict requirement for obtaining a Vietnam visa. However, it is recommended to have a general outline of your travel plans, including your intended arrival and departure dates, as it may be requested during the application process.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I am currently unemployed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can still apply for a Vietnam visa if you are currently unemployed. The visa application process primarily focuses on your travel plans, purpose of visit, and financial capability to support your stay in Vietnam.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Are there any health requirements for obtaining a Vietnam visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are no specific health requirements for obtaining a Vietnam visa. However, it is advisable to check the latest health advisories and ensure you meet any health regulations or vaccination requirements for entry into Vietnam.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I have recently changed my name?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have recently changed your name, update your passport and relevant documents before applying for a Vietnam visa. Your visa application should reflect your current legal name to avoid complications during entry.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Vietnam visa if I have recently changed my name?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have recently changed your name, update your passport and relevant documents before applying for a Vietnam visa. Your visa application should reflect your current legal name to avoid complications during entry.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$2b79b774-19f8-4930-aa43-66f9ab9b6963',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Vietnam',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2600,
    // cost_max: 6500,
    // cost_min: 2860,
  },
  TR: {
    priority: 16,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Turkey 30 days E-Visa (U.K., USA, Schengen visa holders)',
        supported: true,
        message: null,
        eta_post_checkout_duration: 7,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 4250,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 1,
        process_name: 'Turkey 30 days Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 15,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 18900,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 2,
        process_name: 'Turkey 30 days Stamp-Visa (Express)',
        supported: true,
        message: null,
        eta_post_checkout_duration: 8,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '180 days',
        visa_fees: 25978,
        service_charge: 500,
        speed: 'express',
        visa_type: 'Stamp',
      },
    ],
    name: 'Türkiye',
    code: 'TR',
    hero_image_url:
      'https://images.unsplash.com/photo-1570799088236-903069fde316?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8VCVDMyVCQ3JraXllfGVufDB8fDB8fHww',
    process: 'digital',
    // process_name: 'Turkey Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 8,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 90,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Visa',
    // entry_length_stay: '30 days',
    // entry_validity: '180 days',
    // govt_fees: 17500,
    // service_fees: -6737,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          "An e-visa is required to visit, regardless of the duration. The e-visa is checked on departure, and you're required to get an e-visa before your trip. You cannot get an e-visa on arrival. If you do not possess a valid Schengen, US, or UK visa, you are not eligible for an e-visa.",
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['image_required'],
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 775,
              height: 775,
            },
            alt: 'Turkey Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c13b3ec3-4bbc-4fbb-9413-1a010fc9d8c8_Turkey+logo.png?auto=compress,format',
            id: 'ZLpVaxEAAB4AGaDI',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 61,
            },
            alt: 'Turkish Airlines',
            copyright: null,
            url: 'https://atlys.cdn.prismic.io/atlys/6b434069-4400-4a74-a481-572d32b52402_Turkish_Airlines.svg',
            id: 'ZLpTchEAACIAGZfN',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 650,
              height: 842,
            },
            alt: 'Turkey police',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1cd700b4-5ae2-43ef-a554-64381ac43a14_Turkey+police.png?auto=compress,format',
            id: 'ZLpUzxEAACIAGZ3a',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1200,
            },
            alt: 'Turkey Tourism Board\n',
            copyright: null,
            url: 'https://images.prismic.io/atlys/aab26608-862c-47a9-97f1-0b573c896c41_Turkey+Tourism+Board.png?auto=compress,format',
            id: 'ZLpVrREAACEAGaH0',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$cf8badc8-b1dc-4d6d-8903-053738e27e5b',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How can Indian citizens apply for a Turkey visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian citizens can apply for a Turkey visa through tryvisa or the embassy. When you apply through tryvisa, we will collect your documents from you and submit them on your behalf. We will guide you through each step to ensure a smooth and hassle-free application.',
              spans: [
                {
                  start: 52,
                  end: 57,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'You can also apply for an e-visa to Turkey. However, you must meet the strict eligibility criteria to obtain the e-visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for a Turkey visa as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for the Turkey visa, you must submit the following documents: ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Application Form: Fill out the visa application form using a blue or black pen in capital letters. Affix one passport-sized photo onto the page and sign the form.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport should be issued within the last 10 years and have a validity of at least 6 months beyond your intended stay in Turkey. Ensure it has at least two full blank pages.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport Copies: Provide copies of the first and last pages of your passport.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: '2x Passport-Sized Photos: Provide two recent biometric photos with a white background. The photos should clearly show your full face without any shadows, in a size of 2.5 inches x 2.5 inches.',
              spans: [
                {
                  start: 0,
                  end: 25,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Document Checklist: Print the checklist and bring it when submitting your documents. Get the checklist and required documents for your jurisdiction on the government website.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
                {
                  start: 155,
                  end: 173,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://gatewayinternational.com.tr/en/india/visa/turistik-vize',
                    target: '_blank',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Employment Letter: If employed, submit a leave letter from your employer. If self-employed, provide a cover letter on your business letterhead with an authorised signature, name, designation, and company seal.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Travel Itinerary: Include confirmed round-trip flight tickets and hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: "Bank Statements: Submit the original or bank-attested copies of the last three months' bank statements, both personal and, if applicable, from your employer.",
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Income Tax Return: For employees, provide salary slips/certificates for the last three months and Form 16. For employers, include GST details.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Financial Support Letter: If you lack sufficient funds, provide a notarized financial support letter from your sponsor. This is not needed for spouses, parents, and children.',
              spans: [
                {
                  start: 0,
                  end: 26,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: "When Visiting Friend or Family: If visiting a Turkish citizen or foreigner residing in Turkey, include an invitation letter with the inviter's passport number, full name, and a copy of their identity card and bank account.",
              spans: [
                {
                  start: 0,
                  end: 31,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'If Applying Through An Agent: If an agent is applying on your behalf, include an original letter of attorney from every applicant.',
              spans: [
                {
                  start: 0,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: 'Note that depending on the jurisdiction, you might be required to submit additional documents. ',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Turkey visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa at least 1 month before travelling to Turkey.',
              spans: [
                {
                  start: 45,
                  end: 61,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'After visa rejection, you will receive the reasons for the rejection reasons. Fix these reasons and reapply for your visa. Unfortunately, you will lose the funds when applying through the government or online portals. However, through tryvisa, you will receive a refund on visa rejections. The refund will be up to ₹8000 or $100, excluding the service fees.',
              spans: [
                {
                  start: 218,
                  end: 225,
                  type: 'strong',
                },
                {
                  start: 235,
                  end: 240,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I track my Turkey visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you’ve applied through tryvisa, you can track your Turkey visa application through the App. On tryvisa, you will also receive update notifications straight to your Email. ',
              spans: [
                {
                  start: 26,
                  end: 31,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the required Turkey visa photo size?',
          answer: [
            {
              type: 'paragraph',
              text: 'For a Turkey visa application, your photo should be 2.5 x 2.5 inches (63.5 x 63.5 mm) in size. It should be in colour, taken against a plain white or off-white background, and show your full face clearly.',
              spans: [
                {
                  start: 51,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I know if my Turkey visa application is approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your Turkey visa application is approved. You can also check the status online using your application ID and passport number.',
              spans: [],
            },
          ],
        },
        {
          question: 'Where can I find the Turkey visa application form?',
          answer: [
            {
              type: 'paragraph',
              text: 'Here is the Turkey visa application form, which you can download as a PDF. ',
              spans: [
                {
                  start: 12,
                  end: 40,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://cdn.gateway.com.tr/international/VISA_FORM_TURKIYE_OP_cfb2dccdee.pdf?updated_at=2023-03-08T07:42:28.707Z',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How can I apply for a Turkey visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for a Turkey visa from India through tryvisa. With tryvisa, you can apply for your visa from the comfort of your own home. This saves you time and effort while tryvisa does all the hard work of submitting the required documents for you.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Turkey visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, applying for a Turkey visa online through tryvisa is safe. tryvisa’s website uses a 256-bit Advanced Encryption Standard to ensure your data is protected from security breaches, and we do not sell your data to third-party companies. Plus, we have strong logistics and supply chain services to keep your documents safe for handling and submission.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it necessary to book flights before applying for a Turkey visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, proof of return flights is required for a Turkey visa application. It's recommended to book refundable flights in case of any changes.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to attend an interview when applying for a Turkey visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'An interview is not always required. However, when authorities find it necessary, they might request an interview with you.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Turkey with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a tourist visa does not permit you to engage in paid employment in Turkey. You would need a work visa for that.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Are there special Turkey visa requirements for minor Indian travelers?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, minors travelling to Turkey need a visa and must also provide additional documents like parental consent letters and copies of their parent's passports.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the validity period of a Turkey tourist visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period for the Turkey visa is 180 days from the day your visa gets approved.',
              spans: [
                {
                  start: 43,
                  end: 52,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it mandatory to have travel insurance for a Turkey visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, depending on the jurisdiction you are applying from, you might need to submit travel insurance for your application.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does it take to process a Turkey visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying through tryvisa, the processing time generally takes 25 days including the handling time of collecting and submitting your documents.',
              spans: [
                {
                  start: 65,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$8ddfbdad-b4cf-42d7-938f-5f8ccc816d55',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Türkiye',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 4250,
    // cost_max: 22500,
    // cost_min: 4460,
  },
  PH: {
    priority: 17,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Philippines 59 days Single Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 10,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '59 days',
        entry_validity: '90 days',
        visa_fees: 4500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 1,
        process_name:
          'Philippines 14 days Single Entry Free Visa (USA/UK/Canada/Australia/Schengen/Singapore/Japan Visa holders)',
        supported: true,
        message: 'USA/UK/Canada/Australia/Schengen/Singapore/Japan Visa holders',
        eta_post_checkout_duration: 10,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '14 days',
        entry_validity: '90 days',
        visa_fees: 0,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Free Visa',
      },
    ],
    name: 'Philippines',
    code: 'PH',
    hero_image_url:
      'https://images.unsplash.com/photo-1583685133115-90748ccbe274?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fFBoaWxpcHBpbmVzfGVufDB8fDB8fHww',
    process: 'physical',
    // process_name: 'Visa',
    // supported: false,
    // message: null,
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 90,
    eta_pre_travel_unit: 'days',
    // entry_type: 'TOURIST',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 4000,
    // service_fees: -4000,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Tourist Visa',
        description:
          'Indian nationals require a tourist visa to visit the Phillipines.  You are eligible for visa-free access if you possess a valid visa from Australia, Japan,\nCanada, Singapore, Schengen, USA, or the United Kingdom.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['employment_letter_required'],
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['physical_copy_required'],
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2048,
              height: 2048,
            },
            alt: 'Department of Foreign Affairs Philippine Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4311c254-46e0-41d4-9ca8-f42e619a59b5_Department_of_Foreign_Affairs_of_the_Philippines+logo.png?auto=compress,format',
            id: 'ZcNjRhIAACsAh-gG',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 874,
              height: 430,
            },
            alt: 'Philippines tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1b8261e5-91bc-465e-abfb-2743ec611c58_Philippines+tourism+board.png?auto=compress,format',
            id: 'ZcNmDRIAACgAh-yE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3840,
              height: 2160,
            },
            alt: 'Philippines Airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/61162b71-a06e-4fc7-8c4c-885917e4883f_Philippine-Airlines-Logo-1986.png?auto=compress,format',
            id: 'ZcNmsBIAACoAh-2H',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$7d10cedc-9f02-401b-bf67-f7cc0953edde',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way for Indians to apply for a Philippines visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest method is to apply through tryvisa. With tryvisa, we will collect your documents from you and submit them to the embassy on your behalf. Once your application is approved, we will send you your passport and valid visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for a visa in the Philippines?',
          answer: [
            {
              type: 'paragraph',
              text: 'To successfully apply for the Philippines visa, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: A passport with at least 6 more months validity and 2 blank pages.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application form: Print the application form and complete it fully and accurately.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
                {
                  start: 28,
                  end: 44,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://newdelhipe.dfa.gov.ph/images/2023/MAY2023/FA_Form_No_2_Standard_Visa_Application_Form_2021.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photos: Submit two recently taken photos measuring 35mm x 45mm with a white background. Affix one photo to the designated area on the form and cross-sign the form and the attached photo.',
              spans: [
                {
                  start: 0,
                  end: 7,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Cover letter: provide a cover letter including your name, job title, passport number, purpose of visit, duration of stay, and type of entry requested (single or multiple). This letter must be signed by the applicant and addressed to "The Visa Officer, Honorary Consulate of the Philippines, Mumbai." If self-employed, the letter should be on business letterhead. ',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Ticket: Provide proof of a return flight ticket.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Travel itinerary: Provide a full travel itinerary, including your day-to-day travel plans, flight plans, and hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Hotel Bookings: You must provide hotel reservations with proof of payment.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: "Proof of funds: Provide a copy of your last 6 months' bank statements signed and stamped by an authority of the bank. You must also provide your last 3 years' income tax return.",
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank card copy: Submit a copy of your bank card or forex receipts of $700 each.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additional documents will be required depending on your occupation.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You must apply for your visa 3 weeks before travelling to allow sufficient processing time.',
              spans: [
                {
                  start: 29,
                  end: 43,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'What is the minimum bank balance required when applying for a Philippines visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You must show proof of having at least ₹100,000 for your trip to the Philippines.',
              spans: [
                {
                  start: 39,
                  end: 48,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long will my visa take to process?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Philippines visa takes 10 days to process. However, various factors can delay the processing of your visa.',
              spans: [
                {
                  start: 27,
                  end: 35,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for a Philippine visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not required. However, it is advised to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'In case of visa rejection, you will receive an explanation of why your visa was denied. Once you have fixed these reasons, you can reapply for your visa. Unfortunately, you will lose the funds since the government and visa portals do not refund the visa fees, except for tryvisa. At tryvisa, you will receive a refund of up to ₹8000 or $100 if your visa is rejected. The refund excludes the service fees.',
              spans: [
                {
                  start: 281,
                  end: 286,
                  type: 'strong',
                },
                {
                  start: 307,
                  end: 313,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the reasons that can cause a delay in the processing of my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay your visa processing, including the following:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having inaccurate information on your documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'When you apply during peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing proof of sufficient funds.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'When you have a criminal record.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I check my Philippines visa status online through tryvisa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when you apply through tryvisa, you can see your visa status on the tryvisa app:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'First, download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you have applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once selected, you will see your visa’s current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there specific requirements when applying as a minor?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, If the applicant is under 15 years old and travelling alone or with someone who is not a parent, an Affidavit of Support and Consent to Travel is needed. This document must be filled out and signed by one or both parents, mentioning who will accompany the minor and providing the address in the Philippines. The affidavit must be certified by a local Notary Public, approved by the Ministry of External Affairs (MEA), and then by a consular officer at the Philippine Embassy. This document must be shown to immigration officials upon the minor's arrival in the Philippines.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need an approved hotel booking when applying for a visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must submit an approved hotel booking and provide proof of payment.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get a Philippines visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian nationals are not eligible to obtain a visa on arrival in the Philippines. You must have an approved visa before travelling to the country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is the visa valid for?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Philippine visa is valid for up to 90 days and allows you to stay in the country for 30 days.',
              spans: [
                {
                  start: 39,
                  end: 46,
                  type: 'strong',
                },
                {
                  start: 89,
                  end: 96,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to attend an interview when applying for a Philippines visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you are not required to attend an interview when applying for a Philippine visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a visa to the Philippines if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a visa with a criminal record. However, it might delay the processing of your visa since authorities will do a background check.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in the Philippines with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a tourist visa does not allow you to work in the Philippines. You must apply for the appropriate work visa if you plan to work.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$49f8f55e-03bd-45c2-8762-a9f85028190c',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Philippines',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 4500,
    // cost_max: 0,
    // cost_min: 0,
  },
  JP: {
    priority: 18,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Japan 30 days Single/Multiple Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 6,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single/Multi Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 2000,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
    ],
    name: 'Japan',
    code: 'JP',
    hero_image_url:
      'https://images.unsplash.com/photo-1542051841857-5f90071e7989?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8SmFwYW58ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    // process_name: 'Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 6,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: 'days',
    // entry_type: 'Tourist',
    // entry_length_stay: '30 days',
    // entry_validity: '30 days',
    // govt_fees: 1100,
    // service_fees: 810,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Sticker Visa',
        description:
          'The visa is a sticker visa, stamped into your passport. With tryvisa, you can avoid an in-person visit to submit your application.',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['mothers_name_required', 'fathers_name_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 714,
              height: 232,
            },
            alt: 'Japan tourism logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/64548af2-8bc8-458b-af1a-e0d320905ab7_image+%2825%29.png?auto=compress,format',
            id: 'ZdMO2RIAACwA8lgS',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 200,
              height: 203,
            },
            alt: 'Ministry of Foreign Affairs of Japan',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d0eb11a7-7ab0-4c3a-9632-3df647db9898_image+%2824%29.png?auto=compress,format',
            id: 'ZdMO-RIAACgA8liu',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 179,
              height: 154,
            },
            alt: 'Government of Japan logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/65c74b58-3ec8-4eb2-a9a5-1822dca57e47_Government+of+Japan+logo.png?auto=compress,format',
            id: 'ZdRofhEAACwAPvni',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 177,
              height: 75,
            },
            alt: 'Japan e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e40f631d-d1f9-4fb3-905a-ed0a1434d883_Japan+e-visa+logo.png?auto=compress,format',
            id: 'ZdRnQBEAACgAPu-P',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$96a69302-fe41-482a-82d1-84d86bd1d0e2',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How do I get a visa for Japan?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can get a Japan visa digitally on tryvisa with a straightforward process:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Visit tryvisa’s online portal.',
              spans: [
                {
                  start: 6,
                  end: 27,
                  type: 'hyperlink',
                  data: {
                    id: 'ZNnoNBEAACAAu2Yj',
                    type: 'page',
                    tags: ['JP'],
                    lang: 'en-in',
                    slug: 'any-questions-in-mind',
                    first_publication_date: '2023-08-14T09:16:08+0000',
                    last_publication_date: '2024-02-20T09:04:04+0000',
                    uid: 'apply-japan-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Complete the application form.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Upload the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Pay the Japan visa fees and submit the application. ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Our fulfilment centres will pick up and drop off your passport and documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Once your visa gets approved, tryvisa will send you your passport and visa via courier.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the documents required for the Japan Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying on tryvisa for your Japan visa for Indians, the required documents include a valid passport, flight itinerary, and a passport-sized photo and other documents as stated by the tryvisa.',
              spans: [
                {
                  start: 89,
                  end: 121,
                  type: 'strong',
                },
                {
                  start: 143,
                  end: 149,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for a Japan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you do not require travel insurance when applying for a Japan visa for Indians. But having travel insurance when visiting other countries is always a good idea.',
              spans: [
                {
                  start: 8,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Japan visa online on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for digital Japan on behalf of your family through tryvisa's website. Simply add the required information for your family members and submit it on the portal. ",
              spans: [
                {
                  start: 70,
                  end: 85,
                  type: 'hyperlink',
                  data: {
                    id: 'ZNnoNBEAACAAu2Yj',
                    type: 'page',
                    tags: ['JP'],
                    lang: 'en-in',
                    slug: 'any-questions-in-mind',
                    first_publication_date: '2023-08-14T09:16:08+0000',
                    last_publication_date: '2024-02-20T09:04:04+0000',
                    uid: 'apply-japan-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How can I track my Japan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After submitting your Japan visa application with tryvisa, you can track your visa status through tryvisa: Visit the tryvisa app, click on your profile, and select the visa you want to see the status of.',
              spans: [
                {
                  start: 113,
                  end: 122,
                  type: 'strong',
                },
                {
                  start: 138,
                  end: 145,
                  type: 'strong',
                },
                {
                  start: 151,
                  end: 166,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my Japan tourist visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Japan visa application is rejected, the reasons for denial will be communicated to you. You can reapply for your visa once you have fixed the denial reasons. The fees will not be refunded when applying through government and online portals, except tryvisa. Through tryvisa, you will receive a refund of up to ₹8000 ($100) if your visa is rejected. The refund will exclude any service fees.',
              spans: [
                {
                  start: 271,
                  end: 276,
                  type: 'strong',
                },
                {
                  start: 297,
                  end: 303,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How safe is it to apply for a Japan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for a Japan visa online is safe if you use tryvisa’s visa portal or the government site. tryvisa’s website uses a 256-bit Advanced Encryption Standard to ensure your data is protected from security breaches, and we do not sell your data to third-party companies. Plus, we have strong logistics and supply chain services to keep your documents safe for handling and submission.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it necessary to book a flight before applying for a Japan visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must book a flight before applying for a Japan visa since you must provide your flight tickets with the application process.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Japan as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Japan visa allows Indian citizens to stay in the country for up to 90 days.',
              spans: [
                {
                  start: 71,
                  end: 78,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Japan visa online from any country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the Japan visa online system is made accessible by tryvisa. You can apply from anywhere in the country if you have a reliable internet connection and the necessary documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a Japan visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Japan visa at least one month before your intended travel date to allow ample time for processing, but no earlier than 6 months. ",
              spans: [
                {
                  start: 52,
                  end: 62,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I travel to other countries from Japan on a Japan tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Japan tourist visa allows you to travel within Japan only. If you wish to travel to other countries from Japan, you will need the relevant visas for those countries.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I pay the fees for a Japan digital visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Fees for a Japan visa online can be paid with a credit or debit card or a mobile wallet during the application process.',
              spans: [],
            },
          ],
        },
        {
          question: "What happens if my Japan tourist visa expires while I'm in Japan?",
          answer: [
            {
              type: 'paragraph',
              text: 'Suppose your Japan tourist visa expires while in the country. You should contact the local immigration office immediately to avoid legal issues. They may grant a visa extension, or you may be required to leave the country.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What health precautions should I take before travelling to Japan on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's advisable to check with your doctor or a travel health clinic about any recommended vaccinations or other health precautions before travelling to Japan. Also, consider purchasing travel health insurance for your trip.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Japan with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Japan tourist visa does not permit the holder to undertake paid work in Japan. If you intend to work in Japan, you must apply for a work visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a visa upon arrival in Japan?',
          answer: [
            {
              type: 'paragraph',
              text: "No, Japan does not offer visa-on-arrival for Indian citizens. It's essential to obtain a visa before travelling to the country.",
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$fed66994-9f4b-421f-9b86-027dc951d440',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Japan',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 2000,
    // cost_max: 3650,
    // cost_min: 1160,
  },
  KR: {
    priority: 19,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'South Korea Short-Term Single Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: 'Short Term',
        entry_validity: '90 days',
        visa_fees: 4500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 1,
        process_name: 'South Korea Long-Term Single Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: 'Long Term',
        entry_validity: '90 days',
        visa_fees: 7180,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
      {
        id: 2,
        process_name: 'South Korea Multiple Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: 'Short Term',
        entry_validity: '90 days',
        visa_fees: 9580,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
    ],
    name: 'South Korea',
    code: 'KR',
    hero_image_url:
      'https://images.unsplash.com/photo-1538670178144-b8db27bad945?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U291dGglMjBLb3JlYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    // process_name: 'Visa',
    // supported: false,
    // message: 'Not Supported\nUnfortunately we do not currently support visas for South Korea',
    // eta_post_checkout_duration: '',
    // eta_post_checkout_unit: '',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Physical',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 3200,
    // service_fees: -3200,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Sticker Visa',
        description:
          'The visa is delivered by the Korea govt as a PDF, but requires you to submit your passport and documents at the Govt Mission. With tryvisa, you can avoid an in-person visit as tryvisa will submit the application on your behalf.',
      },
    ],
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: '6 Months Bank Statement',
        description: 'Please upload your 6 Months Bank Statement',
        label: 'Please upload your 6 Months Bank Statement',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'bank_statement',
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Details',
        description: 'Please upload your Return Flights to South Korea',
        label: 'Please upload your Return Flights to South Korea',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'flights',
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Bookings',
        description: 'Please upload your Hotel Bookings to South Korea',
        label: 'Please upload your Hotel Bookings to South Korea',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'hotel_voucher',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['employment_letter_required'],
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1203,
            },
            alt: 'South Korea National Emblem logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e11fb11e-f795-431c-b40b-65ef0e500b50_Emblem_of_South_Korea.svg.png?auto=compress,format',
            id: 'ZdUnhBEAACkAQnwt',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1036,
              height: 241,
            },
            alt: 'South Korea MOFA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1866d059-9446-4fce-8e93-88c70b01b2b4_South+Korea+MOFA.png?auto=compress,format',
            id: 'ZdUiuxEAACoAQmb0',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2480,
              height: 884,
            },
            alt: 'Korea Tourism Organization logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d33c67bf-bcff-4d89-bc9b-c8bc3d2033e1_South+Korea+tourism+orginisation+logo.png?auto=compress,format',
            id: 'ZdUj2BEAACcAQmvh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1280,
              height: 152,
            },
            alt: 'Korean Air logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ad72c5d4-137b-4de0-a00f-34e17f5938f5_KoreanAir_logo.png?auto=compress,format',
            id: 'ZdUk4xEAACoAQnB-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$96a69302-fe41-482a-82d1-84d86bd1d0e2',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required to apply for a South Korea visa?',
          answer: [
            {
              type: 'paragraph',
              text: "When applying on tryvisa for your South Korea visa for Indians, the required documents include a valid passport, flight tickets, passport-sized photo, the last 3 months' bank statements, and more.",
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for a South Korea visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is not mandatory when applying for a South Korea tourist visa. However, having travel insurance when visiting a different country is always a good idea.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I track my South Korea visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After submitting your South Korea visa application with tryvisa, you can perform a visa status check by following these steps: ',
              spans: [
                {
                  start: 81,
                  end: 98,
                  type: 'hyperlink',
                  data: {
                    id: 'ZNYbrxEAAB4Aqmfx',
                    type: 'article',
                    tags: ['KR', 'Asia'],
                    lang: 'en-in',
                    slug: 'what-is-the-south-korea-visa-tracking',
                    first_publication_date: '2023-08-14T13:19:41+0000',
                    last_publication_date: '2023-10-18T08:27:30+0000',
                    uid: 'south-korea-visa-tracking',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scroll down to "Profile".',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'In your profile, find the visa you want to see the status.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lastly, click on the visa to see its current status.',
              spans: [
                {
                  start: 8,
                  end: 13,
                  type: 'em',
                },
              ],
            },
          ],
        },
        {
          question:
            'Is it necessary to book a flight before applying for a South Korea visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must have a flight ticket before you apply for your South Korea visa since you will require it to complete the application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians citizens get a South Korea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians are not eligible for a South Korea e-visa. However, you can obtain a South Korea visa through tryvisa without leaving your home. tryvisa will collect the necessary documents and your passport from you and then submit your application to the embassy on your behalf. All you have to do is complete the online application on tryvisa and gather the required documents.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 55,
                  end: 62,
                  type: 'strong',
                },
                {
                  start: 106,
                  end: 111,
                  type: 'hyperlink',
                  data: {
                    id: 'ZN8BrRIAACYAvcrL',
                    type: 'page',
                    tags: ['KR'],
                    lang: 'en-in',
                    slug: 'any-questions-in-mind',
                    first_publication_date: '2023-09-01T05:09:59+0000',
                    last_publication_date: '2024-02-20T22:29:58+0000',
                    uid: 'apply-south-korea-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How Do I Get A Visa For South Korea?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest method to get a South Korea visa is on tryvisa. All you need to do is follow these steps:',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Start your visa application through tryvisa',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Upload your required documents.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Pay the South Korea visa fee and submit your application. ',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Collection of your passport: tryvisa will collect your passport and other required documents from you.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Receive your visa: Once your visa gets approved, tryvisa will send you your passport and approved visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in South Korea as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'The duration you can stay in Korea will depend on the type of visa you apply for. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a South Korea visa online from any country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the South Korea visa online system is made accessible by tryvisa. You can apply from anywhere in the country if you have a reliable internet connection and the necessary documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'How safe is it to apply for a South Korea visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "Applying for a South Korea visa online is safe if you use tryvisa’s visa portal or the government site. tryvisa’s website uses 256-bit Advanced Encryption Standard to ensure your data is protected from security breaches, and we do not sell your data to third-party companies. Plus, we've a strong logistics and supply chain services to keep your documents safe for handling and submissions.",
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a South Korea visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time of a South Korea visa takes about 15 days to be processed for tourism. Applying for your South Korea visa at least 1 month in advance is advised.',
              spans: [
                {
                  start: 4,
                  end: 41,
                  type: 'hyperlink',
                  data: {
                    id: 'ZPhBrxIAACQAaJ7p',
                    type: 'article',
                    tags: ['KR', 'First-Time Travelers', 'Visa Essentials'],
                    lang: 'en-in',
                    slug: 'south-korea-visa-types-and-factors-affecting-processing-time',
                    first_publication_date: '2023-09-07T06:53:07+0000',
                    last_publication_date: '2023-10-02T13:18:49+0000',
                    uid: 'south-korea-visa-processing-time',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  start: 135,
                  end: 153,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Can I travel to other countries from South Korea on a South Korea tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A tourist visa allows you to travel within South Korea only. If you wish to travel to other countries from South Korea, you will need the relevant visas for those countries.',
              spans: [],
            },
          ],
        },
        {
          question: 'What can cause my South Korea visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are multiple reasons that can cause your visa to be rejected. Some of the common reasons are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Missing documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A passport not being valid for 6 more months from the application date.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not enough funds to cover your stay in South Korea.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I pay the fees for a South Korea online visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying on tryvisa, you can pay for your South Korea visa using a credit/debit card or an approved mobile wallet.',
              spans: [],
            },
          ],
        },
        {
          question: "What happens if my South Korea tourist visa expires while I'm in South Korea?",
          answer: [
            {
              type: 'paragraph',
              text: 'Suppose your South Korea tourist visa expires while in the country. You should contact the local immigration office immediately to avoid legal issues. They may grant a visa extension, or you may be required to leave the country.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What health precautions should I take before travelling to South Korea on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's advisable to check with your doctor or a travel health clinic about any recommended vaccinations or other health precautions before travelling to South Korea. Also, consider purchasing travel health insurance for your trip.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my South Korea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indians can apply for a visa extension at the immigration office. Note that you must apply for a visa extension 4 months before your visa expires.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a visa upon arrival in South Korea?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no on-arrival visa for Indians in South Korea. You must have a valid visa before travelling to South Korea.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in South Korea with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a South Korean tourist visa does not permit the holder to undertake paid work in South Korea.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$fed66994-9f4b-421f-9b86-027dc951d440',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'South Korea',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 4500,
    // cost_max: 0,
    // cost_min: 0,
  },
  BR: {
    priority: 20,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'Brazil 90 days Multiple Entry Stamp-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 14,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '90 days',
        entry_validity: '90 days',
        visa_fees: 9150,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Stamp',
      },
    ],
    name: 'Brazil',
    code: 'BR',
    hero_image_url:
      'https://images.unsplash.com/photo-1620218776119-2bc322e2d7ad?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fEJyYXppbHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    // process_name: 'Brazil Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 26,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    // entry_type: 'Sticker Visa',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 8490,
    // service_fees: -1340,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Sticker Visa',
        description:
          'The visa is a sticker visa, stamped into your passport. With tryvisa, you can avoid an in-person visit as tryvisa will submit the application on your behalf.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 499,
              height: 500,
            },
            alt: 'Brazil Coat of Arms ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3e111a03-e888-40dc-8917-e77450282670_Brazil+Coat+of+Arms..png?auto=compress,format',
            id: 'ZdRx-xEAACkAPz9S',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 773,
              height: 323,
            },
            alt: 'Federal Government of Brazil',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e1161e2b-e98f-4099-a6da-6e8839585d6c_Brazil+Tourism+Board+logo..png?auto=compress,format',
            id: 'ZdXiGhEAACsARbYj',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 226,
            },
            alt: 'Ministry of Foreign Affairs Brazil.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/19b09875-338e-43c3-a200-dc9ba8bf3547_Ministry+of+Foreign+Affairs+Brazil..png?auto=compress,format',
            id: 'ZdR0aREAACoAP0qS',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 397,
              height: 127,
            },
            alt: 'Brazil Airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9531b2d0-ecf2-4486-8c75-7d63db2c89f1_Brazil+Airlines..png?auto=compress,format',
            id: 'ZdR1MxEAAC4AP04W',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$b15642d0-1505-4d28-8f82-9e21f5862e83',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Brazil visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents you need are as follows:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A completed application form.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport-sized photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Travel itinerary.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of funds.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Yellow fever vaccination. (Only if you travel through or from a yellow fever area)',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additional documents might be required.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it required to provide a flight itinerary when applying for a Brazil visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a Brazil visa, you must provide a travel itinerary.  This travel itinerary can either be proof of your flight tickets or accommodation bookings.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the minimum bank balllance required to apply for a Brazil visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To proof you have enough funds to cover your stay in Brazil, a bank balance of $150 a day is required.',
              spans: [
                {
                  start: 79,
                  end: 89,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I apply for a Brazil visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Brazil visa through the tryvisa without visiting the embassy. Here are the steps you must follow when applying through tryvisa:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Visit the tryvisa website.',
              spans: [
                {
                  start: 10,
                  end: 23,
                  type: 'hyperlink',
                  data: {
                    id: 'ZNnoBhEAADsYu2VK',
                    type: 'page',
                    tags: ['BR'],
                    lang: 'en-in',
                    slug: 'were-here-to-answer-all-your-questions',
                    first_publication_date: '2023-09-01T05:08:41+0000',
                    last_publication_date: '2024-02-21T11:44:52+0000',
                    uid: 'apply-brazil-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Choose your travel destination and dates.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Upload the documents required.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Complete the online application.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Pay the Brazil visa fees and submit your application.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Once you complete the application, tryvisa will arrange to collect your passport and other documents. When your visa is approved, tryvisa will collect it from the embassy and return it to you along with your passport.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Brazil visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Brazil visa application is rejected, you can reapply for the visa by fixing the reasons for rejection. Unfortunately, you will lose the visa funds and must repay the visa fee when applying through the government or online portals, except through tryvisa. tryvisa guarantees a maximum refund of ₹8000 ($1000) if your visa is rejected. Note that the refund excludes the service fees.',
              spans: [
                {
                  start: 261,
                  end: 277,
                  type: 'strong',
                },
                {
                  start: 288,
                  end: 295,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the validity period of a Brazil tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period of a Brazil tourist visa can vary. Generally, a single-entry tourist visa is valid for 180 days. The stay time is 90 days from the date of entry into Brazil.',
              spans: [
                {
                  start: 107,
                  end: 115,
                  type: 'strong',
                },
                {
                  start: 121,
                  end: 141,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my Brazil visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can extend your Brazil visa for 180 days in a 12-month period. This extension must be applied for at the Federal Police Department in Brazil 30 days before your visa expires.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my Brazil visa once it has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply on tryvisa, we will collect your approved visa from the embassy and deliver it back to you with your passport.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I apply for a Brazil visa if I have previously been denied entry to Brazil?',
          answer: [
            {
              type: 'paragraph',
              text: 'Previous denial of entry to Brazil may impact your eligibility for a visa. It is advisable to disclose any relevant information about past denials or immigration issues when applying for a visa. The Brazil authorities will consider your circumstances and make a decision based on the information provided.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an age limit for applying for a Brazil tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There is no specific age limit for applying for a Brazil tourist visa. Individuals of all ages can apply for a tourist visa as long as they fulfill the necessary requirements and provide the required documents.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Are there any specific health requirements or vaccinations needed for travel to Brazil?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a Brazil visa for Indians, you must provide a yellow fever vaccination if you are travelling from or through a yellow fever area.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Brazil application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Brazil application can vary, but it typically takes around  15-25 days. tryvisa guarantees your money back if your visa takes longer than the estimated processing time.',
              spans: [
                {
                  start: 86,
                  end: 96,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Brazil tourist visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Brazil tourist visa online through tryvisa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 60,
                  end: 65,
                  type: 'hyperlink',
                  data: {
                    id: 'ZNnoBhEAADsYu2VK',
                    type: 'page',
                    tags: ['BR'],
                    lang: 'en-in',
                    slug: 'were-here-to-answer-all-your-questions',
                    first_publication_date: '2023-09-01T05:08:41+0000',
                    last_publication_date: '2024-02-21T11:44:52+0000',
                    uid: 'apply-brazil-visa',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'Is an interview required when applying for a Brazil visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, an interview is not always required. It is only required if the immigration offices find it necessary for you to be interviewed. An interview is generally needed for work or study visas.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to multiple cities within Brazil with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, with a tourist visa to Brazil, you can travel to multiple cities within the country. The tourist visa allows you to explore various destinations and enjoy Brazil's cultural, historical, and natural attractions.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians get a Brazil visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no visa on arrival for Indians in Brazil, you must obtain a valid visa before travelling.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4785ba9d-cd79-40a5-98a5-5c5b4621aaa2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Brazil',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 9150,
    // cost_max: 10890,
    // cost_min: 6400,
  },
  CN: {
    priority: 21,
    supported: true,
    packages: [
      {
        id: 0,
        process_name: 'China 30 days Single Entry Sticker-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 6300,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Sticker',
      },
      {
        id: 1,
        process_name: 'China 30 days Single Entry Sticker-Visa (Express)',
        supported: true,
        message: null,
        eta_post_checkout_duration: 2,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Single Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 11200,
        service_charge: 500,
        speed: 'express',
        visa_type: 'Sticker',
      },
      {
        id: 2,
        process_name: 'China 30 days Double Entry Sticker-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Double Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 6300,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Sticker',
      },
      {
        id: 3,
        process_name: 'China 30 days Double Entry Sticker-Visa (Express)',
        supported: true,
        message: null,
        eta_post_checkout_duration: 2,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Double Entry',
        entry_length_stay: '30 days',
        entry_validity: '90 days',
        visa_fees: 13200,
        service_charge: 500,
        speed: 'express',
        visa_type: 'Sticker',
      },
      {
        id: 4,
        process_name: 'China 180 days Multiple Entry Sticker-Visa',
        supported: true,
        message: null,
        eta_post_checkout_duration: 4,
        eta_post_checkout_unit: 'days',
        eta_post_appointment_duration: 0,
        eta_post_appointment_unit: null,
        entry_type: 'Multi Entry',
        entry_length_stay: '180 days',
        entry_validity: '180 days',
        visa_fees: 11500,
        service_charge: 500,
        speed: 'regular',
        visa_type: 'Sticker',
      },
    ],
    name: 'China',
    code: 'CN',
    hero_image_url:
      'https://images.unsplash.com/photo-1474181487882-5abf3f0ba6c2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Q2hpbmF8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    // process_name: 'China Tourist Visa',
    // supported: true,
    // message: null,
    // eta_post_checkout_duration: 9,
    // eta_post_checkout_unit: 'days',
    // eta_post_appointment_duration: 0,
    // eta_post_appointment_unit: null,
    eta_pre_travel_duration: 90,
    eta_pre_travel_unit: null,
    // entry_type: 'Tourist Visa',
    // entry_length_stay: '30 days',
    // entry_validity: '90 days',
    // govt_fees: 6100,
    // service_fees: 1382,
    // compliance_fees: 0,
    // payment_processing_fees: 0,
    // process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 239,
              height: 85,
            },
            alt: 'Chinese visa application service centre logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e7da6e2b-37d9-4b38-ae46-71b5f304dd5d_China+visa+application+service.png?auto=compress,format',
            id: 'ZdQ6mhEAAC0APhY9',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 569,
              height: 229,
            },
            alt: 'China Ministry of Culture and Tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/13ce7d87-eb88-4a1e-b956-8f2d00b01845_China+ministry+of+tourism+and+culture.png?auto=compress,format&rect=46,98,572,230&w=569&h=229',
            id: 'ZdQ7WREAACwAPhmL',
            edit: {
              x: -45.76003844621843,
              y: -97.48877755933492,
              zoom: 0.9947834444830094,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 900,
              height: 976,
            },
            alt: 'China coat of arms logo.',
            copyright: null,
            url: 'https://atlys.cdn.prismic.io/atlys/b49be8b3-d3ad-41e4-98da-cbb23dce793b_National_Emblem_of_the_People%27s_Republic_of_China_%282%29.svg',
            id: 'ZS6UUxEAACAARbjU',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 501,
              height: 200,
            },
            alt: 'China tourism group logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/132f0192-88d8-4c91-8c1b-c75e7200f154_China+tourism+group+logo.png?auto=compress,format&rect=98,106,455,182&w=501&h=200',
            id: 'ZdQ4UREAACcAPgwU',
            edit: {
              x: -107.9993705798033,
              y: -116.815645729175,
              zoom: 1.1020343936714623,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$3cfa0dc7-4975-4a9b-aa86-f98bff29c240',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How will I receive my China visa once it gets approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'How you receive your visa will vary based on where you apply. You can opt to either receive it via post, courier or collect it at the visa application centre.',
              spans: [],
            },
          ],
        },
        {
          question: 'What documents will I require for a China tourist visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents you require will vary depending on where you apply for your visa. Here are the general documents you need when applying for your China visa for Indians:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Copy of valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2 x passport-sized photos.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Visa application form.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Photocopy of your previous passport, which expired in the last 5 years.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Travel itinerary.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Cover letter.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of sufficient funds.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for a China visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is not mandatory, but it is advised to have travel insurance when visiting a foreign country.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I perform a China visa tracking from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Here are the steps you can follow to complete a China visa tracking:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Visit the visa application portal.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click “Quick Access” in the “How to get a visa” section.',
              spans: [
                {
                  start: 0,
                  end: 5,
                  type: 'em',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Go to “History.”',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Next, provide either your pick-up form number + phone number or your application number + passport number.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Lastly, click “Check” to see your visa status.',
              spans: [
                {
                  start: 8,
                  end: 13,
                  type: 'em',
                },
              ],
            },
          ],
        },
        {
          question: 'Do Indians need to provide a cover letter when applying for a China visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must provide a cover letter when applying for a China visa for Indians. The cover letter must include the following: Reason for your visit, travelling dates, places you will visit, information about the invitee, and your bank details.',
              spans: [
                {
                  start: 24,
                  end: 36,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/post/invitation-letter-for-visa',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What is the Chinese visa for Indians processing time?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa for Indians to China takes about 4 days to be processed.',
              spans: [
                {
                  start: 42,
                  end: 48,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do If my China visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your China visa gets rejected, you will be notified of the reason for the rejection. You can then correct these reasons and reapply for your visa. The fees are non-refundable when applying through the government or online portals, except through tryvisa. At tryvisa, you will receive a refund if your visa is denied. The refund will be up to ₹8000 or $100, excluding the service fees.',
              spans: [
                {
                  start: 258,
                  end: 264,
                  type: 'strong',
                },
                {
                  start: 285,
                  end: 291,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Where is the China visa application office in India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indians can visit the China visa application office in New Delhi at:',
              spans: [
                {
                  start: 55,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Address: Concourse floor, Baba Kharak Singh Marg, Shivaji Stadium metro station, Connaught Place, New Delhi -110001 India.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'em',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Contact: 91-9999036735',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'em',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Email: delhicentre@visaforchina.org',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'em',
                },
                {
                  start: 7,
                  end: 35,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'mailto:delhicentre@visaforchina.org',
                  },
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Application centre in Kolkata:',
              spans: [
                {
                  start: 0,
                  end: 30,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Address: BIPI Building Omega, Ground Floor, Bengal Intelligent Park, Block EP & GP, Sector V, Bidhan Nagar, Kolkata, India.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'em',
                },
                {
                  start: 8,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Contact: 91-9999036735',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'em',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Email: kolkataCenter@visaforchina.org',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'em',
                },
                {
                  start: 7,
                  end: 37,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'mailto:kolkataCenter@visaforchina.org',
                  },
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Application centre in Mumbai:',
              spans: [
                {
                  start: 0,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Address: 8th Floor, Mafatlal Center, Nariman Point, Mumbai, Maharashtra 400021.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'em',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Contact: 91-22-22855271',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'em',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Email: MumbaiCenter@visaforchina.org',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'em',
                },
                {
                  start: 7,
                  end: 36,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'mailto:MumbaiCenter@visaforchina.org',
                  },
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians apply for a multiple-entry visa to China?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indians can apply for a single, double, or multiple-entry visa to China.',
              spans: [
                {
                  start: 28,
                  end: 35,
                  type: 'strong',
                },
                {
                  start: 37,
                  end: 43,
                  type: 'strong',
                },
                {
                  start: 48,
                  end: 62,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I pay for my visa to China?',
          answer: [
            {
              type: 'paragraph',
              text: 'The method of payment varies depending on where you apply. If you apply on tryvisa, you can use a credit/debit card or an approved mobile wallet. If you apply at the application centre, you must pay using cash or a demand draft. ',
              spans: [
                {
                  start: 96,
                  end: 113,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 142,
                  type: 'strong',
                },
                {
                  start: 203,
                  end: 207,
                  type: 'strong',
                },
                {
                  start: 213,
                  end: 225,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are some common reasons that will cause my visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'The rejection of a visa varies for each applicant. But here are some of the common reasons that can cause a visa rejection:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'If authorities find your background to be a threat to security.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'When your passport is close to being expired.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of funds to cover your stay.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know when my China visa online submission has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your China visa online submission has been approved. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the China visa photo requirements?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a China visa, ensure that your photo meets the following requirements:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Size: 33 x 48 mm.',
              spans: [
                {
                  start: 0,
                  end: 5,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Quantity: You will require 2x passport-sized photos.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
                {
                  start: 27,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Background: The background must be white or close to a white colour.',
              spans: [
                {
                  start: 0,
                  end: 11,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Paper: The photo must be printed with a glossy finish',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Colour photo: The photo must be a colour photo.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Quality: You must easily be recognisable and have no scratch or bend marks on the photos.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in China with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians can not work in China with a tourist visa. You must apply for a work visa to China to conduct any employment activities.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What should my travel itinerary include when applying for a China visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for a China visa, you must submit a planned travel itinerary. Here is what your travel itinerary should include:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of flight tickets. (including return tickets)',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of accommodation.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Arrival dates and times.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Invitation letter. (if you do not have proof of accommodation, you can use an invitation letter.)',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the stay period of the tourist visa to China?',
          answer: [
            {
              type: 'paragraph',
              text: 'The China tourist visa allows Indians to stay up to 30 days in the country.',
              spans: [
                {
                  start: 51,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a China visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, China does not provide Indian nationals the option of applying for a visa on arrival.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the China visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Here is a list of the fees for the China visa for Indians:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Single-entry visa:  ₹3900.',
              spans: [
                {
                  start: 20,
                  end: 25,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Double-entry visa: ₹5850.',
              spans: [
                {
                  start: 19,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Multiple-entry visa: ₹7800.',
              spans: [
                {
                  start: 21,
                  end: 26,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note that there might be additional fees depending on where you apply for your visa.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'These fees might change with the currency exchange rate.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0dccd815-820c-46e8-9faa-56afa0352b17',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'China',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 6300,
    // cost_max: 12161,
    // cost_min: 6732,
  },
  ID: {
    priority: 60,
    name: 'Indonesia',
    code: 'ID',
    hero_image_url:
      'https://images.unsplash.com/photo-1476158085676-e67f57ed9ed7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8SW5kb25lc2lhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Indonesia E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 6,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 3650,
    service_fees: 460,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Fast Track Arrival Visa',
        description:
          "Bali Fast Track Visa on Arrival so that you don't have to wait in long lines at the airport. Only valid if you're landing in Bali.",
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: 'Hotel Address Information',
        description: 'Please submit your hotel address information',
        label: 'Hotel Address Information',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Hotel_address',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'text',
        required_doc: '',
        description: 'Please submit your hotel postal code',
        label: 'Hotel Postal code',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Hotel_postal_code',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Return Flight Ticket',
        description: 'Please upload Flight Ticket to Indonesia',
        label: 'Flight ticket to Indonesia',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'flights',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Indonesia Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d4aa1e5b-7131-4028-a123-607dadcd3605_Indonesia+Ministry+of+forgein+affairs..png?auto=compress,format',
            id: 'ZdWd0xEAACsARIAd',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1307,
            },
            alt: 'Indonesia coat of arms ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c4864de8-cf7b-4db8-aa67-49f1b3f1f8de_Government+of+Indonesia.svg.png?auto=compress,format&rect=0,0,1200,1307&w=1200&h=1307',
            id: 'ZIreahAAACIAsry-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2261,
              height: 1080,
            },
            alt: 'Indonesia airlines logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cd371f64-a2a2-4242-94a6-c729e5d0ab07_INDONESIAN_AIRLINES_LOGO_BY_%40YBLIVERIES.png?auto=compress,format',
            id: 'ZK__oxIAACIAaiGR',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 675,
              height: 273,
            },
            alt: 'Indonesia Tourism Board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5e7000eb-1ddb-4197-97e8-d98031031f04_wonderful+Indonesia+logo..png?auto=compress,format',
            id: 'ZdWcSxEAAC4ARHk8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$884dbc7e-51fc-4349-9831-c9350e7f86c9',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for an Indonesia visa?',
          answer: [
            {
              type: 'list-item',
              text: 'Passport photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scanned copy of your passport ID page.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the required photo size for an Indonesia visa?',
          answer: [
            {
              type: 'paragraph',
              text: "The photo size for an Indonesia visa is typically 35mm x 45mm, with a white background, and must be a recent photograph. It's best to check the official requirements as they can change.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to other parts of Indonesia with a Bali visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, a visa for Bali is essentially a visa for Indonesia. You can travel to other parts of Indonesia as long as your visa is valid.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the penalty for overstaying my Indonesia visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Overstaying your Indonesia visa can result in fines and possibly a ban from re-entry. The exact penalty may vary, so it's always recommended to adhere to the dates specified on your visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'What payment methods are accepted for the Bali visa price?',
          answer: [
            {
              type: 'paragraph',
              text: 'Accepted payment methods for the Bali visa fee usually include credit or debit cards and certain mobile wallets. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Bali visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for a new visa if your visa application is rejected. To do so, first, fix the reasons that caused your visa denial. Unfortunately, you will not receive a refund from the government or online portals, except for Atyls. When applying through tryvisa, you are guaranteed a total of ₹8000 or a $100 refund if your visa is rejected. Note that the refund excludes the service fee.',
              spans: [
                {
                  start: 256,
                  end: 261,
                  type: 'strong',
                },
                {
                  start: 270,
                  end: 282,
                  type: 'strong',
                },
                {
                  start: 308,
                  end: 315,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is an Indonesia visa valid for an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'A tourist visa is usually valid for 30 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I convert my Bali tourist visa to a work visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Changing a tourist visa to a work visa while in Indonesia may be complicated and may require you to leave the country to change your visa status. It's best to check with the Indonesian immigration office for specific procedures.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Bali online visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you apply for your Bali visa through tryvisa, your visa will be processed instantly.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Bali visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a Bali visa online, you can go to the tryvisa app or online portal. Follow the step-by-step instructions provided, which typically include filling out a form with personal information and submitting the required documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of a Bali visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The cost of a Bali visa is INR 7000 through tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Indonesia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for an Indonesia visa online through tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the common reason for the rejection of an Indonesia visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Common reasons for visa rejections can include the following:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete or incorrect application forms.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds for the intended stay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Invalid or damaged passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of proof of return or onward travel.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'To avoid rejections, ensure that all requirements are met before applying.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I check the status of my visa online for Indonesia?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can check the status of your Indonesian visa application online through the app. All you need to do is go to your profile on the app.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the difference between a Bali visa online and a Bali visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Bali visa online is applied for before you travel from the comfort of your home, while a visa on arrival is applied for and obtained directly at the airport when you arrive in Bali. The e visa application process through tryvisa is hassle free and you won’t have to stand in a long queue to get your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any additional charges over the Bali visa price?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are additional service charges over the visa price. tryvisa has no hidden fees and is very forthcoming with the service fee they ask.',
              spans: [],
            },
          ],
        },
        {
          question: 'What information do I need to fill out the Indonesia visa form in Mumbai?',
          answer: [
            {
              type: 'paragraph',
              text: 'Typically, the Indonesia visa form requires information such as your personal details, passport information, details of your stay in Indonesia, etc.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need health insurance for a Bali tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While it might not be a mandatory requirement for a visa, having health insurance when traveling to Bali is highly recommended for any potential medical emergencies.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Bali with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, working in Bali with a tourist visa is strictly prohibited and can result in severe penalties, including deportation. If you intend to work, you will need to apply for a work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long is the Indonesia visa processing time from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying with tryvisa you can recieve you Indonesia visa approved in 30 minutes.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Do You Have Questions?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$bc7ea690-2225-4fe2-839c-20a1ca651dd3',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Indonesia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 4110,
    cost_max: 5502,
    cost_min: 3360,
  },
  AU: {
    priority: 40,
    name: 'Australia',
    code: 'AU',
    hero_image_url:
      'https://images.unsplash.com/photo-1494233892892-84542a694e72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8QXVzdHJhbGlhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Australia Visitors Visa (Subclass 600)',
    supported: false,
    message: null,
    eta_post_checkout_duration: 26,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 365,
    eta_pre_travel_unit: 'days',
    entry_type: '600 Sub Visitor Entry',
    entry_length_stay: '90 days',
    entry_validity: '3 years',
    govt_fees: 10979,
    service_fees: 3566,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visitor Visa Subclass 600',
        description:
          'A visa is required to visit Australia. The visa validity is between 3 and 12 months.',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'National ID (Aadhar or PAN Card)',
        description: '',
        label: 'National ID (Aadhar or PAN Card)',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'national_id',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 2 Years ITR',
        description: '',
        label: 'Last 2 Years ITR',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'last_two_years_itr',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 6 Months Bank Statement',
        description: 'Please provide e-statement only',
        label: 'Last 6 Months Bank Statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'last_six_months_bank_statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 3 Months Salary Slip',
        description: "Please upload last 3 months salary slip if you're employed",
        label: 'Last 3 Months Salary Slip',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'last_three_months_salary_slip',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Leave Letter or Letter of Employment from the Company',
        description:
          "Please upload recent leave letter or letter of employment from the company if you're employed",
        label: 'Leave Letter from the Company or Letter of Employment',
        required: false,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1sgCLeU1nu-mjILZSiqBUZy2xyvsugf0v/edit?usp=drive_link&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'leave_employment_letter',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'GST Certificate - Company Bank Statement 6 Months',
        description:
          "Please upload GST Certificate - Company Bank Statement for last 6 Months if you're self employed",
        label: 'GST Certificate - Company Bank Statement 6 Months',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'gst_bank_statement_six_monts',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'COVID Vaccination Certificate',
        description: '',
        label: 'COVID Vaccination Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'covid_vaccination_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Marriage Certificate (Optional)',
        description: '',
        label: 'Marriage Certificate (Optional)',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'marriage_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'All Passport Old & New Used Pages Copies/Scanned Copies',
        description: '',
        label: 'All Passport Old & New Used Pages Copies/Scanned Copies',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'old_new_passport_pages',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Covering Letter (Address to Australia High Commission, New Delhi)',
        description: '',
        label: 'Covering Letter (Address to Australia High Commission, New Delhi)',
        required: true,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1Y7xPm6-EmQzt-QGNkZdP7kF1BRvYMorx/edit?usp=sharing&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'covering_letter',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Net Worth Certificate (Optional)',
        description: '',
        label: 'Net Worth Certificate (Optional)',
        required: false,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1rmQYLUztEjeTco-RXm2qJ_6cfZVXOGCz/edit?usp=drive_link&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'net_worth_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Other Saving & Investment (PPF, Fix Deposit, Shares)',
        description: '',
        label: 'Other Saving & Investment (PPF, Fix Deposit, Shares)',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'other_saving_investment',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Form 54',
        description: '',
        label: 'Form 54',
        required: true,
        only_b2b: true,
        source_url:
          'https://drive.google.com/file/d/1bl2CE2JsMqeooX3f09O9lwK1QG9wZjYK/view?usp=sharing',
        key: 'form_fifty_four',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 593,
              height: 421,
            },
            alt: 'Australia government Department of Immigration and Border Protection logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/67e6c1af-6e81-4c56-9b29-d0508bf138b5_Australia+department+of+immigration+and+border+protection.png?auto=compress,format',
            id: 'ZdSrVxEAACsAQEan',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 348,
              height: 189,
            },
            alt: 'Australia government logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/61b3b98e-834c-4644-84af-9dd2768fd477_Australia+ministry+of+foreign+affairs..png?auto=compress,format',
            id: 'ZdXgZhEAACsARa5r',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3840,
              height: 2160,
            },
            alt: 'Qantas airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0e168f79-444f-4e27-8e16-4c7bf7b3b4d5_Australia+airline.png?auto=compress,format',
            id: 'ZMuk1BAAACUAdSwe',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 853,
            },
            alt: 'Tourism Australia logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/60403f76-b1f8-422a-bd41-fb620d966c91_Tourism+australia.png?auto=compress,format',
            id: 'ZMuk1BAAACMAdSwj',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$124e3510-0d5d-4a29-9131-47ba5173022a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question:
            'What are the requirements for an Australian tourist visa as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Australian tourist visa documents that are required when applying for tryvisa are as follows:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2 years ITR statement',
              spans: [],
            },
            {
              type: 'list-item',
              text: '6-month bank statement',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scanned copies of all your passport pages that have a visa stamp or sticker.\n',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the application process for an Australian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The process is quite simple. First, go online to tryvisa; from there, gather the required documents, do the application, pay the visa fee, and then wait for your visa to be processed.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my Australian tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply well in advance since there are a lot of factors that can delay the processing of your visa. So applying 1-2 months before your travels is advised.',
              spans: [
                {
                  start: 132,
                  end: 162,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will an interview be required for an Australian tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you don’t need to go to an interview to get an Australian visa. If there are additional requirements or clarification needed, then you might be asked for an Interview.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if I apply for an Australian visa and have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have a criminal record, you might be asked to provide additional documents for your visa to be processed. Additionally, the visa approval process also depends on the severity of your crime. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide a letter of invitation as an Indian tourist?',
          answer: [
            {
              type: 'paragraph',
              text: 'A letter of invitation is not mandatory, but it might be beneficial if you have one.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Australian visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Australian visa has been denied, you can reapply for it once all the reasons that caused the visa denial are addressed. You will lose the visa fees you paid since the government and online visa portals do not provide a refund, except for tryvisa. On tryvisa, you will receive a refund if your visa is rejected for up to ₹8000 or $100.',
              spans: [
                {
                  start: 256,
                  end: 261,
                  type: 'strong',
                },
                {
                  start: 282,
                  end: 288,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What type of Australian visa can I get as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of different types of visas for Indian citizens. Here at tryvisa, you can apply for your Australian visitor visa subclass 600. There are also other types, such as transit visas, student visas, and work visas.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the required bank balance for an Australian visa for Indian tourists?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will need to have at least ₹278 000 in your bank account to apply for the Australian visa.',
              spans: [
                {
                  start: 32,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the fastest way to get an Australian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'On Altys, you can apply for your visa in under 10 minutes. The quicker you apply, the quicker the processing can start.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I obtain an Australian visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You cannot extend your tourist visa; however, you are allowed to apply for a new one while you are in Australia.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the quickest way to apply for an Australian e-visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying through tryvisa is the quickest way to get your Australian visitor visa from India. You can apply for your visa in under 10 minutes.',
              spans: [
                {
                  start: 17,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What would the time be for an Australian visa process from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an Australian visa generally takes about 45 days but can take longer if there are additional documents required.',
              spans: [
                {
                  start: 65,
                  end: 72,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Australian visa if I have a one-way flight ticket?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you may apply for an Australian visa even if you only have a one-way flight ticket. You must just provide proof that you will return to your home country.',
              spans: [],
            },
          ],
        },
        {
          question: 'What does a tourist visa to Australia cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa the Australian visitor visa fee is ₹8400.',
              spans: [
                {
                  start: 45,
                  end: 50,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay with an Australian visa for Indian tourists?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can stay in Australia for up to 12 months, depending on the stay period provided on your visa. The Australian officials are the ones deciding how long you can stay.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What happens if I provide the wrong documents when I apply for my Australian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you provide the wrong detail on a document or the document itself is wrong, you must contact tryvisa’s support team immediately and provide the correct details. Incorrect documents may cause a delay in the processing time or visa rejections. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What could cause my Australian visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of factors that could cause your visa to be denied, such as wrong documents, invalid information, and insufficient funds.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$a127f832-8260-4a53-a2c2-acf5595658ab',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Australia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 14545,
    cost_max: 17000,
    cost_min: 14000,
  },
  MA: {
    name: 'Morocco',
    code: 'MA',
    hero_image_url:
      'https://images.unsplash.com/photo-1489749798305-4fea3ae63d43?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TW9yb2Njb3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Morocco E-Visa',
    supported: false,
    message: 'We currently do not support visas to Morocco',
    eta_post_checkout_duration: 11,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '180 days',
    govt_fees: 6570,
    service_fees: -1025,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description: 'Morocco issues a digital e-visa for Indian nationals.',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'covid_vaccine',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'passport_back',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'photo',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'shipping_labels',
      },
      {
        only_b2c: true,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_insurance',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 494,
              height: 505,
            },
            alt: 'Morocco coat of arms',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ee22d694-e712-4056-951f-aa0fc1a5c188_Coat+of+arms+of+Morocco..png?auto=compress,format',
            id: 'ZdXTXxEAACgARXMV',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 666,
              height: 375,
            },
            alt: 'Morocco airline logo ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/80be297f-ecbb-48d6-85e7-a240914c62b8_Morocco+Airlines.+.png?auto=compress,format',
            id: 'ZdXUHBEAACkARXZy',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Morocco tourism board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5f581cf7-a3d1-4469-ae69-26f5f2ac01e4_Morocco+Tourism+Board.+.png?auto=compress,format',
            id: 'ZdXVcREAACcARXyn',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$7fa477fc-2ef6-49b8-adbf-a75f4c21f2ea',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the documents required for the Morocco Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'For the documents required for the Morocco visa application, you’ll need a valid passport and a photo when applying with tryvisa.',
              spans: [
                {
                  start: 35,
                  end: 59,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/moroccan-visa-for-indians',
                    target: '_blank',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for a Morocco visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "It's always a good idea to have travel insurance, and some visa applications require it.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I track my Morocco visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After submitting your Morocco visa application, you can track its status through tryvisa online portal simply by logging into your account using your username and password. Navigate to the "Applications" tab on the top menu. You can keep track of your Bahrain visa application status. The status ranges from "Submitted" to "Approved."',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it necessary to book a flight before applying for a Morocco visa?',
          answer: [
            {
              type: 'paragraph',
              text: " While it is not necessary, it's recommended to have the proof of return flight ticket when filling out the application and traveling to Morocco since the authorities at the airport might request it.",
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Morocco e-visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Morocco e-visa application is denied, the reasons for denial will typically be communicated to you. You may have the opportunity to address these reasons and apply again, but the specifics depend on Moroccan immigration policy.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Morocco visa online from any country?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, tryvisa's Morocco e-visa online system is accessible worldwide. You can apply from any country if you have a reliable internet connection and the necessary documents.",
              spans: [],
            },
          ],
        },
        {
          question: 'How safe is it to apply for a Morocco e-visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for a Morocco e-visa online is safe if you use our online visa application platform. We use advanced technology to ensure your data remains safe and encrypted. ',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a Morocco visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Morocco visa at least a two weeks before your intended travel date to allow ample time for processing. ",
              spans: [],
            },
          ],
        },
        {
          question: 'What should i do if my visa application was rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and online portal do not refund the fees, except for tryvisa. When applying through tryvisa, you will receive a maximum refund of ₹8000 or $100 on visa denial. The refund excludes any service fees.',
              spans: [
                {
                  start: 234,
                  end: 239,
                  type: 'strong',
                },
                {
                  start: 268,
                  end: 274,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I travel to other countries from Morocco on a Morocco tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: ' A Morocco tourist visa allows you to travel within Morocco only. If you wish to travel to other countries from Morocco, you will need the relevant visas for those countries.',
              spans: [
                {
                  start: 60,
                  end: 64,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I pay the fees for a Morocco visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can pay the fees for a Morocco visa online using your credit or debit card and approved mobile wallets during the application process.',
              spans: [],
            },
          ],
        },
        {
          question: "What happens if my Morocco tourist visa expires while I'm in Morocco?",
          answer: [
            {
              type: 'paragraph',
              text: 'Suppose your Morocco tourist visa expires while in the country. You should contact the local immigration office immediately to avoid legal issues. They may grant a visa extension, or you may be required to leave the country.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Before traveling to Morocco on a tourist visa, what health precautions should I take?',
          answer: [
            {
              type: 'paragraph',
              text: "It's advisable to check with your doctor or a travel health clinic about any recommended vaccinations or other health precautions before traveling to Morocco. Also, consider purchasing travel health insurance for your trip.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Morocco visa online if I live outside Delhi?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, tryvisa's Morocco e-visa online service is available to applicants worldwide. You can apply from the comfort of your home, regardless of location.",
              spans: [],
            },
          ],
        },
        {
          question: 'What is the process of getting a Morocco visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "The process of acquiring a Morocco visa online from tryvisa is straightforward. Visit the tryvisa's online portal, complete the application form, upload the required documents, and submit. Then, make the necessary payments and wait for the visa approval.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Morocco with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Morocco tourist visa does not permit the holder to undertake paid work in Morocco. If you intend to work in Morocco, you must apply for a work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Morocco visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Morocco visa online varies but typically takes around 2 days. However, applying well before your planned travel dates is always best.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Morocco visa online on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for visas on behalf of your family through tryvisa's Morocco evisa application portal. ",
              spans: [],
            },
          ],
        },
        {
          question: 'How much does a Morocco visa cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Morocco visa costs INR 9,700; tryvisa uses secure payment gateways to process your visa application fees, ensuring that your financial data is well-protected.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any special requirements for minors applying for a Morocco visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying online, you do not need any additional documents. However, additional documents such as a copy of their Morocco evisa, birth certificate or parental consent letter may be required for minors when travelling.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$da90f6bf-b8c5-45ea-92c8-dd8a7293ec63',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Morocco',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 5545,
    cost_max: 8220,
    cost_min: 4795,
  },
  KH: {
    name: 'Cambodia',
    code: 'KH',
    hero_image_url:
      'https://images.unsplash.com/photo-1594444406267-35a7730c6867?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q2FtYm9kaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Cambodia E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 4,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 170,
    eta_pre_travel_unit: null,
    entry_type: 'Single Entry ',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 3100,
    service_fees: 1450,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa ',
        description: 'The Cambodia E-Visa is a single entry visa valid for a miximum of 30 days',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'identity',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'notary',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'photo',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_nif',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 200,
              height: 200,
            },
            alt: 'Cambodia Ministry of Foreign Affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ae123244-e1ae-43d5-a4ad-8ff371e84fa4_Cambodia_Ministry_of_Forgein_Affairs.png?auto=compress,format',
            id: 'ZdRSJxEAACwAPn9F',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 346,
              height: 146,
            },
            alt: 'Cambodia e-visa portal logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5572d9b2-a701-439c-ae09-f5efcb172329_Cambodia+e-visa+portal.png?auto=compress,format',
            id: 'ZdRVaBEAAC0APo3N',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 652,
              height: 382,
            },
            alt: 'Ministry of Tourism Cambodia ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b68ff842-3ac3-4bec-9ce0-4804240e3953_Ministry+of+Tourism+Cambodia.png?auto=compress,format',
            id: 'ZdRWfREAACkAPpK3',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$810180de-8115-48a6-84f3-200c49f64a55',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need to apply for a Cambodia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply, you need the following travel documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How does the Cambodia e-visa process through tryvisa work?',
          answer: [
            {
              type: 'paragraph',
              text: "The Cambodia e-visa process is simple. After applying online and paying the fee, you'll receive your e-visa via email. You need to print this document and carry it with you during your travels.",
              spans: [],
            },
          ],
        },
        {
          question: 'Is the Cambodia e-visa valid for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the standard Cambodia e-visa is valid for a single entry. If you plan to leave and re-enter the country, you may need a different type of visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need a physical copy of my Cambodia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you need to print a copy of your Cambodia e-visa and carry it with you at all times during your trip.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Cambodia visa online application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you may reapply for the visa after resolving all reasons for the denial. However, visa fees are non-refundable through government and online portals. Fortunately, tryvisa provides a refund in the event of visa rejection. The refund is up to ₹8000 ($100) but will exclude any service fees.',
              spans: [
                {
                  start: 176,
                  end: 187,
                  type: 'strong',
                },
                {
                  start: 206,
                  end: 212,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is the Cambodia e visa review process lengthy?',
          answer: [
            {
              type: 'paragraph',
              text: 'No. Typically, the Cambodia e visa review process is not lengthy. It usually takes about 3 business days to process your application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Cambodia visa online on behalf of someone else?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for a Cambodia visa online on behalf of someone else. You'll need to provide their personal details and necessary documents.",
              spans: [],
            },
          ],
        },
        {
          question:
            'Do I need to have a confirmed itinerary to apply for a Cambodia visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a confirmed itinerary is not a mandatory requirement to apply for a Cambodia visa for Indians. However, it can be helpful in some cases.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the Cambodia visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Cambodia visa fees for Indians through tryvisa are INR 3 485 for a single entry visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an emergency or expedited processing option for the Cambodia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No. Currently, there isn't an official expedited or emergency processing option for the Cambodia e-visa. However, in urgent situations, you may try contacting the Cambodian immigration authorities for assistance.",
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if I lose my Cambodia e-visa during my trip?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you lose your printed Cambodia e-visa during your trip, you can download another copy of your visa from the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for my Cambodia visa online if my passport is about to expire?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, your passport must be valid for at least 6 months from the date of entry into Cambodia.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of the Cambodia e-visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Cambodia e-visa for Indians is generally valid for 90 days from the date of issuance. It allows a stay of up to 30 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if I overstay my Cambodia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Overstaying your Cambodia visa violates immigration rules and can result in fines, deportation, or a ban from reentering the country.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Cambodia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Cambodia visa online through the tryvisa online portal or app.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to get a Cambodia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Typically, it takes 3 business days to process a Cambodia e-visa application. However, processing times may vary, especially during peak tourist seasons.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$34f1de0a-df40-4e67-9abe-1a68bbe3d86c',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Cambodia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 4550,
    cost_max: 6000,
    cost_min: 3800,
  },
  AG: {
    name: 'Antigua & Barbuda',
    code: 'AG',
    hero_image_url:
      'https://images.unsplash.com/photo-1582300857444-5ddd87c86797?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QW50aWd1YSUyMCUyNiUyMEJhcmJ1ZGF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Antigua and Barbuda E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 8500,
    service_fees: -8500,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '6 Months Bank Statement',
        description: 'Last 6 Months Bank Statement',
        label: 'Last 6 Months Bank Statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'bank_statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Birth Certificate',
        description: 'Birth Certificate',
        label: 'Birth Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'birth_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Reservations',
        description: 'Hotel Reservations',
        label: 'Hotel Reservations',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Hotels',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flights',
        description: 'Flight Bookings',
        label: 'Flight Bookings',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'flights',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Letter of Employement',
        description: 'Letter of Employement',
        label: 'Letter of Employement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'employement_r',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 340,
              height: 405,
            },
            alt: 'Antigua and Barbuda Ministry of Foreign Affairs..',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1c12965d-968f-4e1a-beca-150fc1e03013_Government+logo+of+Antigua+and+Barbuda.png?auto=compress,format',
            id: 'ZW8UYxEAACMAqDMT',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 750,
              height: 398,
            },
            alt: 'Antigua and Barbuda Tourism Board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3f74f87c-e065-4d32-966b-4d5e3d5cdc50_consulate+general+of+Antigua+and+Barbuda.png?auto=compress,format',
            id: 'ZW-FqxEAACYAqkEA',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 250,
              height: 92,
            },
            alt: 'Antigua and Barbuda national airline.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/f533d821-2e4e-4720-ad1f-f7b2dc6db4b9_ABM_Air_logo.png?auto=compress,format',
            id: 'ZW-GnBEAACYAqkVi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$79f9a9a5-0e90-425b-b9db-67630cbe13ad',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements when applying for an Antigua and Barbuda visa?',
          answer: [
            {
              type: 'paragraph',
              text: "When applying for your Antigua and Barbuda e-visa, you must submit a copy of your passport's front and back page and a digital photograph.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'After you have checked out, you need to also provide the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Bank statements to prove you have sufficient funds.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight tickets to show your date of arrival and departure.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Accommodation bookings for proof of stay.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Employment letter to show proof of financial income.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Birth certificate to prove your personal details.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I get a multi-entry visa for Antigua and Barbuda?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indians can apply for an Antigua and Barbuda multi-entry visa on the government website.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it easy to apply for an Antigua and Barbuda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, applying for your Antigua and Barbuda e-visa on tryvisa is very straightforward. Simply:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Submit your documents.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Provide your personal details.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Pay the visa fees.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Wait for your visa to be processed.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Note that the refund excludes the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 248,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my stay in Antigua and Barbuda on an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the e-visa for Antigua and Barbuda can be extended. To extend your visa, you need to visit the immigration department in Antigua and Barbuda and apply for a visa extension.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Antigua and Barbuda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised that you apply for your visa at least 14 days before you travel to Antigua and Barbuda.',
              spans: [
                {
                  start: 52,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the validity period of an Antigua and Barbuda e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Tourist visas are typically valid for a specific duration, often up to 90 days.',
              spans: [
                {
                  start: 71,
                  end: 78,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I include friends or relatives in my visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when applying on tryvisa, you can add your friends or relatives to your visa application and apply for their visa at the same time.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 36,
                  end: 66,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What would cause my visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Multiple reasons can be the cause of your visa getting rejected. Some of them are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Inaccurate information on your documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing sufficient proof of funds.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your passport is not valid for 6 more months from the day of your application.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the Antigua and Barbuda e-visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Antigua and Barbuda visa on tryvisa, the fee is ₹11,000',
              spans: [
                {
                  start: 70,
                  end: 77,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Antigua and Barbuda with an online visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Antigua and Barbuda allows Indians to stay in the country for 30 days.',
              spans: [
                {
                  start: 77,
                  end: 84,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does it take to process a visa application for Antigua and Barbuda?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Antigua and Barbuda visa processing time on tryvisa takes 8 days. However, any delays can cause the processing time to be longer.',
              spans: [
                {
                  start: 60,
                  end: 66,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory for visiting Antigua and Barbuda?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not mandatory, having travel insurance is highly recommended when travelling to a foreign country.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I enter Antigua and Barbuda multiple times with a single-entry visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can only enter Antigua and Barbuda once when you have a single entry visa. If you wish to enter the country multiple times, you must apply for a multi-entry visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to provide my biometrics when travelling to Antigua and Barbuda?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, your fingerprints will be taken on arrival in Antigua and Barbuda.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will I get a refund if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the visa fees are used to process your visa, which means they can not be refunded even if your visa gets denied.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Antigua and Barbuda visa if my passport expires soon?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for an Antigua and Barbuda e-visa, ensure your passport is valid for at least six months beyond your planned arrival date.',
              spans: [
                {
                  start: 73,
                  end: 102,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$dbbc2d86-fa19-4f65-8080-2ac0fab9fa0a',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Antigua & Barbuda',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  AM: {
    name: 'Armenia',
    code: 'AM',
    hero_image_url:
      'https://images.unsplash.com/photo-1600758208050-a22f17dc5bb9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QXJtZW5pYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Armenia E-Visa',
    supported: false,
    message: 'The contry is unsupported, we are working on it.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '21 days',
    entry_validity: '60 days',
    govt_fees: 700,
    service_fees: -700,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description: 'An E-Visa is required to enter Armenia. The visa is valid for 21 days,',
      },
    ],
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Tickets',
        description: '',
        label: 'Upload your flight tickets',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'flights',
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Voucher',
        description: '',
        label: 'Uplaod your hotel bookings',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'hotels',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Travel Insurance',
        description: '',
        label: 'Please upload your Travel Insurance',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'insurance',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required'],
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1153,
            },
            alt: 'Coat of Arms of Armenia.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0708d088-1427-43f9-8f2d-b40ad1aef5b5_Coat_of_arms_of_Armenia.svg.png?auto=compress,format',
            id: 'ZXIhxxEAACYAtiZI',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 479,
              height: 480,
            },
            alt: 'Ministry of Forgein Affairs of Armenia',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3a438008-720c-4dbf-80c6-f251474f0b59_Ministry+of+Forgein+Affairs+of+Armenia+Logo.png?auto=compress,format',
            id: 'ZdRnSxEAACkAPu_s',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 768,
            },
            alt: 'Armenia airways logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5d152f8c-0e23-4fc9-bdd6-6085c2a9cba8_Armenia_Airways_Logo.svg.png?auto=compress,format',
            id: 'ZXImDxEAACQAtjoJ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$e3061d62-a49f-4a79-b130-57cc5dbd1dde',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way of getting a visa for Armenia?',
          answer: [
            {
              type: 'paragraph',
              text: 'The fastest way of applying for an Armenia visa is through tryvisa. The application process on tryvisa is straightforward and allows you to apply for your e-visa in under 10 minutes.',
              spans: [
                {
                  start: 59,
                  end: 64,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the requirements for the Armenia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for the e-visa for Armenia on tryvisa, you must upload the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'The front page of your passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'The back page of your passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A digital photo.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'After you have checked out, you must also provide a copy of your bank statements.',
              spans: [
                {
                  start: 52,
                  end: 80,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend My Armenia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indians can extend the Armenia visa. To extend your visa, visit the Migration and Citizenship Service of the RA Ministry of Internal Affairs and submit a visa extension application.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance necessary for the Armenia e visa?',
          answer: [
            {
              type: 'paragraph',
              text: "While it's not compulsory, it's highly recommended to have travel insurance when applying for the Armenia e visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I reapply if my Armenia e-visa application was rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply by submitting a new application on the official Armenia e-visa website, ensuring you address the reasons for your initial rejection. You must pay the visa fees again when reapplying since the government and online portals do not provide a refund. However, applying through tryvisa guarantees a refund of up to ₹8000 ($100) on rejected visas. The refund excludes the service fee charges.',
              spans: [
                {
                  start: 263,
                  end: 270,
                  type: 'strong',
                },
                {
                  start: 289,
                  end: 305,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my Armenian visa online application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: "If your Armenia visa online application is denied, you'll be notified via email with the reasons for denial.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Armenia with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, an Armenia e visa is only for tourism purposes. You'll need to apply for a work visa to work in Armenia.",
              spans: [],
            },
          ],
        },
        {
          question:
            'Does the Armenia e-visa application require a physical stamp or sticker on my passport?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the Armenia e-visa is a digital visa and is sent via email; you can also download it from the tryvisa app.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do children also need an Armenia e visa to travel?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, all Indian citizens, including minors, need an Armenia e visa to travel to Armenia.',
              spans: [],
            },
          ],
        },
        {
          question: 'Does an Armenia e visa guarantee entry into the country?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the final decision on entry is made by the Armenian immigration authorities at the port of entry.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process an Armenia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time takes about 3-5 business days to process an Armenia e-visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I pay the Armenia visa fee online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Armenia visa fee can be paid online during the e-visa application process.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get an Armenia multi-entry visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can get a multi-entry visa for Armenia. However, the multi-entry visa can only be applied for at an embassy. The e-visa is only issued as a single-entry visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I check my Armenia e-visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'After your visa application, you can track your visa by checking your Armenia visa status. To see your visa status on tryvisa, you must:',
              spans: [
                {
                  start: 70,
                  end: 89,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/armenia-visa-status',
                  },
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on your profile.',
              spans: [
                {
                  start: 0,
                  end: 5,
                  type: 'em',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you want to see the status of.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you have selected the visa, you can see its current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my Armenia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Armenia e-visa is usually processed in 7 days. So, applying for your visa two weeks before you travel is advised.',
              spans: [
                {
                  start: 78,
                  end: 94,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an e-visa if my passport is about to expire?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for an Armenia e-visa, you must ensure that your passport is valid for the duration of your stay in Armenia.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the Armenia e-visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa fee for Armenia on tryvisa is ₹1900.',
              spans: [
                {
                  start: 39,
                  end: 45,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory when applying for the Armenia e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for the Armenia e-visa. However, it is always recommended to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the reasons for my visa being denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can be the cause of your visa getting rejected. Some of these reasons include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Inaccurate information on your documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of funds to cover your stay period.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal history with a serious offence.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$e497ed10-d3c7-4b88-842e-2f33da3fe1f6',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Armenia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  AO: {
    name: 'Angola',
    code: 'AO',
    hero_image_url:
      'https://images.unsplash.com/photo-1520709087497-0749666fe1d2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8QW5nb2xhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Angola E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 5000,
    service_fees: -5000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Tickets',
        description: 'Flight Tickets',
        label: 'Flight Tickets',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'flights',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Reservation',
        description: 'Hotel Reservation',
        label: 'Hotel Reservation',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'hotel',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 640,
              height: 723,
            },
            alt: 'Republic of Angola emblem.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f732f84-46bc-4438-973f-9233844d9ea9_Emblem_of_Angola.svg.png?auto=compress,format',
            id: 'ZW77LhEAACQAp7xQ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1024,
              height: 978,
            },
            alt: 'Migration and foreigners service of Angola.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ec51efd3-3c1f-40f7-a237-b5ead00d3a01_SME+Angola.png?auto=compress,format',
            id: 'ZW94YhEAACMAqgFe',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 666,
              height: 285,
            },
            alt: 'Angola Ministry of Foreign affairs.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/fcaed4af-5df4-4b1c-8972-2c2d28254a41_Angola+ministry+of+foreign+affairs.png?auto=compress,format',
            id: 'ZW-B9REAACYAqi_T',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 831,
              height: 300,
            },
            alt: 'Angola airline logo ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d0b9511b-a585-4fbe-93b0-7060a59e6d9e_Angola+airlines+logo.png?auto=compress,format',
            id: 'ZdXeExEAACwARaPB',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$916a846a-6d69-4230-8962-46e48617a2e3',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need to apply for my Angola visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Angola visa on tryvisa, all you need to submit is a copy of the front and back of your passport and a passport-sized photo.',
              spans: [
                {
                  start: 86,
                  end: 91,
                  type: 'strong',
                },
                {
                  start: 96,
                  end: 100,
                  type: 'strong',
                },
                {
                  start: 124,
                  end: 144,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I track my Angola visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can do an Angola visa status check on tryvisa to track your visa. Here are the steps to follow:',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Scroll down to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on the visa to see the current status of your Angola visa online.',
              spans: [
                {
                  start: 0,
                  end: 5,
                  type: 'em',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need a yellow fever vaccination when travelling to Angola?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must have a yellow fever vaccination when travelling to Angola.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Angola visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa at least 3 weeks before you travel is advised.',
              spans: [
                {
                  start: 32,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Angola with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa for Angola allows you to stay in the country for 30 days.',
              spans: [
                {
                  start: 65,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Angola visa online as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for an Angola visa online at tryvisa. Once approved, you will receive an arrival card. With the arrival card, you can travel to Angola and collect your visa at the border point.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 50,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I travel to Angola for business purposes on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can not travel to Angola for business reasons with a tourist visa visa. To perform business activities, you must apply for a business visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for an Angola tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Angola online visa takes about 7 days to be processed on tryvisa. However, this timeframe could be longer if there are any delays with your visa.',
              spans: [
                {
                  start: 35,
                  end: 41,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa for Angola gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, you can reapply. When doing so, ensure that all documents are correct and accurate. Visa fees must be paid again since the government and online portals do not provide refunds. Fortunately, when applying through tryvisa, you are guaranteed a refund if your visa is denied, up to ₹8000 or $100. This refund excludes service fees.',
              spans: [
                {
                  start: 215,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 250,
                  end: 255,
                  type: 'strong',
                },
                {
                  start: 277,
                  end: 284,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get an Angola visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indians can only receive a visa on arrival for Angola when they have applied for the arrival card online. The arrival card is what you receive when applying for a visa on an online portal; this is known as an e-visa on arrival, allowing you to get a pre-approved visa, which is collected on arrival in Angola.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the Angola visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Angola online visa fee for Indians on tryvisa is ₹7100.',
              spans: [
                {
                  start: 51,
                  end: 57,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is my Angola visa valid for?',
          answer: [
            {
              type: 'paragraph',
              text: 'The online visa for Angola is valid for 60 days from the day it gets approved.',
              spans: [
                {
                  start: 40,
                  end: 47,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Angola visa photo specifications?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your photo for the Angola visa must be taken on a white background and can not be larger than 200 KB. The file must be uploaded in JPEG format. The photo size must have a width of 381-571 pixels and a height of 496 - 744 pixels.',
              spans: [
                {
                  start: 50,
                  end: 66,
                  type: 'strong',
                },
                {
                  start: 94,
                  end: 100,
                  type: 'strong',
                },
                {
                  start: 171,
                  end: 194,
                  type: 'strong',
                },
                {
                  start: 201,
                  end: 227,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What methods can I use to pay my Angola visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your visa on tryvisa, you can pay the Angola visa fee using your credit/debit card or one of the approved mobile wallets.',
              spans: [
                {
                  start: 82,
                  end: 99,
                  type: 'strong',
                },
                {
                  start: 114,
                  end: 137,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will I get a refund if my visa for Angola is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the visa fees are non-refundable. This is because the fees are used to complete your visa processing.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory for an Angola visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for a visa to Angola. However, it is advised to have travel insurance whenever you travel to a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$e3dd8fc1-3b72-40c5-9a15-e8db6b61a14e',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Angola',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  BF: {
    name: 'Burkina Faso',
    code: 'BF',
    hero_image_url:
      'https://images.unsplash.com/photo-1645531635989-2102135314c3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8QnVya2luYSUyMEZhc298ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Burkina Faso E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 6086.99,
    service_fees: -6086.99,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Covid 19 Vaccine Certificate',
        description: 'Please uplaod Covid 19 Vaccine Certificate',
        label: 'Please upload Covid 19 Vaccine Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'covid_vaccination',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 184,
              height: 132,
            },
            alt: 'Burkina Faso e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/67938ced-01e0-4199-987f-f03d9074af34_Burkina_Faso_e-visa_logo.png?auto=compress,format',
            id: 'ZdPKShEAACwAPCQF',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 455,
              height: 549,
            },
            alt: 'Burkina Faso coat of arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2ff3e32f-780c-4b32-8261-107da2cf3f11_Burkina_Faso_Coat_of_arms.png?auto=compress,format',
            id: 'ZdPK4xEAACgAPCas',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 755,
            },
            alt: 'Logo of Air Burkina.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/340b69f1-e7d7-4bcb-96e9-93c483f4a482_Air_Burkina_logo.svg.png?auto=compress,format',
            id: 'ZbdoTBEAACAAEFtX',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$6eca6a7c-de18-46f0-8292-908367a17fa7',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How long in advance Should I apply for my Burkina Faso visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa to Burkina Faso at least 2 weeks before your travel dates.',
              spans: [
                {
                  start: 62,
                  end: 76,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What Documents do I need to apply for a Burkina Faso e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your e-visa to Burkina Faso, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: A copy of your passport’s first page.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: A recently taken passport-size photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'International Certificate of Vaccination: Submit your vaccination card to prove your vaccination against yellow fever or other required vaccinations.',
              spans: [
                {
                  start: 0,
                  end: 41,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Accommodation: Submit a hotel reservation to prove your place of stay in Burkina Faso.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I apply for a Burkina Faso visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Burkina Faso visa from India through tryvisa or the government portal. When you apply through Atls, we will guide you through each step to ensure a smooth and hassle-free application.',
              spans: [
                {
                  start: 59,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for a Burkina Faso Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not a requirement. However, it is advised to have travel insurance whenever you visit a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the standard processing time for a Burkina Faso visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Processing times vary, but it usually takes 7 and 10 days. However, applying for your visa well in advance is advised to allow sufficient processing time and to account for delays.',
              spans: [
                {
                  start: 44,
                  end: 57,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the file size requirements when uploading my documents?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you upload your documents, you must ensure that the file size is no more than 1MB and that the file type is a JPG, JPEG, PNG, TIFF, or BMP.',
              spans: [
                {
                  start: 83,
                  end: 86,
                  type: 'strong',
                },
                {
                  start: 115,
                  end: 143,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the reasons that can cause my visa to be denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can be the reasons for your visa denial. The most common reasons include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing inaccurate information on your application.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having a passport valid for at least 6 months.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing sufficient proof of funds for the duration of your stay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Burkina Faso visa while in the country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, extensions are possible while in Burkina Faso. To extend your visa, you must visit the immigration authorities in Burkina Faso to apply for a visa extension.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the validity of a Burkina Faso tourist visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your visa on tryvisa, you will receive a visa valid for 30 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any health requirements for Burkina Faso visas?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes. a yellow fever vaccination is mandatory when applying for your e-visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Burkina Faso with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your visa gets approved, you will receive a single entry visa allowing you to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 49,
                  end: 66,
                  type: 'strong',
                },
                {
                  start: 113,
                  end: 120,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'After visa rejection, you will receive the reasons for the rejection reasons. Fix these reasons and reapply for your visa. Unfortunately, you will lose the funds when applying through the government or online portals. However, through tryvisa, you will receive a refund on visa rejections. The refund will be up to ₹8000 or $100, excluding the service fees.',
              spans: [
                {
                  start: 218,
                  end: 225,
                  type: 'strong',
                },
                {
                  start: 235,
                  end: 240,
                  type: 'strong',
                },
                {
                  start: 261,
                  end: 267,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will I receive a refund if my visa gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the visa fees are non-refundable since they are used to process your visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Burkina Faso with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the e-visa does not allow employment in Burkina Faso. To work in the country, you must first obtain the required employment visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Burkina Faso visa with a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Burkina Faso visa with a criminal record. However, this might delay the processing of your visa if the authorities find it necessary to do a background check.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long should my passport be valid to apply for a Burkina Faso visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months beyond your intended departure date from Burkina Faso.',
              spans: [
                {
                  start: 34,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4c6dd91d-2ae5-4901-91e1-9d44c58d1666',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Burkina Faso',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  BJ: {
    name: 'Benin',
    code: 'BJ',
    hero_image_url:
      'https://images.unsplash.com/photo-1600241005059-71de13374958?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QmVuaW58ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Benin E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 6,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 4500,
    service_fees: 3250,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 706,
            },
            alt: 'Coat of Arms of Benin',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9af15891-411a-46c5-bc5d-ec683eba0e55_Coat_of_arms_of_Benin.svg.png?auto=compress,format',
            id: 'ZXHIKhEAACMAtHzC',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 824,
              height: 463,
            },
            alt: 'E-visa logo of Benin',
            copyright: null,
            url: 'https://images.prismic.io/atlys/93606923-40ee-4aa7-b0df-fd34fdc920b2_e-visa+Benin.png?auto=compress,format',
            id: 'ZXHK5BEAACYAtIjV',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 4498,
              height: 704,
            },
            alt: 'Benin Airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4f69b9ec-f63c-4124-acc1-da014e991ba3_benin-airlines_logo.png?auto=compress,format',
            id: 'ZdPHtBEAACsAPBiW',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$819de4fe-0e57-4396-929f-1dc6ec10801a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way of getting a Benin visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to obtain your Benin visa as an Indian is to apply for an e-visa on tryvisa. With the easy application process, you can complete your application on tryvisa hassle-free in under 10 minutes.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians get a Benin visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians are not eligible for a visa on arrival. You must have a valid visa before travelling to Benin.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for a Benin visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Benin visa in tryvisa, you only need to provide your valid passport and a digital photo.',
              spans: [
                {
                  start: 75,
                  end: 89,
                  type: 'strong',
                },
                {
                  start: 96,
                  end: 109,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I trust online visa agents?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Benin visa agents such as tryvisa have a secure platform to protect your sensitive data during the application process. They comply with all data security laws to ensure your information is safe.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'When your visa is rejected, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees. Luckily, tryvisa provides a maximum refund of ₹8000 or $100 when your visa is rejected. Note that the refund does not include the service fees.',
              spans: [
                {
                  start: 205,
                  end: 212,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Benin visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Benin takes about 10 days to be processed. So applying for your visa 2 weeks before you travel is advisable.',
              spans: [
                {
                  start: 33,
                  end: 41,
                  type: 'strong',
                },
                {
                  start: 83,
                  end: 91,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I enter Benin multiple times with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the e-visa is only available as a single-entry visa, allowing you to enter the country only once. If you want to reenter Benin, you must reapply for a visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 38,
                  end: 55,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Benin with my e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa allows Indians to stay in Benin for 30 days.',
              spans: [
                {
                  start: 47,
                  end: 54,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I have to provide my passport in person when applying for an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the e-visa is an electronic visa, allowing you to apply without submitting your passport or visiting an embassy.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I track my Benin visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when you apply on tryvisa, you can easily check your visa status through the app. To do so, follow these instructions:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Get the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you click on the visa, you can see its current status.',
              spans: [
                {
                  start: 8,
                  end: 9,
                  type: 'strong',
                },
                {
                  start: 9,
                  end: 14,
                  type: 'em',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Benin e-visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Benin e-visa on tryvisa, the fee is ₹7000.',
              spans: [
                {
                  start: 58,
                  end: 64,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when travelling to Benin?',
          answer: [
            {
              type: 'paragraph',
              text: 'While travel insurance is not mandatory, it is advised to have travel insurance when you visit a foreign country.',
              spans: [
                {
                  start: 26,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when applying through tryvisa, you can pay for your e-visa online using either a credit/debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 84,
                  end: 101,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is the e-visa valid for?',
          answer: [
            {
              type: 'paragraph',
              text: 'After the day of approval, your Benin visa will be valid for 60 days.',
              spans: [
                {
                  start: 60,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my Benin e-visa over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, tryvisa is available to use online 24/7. This allows you to apply for your visa even over the weekend.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 31,
                  end: 43,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa processing time to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Multiple factors can cause your visa to be delayed. Some of these are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Submitting inaccurate documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'When applying during peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'If you have a criminal history.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$9ed86725-1895-4f9a-814b-8e60b6f33c18',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Benin',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 7750,
    cost_max: 7000,
    cost_min: 7000,
  },
  BW: {
    name: 'Botswana',
    code: 'BW',
    hero_image_url:
      'https://images.unsplash.com/photo-1591005383946-16532ba69aee?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Qm90c3dhbmF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Botswana E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 1834.45,
    service_fees: -1834.45,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 443,
              height: 341,
            },
            alt: 'Botswana Code of Arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/17a365c3-8f86-41f3-b8d4-0390f2493c70_Code-of-Arms-colour+Botswana.png?auto=compress,format',
            id: 'Zbc5qhEAACAAEBH5',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 548,
            },
            alt: 'Air Botswana Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cb7aafb2-020c-456b-bc6a-7eb13154f813_Air_Botswana_logo.svg.png?auto=compress,format',
            id: 'ZdPFuhEAAC4APAu_',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 426,
              height: 118,
            },
            alt: 'Botswana Tousim logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9d67ea69-846b-4828-af85-7ab13e86e954_Botswana+tousirm+logo.png?auto=compress,format',
            id: 'ZdPGMREAACcAPA9g',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$d00075a5-93e0-4c78-88d5-63f91a1a2893',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'Do Indians need a visa to visit Botswana?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens are required a visa to enter Botswana.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way to apply for a Botswana visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest and fastest way to get your visa for Botswana is to apply through tryvisa. At Atyls, we will guide you through your application process, allowing you to complete the application in under 5 minutes. We will also keep you updated about the process of your visa.',
              spans: [
                {
                  start: 78,
                  end: 83,
                  type: 'strong',
                },
                {
                  start: 197,
                  end: 206,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents are required for a Botswana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your visa to Botswana, these are the documents you will require:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: You must submit a valid passport with at least 3 blank pages.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: You will be required to upload a recently taken photo.',
              spans: [
                {
                  start: 0,
                  end: 7,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Invitation Letter: Submit an invitation letter from a host in Botswana.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Return Flight Ticket: Show proof that you have a flight back home by submitting a flight ticket.',
              spans: [
                {
                  start: 0,
                  end: 21,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof Of Sufficient Funds: Show proof that you have sufficient funds for your trip to Botswana.',
              spans: [
                {
                  start: 0,
                  end: 26,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Marriage/Birth Certificate: Notarised copies of marriage and birth certificates for your spouse and any dependents.',
              spans: [
                {
                  start: 0,
                  end: 27,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: "Host National ID Card: Notarised copies of your host's national identification card, residence and work permits, or exemption certificates. If a church is inviting you, notarise ordination certificates from religious leaders.",
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Return Visa/Resident Permit: A valid return visa or residence permit from your country of current residence.',
              spans: [
                {
                  start: 0,
                  end: 28,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Parent Consent Letter: For minors under 18 travelling without their biological parents, a consent letter from both parents is needed.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I check my Botswana e-visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you have applied for your e-visa on tryvisa, you can easily check your visa status through the tryvisa app:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'First, download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your “Profile”.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Find the visa you have applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you click on your visa, you will be able to see its current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I reapply if my Botswana visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are provided and you meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Instead, you can apply through tryvisa, where you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. This refund will not include the service fee.',
              spans: [
                {
                  start: 122,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 158,
                  type: 'strong',
                },
                {
                  start: 186,
                  end: 193,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long in advance must I apply for my Botswana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa to Botswana at least 2-3 weeks before your travel dates.',
              spans: [
                {
                  start: 58,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it possible to get a visa on arrival in Botswana?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian nationals can not get a visa Botswana visa on arrival.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my stay in Botswana?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, extensions are possible, but you must apply at the Department of Immigration before your current visa expires. To apply for your visa extension, you must have the following documents:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Invitation Letter: An invitation letter from your host in Botswana.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Covering Letter: Covering letter from the applicant indicating the reasons for extension of days.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: One coloured passport-size photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Valid Passport: Certified copy of valid applicant’s passport information page.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Copy of Passport: Two copies of your passport pages showing entry stamps of countries visited.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Host ID: Certified copy of the Host’s Residence or Exemption Certificate Permit or Certified copies of a valid national identity card (Omang).',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Are there any specific health requirements for Botswana visas?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, travellers travelling through or visiting from a yellow fever country must have a yellow fever vaccination.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a multiple-entry Botswana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your e-visa through tryvisa, you will receive a single entry visa. However, if you apply through the embassy, you can obtain a multi-entry visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for my Botswana trip?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not mandatory for a visa application, having travel insurance is highly recommended for medical emergencies and unexpected events.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Botswana visa if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can still apply for a visa with a criminal history. However, your application may be delayed if authorities find it necessary to do a background check.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for a Botswana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Botswana e-visa processing times take about 7 days to be processed. This time might take longer depending on various factors, such as peak travel seasons, and application mistakes.',
              spans: [
                {
                  start: 48,
                  end: 54,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long should my passport be valid to apply for a Botswana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months beyond your intended departure date from Botswana.',
              spans: [
                {
                  start: 34,
                  end: 54,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is the Botswana e-visa valid for?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Botswana is valid for 90 days once it gets approved.',
              spans: [
                {
                  start: 37,
                  end: 44,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$907d8935-d0d7-4d34-9686-9e1b4b6cdf78',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Botswana',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  CY: {
    name: 'Cyprus',
    code: 'CY',
    hero_image_url:
      'https://images.unsplash.com/photo-1602521163379-79fba9eac5e6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Q3lwcnVzfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Black - Cyprus visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 15,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '90 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1211,
            },
            alt: 'Cyprus ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e7649e41-aba9-4bde-94f7-6b4f1b2c9e45_Coat_of_arms_of_Cyprus_.png?auto=compress,format',
            id: 'ZYEr_hEAACcA4_m1',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 487,
              height: 175,
            },
            alt: 'Deputy Ministry of Tourism, Cyprus logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/7b32b509-d875-4de0-b30a-259ba5feddb5_Cyprus+ministry-of-tourism-logo.png?auto=compress,format',
            id: 'ZYEtuREAACQA5AFv',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 587,
            },
            alt: 'Cyprus Airways logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a1311f73-2dad-410c-9670-e69b9fdfd3c7_Cyprus_Airways.png?auto=compress,format',
            id: 'ZYEwHhEAACQA5Awq',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$842bfffa-4ba4-4e90-9431-d42e3ea3024e',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Cyprus',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  ET: {
    name: 'Ethiopia',
    code: 'ET',
    hero_image_url:
      'https://images.unsplash.com/photo-1572888195250-3037a59d3578?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8RXRoaW9waWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Ethiopia E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 4,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 7050,
    service_fees: -2800,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 583,
              height: 168,
            },
            alt: 'Ethiopia E visa official website',
            copyright: null,
            url: 'https://images.prismic.io/atlys/56f25563-d990-4ff7-977c-99da9eb33aa8_Ethiopia+e-visa+official+website.png?auto=compress,format',
            id: 'ZJWKABEAACIAl-pn',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 592,
              height: 85,
            },
            alt: 'Ethiopia ICS immigration and Citizenship Services logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d160966d-784d-4ed4-859b-0cbe061e3988_Ethiopia+ICS+services+logo.png?auto=compress,format',
            id: 'ZdRR6xEAAC4APn4t',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1280,
              height: 539,
            },
            alt: 'Ethiopian Airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/043d9e28-9f20-4387-9ffb-774287143556_Ethiopian_Airlines_Logo.svg.png?auto=compress,format',
            id: 'ZdRTqhEAAC4APoX8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$d37c97b7-9968-46cf-aac4-8424bc123aae',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for an Ethiopian visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents for an Ethiopian visa are a valid passport, two passport-size photos, proof of accommodation, sufficient funds for the time of our stay, and a confirmed onward or return flight ticket.',
              spans: [
                {
                  start: 57,
                  end: 65,
                  type: 'strong',
                },
                {
                  start: 85,
                  end: 91,
                  type: 'strong',
                },
                {
                  start: 93,
                  end: 115,
                  type: 'strong',
                },
                {
                  start: 116,
                  end: 133,
                  type: 'strong',
                },
                {
                  start: 193,
                  end: 206,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I know the status of my Ethiopian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Ethiopian visa on tryvisa, you will be notified of your visa process via email and messages. You can also log onto the tryvisa portal and see the current status of your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the photo size requirements for the Ethiopian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The correct photo size for your Ethiopian visa application is 34mm by 45mm. It must be a color photo with a white background. You can simply upload your photo on a plain background, and tryvisa will automatically crop the image to meet the visa requirements',
              spans: [
                {
                  start: 62,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I enter Ethiopia with an e-visa from any port of entry?',
          answer: [
            {
              type: 'paragraph',
              text: 'As an Indian national with an e-visa, you will need to enter Ethiopia from theAddis Ababa Bole International Airport.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need travel insurance to get an Ethiopian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you don’t need travel insurance to apply for your Ethiopian visa, but having travel insurance is highly recommended.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long before I go to Ethiopia should I apply for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Even though the processing times for an Ethiopian visa are relatively quick, It is advised that you apply for your visa 1-2 weeks before you travel to Ethiopia.',
              spans: [
                {
                  start: 120,
                  end: 129,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I use my e-visa for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you cannot enter Ethiopia multiple times on an e-visa; the e-visa is a single-entry visa. To enter multiple times, you will need to reapply for a visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to have any specific vaccinations to enter Ethiopia?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are currently no vaccines required if you want to visit Ethiopia.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the quickest way to get an Ethiopian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying on tryvisa for your visa is the quickest way to get your Ethiopian visa. You can complete the application in under 10 minutes, and from there, your visa will be processed.',
              spans: [
                {
                  start: 116,
                  end: 132,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the application process for an Ethiopian visa for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Ethiopian visa application process is very simple. You will simply gather all the required documents, go to tryvisa, fill out the application, submit the documents, and then wait for your visa to be processed.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Ethiopian visa while I am there?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is possible to extend your visa while you are in Ethiopia. You will need to visit the Head Office of Immigration and Citizen Service, where you will then apply for a visa extension. Keep in mind that you will need to apply for an extension before your visa expires.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and online portal do not refund the fees, except for tryvisa. At tryvisa, you will receive a refund when your visa is rejected. The refund is up to ₹8000 or $100 and excludes service fees.',
              spans: [
                {
                  start: 215,
                  end: 220,
                  type: 'strong',
                },
                {
                  start: 241,
                  end: 247,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What would the reason be for an Ethiopian visa rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are numerous reasons for your visa not being approved; some of them are inaccurate information, reasons for your stay, and not having enough funds for your stay period.',
              spans: [
                {
                  start: 78,
                  end: 100,
                  type: 'strong',
                },
                {
                  start: 102,
                  end: 123,
                  type: 'strong',
                },
                {
                  start: 129,
                  end: 152,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my Ethiopian visa online over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for your visa over the weekend; the tryvisa portal is available 24/7. The visa processing will only happen on business days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the types of payments accepted for the Ethiopian visa fee?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can pay for your e-visa online with tryvisa; you can simply use a debit card, credit card, or your preferred mobile wallet. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the e-visa price for Ethiopia?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa Ethiopian price is about ₹8550. But the price can change, and sometimes there are processing fees, so it is best to check the fee before you apply.',
              spans: [
                {
                  start: 37,
                  end: 41,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for an Ethiopian visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for the Ethiopian visa is relatively quick; on average, it takes about 7 days.',
              spans: [
                {
                  start: 91,
                  end: 97,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Ethiopia with the e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Ethiopian e-visa allows you to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 64,
                  end: 72,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indian nationals get an Ethiopian visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians cannot obtain an Ethiopian visa on arrival; you will have to apply for a visa before you go to Ethiopia.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a Specific dress code for traveling to Ethiopia?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no specific dress code for Indian nationals who want to travel to Ethiopia. But it is always advised to dress neatly.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any question in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4f45eba9-3e6b-4fd7-8d8a-69dd6e5371b7',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Ethiopia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 4250,
    cost_max: 10052,
    cost_min: 3500,
  },
  GN: {
    name: 'Guinea',
    code: 'GN',
    hero_image_url:
      'https://images.unsplash.com/photo-1615608178738-37d47d27c13d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8R3VpbmVhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Evisa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 12,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 6700,
    service_fees: 1050,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '6 Months Bank Statement',
        description: '6 Months Bank Statement',
        label: 'Please upload 6 Months Bank Statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'bank_statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Yellow Fever Certificate',
        description: 'Yellow Fever Certificate',
        label: 'Yellow Fever Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'yellow_fever',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1080,
              height: 990,
            },
            alt: 'Police National website of Guinea.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2094cf5d-2d05-4e01-b506-98034784f1a7_Guinea+Visa+Website+logo.png?auto=compress,format',
            id: 'ZXqxzREAACIAMWRy',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 439,
              height: 198,
            },
            alt: 'Guinea Airline Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c9f43e59-f06b-472c-a58e-c034fe52f539_Guinea+airlines..png?auto=compress,format',
            id: 'ZdSlWBEAACgAQCwk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 440,
              height: 567,
            },
            alt: 'Coat of Arms for Guinea',
            copyright: null,
            url: 'https://images.prismic.io/atlys/91a5c7f3-9553-436f-aab3-f6625a3eae14_Coat+of+arms+Guinea..png?auto=compress,format',
            id: 'ZdSoqxEAAC0AQDrN',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$85203214-2558-4920-b1d2-1c2cb594f3ac',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the general requirements for a Guinea visa as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'The general documents required for a Guinea e-visa include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Travel Document: A valid passport or travel document.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Vaccinations: You need a valid yellow fever vaccination.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: You need to submit 1x passport-sized photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport Data Page: You need 1x scanned copy of your passport’s data page.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Ticket: You must prove you have a return flight by submitting a return ticket.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statements: You must prove you have enough funds to cover your stay in Guinea. This is done by submitting your bank statements.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I apply for a Guinea visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to apply for your Guinea visa from India is through tryvisa. With a smooth application process, you can complete your application online in under 10 minutes.',
              spans: [
                {
                  start: 68,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is there an interview required when I apply for a Guinea tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no interview required for the Guine tourist visa. The Guinea e-visa is an online application. You can apply for and obtain your visa without visiting an embassy or consulate.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'When should I start the application process for a Guinea visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Guinea e-visa takes about 12 days to get processed. Hence, applying for your visa at least 2-3 weeks before travelling is advised.',
              spans: [
                {
                  start: 95,
                  end: 122,
                  type: 'strong',
                },
                {
                  start: 133,
                  end: 134,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need when entering through immigration in Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'The following documents are required when entering through Guinea immigration:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Valid Passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your approved visa.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Sufficient funds (Cash/ Travellers Cheque/Debit or Credit Cards) to cover expenses during your stay in Guinea.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid return ticket.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of accommodation.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I receive my visa once it is approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'When your e-visa gets approved, you will be notified via e-mail. You can then download your electronic visa from your tryvisa profile or email.',
              spans: [
                {
                  start: 78,
                  end: 86,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if I provide incorrect documents for my visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you haven’t provided all the required documents, you can submit them afterwards. This will, however, delay the processing of your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How does having a criminal record impact my Guinea visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Having a criminal record can sometimes delay the processing of your visa. This is because the authorities usually do a background check to see the severity of your offence and if there is any risk to national security. Once the background check is complete and there is no reason to reject the visa, your visa will continue processing.',
              spans: [
                {
                  start: 39,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a visa to Guinea if my passport expires soon?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your visa, your passport must be valid for at least six more months from the day you enter Guinea.',
              spans: [
                {
                  start: 71,
                  end: 86,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are provided and you meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Instead, you can apply through tryvisa, where you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. This refund will not include the service fee.',
              spans: [
                {
                  start: 122,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 158,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when visiting Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'While travel insurance is not mandatory when applying for a Guinea visa, it is recommended to have travel insurance whenever you visit a foreign country.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long is my Guinea e-visa valid?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your visa gets approved, it will be valid for 90 days.',
              spans: [
                {
                  start: 51,
                  end: 58,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are common reasons for Guinea visa rejections?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can cause a visa rejection. Here are some of the more common factors:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing inaccurate documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having sufficient funds for your stay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'If your passport expires within 6 months of your arrival date.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a visa on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when applying on tryvisa, you can apply for multiple people simultaneously. Ensure you have all the required information of your family members when doing so.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Guine with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Guinea allows Indians to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 70,
                  end: 77,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to provide proof of accommodation when entering Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must show proof of accommodation for the duration of your stay when you enter through immigration in Guinea.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$41ccbe46-37b5-4ed2-b16f-82b9c82632c3',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Guinea',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 7750,
    cost_max: 9200,
    cost_min: 7000,
  },
  GQ: {
    name: 'Equatorial Guinea',
    code: 'GQ',
    hero_image_url:
      'https://images.unsplash.com/photo-1509840825703-6af73fa6c128?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8RXF1YXRvcmlhbCUyMEd1aW5lYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Equatorial Guinea E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 6251.2,
    service_fees: -6251.2,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flights',
        description: '',
        label: 'Please upload your flights to Equatorial Guinea',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Flights',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Vouchers',
        description: 'Please upload Hotel Vouchers for your stay in Equatorial Guinea',
        label: 'Please upload Hotel Vouchers for your stay in Equatorial Guinea',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Hotels',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Yellow Fever Vaccinaction Certificate',
        description: 'Please upload Yellow Fever Vaccinaction Certificate',
        label: 'Please upload Yellow Fever Vaccinaction Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Yellow_fever',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Travel Insurance',
        description: '',
        label: 'Please Upload your Travel Insurance',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'insurance',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1435,
            },
            alt: 'Equatorial Guinea coat of arms.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c88564e4-ae5d-4b4a-9e0e-5b452f2dd48b_Coat_of_arms_of_Equatorial_Guinea.svg.png?auto=compress,format',
            id: 'ZXCDxBEAACMArpon',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1414,
              height: 659,
            },
            alt: 'Ceiba international airline for ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e3b3dada-6f75-4655-b08e-077e7900b849_CEIBA+International.png?auto=compress,format',
            id: 'ZXCIUxEAACUArrF9',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 482,
              height: 119,
            },
            alt: 'Equatorial Ministry of Exterior logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c535ef81-be35-44ac-8b49-60721f5379d1_Equatorial+Guinea+Ministry+of+exterior+logo.png?auto=compress,format',
            id: 'ZdXRlhEAACcARWqU',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$763a02fd-b9db-4307-8ae5-2dddbda0fcd3',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required for the Equatorial Guinea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Equatorial Guinea e-visa on tryvisa, you only need to present:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A digital copy of your passport’s front page.',
              spans: [
                {
                  start: 23,
                  end: 44,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'A digital copy of your passport’s back page.',
              spans: [
                {
                  start: 22,
                  end: 43,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'A recently taken digital photo.',
              spans: [
                {
                  start: 17,
                  end: 30,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additionally, you must provide proof of a Yellow Fever Vaccination after you have checked out.',
              spans: [
                {
                  start: 42,
                  end: 66,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way to obtain an Equatorial Guinea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to get an Equatorial Guinea visa is to apply online at tryvisa. tryvisa will guide you through each step of the e-visa application process, allowing a smooth and hassle-free application.',
              spans: [
                {
                  start: 70,
                  end: 76,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'What are the passport requirements when applying for an Equatorial Guinea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for an e-visa for Equatorial Guinea, your passport needs to:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Be valid for at least 30 days from your arrival in Equatorial Guinea.',
              spans: [
                {
                  start: 22,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Have at least one blank page.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check the status of my Equatorial Guinea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'After you have applied for your e-visa on tryvisa, you can easily check your visa status on the app. Here are the steps you can follow:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Find the visa you want to see the status of.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on the visa to see its current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'What documents do I need to enter the Equatorial Guinea border point?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you travel to Equatorial Guinea, you must provide the following documents when entering through immigration:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your approved e-visa.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Copies of your round-trip airline tickets or reservation.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A yellow fever card or vaccination.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the fees for the Equatorial Guinea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for the Equatorial Guinea e-visa on tryvisa is ₹8751,2.',
              spans: [
                {
                  start: 57,
                  end: 66,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: "Upon visa rejection, you can reapply once you've addressed the reasons for denial. Remember that visa fees are non-refundable through government and online portals, except through tryvisa. At tryvisa, we provide a refund for denied visas. The refund is up to ₹8000 ($100) and excludes service fees.",
              spans: [
                {
                  start: 190,
                  end: 195,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Equatorial Guinea with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Equatorial Guinea allows you to stay in the country for 30 days.',
              spans: [
                {
                  start: 71,
                  end: 78,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'When should I apply for my Equatorial Guinea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Equatorial Guinea e-visa takes about 8 days to get processed. So it is advised to apply for your e-visa at least 2 weeks before you travel.',
              spans: [
                {
                  start: 117,
                  end: 124,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I get a multi-entry e-visa for Equatorial Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the e-visa for Equatorial Guinea is only available for a single entry.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 61,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is a yellow fever vaccination mandatory for an Equatorial Guinea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must have a yellow fever vaccination when travelling to Equatorial Guinea.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your e-visa for Equatorial Guinea gets approved, you will receive a digital copy via email. You can also download it from your tryvisa profile.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the processing time for my Equatorial Guinea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you have applied for your e-visa on tryvisa, it will take about 8 days to be processed. If you apply through tryvisa, we guarantee your money back if your visa does not arrive within the provided processing time.',
              spans: [
                {
                  start: 67,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do minors need a visa for Equatorial Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, all Indian travellers travelling to Equatorial Guinea must have an approved visa. When applying for a minor, additional documents might be required, such as birth certificates.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$b2845a42-9750-4b34-84f8-ef59a0732ce0',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Equatorial Guinea',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  GY: {
    name: 'Guyana',
    code: 'GY',
    hero_image_url:
      'https://images.unsplash.com/photo-1595793822823-054c2b603dc4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8R3V5YW5hfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Visa on Arrival Letter',
    supported: false,
    message: null,
    eta_post_checkout_duration: 8,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Visa on Arrival Letter',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 800,
    service_fees: 950,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Birth Certificate',
        description: 'Please upload Birth Certificate',
        label: 'Birth Certificate',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'birth_certificate',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 225,
            },
            alt: 'Logo of the Ministry of Home Affairs, Guyana.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/878e2b0b-f7a9-41f4-ae10-bd06a5377255_Guyana+ministry+of+home+affairs+logo..png?auto=compress,format',
            id: 'ZZz-pBAAAB8ARa4y',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 955,
              height: 999,
            },
            alt: 'Guyana Ministry of Tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/35091ed6-c991-4626-ab47-f97517f33b27_Guyana+tourism+ministry.png?auto=compress,format',
            id: 'ZZz_tBAAAB4ARbMY',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 374,
              height: 135,
            },
            alt: 'Guyana immigration and support services logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6f245a84-69fb-4174-81f5-adcf0515d630_Guyana+immigration+and+support+services.png?auto=compress,format',
            id: 'ZZ0BCxAAACAARblN',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$982ce23e-07bd-41ab-ac73-148d9baf1a97',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way for Indians to get a Guyana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian nationals can apply for a Guyana visa through tryvisa. At tryvisa, we provide you with a Guyana visa on arrival letter. This allows you to obtain your visa on arrival in Guyana.',
              spans: [
                {
                  start: 53,
                  end: 58,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'This requires you to complete the online application, pay the application fee, and receive a visa-on-arrival request letter.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians get a visa on arrival in Guyana?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can obtain an on-arrival visa in Guyana. However, to do so, you must get a visa-on-arrival approval letter, which can be obtained online through tryvisa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 61,
                  end: 69,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need to submit for the Guyana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you complete the online application on tryvisa, you require the following documents for your Guyana visa application:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s front page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s back page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recently taken passport-sized photo.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additional documents might be required, such as your birth certificate.',
              spans: [
                {
                  start: 53,
                  end: 70,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 223,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 278,
                  end: 288,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to print my Guyana visa on arrival approval letter?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, once your visa-on-arrival letter gets approved, you must print it. You will not be allowed to board the flight without your visa approval letter.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Guyana with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa on arrival for Guyana will allow you to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 79,
                  end: 86,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents are required upon arrival at the port of entry?',
          answer: [
            {
              type: 'paragraph',
              text: 'When arriving in Guyana, you need to submit the following documents to immigration at the entry port:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2x Guyana visa application forms.',
              spans: [
                {
                  start: 3,
                  end: 32,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.embassyofguyana.be/assets/images/pdf/Visitor-Visa.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'A passport that is valid for at least six more months.',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2x passport-sized photographs.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A detailed travel itinerary.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Accommodation proof, such as hotel reservations.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "Proof of sufficient funds for your stay in Guyana. This can be your last 3 months' bank statements.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Invitation letter if invited from a host in Guyana.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Funds to pay the $25 Guyana visa fee.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can my visa on arrival for Guyana be denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, even with an approval letter, your application for the on-arrival visa in Guyana can still be rejected if the authorities find a reason not to approve your visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the reasons for the denial of a Guyana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can cause your visa to get rejected. Some of the common reasons are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds for your trip.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having a passport valid for at least 6 months from the date of your application.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my on-arrival approval letter?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa on arrival approval at least 2 weeks before your travel date.',
              spans: [
                {
                  start: 66,
                  end: 80,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I check the status of my Guana visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can check the status of your visa on arrival approval on the tryvisa app. Or on the government portal if you have applied through the government website.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get an e-visa for Guyana?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Guyana does not have an electronic visa. Instead, you can apply for a visa-on-arrival approval letter online through tryvisa or the government website.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 127,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my Guyana tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the tourist visa for Guyana can be extended. To do so, you need to complete the extension form online.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required when applying for a Guyana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for the Guyana visa application. However, it is recommended to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 86,
                  end: 98,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the fee for the Guyana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Guyana visa-on-arrival fee is $25. ',
              spans: [
                {
                  start: 34,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'This price can change anytime, so checking the government website before applying for a Guyana visa is advised.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$80e74d5a-4a54-4b8d-b864-3964de0175ce',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Guyana',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 1750,
    cost_max: 1100,
    cost_min: 1000,
  },
  JO: {
    name: 'Jordan',
    code: 'JO',
    hero_image_url:
      'https://images.unsplash.com/photo-1614772067419-1452cf9aeaa3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fEpvcmRhbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Jordan E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 5,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '3 months',
    govt_fees: 5100,
    service_fees: -990,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 1057,
            },
            alt: 'Ministry of foreign affairs logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9161d12f-e8e5-485e-a37d-b6ffa68861d1_800px-Coat_of_arms_of_Jordan.svg.png?auto=compress,format',
            id: 'ZLeOihIAACAAYlWi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 218,
            },
            alt: 'Jordan airline logo ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/510368a5-a2b8-4161-affe-652737da3699_Jordan+airline+logo..png?auto=compress,format',
            id: 'ZdWudxEAACcARMq8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 495,
              height: 504,
            },
            alt: 'Jordan Ministry of Interior',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4ef1a381-12d7-4160-851a-f666aba5614d_Jordan+Ministry+of+Interior+logo..png?auto=compress,format',
            id: 'ZdWwVxEAACkARNNc',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 218,
              height: 231,
            },
            alt: 'Jordan tourism Board logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/007d5b93-9944-4a25-8ee6-eddf4886efd6_Jordan+tourism+board+logo..png?auto=compress,format',
            id: 'ZdWxLxEAACsARNaG',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$b15642d0-1505-4d28-8f82-9e21f5862e83',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Jordan e visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents you need are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A scanned copy of your passport's back page.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Jordan e-visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Jordan e-visa application can vary, but it typically takes around 7 days. tryvisa guarantees your money back if your visa takes longer than the estimated processing time.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it mandatory to provide a flight itinerary when applying for a Jordan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is generally required to provide a flight itinerary when applying for a Jordan e-visa. The Jordan authorities want to ensure that you have a confirmed plan to enter and exit the country within the visa validity period. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of a Jordan tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period of a Thailand tourist visa can vary. Generally, a single-entry tourist visa is valid for 90 days from the date of entry into Jordan. ',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I apply for a Jordan e-visa if I have previously been denied entry to Jordan?',
          answer: [
            {
              type: 'paragraph',
              text: 'Previous denial of entry to Jordan may impact your eligibility for a visa. It is advisable to disclose any relevant information about past denials or immigration issues when applying for a visa. The Thai authorities will consider your circumstances and make a decision based on the information provided.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an age limit for applying for a Jordan tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There is no specific age limit for applying for a Jordan tourist visa. Individuals of all ages can apply for a tourist visa as long as they fulfill the necessary requirements and provide the required documents.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Are there any specific health requirements or vaccinations needed for travel to Jordan?',
          answer: [
            {
              type: 'paragraph',
              text: 'While there are no mandatory vaccinations for entering Jordan, it is advisable to check with a healthcare professional or travel health clinic for any recommended vaccinations based on your health status and the areas you plan to visit. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and online portal do not refund the fees, except for tryvisa. At tryvisa, a refund of up to ₹8000 or $100 will be issued for visa rejections, which excludes the service fee.',
              spans: [
                {
                  start: 215,
                  end: 220,
                  type: 'strong',
                },
                {
                  start: 224,
                  end: 230,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it possible to convert a tourist visa to a work visa in Jordan?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is impossible to convert a tourist visa to a work visa in Jordan. If you wish to work in Jordan, you would need to exit the country and apply for an appropriate work visa from a Thai embassy or consulate in your home country or another eligible location.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Jordan e-visa if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Jordan e-visa if you have dual citizenship. However, it is important to apply using the passport that you intend to travel with. Ensure that you provide accurate information and use the appropriate passport details in your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Jordan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Jordan e visa through the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Jordan tourist visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Jordan tourist visa online through tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I travel to Jordan with a copy of the e-visa, or do I need to carry the original?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advisable to carry the original e-visa when traveling to Jordan. While a digital or printed copy might be accepted in some cases, having the original e-visa document ensures a smoother entry process at immigration checkpoints.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Jordan e-visa is lost or damaged?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Jordan e-visa is lost or damaged, you can download a new copy from the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of a Jordan tourist visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa cost through tryvisa is INR 8,100 . Please note that this visa fee is nonrefundable.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in Jordan on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can stay a maximum of 90 days with a Jordan for Indians',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to multiple cities within Jordan with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, with a tourist visa to Jordan, you can travel to multiple cities within the country. The tourist visa allows you to explore various destinations and enjoy the cultural, historical, and natural attractions Jordan offers.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4785ba9d-cd79-40a5-98a5-5c5b4621aaa2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Jordan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 4110,
    cost_max: 9280,
    cost_min: 3360,
  },
  KW: {
    name: 'Kuwait',
    code: 'KW',
    hero_image_url:
      'https://images.unsplash.com/photo-1558634742-56096b49522b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8S3V3YWl0fGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Kuwait E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 21,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 45000,
    service_fees: -15750,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Degree or Graduation Certificate',
        description: 'Please upload Degree or Graduation Certificate',
        label: 'Degree or Graduation Certificate',
        required: true,
        only_b2b: false,
        source_url: '',
        key: 'education_certificate',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 275,
              height: 300,
            },
            alt: 'Kuwait Police logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6c391475-fe8e-417d-884c-6e91c7577252_ministry-of-interior-kuwait-logo-.png?auto=compress,format',
            id: 'ZZ5XWREAAB0ATjwO',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 224,
            },
            alt: 'Kuwait General Department of Residency logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/87635888-9300-4078-bb76-e71f699545d1_Kuwait+general+deparrtment+of+residency+logo..png?auto=compress,format',
            id: 'ZZ5a2xEAACEATkyy',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 630,
              height: 396,
            },
            alt: 'Kuwait airlines logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/fbf41759-9b0d-4ab3-9ce6-bf7d744010d4_Kuwait+Airways+Logo..png?auto=compress,format',
            id: 'ZdWqWBEAACwARLiL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 470,
              height: 531,
            },
            alt: 'Ministry Of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ee7daa1c-cfda-40f6-b468-4e8198e6fd6c_Kuwait+coat+of+arms..png?auto=compress,format',
            id: 'ZdWrdBEAAC4ARL1g',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 251,
            },
            alt: 'Kuwait tourism board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e631928d-d3ed-41db-9154-4a8edea69b5d_Kuwait+tourism+services..png?auto=compress,format',
            id: 'ZdWsnREAAC0ARMJk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$26b0d444-8b8c-49ff-ae1b-b6a927a696bb',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the process for Indian citizens to obtain a Kuwait visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Kuwait visa for Indians can easily be applied online through tryvisa or the government portal. When applying at tryvisa, we will ensure your documents meet the requirements and update you about your visa process. To apply on tryvisa, you must:',
              spans: [
                {
                  start: 65,
                  end: 70,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Visit tryvisa: Go to our online portal.',
              spans: [
                {
                  start: 0,
                  end: 12,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Provide Travel Details: Choose the date you plan to travel to Kuwait.',
              spans: [
                {
                  start: 0,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Submit Documents: Upload the required document.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Pay The Visa Fees: Lastly, pay the Kuwait visa fees and submit your application for processing.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need for my Kuwait visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents required for the visa to Kuwait include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s front page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s back page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recently taken passport-sized photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Graduation or degree certificate.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of sponsorship.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Kuwait visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to start your Kuwait e-visa application at least 2 weeks before your travel date.',
              spans: [
                {
                  start: 63,
                  end: 70,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What type of Kuwait visas can Indians apply for?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian nationals can apply for various visa types, such as:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Tourist visa: Indian tourists can stay in Kuwait for a maximum of three months with this visa for leisure and tourism purposes.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Work visa: Indian nationals wishing to work in Kuwait are granted a work visa by the sponsoring company. It might even make you eligible for a work permit in Kuwait.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Family Joining visa: Indian nationals can join their spouse, parents, or children who have made Kuwait their home by obtaining a Kuwait joining family visa.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Student visa: This Kuwait visa is issued for Indian students travelling to study in an educational institution in Kuwait.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Take note that the refund excludes the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can Indians stay in Kuwait with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Kuwait e-visa allows Indians to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 66,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it mandatory to have a sponsor in Kuwait for a visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian nationals must have a sponsor when applying for a Kuwait e-visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my visa to Kuwait during the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, tryvisa is available online 24/7. This allows you to apply for your e-visa to Kuwait over the weekends and after hours. The processing of your visa will only happen during business days.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 31,
                  end: 35,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Kuwait with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa can only be used for leisure, sightseeing, or exploring activities. If you want to work in Kuwait, you need the correct employment visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does it take for me to get my Kuwait visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'After you have submitted your application, your visa will be processed in about 5 days. Once approved, you will receive a digital copy of the Kuwait e-visa.',
              spans: [
                {
                  start: 80,
                  end: 86,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Kuwait e-visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Kuwait e-visa fees for Indians are ₹45,000.',
              spans: [
                {
                  start: 39,
                  end: 47,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note that this fee does not include any handling or service fees. The fee mentioned can change anytime, so visiting the official website before applying for your visa is advised.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required when applying for an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is not mandatory for the Kuwait e-visa application. However, it is recommended to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 19,
                  end: 34,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What payment methods can I use to pay for my e-visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your e-visa to Kuwait on tryvisa, you can pay the fees using your credit/debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 83,
                  end: 96,
                  type: 'strong',
                },
                {
                  start: 116,
                  end: 129,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the consequences of Indian citizens overstaying a Kuwait visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When overstaying your Kuwait visa, you must pay a fine of ₹1 lakh (₹100,000).',
              spans: [
                {
                  start: 57,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What factors can affect the processing of my Kuwait visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay your visa processing. Some of these factors include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect information on your application.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal history.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Public holidays.',
              spans: [],
            },
          ],
        },
        {
          question:
            "Can I apply for a Kuwait visa if I hold a passport with less than six months' validity?",
          answer: [
            {
              type: 'paragraph',
              text: 'No, your passport must be valid for at least 6 months to apply for the Kuwait e-visa. Your visa application will be denied if your passport expires within 6 months.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$527b3328-1f13-49b2-8df0-acab474e1757',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Kuwait',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 29250,
    cost_max: 75590,
    cost_min: 28500,
  },
  LA: {
    name: 'Laos',
    code: 'LA',
    hero_image_url:
      'https://images.unsplash.com/photo-1540611025311-01df3cef54b5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TGFvc3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Laos E-Visa',
    supported: false,
    message: 'Not Supported\nThe E-Visa is currently suspended by the government in Laos',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 4200,
    service_fees: -4200,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Laos E-Visa',
        description:
          'The Laos E-Visa is a single entry visa and has a stay permit of a maximum of 30 days',
      },
    ],
    additional_questions: [
      {
        extra_info: '',
        question_type: 'text',
        description: 'This is required since the port of entry is printed on the E-visa',
        label: 'What is your port of entry? ',
        key: 'port_of_entry',
        required: true,
        source_url: '',
      },
    ],
    components_required: [
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'fbi_background_check',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'identity',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'india_itr',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'notary',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'photo',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_insurance',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_itinerary',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1148,
            },
            alt: 'Laos e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cf81b2af-0e27-43a8-b8cf-b65ce8ee210b_Lao-eVisa-Official-Logo.png?auto=compress,format',
            id: 'ZdW7GxEAACsARQLB',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1054,
            },
            alt: 'Laos Ministry of Foreign  Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8980e6f1-182d-4130-8b05-dfa2581165b8_Laos+MOFA+logo.png?auto=compress,format',
            id: 'ZdW9ShEAACoARQz9',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 400,
              height: 400,
            },
            alt: 'Laos tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/37367112-484c-4402-bffa-b9844475d00c_Laos+tourism+board.png?auto=compress,format&rect=23,17,345,345&w=400&h=400',
            id: 'ZdW96hEAACsARQ_d',
            edit: {
              x: -26.681741194547918,
              y: -19.72128696988324,
              zoom: 1.160075704110779,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 521,
              height: 191,
            },
            alt: 'Laos airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/61fe8322-2f58-4e37-9827-092b66b09da9_Laos+airline+logo.png?auto=compress,format',
            id: 'ZdW8MBEAACsARQfL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$84cb8d78-0ed6-4dcd-b665-2bbbd5a12d7a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for a Laos e visa?',
          answer: [
            {
              type: 'paragraph',
              text: "You'll only need the following documents to apply for your visa through tryvisa:",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I obtain a Laos visa for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can get a Laos visa for Indians through the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the process for obtaining a Laos e-visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The process for obtaining a Laos e-visa online involves:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Downloading the tryvisa app.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Uploading your documents and completing the application form.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Paying the visa fee.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Submitting your application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Laos e-visa for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Laos e-visa for Indian nationals through tryvisa is 3 business days. Please note that the processing time does not include weekends or public holidays. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos e-visa as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens are eligible to apply for a Laos e-visa online. You can apply for this visa online through the tryvisa portal or app.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the visa fees for Indian citizens applying for a Laos e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fees for Indian citizens applying for a Laos e-visa is INR 4,650.',
              spans: [],
            },
          ],
        },
        {
          question: 'How many months of validity does my passport need to apply for a Laos visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport must be valid for 6 months after your intended date of travel',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any specific entry requirements for Indian nationals visiting Laos?',
          answer: [
            {
              type: 'paragraph',
              text: 'Apart from a valid visa, Indian nationals must have a passport with a validity of at least six months beyond the date of entry into Laos.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I enter Laos multiple times with a single e-visa as an Indian national?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a single-entry e-visa for Laos allows you to enter the country only once. Therefore, if you plan to exit and re-enter Laos, you must apply for a new visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: "In case of visa rejection, you can reapply for the visa once you've addressed all the reasons for denial. Remember that visa fees are non-refundable through government and online portals, except for tryvisa, which offers a full refund of up to ₹8000 or $100 upon visa rejection. This refund excludes the service fee.",
              spans: [
                {
                  start: 198,
                  end: 204,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'What is the recommended time to apply for a Laos e-visa before my intended travel dates?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply for a Laos e-visa at least 2 weeks before your intended travel dates. This allows sufficient time for processing and any unexpected delays.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the duration of stay allowed with a Laos e-visa for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: "The duration of stay allowed with a Laos e-visa for Indian nationals is typically 30 days from the date of entry. Therefore, it's important to respect the allowed duration and not overstay your visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check my Laos e visa for Indian citizens status?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can check your visa status on the tryvisa app or online portal by clicking on the profile icon.',
              spans: [],
            },
          ],
        },
        {
          question: 'Will I get a refund if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you will receive a refund if you apply through tryvisa. ',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Will I get a refund if my visa takes longer than the guaranteed processing time?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you will. If you apply through tryvisa and your visa takes longer than the guaranteed processing time, you’ll get your money back.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it mandatory to provide proof of accommodation when applying for a Laos visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is typically required to provide proof of accommodation such as hotel bookings or an invitation letter from a host in Laos.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos visa if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'The approval of a Laos visa may depend on the severity of the criminal record. It is advisable to consult with the Lao embassy or consulate for specific cases.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos visa if I have a one-way ticket?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advisable to have a return or onward ticket when applying for a Laos visa, as proof of your intent to leave the country within the permitted stay duration.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos visa if I am unemployed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Laos visa even if you are currently unemployed. However, you may be required to provide other supporting documents to demonstrate your financial stability.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos visa if I am traveling with my minor child?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for a Laos visa for your minor child. Additional documents such as the child's birth certificate and consent letter from both parents may be required.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Laos visa if I have previously overstayed my visa in Laos?',
          answer: [
            {
              type: 'paragraph',
              text: 'Previous overstays may impact the decision on your new Laos visa application. It is advisable to provide all necessary documentation and explain any previous overstays in your application.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Travellers Also Asked',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$77c59355-42c7-4a31-9856-b58bd9865fce',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Laos',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  MD: {
    name: 'Moldova',
    code: 'MD',
    hero_image_url:
      'https://images.unsplash.com/photo-1593340646418-af8bc7d03b2e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8TW9sZG92YXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Moldova E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 3671.1,
    service_fees: -3671.1,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '6 Months Bank Statement',
        description: '6 Months Bank Statement',
        label: '6 Months Bank Statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Bank_Statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Itinerary',
        description: 'Flight Itinerary',
        label: 'Flight Itinerary',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Flights',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Voucher',
        description: 'Hotel Voucher with name mentioned',
        label: 'Hotel Voucher',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'hotel_voucher',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 312,
              height: 131,
            },
            alt: 'Moldova e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/73eb4927-9f62-4ac7-a06a-142e56142c45_Moldova+E-visa+Logo.png?auto=compress,format',
            id: 'ZZZPZRAAACAAkmcr',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1512,
            },
            alt: 'Coat of arms of the Republic of Moldova.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/efdfa655-430b-4919-bf13-86381713b0b9_Coat_of_arms_of_Moldova.svg.png?auto=compress,format',
            id: 'ZZZRMxAAACAAkm9j',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 473,
              height: 163,
            },
            alt: 'Logo of Moldova Tourism Board.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/730f4fb1-3f81-4acd-acee-c9843d4a8478_Moldova+tourism+board+logo.png?auto=compress,format',
            id: 'ZZZSNhAAACAAknPv',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 419,
            },
            alt: "Moldova's national airline logo.",
            copyright: null,
            url: 'https://images.prismic.io/atlys/c1d6c86e-ab23-4602-a44a-12641684f89c_Air_Moldova.svg.png?auto=compress,format',
            id: 'ZZZSzxAAACAAknaf',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$abdb116a-8a3a-4733-86a7-ec18ac8c679e',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the visa requirements for Indian citizens travelling to Moldova?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a Moldova visa as an Indian, you need to provide:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport must be valid for at least 3 months after you depart from Moldova.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: A passport-sized photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Ticket: A return flight ticket showing your departure from Moldova.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Hotel Voucher: A hotel letter or voucher stating where you will stay in Moldova.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
                {
                  start: 17,
                  end: 40,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://mfa.gov.md/sites/default/files/anexa3_hg_nr_50_din_15_01_2013_ro_14.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Medical Insurance: Valid travel insurance for your trip to Moldova.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statements: Proof of funds through bank statements.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way of getting a Moldova visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way of getting your visa for Moldova is by applying through tryvisa. With the hassle-free application process on tryvisa, you can finish your application in under 10 minutes.',
              spans: [
                {
                  start: 72,
                  end: 77,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'After the application, tryvisa will handle the rest and inform you once your visa is approved.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an interview process for a Moldova visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, when applying for the e-visa, you do not need to attend an interview. You might need to attend an interview only when applying for more complex visas, such as work or study visas.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the minimum bank balance required when visiting Moldova?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a Moldova visa, you must prove you have enough funds. You require at least EUR 30 per day for Moldova but not less than EUR 300 for stays of less than 10 days.',
              spans: [
                {
                  start: 93,
                  end: 107,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What steps should I take if my visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and visa portals do not refund the fees, except for tryvisa. At tryvisa, you will receive a refund of up to ₹8000 or $100 when your visa is rejected. This excludes the service fee.',
              spans: [
                {
                  start: 214,
                  end: 219,
                  type: 'strong',
                },
                {
                  start: 240,
                  end: 246,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my visa to Moldova?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa 2-3 weeks before you travel to Moldova is advised.',
              spans: [
                {
                  start: 23,
                  end: 32,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa to get rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can cause visa rejections. Here are some of the more common reasons that can cause your visa to be rejected:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing false information.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Submitting a passport with a lack of validity.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having enough funds for the duration of your stay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I check my visa status for Moldova?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when you apply for your visa through tryvisa, you can check its status through the tryvisa app. Or you can check your visa status on the government portal through which you have applied.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 86,
                  end: 95,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required when applying for a Moldova visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian nationals must provide travel insurance when applying for a visa to Moldova.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for a Moldova visa application from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Moldova visa takes about  5-10 days to get processed. This time can take longer if there are any delays with processing your application.',
              spans: [
                {
                  start: 30,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is Moldova part of the Schengen area?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Moldova is not part of the Schengen area. This means you must have a specific Moldova visa when travelling to the country. ',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Moldova with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Moldova allows you to stay for 30 days. The visa is valid for 90 days from the day of approval.',
              spans: [
                {
                  start: 45,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my visa to Moldova?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can extend your visa to Moldova under certain conditions. Be sure to apply for your visa extension at least 3 days before your original visa expires. ',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Moldova visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for Moldova as an Indian is EUR 40. This does not include any service or handling fees.',
              spans: [
                {
                  start: 40,
                  end: 48,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What methods of payment can I use to pay for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying on tryvisa, you can pay for your visa with a credit/debit card or through an approved mobile wallet.',
              spans: [
                {
                  start: 57,
                  end: 74,
                  type: 'strong',
                },
                {
                  start: 98,
                  end: 111,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa once it is approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your e-visa for Moldova gets approved, you will receive a digital copy of the visa through e-mail. You can then download this digital visa and print it.',
              spans: [
                {
                  start: 63,
                  end: 75,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$38d14269-6220-4015-b858-97f61e08976b',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Moldova',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  MG: {
    name: 'Madagascar',
    code: 'MG',
    hero_image_url:
      'https://images.unsplash.com/photo-1570742544137-3a469196c32b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TWFkYWdhc2NhcnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Madagascar E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 6,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Evisa',
    entry_length_stay: '15 days',
    entry_validity: '60 days',
    govt_fees: 837,
    service_fees: 1750,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'covid_vaccine',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'fbi_background_check',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'identity',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'indonesia_hotel_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'pan_card',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'passport_back',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        only_b2b: false,
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'shipping_labels',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 351,
              height: 170,
            },
            alt: 'Madagascar airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/93266286-7a2f-4a32-97ec-7af7b510f6f8_Madagascar+Airlines.png?auto=compress,format',
            id: 'ZMjweBAAACEASn8L',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 618,
              height: 184,
            },
            alt: 'Madagascar e-visa logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/97370b8f-aa0a-4d9b-a8c7-d5a0866cf650_Madagascar+E+Visa+portal.png?auto=compress,format',
            id: 'ZMjxRxAAACAASoLL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 165,
            },
            alt: 'Madagascar Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c2234cdc-535a-4ca3-9486-5d7e91a286c5_Republic+of+Madagascar.png?auto=compress,format',
            id: 'ZMjxRxAAACAASoLM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$96a69302-fe41-482a-82d1-84d86bd1d0e2',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the documents required for the Madagascar Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying on tryvisa for your Madagascar visa for Indians, the required documents include a valid passport, flight itinerary, and a passport-sized photo.',
              spans: [
                {
                  start: 94,
                  end: 126,
                  type: 'strong',
                },
                {
                  start: 148,
                  end: 154,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance for a Madagascar visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a Madagascar visa for Indians, you do not require travel insurance. But having travel insurance when visiting other countries is always a good idea.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I track my Madagascar visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After submitting your Madagascar visa application, you can track its status through tryvisa’s online portal. Simply visit the tryvisa website and click on the dashboard on the right-hand side. You will be able to check the status of your visa application.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it necessary to book a flight before applying for a Madagascar visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: "While it is not always necessary, some visa applications require proof of return travel. It's recommended to check this requirement on the official Madagascar e-visa online website or consult a Madagascar visa agent.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in Madagascar as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Madagascar online visa allows Indian citizens to stay in the country for up to 60 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Madagascar visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and online portal do not refund the fees, except for tryvisa. At tryvisa, if your visa is denied, we will refund you up to ₹8000 or $100, excluding the service fee.',
              spans: [
                {
                  start: 215,
                  end: 220,
                  type: 'strong',
                },
                {
                  start: 254,
                  end: 260,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Madagascar visa online from any country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the Madagascar e-visa online system is accessible worldwide. You can apply from any country if you have a reliable internet connection and the necessary documents.',
              spans: [],
            },
          ],
        },
        {
          question: 'How safe is it to apply for a Madagascar e-visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for a Madagascar e-visa online is safe if you use tryvisa’s visa portal or the government site. tryvisa’s website uses 256-bit Advanced Encryption Standard to ensure your data is protected from security breaches, and we do not sell your data to third-party companies.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for a Madagascar visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Madagascar visa at least one week and 6 months before your intended travel date to allow ample time for processing. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to other countries from Madagascar on a Madagascar tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Madagascar tourist visa allows you to travel within Madagascar only. If you wish to travel to other countries from Madagascar, you will need the relevant visas for those countries.',
              spans: [],
            },
          ],
        },
        {
          question: 'How much does a Madagascar visa cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'A Madagascar visa costs＄10; tryvisa uses secure payment gateways to process your visa application fees, ensuring that your financial data is well-protected.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I pay the fees for a Madagascar online visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Fees for a Madagascar visa online can be paid with a credit or debit card or a mobile wallet during the application process.',
              spans: [],
            },
          ],
        },
        {
          question: "What happens if my Madagascar tourist visa expires while I'm in Madagascar?",
          answer: [
            {
              type: 'paragraph',
              text: 'Suppose your Madagascar tourist visa expires while in the country. You should contact the local immigration office immediately to avoid legal issues. They may grant a visa extension, or you may be required to leave the country.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What health precautions should I take before travelling to Madagascar on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's advisable to check with your doctor or a travel health clinic about any recommended vaccinations or other health precautions before travelling to Madagascar. Also, consider purchasing travel health insurance for your trip.",
              spans: [],
            },
          ],
        },
        {
          question: 'How Do I Get A Visa For Madagascar?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can get a Madagascar visa online and the process is straightforward. Visit tryvisa’s online portal, complete the application form, upload the required documents, and submit. Then, make the necessary payments and wait for the visa approval.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Madagascar with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a Madagascar tourist visa does not permit the holder to undertake paid work in Madagascar. If you intend to work in Madagascar, you must apply for a work visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Madagascar visa online on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for evisa Madagascar on behalf of your family through tryvisa's website. Simply add the required information for your family members and submit it on the portal. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a visa upon arrival in Madagascar?',
          answer: [
            {
              type: 'paragraph',
              text: "No, Madagascar does not offer visa-on-arrival for Indian citizens. It's essential to obtain a visa before travelling to the country.",
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$fed66994-9f4b-421f-9b86-027dc951d440',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Madagascar',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2587,
    cost_max: 5885,
    cost_min: 1837,
  },
  MN: {
    name: 'Mongolia',
    code: 'MN',
    hero_image_url:
      'https://images.unsplash.com/photo-1595692732588-2e8a32b77911?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    process: 'digital',
    process_name: 'Mongolia E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 6,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '120 days',
    govt_fees: 800,
    service_fees: 1400,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: [
          'departure_flight_required',
          'return_flight_required',
          'upload_departure_flight',
          'upload_return_flight',
          'hotel_required',
        ],
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 270,
              height: 300,
            },
            alt: 'Mongolia government',
            copyright: null,
            url: 'https://images.prismic.io/atlys/40f55f9b-dac1-46e8-b08e-ec2acab6dd9b_mongolia+government+logo.png?auto=compress,format',
            id: 'ZJvcQhEAACEAspz0',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 674,
              height: 370,
            },
            alt: 'Mongolia Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e360945e-2c54-4cdf-b877-256c0e1152bc_Mongolia+MOFA+logo.png?auto=compress,format',
            id: 'ZdXEohEAAC0ARS86',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 967,
              height: 258,
            },
            alt: 'Mongolia airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b9a806c2-e7b6-4d44-b645-9f874485271f_Mongolia+airline+logo.png?auto=compress,format',
            id: 'ZdXIVBEAAC0ARUBL',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$2f8a1af9-145c-40f6-a2f1-4a7c57729c45',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the Mongolian visa requirements for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents needed to obtain a visa for Mongolia are: ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid passport. ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport-size photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A completed application form.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Flight itinerary.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of accommodation.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Visa fees.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need travel Insurance to travel to Mongolia?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is not a requirement to visit Mongolia. But getting travel insurance when you visit a different country is always a good idea.',
              spans: [],
            },
          ],
        },
        {
          question: 'Are there any specific vaccinations required to visit Mongolia as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are no specific vaccinations required to visit Mongolia as an Indian national.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I pay for my Mongolia visa for Indians online?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa, you can pay for your Mongolian visa in multiple ways. You can pay using your credit or debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 87,
                  end: 93,
                  type: 'strong',
                },
                {
                  start: 96,
                  end: 107,
                  type: 'strong',
                },
                {
                  start: 123,
                  end: 136,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I check my Mongolian tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can easily track your visa process; just visit tryvisa, sign in, and track your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens If my Mongolian visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'When your visa gets denied, you can see the reasons for the rejection. Fix these reasons to reapply for your visa. The government or visa portals do not refund the funds, except for tryvisa. At tryvisa, you are guaranteed a refund of up to ₹8000 ($100), excluding the service fee.',
              spans: [
                {
                  start: 192,
                  end: 197,
                  type: 'strong',
                },
                {
                  start: 207,
                  end: 217,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Mongolian visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your Mongolian visa 1-2 weeks before you depart is advised.',
              spans: [
                {
                  start: 33,
                  end: 42,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need financial proof to apply for a Mongolian visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No financial documents are required while applying for a visa to Mongolia. But it is a good idea to bring them with you when you travel to Mongolia in case the immigration officers ask for them.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Mongolian visa on behalf of someone else?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, on tryvisa, you can apply for a Mongolian visa for someone else. You just need the required documents and can then apply for them.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I get a visa for Mongolia?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa on tryvisa is very easy. You just choose the country you want to visit, select your travel dates, complete the application form, upload your photo and passport, and wait for your visa to be processed.',
              spans: [
                {
                  start: 54,
                  end: 73,
                  type: 'strong',
                },
                {
                  start: 105,
                  end: 117,
                  type: 'strong',
                },
                {
                  start: 132,
                  end: 148,
                  type: 'strong',
                },
                {
                  start: 162,
                  end: 180,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I travel to Mongolia with a Schengen visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Unfortunately, Mongolia does not fall under the Schengen area, so you will need a specific visa for Mongolia.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Mongolian visa while in Mongolia?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can extend your visa while you are in Mongolia. You must visit the Immigration Agency of Mongolia and apply for an extension. Be sure to apply for the extension one week before your visa expires.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in Mongolia with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa is valid for 150 days, and you can stay in the country for up to 30 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the price of a Mongolian e-visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you apply for your Mongolian visa for Indians on tryvisa, the price for an e-visa is ₹2430.',
              spans: [
                {
                  start: 85,
                  end: 91,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for a visa to Mongolia?',
          answer: [
            {
              type: 'paragraph',
              text: 'Typically the visa processing time for a Mongolian visa is only 3 days.',
              spans: [
                {
                  start: 64,
                  end: 71,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'If my visa gets rejected, will I be refunded?',
          answer: [
            {
              type: 'paragraph',
              text: 'The government does not refund the visa fees. However, when you apply through tryvisa, you will receive a refund of up to ₹8000 or $100, which excludes the service fee.',
              spans: [
                {
                  start: 46,
                  end: 53,
                  type: 'strong',
                },
                {
                  start: 78,
                  end: 83,
                  type: 'strong',
                },
                {
                  start: 104,
                  end: 110,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the common reasons for a Mongolian visa rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of factors that can cause your visa to be rejected, such as:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your passport is close to the expiry date.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of proof of intent to return to your home country.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'You are not providing proof of accommodation.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indian work on a Mongolian tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, if you have a tourist visa, you are not allowed to conduct any business in Mongolia. You will need a business visa for that.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$3049372a-ecd2-4a2c-8a28-75f4fb6c1fa1',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Mongolia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 2200,
    cost_max: 1770,
    cost_min: 1450,
  },
  MZ: {
    name: 'Mozambique',
    code: 'MZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1526998758291-f87c4c1a8fff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TW96YW1iaXF1ZXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'E-visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'EVISA',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 2000,
    service_fees: -2000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    country_name: 'Mozambique',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  PG: {
    name: 'Papua New Guinea',
    code: 'PG',
    hero_image_url:
      'https://images.unsplash.com/photo-1613183919710-2ff7b3bec845?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8UGFwdWElMjBOZXclMjBHdWluZWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'EVISA',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 4200,
    service_fees: -4200,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Covid Vaccination Certificate',
        description: 'Covid Vaccination Certificate',
        label: 'Covid Vaccination Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Covid',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1080,
              height: 990,
            },
            alt: 'Immigration and Citizenship service of Papua New Guinea Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9da96967-4d31-4eae-a4e0-81c3b5271a02_Papua+New+Guine+Immigration+Service+logo.png?auto=compress,format',
            id: 'ZXrYGhEAACEAMhjJ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1080,
              height: 990,
            },
            alt: 'National emblem of Papua new Guinea logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/7a7bb063-efaa-47fb-a70b-18c37c325d39_national+emblem+of+Papua+New+Guinea.png?auto=compress,format',
            id: 'ZXrZwBEAACAAMiCg',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1171,
            },
            alt: 'National airline logo of Papua New Guinea.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/80e6bbfd-211f-4ba4-97e1-c17067ca0371_Air_Niugini_Logo.svg.png?auto=compress,format',
            id: 'ZXraPxEAACMAMiLW',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Papua New Guinea Tourism Promotion Authority logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b5674317-3c99-4efa-94af-675183487724_Papua+New+Guinea+Tourism+Board.png?auto=compress,format',
            id: 'ZdXHQxEAACwARTtq',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$54b2d097-a44d-4a5d-aed0-02ed3ea175da',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the process of applying for my Papua New Guinea e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The online application process for your e-visa to Papua New Guinea is straightforward. You can complete your application on tryvisa in under 10 minutes. Here are the application steps you will follow:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to the tryvisa website.',
              spans: [
                {
                  start: 10,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Provide your travel dates.',
              spans: [
                {
                  start: 12,
                  end: 25,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Upload your photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Upload your passport-required pages.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Pay the visa fees and submit your application.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the requirements for a Papua New Guinea visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your Papua New Guinea visa, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid passport or travel document.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Covid-19 Certificate.',
              spans: [],
            },
            {
              type: 'list-item',
              text: '1x copy of your passport’s data page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A cover letter stating your purpose of visit, accommodation, and destinations.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of sufficient funds.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note: When you apply through tryvisa, you might not submit all the above documents since tryvisa works with government partners to provide you with a more simplified process.',
              spans: [
                {
                  start: 0,
                  end: 5,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I track my visa application online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can easily check your visa status on the tryvisa app by following these steps:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 50,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Choose the visa you applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you click on the visa, you can see its current status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'If you have applied through the government portal, you can check your visa status online on the website.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'When should I start applying for a Papua New Guinea visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You should start your visa application for Papua New Guinea 2-3 weeks before you travel.',
              spans: [
                {
                  start: 60,
                  end: 69,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What method of payment can I use to pay for my e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa, you can pay the visa fees using a credit/debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 44,
                  end: 57,
                  type: 'strong',
                },
                {
                  start: 77,
                  end: 90,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is the e-visa for Papua New Guinea a single or multiple-entry visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Papua New Guinea is only available as a single entry visa. If you want to apply for a multiple-entry visa, you must visit the embassy.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the e-visa fee for Papua New Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Papua New Guinea e-visa on tryvisa, the fee is ₹6700.',
              spans: [
                {
                  start: 69,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa application to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay your visa processing time, including:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Missing documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Inaccurate information on your documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'The complexity of each application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Papua New Guinea Tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'In certain cases, you can extend your visa. To do so, you must contact the authorities and explain your circumstances. They will then decide if your visit can be extended.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity of my visa for Papua New Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Papua New Guinea is valid for 60 days.',
              spans: [
                {
                  start: 45,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Are there any specific vaccination requirements for a visa to Papua New Guinea?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indians must have a covid-19 certificate to apply for a Papua New Guinea visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 25,
                  end: 45,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for the Papua New Guinea visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for the visa takes about 12 days to process',
              spans: [
                {
                  start: 45,
                  end: 52,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my e-visa over the weekends?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, tryvisa is an online platform available 24/7. So, you can apply for your visa online at any time.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$b8a3c312-3ee0-4695-a7d5-fdb5042fccab',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Papua New Guinea',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  QA: {
    priority: 80,
    name: 'Qatar',
    code: 'QA',
    hero_image_url:
      'https://images.unsplash.com/photo-1539475314840-751cecc1dacd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8UWF0YXJ8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Qatar E-Visa 30 Days Single Entry',
    supported: false,
    message: "Visa on Arrival\nYou're good to fly to Qatar and get a visa on arrival! Hooray!",
    eta_post_checkout_duration: 9,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: null,
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 2000,
    service_fees: 1250,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa',
        description:
          'Indian nationals do not require a prior visa to visit Qatar for up to a period of 30 days. ',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 813,
              height: 847,
            },
            alt: 'State of Qatar embassy in New Delhi logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e57bf8ab-17bf-4973-9cd8-25bb80f5def0_state+of+Qatar+in+New+Delhi.png?auto=compress,format',
            id: 'ZZaASBAAACEAk0bi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 499,
            },
            alt: 'Qatar MOFA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/48a5a967-7cc0-4b28-87b2-58af5f95a549_Qatar+MOFA+logo.png?auto=compress,format',
            id: 'ZZaCqBAAACAAk1C2',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 283,
            },
            alt: 'National Airline of Qatar logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/fc42370c-33a1-4013-a6bb-276a32c4bf38_Qatar+airways..png?auto=compress,format',
            id: 'ZdXDWhEAACkARSlK',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$8941eafa-b74e-4357-bb77-dcd96224f110',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How do I get a Qatar visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian citizens can apply for a Qatar visa in various ways. You can apply online at tryvisa for the e-visa in under 10 minutes.',
              spans: [
                {
                  start: 84,
                  end: 89,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'You can also apply through the Qatar government portal, known as the Hayya online platform. There, you can apply for various types of visas.',
              spans: [
                {
                  start: 68,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need for the Qatar e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You only need a few documents when you apply for your e-visa through tryvisa. This is because tryvisa works with government partners and completes other required forms for you. The documents you need are: ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s front page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A copy of your passport’s back page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Digital photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my Qatar visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your Qatar visa at least 1 week before travel is advised.',
              spans: [
                {
                  start: 38,
                  end: 51,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I check my Qatar visa status online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can easily see the status of your Qatar visa on the tryvisa app:',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'There, you will be able to see your visa’s current status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'If you have applied through the Hayya online portal, you can visit the portal to see your visa status there.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happen if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, you can reapply. When doing so, ensure that all documents are correct and accurate. Visa fees must be paid again since the government and online portals do not provide refunds. Fortunately, when applying through tryvisa, you are guaranteed a refund if your visa is denied, up to ₹8000 or $100. This refund excludes service fees.',
              spans: [
                {
                  start: 215,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
                {
                  start: 277,
                  end: 285,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for a Qatar visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for the Qatar visa. However, it is always advised to have insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I apply for a Qatar visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can easily apply for your Qatar visa from India through tryvisa. At tryvisa, you will:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Complete the easy online application.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Submit the required documents.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Pay the visa fees.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'From there, tryvisa will handle the rest and inform you once your visa is completed.',
              spans: [],
            },
          ],
        },
        {
          question: 'What factors can cause a delay in the processing of my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay the processing of your visa. Some of the more common reasons include the following:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing inaccurate documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'The complexity of each application.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
          ],
        },
        {
          question: 'How does having a criminal record affect the processing of my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Generally, having a criminal record will delay the processing of your visa. This is because the authorities will need to conduct a background check to identify if there are any national security risks. If the authorities find no threat, they will continue processing your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for multiple visas at once?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, on tryvisa, you can complete multiple visas at once. This means you can apply for your friends or family members' visas along with your own. Be sure to have all the details for each applicant.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Qatar with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'With the e-visa, you can stay in Qatar for up to 30 days.',
              spans: [
                {
                  start: 49,
                  end: 56,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the Qatar visa processing time?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Qatar visa takes about 3-5 days to process. This time can take longer if any delays occur.',
              spans: [
                {
                  start: 27,
                  end: 35,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I use the e-visa for Qatar for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the e-visa is only issued as a single entry visa. If you wish to apply for a multi-entry visa, you must visit the embassy.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Qatar visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Qatar e-visa fee for Indians is ₹2500 on tryvisa. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note: Before applying, check the e-visa fees since they can change at any time with the exchange rate.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Qatar with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa for Qatar is only for leisure purposes. This means you can not work or conduct any employment activities in Qatar. To be employed in Qatar, you must have a valid employment visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$3165dc2c-a7b4-492c-a150-e6cb951637c6',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Qatar',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 3250,
    cost_max: 6250,
    cost_min: 2500,
  },
  RW: {
    name: 'Rwanda',
    code: 'RW',
    hero_image_url:
      'https://images.unsplash.com/photo-1489640818597-89b1edc97db5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8UndhbmRhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Rwanda E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 50,
    eta_pre_travel_unit: null,
    entry_type: 'Digital',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 4500,
    service_fees: -4500,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport_back',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_lease_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'shipping_labels',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'travel_itinerary',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1181,
              height: 1181,
            },
            alt: 'Rwanda coat of arms ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a38ef604-5136-4f92-ba97-f350ac21c76e_Coat_of_Arms_Rwanda-01.png?auto=compress,format',
            id: 'ZSY6TRAAACMAfR6f',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 400,
              height: 400,
            },
            alt: 'Ministry of foreign affairs ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5afcb4df-3abf-465c-aa5f-57acdd2f6433_xtiCTQno_400x400.jpg?auto=compress,format',
            id: 'ZSY6ohAAACMAfSAp',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 666,
              height: 375,
            },
            alt: 'Rwanda airline logo. ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d31e4a85-13eb-4e95-8bff-cb1d66031783_Rwand+airline+Logo..png?auto=compress,format',
            id: 'ZdW-FxEAACsARRCk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$d37c97b7-9968-46cf-aac4-8424bc123aae',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for a Rwanda visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents for a Rwanda visa are a valid passport, two passport-size photos, proof of accommodation, sufficient funds for the time of our stay, and a confirmed onward or return flight ticket.',
              spans: [
                {
                  start: 53,
                  end: 61,
                  type: 'strong',
                },
                {
                  start: 81,
                  end: 87,
                  type: 'strong',
                },
                {
                  start: 89,
                  end: 111,
                  type: 'strong',
                },
                {
                  start: 112,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 189,
                  end: 202,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I know the status of my Rwanda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your Rwanda visa on tryvisa, you will be notified of your visa process via email and messages. You can also log onto the tryvisa portal and see the current status of your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the photo size requirements for the Rwanda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The correct photo size for your Rwanda visa application is 34mm by 45mm. It must be a color photo with a white background. You can simply upload your photo on a plain background, and tryvisa will automatically crop the image to meet the visa requirements',
              spans: [
                {
                  start: 59,
                  end: 71,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance to get an Rwanda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you don’t need travel insurance to apply for your Rwanda visa, but having travel insurance is highly recommended.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long before I go to Rwanda should I apply for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Even though the processing times for an Rwanda visa are relatively quick, It is advised that you apply for your visa 1-2 weeks before you travel to Rwanda.',
              spans: [
                {
                  start: 117,
                  end: 126,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I use my e-visa for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you cannot enter Rwanda multiple times on an e-visa; the e-visa is a single-entry visa. To enter multiple times, you will need to reapply for a visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to have any specific vaccinations to enter Rwanda?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are currently no vaccines required if you want to visit Rwanda.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can reapply for your visa after your visa is denied. To do so, ensure you have fixed the reasons that caused the rejection. You will lose the funds when applying through government or online portals, except tryvisa. On tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. Note that the refund excludes the service fees.',
              spans: [
                {
                  start: 221,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 255,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the quickest way to get an Rwanda visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying on tryvisa for your visa is the quickest way to get your Rwanda visa. You can complete the application in under 10 minutes, and from there, your visa will be processed.',
              spans: [
                {
                  start: 113,
                  end: 129,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the application process for an Rwanda visa for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Rwanda visa application process is very simple. You will simply gather all the required documents, go to tryvisa, fill out the application, submit the documents, and then wait for your visa to be processed.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Rwanda visa while I am there?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is possible to extend your visa while you are in Rwanda. You will need to visit the Head Office of Immigration and Citizen Service, where you will then apply for a visa extension. Keep in mind that you will need to apply for an extension before your visa expires.',
              spans: [],
            },
          ],
        },
        {
          question: 'What would the reason be for an Rwanda visa rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are numerous reasons for your visa not being approved; some of them are inaccurate information, reasons for your stay, and not having enough funds for your stay period.',
              spans: [
                {
                  start: 78,
                  end: 100,
                  type: 'strong',
                },
                {
                  start: 102,
                  end: 123,
                  type: 'strong',
                },
                {
                  start: 129,
                  end: 152,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the types of payments accepted for the Rwanda visa fee?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can pay for your e-visa online with tryvisa; you can simply use a debit card, credit card, or your preferred mobile wallet. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the processing time for a Rwanda visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for the Rwanda visa is relatively quick; on average, it takes about 7 days.',
              spans: [
                {
                  start: 88,
                  end: 94,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Rwanda with the e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Rwanda e-visa allows you to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 61,
                  end: 69,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indian nationals get an Rwanda visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians cannot obtain an Rwanda visa on arrival; you will have to apply for a visa before you go to Rwanda.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a Specific dress code for traveling to Rwanda?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no specific dress code for Indian nationals who want to travel to Rwanda. But it is always advised to dress neatly.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any question in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4f45eba9-3e6b-4fd7-8d8a-69dd6e5371b7',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Rwanda',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  SA: {
    priority: 90,
    name: 'Saudi Arabia',
    code: 'SA',
    hero_image_url:
      'https://images.unsplash.com/photo-1565552645632-d725f8bfc19a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U2F1ZGklMjBBcmFiaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Saudi Arabia E-Visa Multiple Entry',
    supported: false,
    message: "Currently not supported\nWe don't yet support Saudi Arabia visas.",
    eta_post_checkout_duration: 7,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Digital',
    entry_length_stay: '90 days',
    entry_validity: '365 days',
    govt_fees: 9980,
    service_fees: 7270,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description: 'US, UK, Schengen visa holders can apply for an e-visa for Saudi Arabia.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'covid_vaccine',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'fbi_background_check',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'identity',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'indonesia_hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        source_url: '',
        key: 'passport',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'passport_back',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_bank_account',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_nif',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'travel_itinerary',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        currency: 'INR',
        toggle: true,
        attributes: ['image_required'],
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 759,
            },
            alt: 'Saudi Arabia Ministry of Culture logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4a5a47b8-79b5-4bc3-83d4-439e3af31ae0_Ministry_of_Culture_%28Saudi_Arabia%29.png?auto=compress,format',
            id: 'ZdSiaxEAACcAQB8t',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3840,
              height: 2160,
            },
            alt: 'Saudi Arabia airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c6874f8d-1b18-4cc2-b48e-f436691894b4_Saudia-Emblem.png?auto=compress,format',
            id: 'ZLeXUBIAACEAYn8T',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 651,
              height: 383,
            },
            alt: 'Ministry of Tourism ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c9afcaf3-a4e3-41ea-b8de-199dc02866c2_Saudi+ministry+of+tourism..png?auto=compress,format',
            id: 'ZdW2_REAACoARO-T',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 427,
              height: 502,
            },
            alt: 'Saudi Minister of foreign affairs ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/a32f5141-9902-45cf-b351-a7b9096b4b86_Saudi+Ministry+of+foreign+affairs.+.png?auto=compress,format',
            id: 'ZdW7CBEAACcARQJi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$3cfa0dc7-4975-4a9b-aa86-f98bff29c240',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents to apply for a Saudi tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To apply for a Saudi visa on tryvisa, you need the following documents: ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport front',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Photo of yourself',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'UK/US/Schengen Valid Visa OR Extension Copy',
              spans: [],
            },
          ],
        },
        {
          question: 'How to ensure a successful Saudi visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'For a successful Saudi visa online application, ensure you fill in accurate information, submit all the required documents, and pay the visa fee promptly.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a fast-track service for Saudi visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: "Currently, there isn't a fast-track service for the Saudi visa online submission. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Saudi Arabia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is safe to apply for a Saudi  visa online. tryvisa uses 256-bit Advanced Encryption Standard to keep your data safe and does not sell your data to third-party companies. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get help with my Saudi Arabia visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Absolutely. Many visa services, like tryvisa, provide guidance and assistance in completing your Saudi visa online application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I track the status of my Saudi visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "Once you've submitted your Saudi visa online application, you can track its status through the app or online portal by going to your profile.",
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Saudi visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "In case of visa rejection, you can proceed to reapply for the visa once you've addressed all the reasons for denial. Remember that visa fees are non-refundable through government and online portals, except tryvisa. When applying through tryvisa, you are guaranteed a refund of up to ₹8000 or $100 if your visa is denied. The refund excludes any service fees.",
              spans: [
                {
                  start: 235,
                  end: 240,
                  type: 'strong',
                },
                {
                  start: 249,
                  end: 260,
                  type: 'strong',
                },
                {
                  start: 262,
                  end: 269,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for a Saudi Arabia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Saudi Arabia visa at least a month before your planned departure date to allow for sufficient processing time.",
              spans: [],
            },
          ],
        },
        {
          question: 'What happens after my Saudi visa online application has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Saudi visa online application has been approved, you will receive an email confirmation with your e visa. You should print this out and carry it with you when you travel to Saudi.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the Saudi visa price?',
          answer: [
            {
              type: 'paragraph',
              text: 'The cost to apply for a Saudi visa online is SAR 480 (approximately INR 10,507).',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Saudi with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, a Saudi tourist visa does not allow you to work in the country. If you wish to work in Saudi, you'll need to apply for a work visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long does the Saudi visa online application process take?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for the Saudi visa online application takes 3-5 days when you apply on tryvisa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance required for a Saudi visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "While not mandatory, it's recommended to have travel insurance for your trip to Saudi. It's not a requirement for the Saudi visa online application.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the minimum passport validity required for a Saudi visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months from the date of entry into Saudi for a successful Saudi visa online submission.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Saudi visa online on behalf of a friend or family member?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Saudi visa online on behalf of a friend or family member. You will need to have the necessary documents and details to complete the application.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question:
            'What are the specifications for the passport-sized photos needed for the Saudi visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The passport-sized photos needed for the Saudi visa online application should be in color, taken against a white background, and should show your full face without any headgear (unless you wear it for religious reasons).',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know when my Saudi visa online submission has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your Saudi visa online submission has been approved. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I cancel my Saudi visa online application after it has been submitted?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Saudi visa online application has been submitted, it cannot be canceled. However, you can choose not to use the visa if your travel plans change.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I pay for my Saudi visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for your Saudi visa online application can be paid online through tryvisa using a variety of payment methods, including certain mobile wallets.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it possible to apply for a Saudi e visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Saudi e-visa. The Saudi e-visa is a digital visa that allows you to enter Saudi for tourism, business, or transit purposes.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a physical visa stamp for the Saudi e visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, the Saudi e visa is an electronic visa, meaning there's no physical stamp.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Saudi visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply fast and hassle-free on the tryvisa online portal.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the procedure to apply for a Saudi visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Go to the tryvisa online portal and enter your travel dates, upload your documents, complete the visa application form, and pay the visa fee.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0dccd815-820c-46e8-9faa-56afa0352b17',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Saudi Arabia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 17250,
    cost_max: 23237,
    cost_min: 12030,
  },
  TW: {
    name: 'Taiwan',
    code: 'TW',
    hero_image_url:
      'https://images.unsplash.com/photo-1470004914212-05527e49370b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8VGFpd2FufGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Taiwan Travel Authorization',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'ROC Taiwan TAC',
    entry_length_stay: '14 days',
    entry_validity: '90 days',
    govt_fees: 2000,
    service_fees: -2000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Printerd Copy of TAC',
        description:
          'A printed copy of the TAC is mandatory to be presented to immigration upon arrival in Taiwan',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'fbi_background_check',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'identity',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'indonesia_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'occupation',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'pan_card',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'passport_back',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_bank_account',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_insurance',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'travel_itinerary',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 400,
              height: 400,
            },
            alt: 'Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0ee675a8-fd14-4d57-b77d-79a0574395b2_Taiwan+Ministry+of+foreign+affairs..png?auto=compress,format',
            id: 'ZdT3VhEAACwAQZ8p',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 591,
              height: 422,
            },
            alt: 'Taiwan government website logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/00bc6980-8040-446e-b311-9e9b01503548_Taiwan+government+website+logo.png?auto=compress,format',
            id: 'ZdUpyhEAACgAQoZM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 634,
              height: 162,
            },
            alt: 'Taiwan Bureau of Consular Affairs logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/282ecd83-40b0-42e0-9864-7e8ad5749cdd_Taiwan+Bureau+of+consular+affairs.png?auto=compress,format',
            id: 'ZdUrvxEAACwAQo7t',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 823,
              height: 303,
            },
            alt: 'Taiwan tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ff8d5e79-7f75-46b6-9c6e-2bec6b3a4ee9_Taiwan_tourism_logo.png?auto=compress,format',
            id: 'ZdUuMBEAAC4AQpm8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$3cfa0dc7-4975-4a9b-aa86-f98bff29c240',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How to ensure a successful Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'For a successful Taiwan visa online application, ensure you fill in accurate information, submit all the required documents, and pay the visa fee promptly. ',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'The applicant’s passport must have a remaining validity of at least six months starting from the date of arrival in Taiwan.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'The applicant must possess an onward/return air or ferry ticket.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'The applicant has never been employed as a migrant worker in Taiwan.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'The applicant has no record of violations of immigration irregularities or laws in Taiwan.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Special requirements:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'In addition to the aforementioned basic requirements, one of the following requirements must also be met:',
              spans: [
                {
                  start: 54,
                  end: 57,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'The applicant possesses at least one of the following documents issued by the United States, Canada, the United Kingdom, any of the EU and Schengen countries, New Zealand, Australia, Japan or Korea. ',
              spans: [
                {
                  start: 33,
                  end: 53,
                  type: 'strong',
                },
                {
                  start: 54,
                  end: 63,
                  type: 'strong',
                },
                {
                  start: 78,
                  end: 197,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'A valid resident or permanent resident certificate',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'A valid entry visa (including electronic visa)',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'A resident certificate or visa that has expired less than 10 years prior to the date of arrival in Taiwan; however, applicants who use an electronic visa issued by Australia or New Zealand as the required document must present the valid Australia or New Zealand electronic visa as entering Taiwan.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there a fast-track service for Taiwan visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: "Currently, there isn't a fast-track service for the Taiwan visa online submission. ",
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a Taiwan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is safe to apply for a Taiwan visa online. tryvisa uses 256-bit Advanced Encryption Standard to keep your data safe and does not sell your data to third-party companies. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get help with my Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Absolutely. Many visa services, like tryvisa, provide guidance and assistance in completing your Taiwan visa online application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I track the status of my Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "Once you've submitted your Taiwan visa online application, you can track its status through the app or online portal by going to your profile.",
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Taiwan visa online submission is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "Upon visa rejection, you can reapply for the visa once you've addressed the reasons for denial. Remember that visa fees are non-refundable through government and online portals, which causes you to repay the funds. Fortunately, tryvisa provides a maximum of ₹8000 ($100) refund for denied visas. This refund will exclude any service fees.",
              spans: [
                {
                  start: 215,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 269,
                  end: 275,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for a Taiwan visa?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Taiwan visa at least a month before your planned departure date to allow for sufficient processing time.",
              spans: [],
            },
          ],
        },
        {
          question: 'What happens after my Taiwan visa online application has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Taiwan visa online application has been approved, you will receive an email confirmation with your e visa. You should print this out and carry it with you when you travel to Taiwan.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Taiwan with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: "No, a Taiwan tourist visa does not allow you to work in the country. If you wish to work in Taiwan, you'll need to apply for a work visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long does the Taiwan visa online application process take?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Taiwan visit visa processing time varies based on where you apply. Generally, it takes 3-10 days to process the visit visa. tryvisa will provide you with a guaranteed processing time. ',
              spans: [
                {
                  start: 91,
                  end: 100,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required for a Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: "While not mandatory, it's recommended to have travel insurance for your trip to Taiwan. It's not a requirement for the Taiwan visa online application.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the minimum passport validity required for a Taiwan visa online submission?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months from the date of entry into Taiwan for a successful Taiwan visa online submission.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Taiwan visa online on behalf of a friend or family member?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Taiwan visa online on behalf of a friend or family member. You will need to have the necessary documents and details to complete the application.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question:
            'What are the specifications for the passport-sized photos needed for the Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The passport-sized photos needed for the Taiwan visa online application should be in color, taken against a white background, and should show your full face without any headgear (unless you wear it for religious reasons).',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know when my Taiwan visa online submission has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive an email notification once your Taiwan visa online submission has been approved. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I cancel my Taiwan visa online application after it has been submitted?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Taiwan visa online application has been submitted, it cannot be canceled. However, you can choose not to use the visa if your travel plans change.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I pay for my Taiwan visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for your Taiwan visa online application can be paid online through tryvisa using a variety of payment methods, including certain mobile wallets.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the procedure to apply for a Taiwan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Go to the tryvisa online portal and enter your travel dates, upload your documents, complete the visa application form, and pay the visa fee.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my visit visa to Taiwan?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian citizens can extend their visit visa for an additional 30 days. To extend your visa, you must apply for your visa extension at the National Immigration Agency (NIA) in Taiwan. This extension must be applied for at least 15 days before the expiration date of your visa but not earlier than 30 days before your visa expires.',
              spans: [
                {
                  start: 51,
                  end: 69,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for a Taiwan visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Taiwan does not provide Indian nationals the option of applying for a visa on arrival.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0dccd815-820c-46e8-9faa-56afa0352b17',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Taiwan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  UZ: {
    name: 'Uzbekistan',
    code: 'UZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1624792301219-f4a98797f81e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fFV6YmVraXN0YW58ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Uzbekistan E-Visa ',
    supported: false,
    message: null,
    eta_post_checkout_duration: 5,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 2001,
    service_fees: 1209,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-visa',
        description:
          'An E-visa is required to enter Uzbekistan. The visa is a single entry visa valid for 30 days',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 487,
              height: 513,
            },
            alt: 'Ministry of Foreign Affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/08db4ac0-c979-4fb5-b0e2-220f53560016_Government_of_Uzbekistan.png?auto=compress,format',
            id: 'ZdNgrxIAACoA886e',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1037,
              height: 240,
            },
            alt: 'Uzbekistan airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cbf0ee87-73f1-4c1d-81f7-10ee091dc170_Uzbekistan+Airways..png?auto=compress,format',
            id: 'ZdRZzhEAACcAPqGb',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 548,
              height: 180,
            },
            alt: 'Uzbekistan e-visa portal logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/65e6b95d-5dcd-43ec-8929-cde189a22e8a_Logo+of+Uzbekistan.png?auto=compress,format',
            id: 'ZdRdfhEAACgAPrIh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$193a241b-ca77-49a0-b5c7-bf699a904c9b',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for an Uzbekistan visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: "You'll need the following for your Uzbekistan visa:",
              spans: [
                {
                  start: 35,
                  end: 50,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/uzbekistan-visa-for-indians',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the procedure to check the status of my Uzbekistan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "To check the status of your Uzbekistan visa application, visit the tryvisa app or online portal. From there, you need to click on the profile button. Then, you'll be able to see all the information about your visa, including your visa status.",
              spans: [],
            },
          ],
        },
        {
          question:
            'Is it mandatory to have a confirmed flight ticket while applying for an Uzbekistan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is generally required to have a confirmed flight ticket when applying for an Uzbekistan e-visa. The authorities want to ensure that visitors have a return ticket or a plan to leave Uzbekistan within the visa validity period. Make sure to provide the necessary flight details in your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Uzbekistan visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, review the reasons for the rejection and correct those reasons. Reapply for your visa once all the reasons are corrected. When applying through the government or online portals, you will not be refunded, which causes you to lose funds. However, on tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on denied visas. Please note this refund excludes any service fees.',
              spans: [
                {
                  start: 274,
                  end: 281,
                  type: 'strong',
                },
                {
                  start: 286,
                  end: 291,
                  type: 'strong',
                },
                {
                  start: 313,
                  end: 320,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Are there any restrictions on the activities I can engage in with an Uzbekistan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'With an Uzbekistan e-visa, you are generally allowed to engage in tourism and business activities and visit friends or family. However, it is important to check the visa conditions and ensure that your intended activities comply with the purpose of your visa. Engaging in unauthorized activities may have legal consequences.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the entry requirements for an Uzbekistan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The entry requirements for an Uzbekistan e-visa include the following:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Meeting the eligibility criteria for the specific visa category.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing the necessary supporting documents.\nMake sure to fulfill these requirements and adhere to the visa conditions to ensure a smooth entry into Uzbekistan.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if I make a mistake in my Uzbekistan e-visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have made a mistake in your Uzbekistan e-visa application, it is recommended to contact the relevant authorities or the support team of tryvisa. They may provide guidance on how to rectify the error or advise you on the best course of action.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to have travel insurance to apply for an Uzbekistan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While travel insurance is not mandatory for a Uzbekistan e-visa application, it is highly recommended. Having travel insurance provides financial protection in case of unforeseen events, such as medical emergencies or trip cancellations. It is advisable to obtain comprehensive travel insurance to safeguard your travel plans.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of an Uzbekistan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The validity period of an Uzbekistan e-visa is 30 days starting from the date of entry into Uzbekistan.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of an Uzbekistan e-visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The cost of an Uzbekistan e-visa for Indian citizens is INR 2150.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process an Uzbekistan e-visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an Uzbekistan e-visa for Indian citizens is usually within 5 business days. However, it is recommended to apply well in advance to avoid any delays. Factors such as peak seasons or unforeseen circumstances may affect the processing time.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Uzbekistan e-visa if I have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: "The eligibility criteria for a Uzbekistan e-visa may take into account an applicant's criminal record. It is recommended to check the specific visa requirements and consult the official Uzbekistan embassy or consulate if you have concerns about your eligibility based on a criminal record.",
              spans: [],
            },
          ],
        },
        {
          question:
            'Do I need a hotel reservation for the entire duration of my stay in Uzbekistan?',
          answer: [
            {
              type: 'paragraph',
              text: 'While it is generally recommended to have a hotel reservation for at least the initial part of your stay in Uzbekistan, it may not be necessary to provide reservations for the entire duration.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indian citizens apply for an Uzbekistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for an Uzbekistan visa. Uzbekistan welcomes visitors from various countries, including India. By following the correct visa application process, Indian citizens can obtain the necessary visa to travel to Uzbekistan.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Uzbekistan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Uzbekistan visa through the tryvisa app. Applying for an Uzbekistan visa online has never been more simple.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$d03e455a-c304-4324-a0e2-bcba15ff1b9d',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Uzbekistan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 3210,
    cost_max: 3964,
    cost_min: 2460,
  },
  AL: {
    name: 'Albania',
    code: 'AL',
    hero_image_url:
      'https://images.unsplash.com/photo-1557313501-341d9d6fd40f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QWxiYW5pYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Digital',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 11745,
    service_fees: -11745,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'appointment',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'flight_hotel_details',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'indonesia_hotel_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'notary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'occupation',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        currency: 'INR',
        toggle: true,
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'photo',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_bank_account',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_nif',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'shipping_labels',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'us_residence',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 613,
              height: 407,
            },
            alt: 'Albania Ministry of Tourism ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/6a69393a-cfff-4661-a352-f5d387a6a2a0_MInistry_of_tourism_Albania-.png?auto=compress,format',
            id: 'ZdNUqxIAACgA85cZ',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2048,
              height: 2048,
            },
            alt: 'Albania Foreign Ministry',
            copyright: null,
            url: 'https://images.prismic.io/atlys/171225a6-7f34-4c09-b56f-026fc265aacd_Albanian_ministry_of_foreign_affairs_logo.svg.png?auto=compress,format',
            id: 'ZLeN3BIAACEAYlKc',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 407,
              height: 407,
            },
            alt: 'Air Albania Logo ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e7133bd1-2f87-40a9-ac89-4517c04f9af9_Air+Albania+logo..png?auto=compress,format',
            id: 'ZdRfdBEAAC4APrsF',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$6132cdfb-79e6-4e09-b737-e7cf2ea6c2b8',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for Albania visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: "When applying for the Albania eVisa, you'll need a valid passport, passport-sized photos, and flight & hotel details.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the Albania visa photo size requirement, and can I do it online for free?',
          answer: [
            {
              type: 'paragraph',
              text: 'The standard photo size is 3.5 x 4.5 cm. tryvisa will adjust your photo to meet the required photo size for free.',
              spans: [],
            },
          ],
        },
        {
          question: 'Who is a well-regarded Albania visa agent?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa is one of the numerous agents that provide visa services. With tryvisa handling your visa application, you can save precious time that would otherwise be spent on gathering documents and filling out forms. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I trust online Albania visa agents?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Albania visa agents such as tryvisa have a secure platform to protect your sensitive data during the application process. They comply with all data security laws to ensure your information is safe.',
              spans: [
                {
                  start: 33,
                  end: 38,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Is the process to apply for an online Albania tourist visa for Indians straightforward?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the process is quite straightforward. tryvisa simplifies the visa application process, making it efficient and hassle-free.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Albania visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "After your visa is denied, you can reapply for the visa once you've resolved all the reasons for the denial. However, please be aware that visa fees are non-refundable through government and online portals. tryvisa stands out by providing up to ₹8000 ($100) refund in case of visa rejection. This refund excludes any service fees.",
              spans: [
                {
                  start: 207,
                  end: 212,
                  type: 'strong',
                },
                {
                  start: 256,
                  end: 262,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'Where can I get assistance to apply for a Albania tourist visa for Indians online?',
          answer: [
            {
              type: 'paragraph',
              text: "tryvisa will assist you in reviewing your application to ensure it's correctly filled out before submission. This greatly reduces the chance of mistakes that might lead to application rejection.",
              spans: [],
            },
          ],
        },
        {
          question: 'What are the consequences of providing an incorrect Albania visa photo size?',
          answer: [
            {
              type: 'paragraph',
              text: 'Providing an incorrect photo size may lead to application delays or rejections. Always ensure your photo meets the stated requirements.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check the status of my Albania visa application online?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa updates you about your application status and helps you track your progress by sending timely messages and notifications. You can log into your online portal to stay up to date with your application. ',
              spans: [],
            },
          ],
        },
        {
          question: "What's the fastest way to get an online Albania visa for Indians ?",
          answer: [
            {
              type: 'paragraph',
              text: 'The fastest way is often through an expedited service, available through certain visa agents or sometimes directly from the official immigration website.',
              spans: [],
            },
          ],
        },
        {
          question: "What's the easiest way to apply for a Albania visa?",
          answer: [
            {
              type: 'paragraph',
              text: 'Applying online is generally the easiest way. You can do this either through tryvisa or the official immigration website.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Albania visa for Indians online over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, online visa applications are generally available 24/7. However, processing times may still depend on business days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the common reasons for a Albania visa application rejection?',
          answer: [
            {
              type: 'paragraph',
              text: 'The common reasons for the Albania visa to be rejected could include',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect or incomplete documentation',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of a valid reason for travel',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Albania tourist visa for Indians online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for a Albania tourist visa online. Ensure you have all the required documents. Download the App and fill out the application form.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the steps to apply for a Albania visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Start by gathering your documents, completing the application form, paying the fees, and submitting the form online or at the visa center.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Albania visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply through tryvisa; ensure you have the necessary documents ready. You’ll complete your application in 5 minutes.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the fees when I apply for a Albania visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Albania visa fee is INR 15,875, and tryvisa has no hidden fees. These costs are subject to change, and there might be visa processing fees for Indian citizens, so I recommend you check the visa fees before applying.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Albania tourist visa online from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens can apply for a Albania tourist visa for India online. Make sure to check the specific requirements for Indian nationals.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to apply for a Albania visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Albania processing time is 15-30 days. tryvisa efficiently handles fee payments as part of their visa processing services. This can save you time and effort.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$701803e7-d9ea-4579-89e5-9cc5d834d6c9',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Albania',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  AR: {
    name: 'Argentina',
    code: 'AR',
    hero_image_url:
      'https://images.unsplash.com/photo-1589909202802-8f4aadce1849?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QXJnZW50aW5hfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 11,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 13000,
    service_fees: -12250,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 350,
              height: 112,
            },
            alt: 'Argentina MOFA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/b0427b92-a9f4-4a6e-b307-27982a2caa7a_Argentina+MOFA+Logo..png?auto=compress,format',
            id: 'Za4j6hAAACIAGyrd',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 340,
            },
            alt: 'Argentina national airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/501ed725-340d-490f-b2c4-5185802ef077_Aerol%C3%ADneas_Argentinas_Logo.png?auto=compress,format',
            id: 'Za4khxAAACMAGyvk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 924,
              height: 218,
            },
            alt: 'Argentina tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/4c1ceead-35cf-4c75-829a-d72f8accc119_Visit+Argentina+tourism+board+logo.png?auto=compress,format',
            id: 'ZdNbbRIAAC4A87V0',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$49171764-36a9-4e00-806d-47e242fcd0d2',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How long in advance must I apply for my visa to Argentina?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa at least 2 month before you travel to Argentina.',
              spans: [
                {
                  start: 46,
                  end: 61,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents are required to obtain a tourist visa to Argentina?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a tourist visa to Argentina, you need the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport must be valid for at least 6 months from the entry date and have 2 empty pages.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Copy of your passport: You need a copy of your valid passport and any valid or expired visas.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application form: You must submit a duly completed application form. The pages can not be printed back-to-back.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
                {
                  start: 51,
                  end: 67,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/226b031b-bf92-4324-9bdf-e9f3af4d7233/visa_application_form_2024_0.pdf?id=f6baeb3a-65c5-45c4-bff4-a240d193d186&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1706054400000&signature=LFwgApOKjbe4o1kks_bV9W-JyXYe76hPf3zqbVDbhh8&downloadName=visa_application_form_2024_0.pdf',
                    target: '_blank',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: You require 2x recently taken passport-sized photos, which are 4cm x 4cm.',
              spans: [
                {
                  start: 0,
                  end: 7,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Cover letter: You must submit a cover letter stating your travel itinerary, such as place of stay, flight details, and who is covering the costs. The cover letter must be translated into Spanish.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'An employment letter: A letter from your employer stating how long you have been working and are currently employed. This letter must be notarised, and you must provide an additional letter in Spanish.',
              spans: [
                {
                  start: 0,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank statements: You must submit your last 3-month bank statements to show proof of sufficient funds. The bank must stamp these statements.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight tickets: Show proof of your flight details by submitting your flight tickets.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Hotel reservations: Proof of where you will stay is required. You can prove this by providing your hotel reservation.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Travel itinerary: A detailed travel itinerary corresponding to your flight tickets and hotel reservations is required.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Invitation letter: If you have been invited to Argentina and do not stay at a hotel, you must provide an invitation letter.',
              spans: [
                {
                  start: 0,
                  end: 19,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: "Salary slips: You require the last 3 months' salary slips and the last 3 years' tax returns.",
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note that additional documents, such as an authorised letter, might be required when applying through a visa company. Contact the embassy to get the most accurate documents based on your application.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
                {
                  start: 43,
                  end: 60,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my Argentina visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Upon visa rejection, you can reapply for the visa once you have fixed all the reasons for the denial. You must repay the visa fees since the government and visa portals do not refund the fees, except for tryvisa. At tryvisa, you will receive a refund of up to ₹8000 or $100 when your visa is rejected. The refund does not include the service fee.',
              spans: [
                {
                  start: 214,
                  end: 219,
                  type: 'strong',
                },
                {
                  start: 240,
                  end: 246,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way to obtain a tourist visa to Argentina?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to obtain your Argentina visa is through tryvisa. At tryvisa, we will ensure you have a smooth and efficient visa application process. We will submit your application and documents at the embassy on your behalf. \n\nThis is done by collecting your documents from you via courier. Once your visa gets approved, we will send your visa to you.',
              spans: [
                {
                  start: 57,
                  end: 62,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does it take to process an Argentina visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for the Argentina visa can take anywhere from 3 weeks to 2 months.',
              spans: [
                {
                  start: 65,
                  end: 85,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Argentina visa online from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply online through tryvisa. When doing so, tryvisa will take care of submitting your application at the embassy on your behalf. This allows you to apply online without visiting the embassy.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Note, If an interview is required, you must visit the embassy.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is a personal interview required for an Argentina visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, an interview is not always required. This will depend on each applicant. If the authorities find it necessary, they will ask you to come in for an interview.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Argentina with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa allows Indians to stay in the country for up to 90 days.',
              spans: [
                {
                  start: 65,
                  end: 72,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for the Argentina tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not one of the requirements. However, it is always advised to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Argentina with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a tourist visa is not intended for employment purposes. If you plan to work in Argentina, you need to apply for an appropriate work visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I track the status of my Argentina visa application from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can track your application if you have applied through tryvisa:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you have applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once selected, you will see your visa’s current status.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Can I enter Argentina with a one-way ticket, or do I need a round-trip ticket for the visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you must have a round-trip ticket when applying for your visa to Argentina.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa to get rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can cause your visa to be rejected, some of them include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds to cover your trip to Argentina.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing false documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'If your passport is not valid for at least 6 months.',
              spans: [],
            },
          ],
        },
        {
          question: 'Will I get a refund if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the visa fees are non-refundable when applying through government portals since they are used to process your visa. But, when applying through tryvisa, you will receive a refund of up to ₹8000 even if your visa gets denied. (The refund excludes service fees).',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 123,
                  type: 'strong',
                },
                {
                  start: 173,
                  end: 180,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Where do I submit my Argentina visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indian nationals who are not residents of Maharashtra can submit their applications at the embassy in New Delhi. However, if your residency is in Maharashtra, you must submit your application at the embassy in Mumbai.',
              spans: [
                {
                  start: 91,
                  end: 111,
                  type: 'strong',
                },
                {
                  start: 199,
                  end: 216,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$48ac75f9-ad81-4cfc-92d1-bc564281e474',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Argentina',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: 750,
    cost_max: 21000,
    cost_min: 15652,
  },
  AT: {
    priority: 50,
    name: 'Austria',
    code: 'AT',
    hero_image_url:
      'https://images.unsplash.com/photo-1485081669829-bacb8c7bb1f3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QXVzdHJpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Austria',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  BE: {
    name: 'Belgium',
    code: 'BE',
    hero_image_url:
      'https://images.unsplash.com/photo-1547057951-61fcf322bb1e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8QmVsZ2l1bXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Belgium',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  CA: {
    name: 'Canada',
    code: 'CA',
    hero_image_url:
      'https://images.unsplash.com/photo-1517935706615-2717063c2225?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q2FuYWRhfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'tryvisa Black - Canada Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 31,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '180 days',
    entry_validity: '10 years',
    govt_fees: 0,
    service_fees: 15210,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa & Guidance',
        description:
          'With tryvisa Black you will get a personalzied concierge to assist you with the Canada visa application process. ',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 3840,
              height: 2160,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/e789da9d-9acc-49ab-b0b5-4d23f0cfe4b8_Air-Canada-Logo.png?auto=compress,format',
            id: 'ZW8qMBEAACMAqJiG',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2048,
              height: 886,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/72e1b1e9-cb0e-47fa-8354-c765b1e66afe_ATAC-Logo-PNG.png?auto=compress,format',
            id: 'ZW8qaxEAACQAqJmi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$7b716425-0447-45aa-be97-1e2e42cc8744',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Canada',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 15210,
    cost_max: 25000,
    cost_min: 14460,
  },
  CH: {
    name: 'Switzerland',
    code: 'CH',
    hero_image_url:
      'https://images.unsplash.com/photo-1527668752968-14dc70a27c95?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8U3dpdHplcmxhbmR8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Switzerland',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  CL: {
    name: 'Chile',
    code: 'CL',
    hero_image_url:
      'https://images.unsplash.com/photo-1478827387698-1527781a4887?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q2hpbGV8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Chile E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 16,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 100,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 12500,
    service_fees: 1778,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 630,
            },
            alt: 'Chile Ministry of Foreign Affairs Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8094833c-5cb7-4946-b315-b485d17e829b_Chile+MOFA+logo.png?auto=compress,format',
            id: 'ZbeQBRIAACAAOlBp',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 397,
              height: 127,
            },
            alt: 'Chile airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/9531b2d0-ecf2-4486-8c75-7d63db2c89f1_Brazil+Airlines..png?auto=compress,format',
            id: 'ZdR1MxEAAC4AP04W',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 135,
              height: 160,
            },
            alt: 'Chile tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d75c4836-4334-449c-9e6d-a58dacd78a79_logo-chile-travel.png?auto=compress,format',
            id: 'ZbeSOhIAACMAOlQw',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$68772f0d-f99d-42ef-bcc1-9e31920f5e19',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way to get a Chile visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to get a visa for Chile from India is to apply for an e-visa on Altys. With the easy online visa application, you can apply for your visa in under 5 minutes.',
              spans: [
                {
                  start: 80,
                  end: 85,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'When should I apply for my Chile e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised to apply for your visa to Chile at least one month before your travel date to Chille.',
              spans: [
                {
                  start: 55,
                  end: 71,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for the Chile e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To successfully complete the Chile e-visa application, you must provide the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: A copy of your passport’s information page is required.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: A recently taken photo must be submitted.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of accommodation: Submit a hotel reservation or an invitation letter from a host in Chile.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of funds: You must prove you have enough funds to cover your stay in Chile. This can be done by submitting your last 3 month’s bank statements.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Tickets: Submit your flight tickets to show your entry and departure date for Chile.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additional documents might be required if authorities find it necessary.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I reapply if my Chile visa application is denied?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I get a visa on arrival in Chile as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Chile does not offer visas on arrival. Pre-application is required.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it possible to extend my Chile visa while in the country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, it is possible to extend your stay in Chile. To do so, you must apply at Extranjería (Chilean foreign department). But before you can apply, an appointment is required.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 78,
                  end: 90,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Once you have an appointment, you can submit the required documents and request a visa extension. Once approved, you can stay in Chile for a longer period.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity of a Chile tourist visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Chile e-visa has a validity of 90 days and allows you to enter the country for a single entry.',
              spans: [
                {
                  start: 34,
                  end: 42,
                  type: 'strong',
                },
                {
                  start: 85,
                  end: 98,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my Chile visa application to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can be the reason for your visa getting delayed. The most common reasons include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Providing a lack of documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Details on the application form do not match your passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory for Chile trips?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not mandatory for a visa application, having travel insurance is highly recommended for emergencies.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check my e-visa status online?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you have applied for your visa on tryvisa, you can easily see your visa status through the tryvisa app:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Get the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you have applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you have clicked on the visa, you will see its current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long should my passport be valid to apply for a Chile visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your passport should be valid for at least six months beyond your intended departure date from Chile.',
              spans: [
                {
                  start: 43,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will I get a refund if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, when applying through the government portal, you will not receive a refund if your visa is rejected. This is because the fees are used to process your visa application. However, when you apply through tryvisa, you will receive a refund of up to ₹8000 (excluding the service fees) even if your visa gets rejected.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 173,
                  end: 180,
                  type: 'strong',
                },
                {
                  start: 205,
                  end: 210,
                  type: 'strong',
                },
                {
                  start: 231,
                  end: 237,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the Chile e-visa processing time?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Chile takes about 15 days to get processed. However, various factors can affect the processing time.',
              spans: [
                {
                  start: 32,
                  end: 40,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for multiple travellers at once?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply multiple travellers at once when using tryvisa. All you need is each individual's details to complete the application.",
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$0f94c8d2-6f51-4bff-ab1e-8aa9d5445838',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Chile',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 14278,
    cost_max: 12500,
    cost_min: 3528,
  },
  CO: {
    name: 'Colombia',
    code: 'CO',
    hero_image_url:
      'https://images.unsplash.com/photo-1576018753502-1a55b66cc44a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Q29sb21iaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: 36,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 12100,
    service_fees: 150,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 746,
              height: 151,
            },
            alt: 'Colombia government website logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/99db7b9b-0fbf-4f66-914e-a9aa69aa9e0a_logo_gov_Colombia.png?auto=compress,format',
            id: 'ZZv0ZREAAB4Atdgk',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Colombia tourism board Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/17bbda00-498a-4477-922b-46664456bf45_tourism-logo-colombia.png?auto=compress,format',
            id: 'ZZv08REAACEAtdpR',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 5000,
              height: 3000,
            },
            alt: "Colombia's national airline logo, Avianca.",
            copyright: null,
            url: 'https://images.prismic.io/atlys/81304938-36ee-4018-b2a0-c9bf3dbc4419_Avianca-logo+Colombia.png?auto=compress,format',
            id: 'ZZv1vxEAACEAtd4B',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 269,
              height: 300,
            },
            alt: 'Colombia Coat of Arms ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/680fdd41-b580-4009-9cce-cc64d3825efe_Colombia+coat+of+arms..png?auto=compress,format',
            id: 'ZdSOrxEAACkAP8V-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$cb351b0a-90ce-435b-b772-ea5a18da2059',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required when applying for a Colombia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your visa to Colombia, you require the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2x passport-sized photos.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of sufficient funds to cover your stay in Colombia.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Flight ticket for a departure flight from Colombia.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Additional documents can be requested from the embassy, such as invitation letters, tax returns, and proof of stay.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the easiest way to get a visa for Colombia?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to get a visa for Colombia is by applying through tryvisa. tryvisa has a smooth online process, allowing you to complete your application in under 10 minutes. Once submitted, tryvisa will handle the rest.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Colombia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is advised that you apply for your visa 2-3 weeks before travelling to Colombia.',
              spans: [
                {
                  start: 43,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required when applying for a Colombia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory. However, it is always advised when travelling to a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, you can reapply. When doing so, ensure that all documents are correct and accurate. Visa fees must be paid again since the government and online portals do not provide refunds. Fortunately, when applying through tryvisa, you are guaranteed a refund if your visa is denied, up to ₹8000 or $100. This refund excludes service fees.',
              spans: [
                {
                  start: 215,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 250,
                  end: 255,
                  type: 'strong',
                },
                {
                  start: 265,
                  end: 276,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get a visa on arrival for Colombia?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian nationals can not get a visa on arrival in Colombia. They must have a valid visa before travelling to the country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What will affect the processing of my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Multiple factors can delay the processing of your visa. Some of these factors are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not submitting all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not providing enough proof of either funds, stay, or travel.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the processing time of the Colombia visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa for Colombia generally takes about 5 days to process.',
              spans: [
                {
                  start: 44,
                  end: 50,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Colombia with an e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa for Colombia allows you a stay of up to 30 days.',
              spans: [
                {
                  start: 50,
                  end: 58,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa for Colombia?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you have applied at tryvisa, you will receive a digital visa via email. You can download and print this visa before travelling to Colombia.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Colombia on a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa for Colombia can only be used for leisure activities. If you want to work in Colombia, you must have the appropriate employment visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$1279e79a-5f5f-4eed-bc79-f7eb9e3ebc75',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Colombia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: 12250,
    cost_max: 19152,
    cost_min: 11500,
  },
  CR: {
    name: 'Costa Rica',
    code: 'CR',
    hero_image_url:
      'https://images.unsplash.com/photo-1536708880921-03a9306ec47d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Q29zdGElMjBSaWNhfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 50,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 3000,
    service_fees: -3000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1313,
            },
            alt: 'Official emblem logo of Costa Rica.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/10dfd67d-b80a-4281-bca7-33bd660813e8_Emblem+of+Costa+Rica.png?auto=compress,format',
            id: 'ZYGW6xEAACcA5de8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 891,
              height: 116,
            },
            alt: 'Costa Rica Ministry of Exteriors logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5f81a43b-273a-4a11-a0e2-a33e66b47e3c_Costa+Rica+Ministry+of+Exteriors+logo.png?auto=compress,format&rect=14,3,845,110&w=891&h=116',
            id: 'ZdRBGhEAACwAPjL3',
            edit: {
              x: -14,
              y: -3,
              zoom: 1.0520584399998185,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 96,
            },
            alt: 'Costa Rica tourism board logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/962cd473-bae1-43d7-960a-4109d9bfe4f9_Costa+Rica+tourism+board.png?auto=compress,format',
            id: 'ZdQ9IxEAAC4APiF8',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 532,
              height: 207,
            },
            alt: 'Costa Rica airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cbd42624-8c8a-4c39-a8aa-78c5df15d899_Costa+Rica+airline..png?auto=compress,format',
            id: 'ZdXlLBEAAC4ARcQS',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$328ee3c6-051b-400f-be95-b42b046eed19',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question:
            'Do I need to submit my passport at the embassy when applying for a Costa Rica visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must submit your passport at the embassy for the Costa Rica visa application. However, if you apply through tryvisa, we will submit your documents at the embassy on your behalf.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 87,
                  end: 94,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Costa Rica visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your Costa Rica visa at least 3-4 weeks before visiting Costa Rica is advised.',
              spans: [
                {
                  start: 43,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for a Costa Rica visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your Costa Rica visa, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: You need a passport that is valid for at least six more months.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport Copy: You must submit a copy of each of your passport’s pages.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application form: You must fully and accurately complete the visa application form.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
                {
                  start: 66,
                  end: 82,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/bc742cd2-a7be-475d-a0b1-9d0db6b528e9/Untitled.pdf?id=4362e285-8144-4628-a205-8091a8b443af&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1703116800000&signature=YAsJzQw-IAy3hUDDNaCKyyXOroS5S0KF-PVXnzCUSwI&downloadName=Untitled.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Accommodation: Show proof of stay by submitting hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Tickets: Provide your flight itinerary by submitting your flight bookings.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Police Clearance Certificate: The police clearance certificate must be issued by the relevant Regional Passport Office and be duly apostilled by the Ministry of External Affairs. You also require a Spanish translation of the certificate.',
              spans: [
                {
                  start: 0,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Cover Letter: Submit a cover letter providing all your details, including your address, occupation, and contact details.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statements: You must submit your bank statements to prove you have sufficient funds for your trip.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Tax Returns: If applicable, your last 3 year’s income tax returns.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are provided and you meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Instead, you can apply through tryvisa, where you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. This refund will not include the service fee.',
              spans: [
                {
                  start: 122,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 158,
                  type: 'strong',
                },
                {
                  start: 187,
                  end: 193,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I get a multiple-entry visa for Costa Rica?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, the tourist visa for Costa Rica is a multi-entry visa. This allows you to enter the country multiple times.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way for Indians to get a Costa Rica visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indians can easily apply for their Costa Rica visa on tryvisa. tryvisa, we will guide you in the visa application and submit your documents at the embassy on your behalf. This means you do not need to visit the embassy in person.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to attend an interview when applying for a Costa Rica visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'An interview is not always required when applying for your Costa Rica visa. However, this depends on each application, and if the authorities find it necessary, they can ask you to attend an interview.',
              spans: [
                {
                  start: 16,
                  end: 26,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you apply for your visa through tryvisa, we will send your approved visa to you via courier.',
              spans: [
                {
                  start: 81,
                  end: 92,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the Costa Rica visa processing time?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Costa Rica visa takes about 10 to 15 days to process. However, delays can cause your visa processing to take longer.',
              spans: [
                {
                  start: 32,
                  end: 45,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Costa Rica with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can not work in Costa Rica with a tourist visa. The tourist visa can only be used for tourism and leisure purposes.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Costa Rica with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indians can stay in Costa Rica for up to 30 days with a tourist visa.',
              spans: [
                {
                  start: 35,
                  end: 49,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long does a Costa Rica visa interview take?',
          answer: [
            {
              type: 'paragraph',
              text: 'An interview is not always required when applying for a Costa Rica visa. It depends on each application, and most of the time, an interview is not required. But, if you must attend an interview, it takes about 10 to 15 minutes to complete.',
              spans: [
                {
                  start: 210,
                  end: 226,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for multiple visa applicants at once?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, on tryvisa, you can apply for various visa applicants at once. When doing so, ensure that you have the required details of each applicant.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Costa Rica visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply on tryvisa, the Costa Rica visa fee is ₹5500.',
              spans: [
                {
                  start: 52,
                  end: 58,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa processing time to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay the processing of your visa. Here are some of the more common reasons:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'The complexity of the application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Costa Rica visa if my passport expires soon?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for a visa to Costa Rica, ensure that your passport is valid for at least six more months.',
              spans: [
                {
                  start: 70,
                  end: 104,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$a7b2cb73-64d4-43d7-963d-e2dc9c56334b',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Costa Rica',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  CU: {
    name: 'Cuba',
    code: 'CU',
    hero_image_url:
      'https://images.unsplash.com/photo-1588614380352-e8533179653b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Q3ViYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 7200,
    service_fees: -7200,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 301,
              height: 164,
            },
            alt: 'Cuba Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/f7305e7a-35b6-4db4-9767-2d8b25ff94d0_Cuba+Minsitry+of+Foreign+Affiars.png?auto=compress,format',
            id: 'ZYFZQxEAACcA5MjH',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 261,
            },
            alt: 'Cuba National Airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/09b5f127-65a2-4819-8af8-7ec1faf94208_cubana+Airline+logo..png?auto=compress,format',
            id: 'ZdSUAhEAACkAP92c',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1350,
            },
            alt: 'Coat of Arms of Cuba.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/de5bac37-6256-4eed-a2ae-dcb3463c2957_Coat_of_arms_of_Cuba.svg.png?auto=compress,format',
            id: 'ZYFiwBEAACYA5PV2',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$399f585c-2943-4267-aeb8-087d95877c7d',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way for Indians to get a Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Indians can easily apply for their Cuba visa on tryvisa. tryvisa, we will guide you in the Cuba visa application and submit your documents at the embassy on your behalf. This means you do not need to visit the embassy in person.',
              spans: [
                {
                  start: 45,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your Cuba visa at least 2-3 weeks before visiting Cuba is advised.',
              spans: [
                {
                  start: 37,
                  end: 53,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents do I need when applying for a Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your Cuba visa, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: You need a passport that is valid for at least six more months.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: One passport-sized photo is required.',
              spans: [
                {
                  start: 0,
                  end: 7,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application form: You must submit a duly completed application form.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
                {
                  start: 51,
                  end: 67,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/39a1a8f0-cb54-44b2-8ef7-c37ba4400d7f/Untitled.pdf?id=5458099a-cf3d-4cc1-bb34-c72714a47878&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1703116800000&signature=znYeBZCRvRQ8kS_YT09lNNKe6NT6gxgwjQThfXT15dk&downloadName=Untitled.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Accommodation: Show proof of stay by submitting hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Tickets: Provide your flight itinerary by submitting your flight bookings.',
              spans: [
                {
                  start: 0,
                  end: 81,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Notarised Affidavit: This is required to show that you only visit Cuba for tourism purposes.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statements: You must submit your bank statements to prove you have sufficient funds for your trip.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to submit my passport at the embassy when applying for a Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you must submit your passport at the embassy for the Cuba visa application. However, if you apply through tryvisa, we will submit your documents at the embassy on your behalf.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 81,
                  end: 88,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are some common reasons for the rejection of a Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can cause your visa to get rejected. These reasons include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Submitting incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not submitting all the required documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having sufficient funds for your trip.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'If your passport expires in less than six months.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get an e-visa for Cuba?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian citizens can not apply for a Cuba e-visa. You must submit your documents at the embassy to receive a sticker visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my visa processing to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can delay the processing of your visa, including:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Visa appointment availability.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long can I stay in Cuba with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Cuba tourist visa allows you to stay in the country for up to 30 days.',
              spans: [
                {
                  start: 66,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens when my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, you can reapply. When doing so, ensure that all documents are correct and accurate. Visa fees must be paid again since the government and online portals do not provide refunds. Fortunately, when applying through tryvisa, you are guaranteed a refund if your visa is denied, up to ₹8000 or $100. This refund excludes service fees.',
              spans: [
                {
                  start: 215,
                  end: 226,
                  type: 'strong',
                },
                {
                  start: 250,
                  end: 255,
                  type: 'strong',
                },
                {
                  start: 277,
                  end: 284,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I check my Cuba visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you have applied through tryvisa, you can see the status of your visa by following these steps:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Click on your profile.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Select the visa you have applied for.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Once selected, you will see your visa’s current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory for a Cuba visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory. However, having travel insurance when travelling to a foreign country is advised.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Cuba visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'The visa fee for Cuba on tryvisa is ₹9700.',
              spans: [
                {
                  start: 34,
                  end: 39,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my Cuba tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can extend your Cuba tourist visa for an additional 30 days. This must be done at the hotel desk where you are staying or at the immigration authorities.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 61,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I enter Cuba multiple times with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa for Cuba is a single entry visa only. This means you can only enter the country once. You must reapply for a new visa if you wish to re-enter Cuba.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$9985be8d-3e8b-4f79-b20a-b311f0f2e439',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Cuba',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  CZ: {
    name: 'Czechia',
    code: 'CZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1589312893179-38d2eb60655d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q3plY2hpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Czechia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  DE: {
    name: 'Germany',
    code: 'DE',
    hero_image_url:
      'https://images.unsplash.com/photo-1501952476817-d7ae22e8ee4e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8R2VybWFueXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Germany',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  DZ: {
    name: 'Algeria',
    code: 'DZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1549145177-238518f1ec1a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8QWxnZXJpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 50,
    eta_pre_travel_unit: 'days',
    entry_type: 'Visa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 8100,
    service_fees: -8100,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1870,
              height: 1867,
            },
            alt: 'Ministry of Foreign Affairs of Algeria logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2e04e331-6c3d-46e4-86ea-ba73c48bf448_MOFA+of+Algeria+logo.png?auto=compress,format',
            id: 'ZXlHohEAACIAKxcH',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 900,
              height: 640,
            },
            alt: 'Algeria airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/fc6bb7eb-ebca-48a9-af17-cbefe09faed7_airline-logo-algeria.jpg?auto=compress,format',
            id: 'ZXlIkxEAACAAKxtD',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$94bf59da-60ae-40f4-94d0-58b88adc8f31',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What is the easiest way for Indians to get an Algeria visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way of obtaining your Algeria visa is by applying through tryvisa. We will guide you through each application step and collect your documents for submission.',
              spans: [
                {
                  start: 70,
                  end: 75,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents are required for the Algeria visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your Algeria visa, you must submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport must be valid for at least six months.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application Form: You need 2x copies of the application form filled out electronically. Then, print out both forms and sign them.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
                {
                  start: 27,
                  end: 36,
                  type: 'strong',
                },
                {
                  start: 44,
                  end: 60,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/82ca5f92-7326-4435-b602-16e88f9503d9/Visa_application42.pdf?id=f524f5b5-b16c-4d7e-93d4-b04a33cbbd82&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1702598400000&signature=_4Ri6LrH2fkBGRHyCj584iXV4GAgDUzT7D726gETuZE&downloadName=Visa_application42.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photos: You need 2 recently taken passport-sized photos.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'strong',
                },
                {
                  start: 17,
                  end: 33,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Tickets: A copy of your flight tickets showing arrival and departure dates.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Accommodation: A copy of your hotel reservations or accommodation certifications duly certified by the local authorities of Algeria. Or an invitation letter with a travel itinerary from an Algerian travel agency.',
              spans: [
                {
                  start: 0,
                  end: 23,
                  type: 'strong',
                },
                {
                  start: 39,
                  end: 57,
                  type: 'strong',
                },
                {
                  start: 61,
                  end: 89,
                  type: 'strong',
                },
                {
                  start: 148,
                  end: 165,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof Of Funds: You must prove you have sufficient funds to cover your stay in Algeria. This can be documents such as bank statements.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
                {
                  start: 118,
                  end: 133,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it safe to apply for a visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your Algeria visa online is safe if you use tryvisa’s online portal or the government site. tryvisa’s website uses a 256-bit Advanced Encryption Standard to protect your data from security breaches. Plus, we have strong logistics and supply chain services to keep your documents safe for handling and submission.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa 3-4 weeks before travel is advised to allow sufficient processing time.',
              spans: [
                {
                  start: 22,
                  end: 33,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians apply for an Algeria electronic visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Algeria does not offer an electronic visa. All visa applications must be submitted at the embassy.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I track my Algeria visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when you have applied through tryvisa, you can check your visa status by following these steps:',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [
                {
                  start: 13,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Go to your profile.',
              spans: [
                {
                  start: 11,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Select the visa you applied for.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Once you click on the visa, you can see its current status.',
              spans: [
                {
                  start: 9,
                  end: 14,
                  type: 'em',
                },
                {
                  start: 36,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Algeria with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa for Algeria allows you to stay in the country for 30 days.',
              spans: [
                {
                  start: 67,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa once it is approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your visa gets approved, tryvisa will send it to you via courier.',
              spans: [
                {
                  start: 56,
                  end: 67,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my visa gets denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa application is rejected, review the reasons for the rejection and correct those reasons. Reapply for your visa once all the reasons are corrected. When applying through the government or online portals, you will not be refunded, which causes you to lose funds. However, on tryvisa, you are guaranteed a refund of up to ₹8000 or $100 on denied visas. Please note this refund excludes any service fees.',
              spans: [
                {
                  start: 274,
                  end: 281,
                  type: 'strong',
                },
                {
                  start: 286,
                  end: 291,
                  type: 'strong',
                },
                {
                  start: 313,
                  end: 320,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an Algeria visa on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, on tryvisa, you can apply for multiple visas at once. All you need to do is add the additional information of your family members and submit the applications.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is an Invitation letter required when applying for an Algeria visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not always necessary, you might need to submit an invitation letter when applying through an Algeria travel agency or visiting a friend.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the Algeria visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your visa on tryvisa, the fee is ₹10,200.',
              spans: [
                {
                  start: 50,
                  end: 58,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get an Algeria visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian nationals can not obtain a visa on arrival for Algeria. You must have a visa before you travel.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the processing time for the Algeria visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for your Algeria visa on tryvisa is 15 days. tryvisa also guarantees your money back if your visa takes longer than the provided processing time.',
              spans: [
                {
                  start: 54,
                  end: 61,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is there a multiple-entry visa option?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, if you apply in person through the embassy, you can apply for a multiple-entry visa.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What will cause my visa application to be denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various factors can cause your visa to be rejected. Some of the common reasons are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Insufficient funds for the duration of your trip.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'If your passport expires within six months.',
              spans: [
                {
                  start: 8,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Providing inaccurate documents.',
              spans: [
                {
                  start: 10,
                  end: 30,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Not submitting all the required documents.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'If you have a criminal record with a serious offence.',
              spans: [
                {
                  start: 14,
                  end: 29,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I use an Algeria tourist visa for business purposes?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa for Algeria is only for tourism purposes, such as leisure activities. If you want to travel to Algeria for business reasons, you must apply for an Algeria business visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 168,
                  end: 189,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$86c05acc-958a-43b5-954f-3fb614548887',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Algeria',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  EE: {
    name: 'Estonia',
    code: 'EE',
    hero_image_url:
      'https://images.unsplash.com/photo-1627727240079-2d3c29bf8a23?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8RXN0b25pYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Estonia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  ES: {
    name: 'Spain',
    code: 'ES',
    hero_image_url:
      'https://images.unsplash.com/photo-1543783207-ec64e4d95325?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8U3BhaW58ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Spain',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  FI: {
    name: 'Finland',
    code: 'FI',
    hero_image_url:
      'https://images.unsplash.com/photo-1538332576228-eb5b4c4de6f5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8RmlubGFuZHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Finland',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  FR: {
    name: 'France',
    code: 'FR',
    hero_image_url:
      'https://images.unsplash.com/photo-1503917988258-f87a78e3c995?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8RnJhbmNlfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'France',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  GB: {
    name: 'United Kingdom',
    code: 'GB',
    hero_image_url:
      'https://images.unsplash.com/photo-1486299267070-83823f5448dd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8VW5pdGVkJTIwS2luZ2RvbXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'tryvisa Black - UK Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist ',
    entry_length_stay: '180 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa ',
        description:
          'With tryvisa Black you will get a personalized conceirge to help you with the UK visa application process.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1280,
              height: 290,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/a4606c79-86c9-4f13-96ae-7dac73ea39c7_british-airways-logo.png?auto=compress,format',
            id: 'ZW8pgBEAACQAqJUr',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2400,
              height: 2400,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/e98fc943-c50f-4815-88d2-b53ed131c927_engladn.png?auto=compress,format',
            id: 'ZW8o1xEAACUAqJHn',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$d286c753-71e9-487d-896d-6556a6249cf5',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'United Kingdom',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  GE: {
    name: 'Georgia',
    code: 'GE',
    hero_image_url:
      'https://images.unsplash.com/photo-1561731172-9d906d7b13bf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8R2VvcmdpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Georgia E-Visa',
    supported: false,
    message: "Currently not supported\nWe're working on adding Georgia.",
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '120 days',
    govt_fees: 6592,
    service_fees: -6592,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Tourist Visa',
        description:
          'A visa is required to apply to Georgia. The visa is stamped on your passport. With tryvisa, you may skip the in-person interview.',
      },
    ],
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Flight Tickets',
        description: 'Please upload Flight TIckets to and from',
        label: 'Please upload Flight TIckets to and from',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Flight',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Hotel Vouchers',
        description: 'Please upload Hotel/Stay Voucher of Gerogia',
        label: 'Please upload Hotel/Stay Voucher of Gerogia',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Hotel',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '',
        description: 'Please Upload Tavel Insurance',
        label: 'Please Upload Tavel Insurance',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Insurance',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Bank E - Statement',
        description: 'Please upload 6 months Bank E statement',
        label: 'Please upload 6 months Bank E statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Bank_Statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'All Pages of the passport Scanned',
        description: 'Please upload All Pages of the passport Scanned',
        label: 'Please upload All Pages of the passport Scanned',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Passport_Pages_Scanned',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '2 Years ITR',
        description: 'Please upload 2 Years ITR',
        label: 'Please upload 2 Years ITR',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'ITR',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Marriage Certificate (Optional)',
        description: 'Please upload Marriage Certificate ',
        label: 'Please upload Marriage Certificate ',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'Marriage_Certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Company Leave Letter',
        description: 'Please upload Company Leave Letter',
        label: 'Please upload Company Leave Letter',
        required: true,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1sgCLeU1nu-mjILZSiqBUZy2xyvsugf0v/edit?usp=drive_link&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'Leave_Letter',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '3 Months Salary Slip /GST Certificate',
        description: 'Please upload 3 Months Salary Slip /GST Certificate\n',
        label: 'Please upload 3 Months Salary Slip /GST Certificate\n',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'Salary_slip',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: true,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 408,
              height: 353,
            },
            alt: 'Georgia Coat of Arms',
            copyright: null,
            url: 'https://images.prismic.io/atlys/3508f6b3-2dff-47aa-a023-46e32a8dcd2f_Georgia+coat+of+arms..png?auto=compress,format',
            id: 'ZdScMhEAACcAQAMc',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 500,
            },
            alt: 'Georgia Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/f64fe871-edfb-48ff-9a29-1ecf033671ea_image+%2810%29.png?auto=compress,format',
            id: 'ZMoKoRAAACAAT4l9',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 310,
              height: 161,
            },
            alt: 'Georgian Airways',
            copyright: null,
            url: 'https://images.prismic.io/atlys/0ee6da7b-660f-415f-814d-491d6605d60e_Georgian+Airways+logo.+.png?auto=compress,format',
            id: 'ZdSfbhEAACgAQBHr',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$59c7b670-47d4-4d19-8d4d-b540b5e88a8d',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for the Georgia visa?',
          answer: [
            {
              type: 'paragraph',
              text: "You'll need the following traveling documents:",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport photo photo',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Bank statements that show you can cover the stay of your trip.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scanned copy of your passport ID page.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What should I keep in mind while filling the Georgia online visa application form?',
          answer: [
            {
              type: 'paragraph',
              text: 'When filling out the Georgia online visa application form, ensure that all the information is accurate and matches the details in your passport. Upload all necessary documents and double-check everything before submitting',
              spans: [],
            },
          ],
        },
        {
          question: 'What can I do if I made a mistake on my Georgia online visa application form?',
          answer: [
            {
              type: 'paragraph',
              text: "If your application has not yet been processed, you may be able to make corrections. It's advisable to contact the tryvisa support team immediately to avoid any potential issues or delays.",
              spans: [],
            },
          ],
        },
        {
          question: 'What are the photo requirements for the Georgia online visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "Photos for the Georgia online visa application should be passport-sized, with a white background, and taken within the last six months. Make sure your full face is visible, and you are not wearing any head covering unless it's for religious purposes.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I know if my Georgia online visa application has been approved?',
          answer: [
            {
              type: 'paragraph',
              text: "Once your Georgia online visa application is processed, you'll receive an email notifying you of the decision. Make sure to check your spam folder in case the email gets redirected there. Or simply check your online portal.",
              spans: [],
            },
          ],
        },
        {
          question: 'How far in advance should I apply for a Georgia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for a Georgia visa online at least two weeks before your planned travel date. This allows for any potential delays in visa processing.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How to check the status of my Georgia online visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can check the status of your Georgia online visa application on the tryvisa online portal by going to your profile.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my visa is denied?',
          answer: [
            {
              type: 'paragraph',
              text: 'When your visa is rejected, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees. Luckily, tryvisa will refund you a total of ₹8000 or $100 when your visa is rejected. This refund excludes any service fees.',
              spans: [
                {
                  start: 205,
                  end: 212,
                  type: 'strong',
                },
                {
                  start: 225,
                  end: 231,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is it safe to apply for the Georgia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, it's safe to apply for the Georgia visa online. tryvisa will never share your information with any third parties.",
              spans: [],
            },
          ],
        },
        {
          question: 'What is the cost of applying for a Georgia visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Georgia visa cost is INR 9113 when applying through tryvisa',
              spans: [],
            },
          ],
        },
        {
          question: 'Is there an option to apply for a Georgia visa online for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian nationals can apply for a Georgia visa online. You can apply through the tryvisa online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to other countries with a Georgia E visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Georgia E visa typically only allows travel within Georgia. If you wish to travel to other countries, you must apply for separate visas.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Georgia online visa if my passport is about to expire?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended that your passport is valid for at least six months beyond your planned stay in Georgia. If your passport is about to expire, you must renew it before applying for the visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process the Georgia online visa?',
          answer: [
            {
              type: 'paragraph',
              text: "The processing time for the Georgia online visa is 7 business days. tryvisa guarantees your money back if you don't receive your visa after 7 business days.",
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a family Georgia online visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, you can apply for your family's visas through the tryvisa online portal.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I change my travel plans after receiving my Georgia E visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Georgia E visa has been issued, any changes to your travel plans may require you to apply for a new visa. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get a refund if my Georgia online visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Visa application fees are non-refundable on government and most visa portals, even if the application is rejected. However, if you apply through tryvisa, you will receive a full refund.',
              spans: [
                {
                  start: 115,
                  end: 122,
                  type: 'strong',
                },
                {
                  start: 145,
                  end: 150,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the process for the Georgia online visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for the Georgia online visa by filling out the Georgia online visa application form on the tryvisa. Ensure you provide accurate details and submit all the necessary documents for a successful application.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$8e869d2b-3ad8-44f2-88f2-82cc8eb5d23e',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Georgia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  GH: {
    name: 'Ghana',
    code: 'GH',
    hero_image_url:
      'https://images.unsplash.com/photo-1568025848823-86404cd04ad1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fEdoYW5hfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Visa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 10300,
    service_fees: -10300,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1000,
            },
            alt: "Logo of Ghana's official web portal.",
            copyright: null,
            url: 'https://images.prismic.io/atlys/ad1a57de-aca1-42b0-bd15-f17b121b5145_Ghana+official+web+portal.png?auto=compress,format',
            id: 'ZXl5VhEAACMAK_zo',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 239,
              height: 239,
            },
            alt: 'Ghana Immigration logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/bb04e642-c8fd-4ebc-aed4-3a0bee1c2cae_Ghana_Immigration_Service_logo+%281%29.png?auto=compress,format',
            id: 'ZdRYChEAACcAPpm-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 396,
              height: 147,
            },
            alt: 'Air Ghana logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/284815e7-bb8a-4b73-ac6a-53fc8aff4b31_AirGhanaLogo.png?auto=compress,format',
            id: 'ZdRW4BEAACsAPpRq',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 328,
              height: 300,
            },
            alt: 'Ghana Tourism Authority logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2880b6a4-b150-4280-93d3-9473f201ca6b_Ghana+tourism+authority.png?auto=compress,format',
            id: 'ZdRa3xEAACgAPqZi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$8771dab6-5a04-4b0f-9ebc-eb7d4416feb6',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required for a Ghana tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for a Ghana visa, you need to submit the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Covid Vaccinations: Proof of Covid-19 full (double) vaccination certificate.',
              spans: [
                {
                  start: 0,
                  end: 20,
                  type: 'strong',
                },
                {
                  start: 43,
                  end: 76,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: You need 1x passport-sized photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
                {
                  start: 16,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport: You need a valid passport.',
              spans: [
                {
                  start: 0,
                  end: 10,
                  type: 'strong',
                },
                {
                  start: 21,
                  end: 35,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Hotel reservation: Prove your accommodation by submitting hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statement: You must have the most recent bank statement.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight tickets: You must submit proof of your arrival and return flight tickets.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my Ghana visa on the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can submit an application over the weekend. tryvisa is available 24/7. However, the collection of your documents will happen during weekdays.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 62,
                  end: 76,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I apply for my Ghana visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Ghana visa on tryvisa. Here is how the process works:',
              spans: [
                {
                  start: 37,
                  end: 42,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Visit tryvisa: Go to the tryvisa online portal.',
              spans: [
                {
                  start: 0,
                  end: 13,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Provide Your Travel Details: Submit your travel details by choosing your destination and dates.',
              spans: [
                {
                  start: 0,
                  end: 28,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Complete The Application: Complete the online application by providing all your details.',
              spans: [
                {
                  start: 0,
                  end: 25,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Pay The Visa Fee: Pay the Ghana visa fees and submit your application.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Wait For tryvisa: tryvisa will collect your documents via courier and submit them to the embassy.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Receive Your Visa: Once your visa gets approved, a courier will drop off your passport and visa.',
              spans: [
                {
                  start: 0,
                  end: 19,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my Ghana visa once approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your visa gets approved, it will be sent to you via courier with your passport.',
              spans: [
                {
                  start: 53,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To allow sufficient processing time, applying for your visa 3-4 weeks before you travel is advised.',
              spans: [
                {
                  start: 60,
                  end: 69,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians apply for a Ghana e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Ghana does not offer the option of an e-visa. However, Indians can apply for their Ghana visa online through tryvisa and receive a sticker visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 113,
                  end: 118,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if my visa application gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long is my Ghana visa valid for?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Ghana visa is valid for 30 days when you apply through tryvisa.',
              spans: [
                {
                  start: 28,
                  end: 35,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What can cause my Ghana visa processing time to be delayed?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can delay your visa processing, such as:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Peak travel seasons.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incorrect documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Inaccurate information.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Public holidays.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I check my Ghana visa status online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can check your visa status through the tryvisa app when you apply on tryvisa. Or you can check your visa status online through the government portal of Ghana.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
                {
                  start: 48,
                  end: 57,
                  type: 'strong',
                },
                {
                  start: 136,
                  end: 153,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Ghana visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'The fee for the Ghana visa on tryvisa is ₹12,800.',
              spans: [
                {
                  start: 39,
                  end: 47,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What methods can I use to pay my Ghana visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa, you can pay the visa fees using your credit/debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 47,
                  end: 64,
                  type: 'strong',
                },
                {
                  start: 80,
                  end: 93,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I extend my Ghana Visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can extend your visa to Ghana. To extend your visa, submit the required documents and complete the required application form, which is obtained at the Immigration Headquarters or the nearest Regional Commands Service.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Ghana with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, employment is prohibited in Ghana when you have a tourist visa. To be employed, you need to have an employment visa.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
                {
                  start: 54,
                  end: 66,
                  type: 'hyperlink',
                  data: {
                    id: 'ZKWebBAAACEAr_u8',
                    type: 'article',
                    tags: ['GH', 'Tourism', 'Indians', 'Tourist visa'],
                    lang: 'en-in',
                    slug: 'do-indians-need-a-visa-for-ghana',
                    first_publication_date: '2023-07-05T17:33:35+0000',
                    last_publication_date: '2024-02-16T12:06:55+0000',
                    uid: 'ghana-visa-for-indians',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Ghana with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once you enter Ghana, you can stay there for 30 days on a tourist visa.',
              spans: [
                {
                  start: 45,
                  end: 52,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance mandatory for a Ghana tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for a Ghana tourist visa. However, it is advised to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$46a2418a-b798-4b82-b1bd-7ffb8b39cae1',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Ghana',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  GR: {
    name: 'Greece',
    code: 'GR',
    hero_image_url:
      'https://images.unsplash.com/photo-1533104816931-20fa691ff6ca?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8R3JlZWNlfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Greece',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  HU: {
    name: 'Hungary',
    code: 'HU',
    hero_image_url:
      'https://images.unsplash.com/photo-1616432902940-b7a1acbc60b3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8SHVuZ2FyeXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Hungary',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  IL: {
    name: 'Israel',
    code: 'IL',
    hero_image_url:
      'https://images.unsplash.com/photo-1547483029-77784da27709?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8SXNyYWVsfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Sticker Visa',
    supported: false,
    message:
      "Currently not supported\nWe do not yet support Israel visa on tryvisa. We know this is not ideal and we're working hard to bring it on tryvisa.",
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 10500,
    service_fees: -10500,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Sticker Visa',
        description:
          'The visa is a sticker visa, stamped into your passport. With tryvisa, you can avoid an in-person visit as tryvisa will submit the application on your behalf.',
      },
    ],
    additional_questions: [],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: false,
        currency: 'INR',
        attributes: ['employment_letter_required'],
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['use_surfer'],
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1489,
            },
            alt: 'Isreal ministry of justice logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/23ff67ac-dcbe-4e23-9cc9-e44e454ce639_Emblem_of_Israel.svg.png?auto=compress,format',
            id: 'ZNoEiREAAB4Au-dG',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 392,
              height: 392,
            },
            alt: 'Israel Ministry of foreign affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/67a198b0-cf37-4ade-935e-d66ba5904a95_Israel+ministry+of+foreign+affairs..png?auto=compress,format',
            id: 'ZdWhkBEAACgARJEx',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 349,
              height: 144,
            },
            alt: 'Israel tourism board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/755fc531-0347-4f70-b840-a8da702e397c_Israel+ministry+of+toursim..png?auto=compress,format',
            id: 'ZdWgtREAACsARI1r',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 203,
              height: 167,
            },
            alt: 'Israel airline logo ',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e1ad9ef3-2309-4e7c-a0bf-202c44c1033e_Israel+airline..png?auto=compress,format',
            id: 'ZdWi6BEAACgARJco',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$124e3510-0d5d-4a29-9131-47ba5173022a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the requirements for an Israel tourist visa as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Israel tourist visa documents that are required when applying for tryvisa are as follows:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Two passport-sized photos.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Approval letter from the place you work.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Completed application form',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Cover letter.',
              spans: [
                {
                  start: 0,
                  end: 12,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/post/invitation-letter-for-visa',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Copy of your ID.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Salary slips of other last 4 months.',
              spans: [],
            },
            {
              type: 'list-item',
              text: '6-month bank statement',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scanned copies of all your passport pages with a visa stamp or sticker.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Invitation letter if you are invited to visit a friend, for work, or to study in Israel.\n',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the application process for an Israel visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The process is quite simple. First, go online to tryvisa; from there, gather the required documents, make the application, pay the visa fee, and then wait for your visa to be processed.',
              spans: [
                {
                  start: 49,
                  end: 54,
                  type: 'strong',
                },
                {
                  start: 68,
                  end: 97,
                  type: 'strong',
                },
                {
                  start: 99,
                  end: 119,
                  type: 'strong',
                },
                {
                  start: 121,
                  end: 137,
                  type: 'strong',
                },
                {
                  start: 148,
                  end: 182,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Israel tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply well in advance since there are a lot of factors that can delay the processing of your visa. So applying 1-2 months before your travels is advised.',
              spans: [
                {
                  start: 132,
                  end: 162,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will an interview be required for an Israel tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you don’t need to go to an interview to get an Israel visa. If there are additional requirements or clarification needed, then you might be asked for an Interview.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What happens if I apply for an Israel visa and have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have a criminal record, you might be asked to provide additional documents for your visa to be processed. Additionally, the visa approval process also depends on the severity of your crime. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide a letter of invitation as an Indian tourist?',
          answer: [
            {
              type: 'paragraph',
              text: 'yes, if you are travelling to Israel for studying, working, or visiting a friend, you will require an invitation letter from the invitee.',
              spans: [
                {
                  start: 102,
                  end: 119,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/post/invitation-letter-for-visa',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What should I do if my Israel visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are correct and the information provided is accurate. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Instead, you can apply through tryvisa, where you are guaranteed a total of ₹8000 ($100) refund if your visa is denied. This refund will exclude any service charges.',
              spans: [
                {
                  start: 121,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 158,
                  type: 'strong',
                },
                {
                  start: 209,
                  end: 216,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What type of Israel visa can I get as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of different types of visas for Indian citizens, such as tourist visas, transit visas, student visas, and work visas.',
              spans: [
                {
                  start: 105,
                  end: 118,
                  type: 'hyperlink',
                  data: {
                    id: 'ZOhmWBIAACMAikGr',
                    type: 'article',
                    tags: ['IL', 'First-Time Travelers', 'Visa Essentials'],
                    lang: 'en-in',
                    slug: 'what-is-the-student-visa-for-israel',
                    first_publication_date: '2023-08-25T08:29:16+0000',
                    last_publication_date: '2023-10-02T13:00:34+0000',
                    uid: 'student-visa-for-israel',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  start: 124,
                  end: 134,
                  type: 'hyperlink',
                  data: {
                    id: 'ZONdhxIAACYA0U3m',
                    type: 'article',
                    tags: ['IL', 'First-Time Travelers', 'Visa Essentials'],
                    lang: 'en-in',
                    slug: 'what-is-an-israel-work-visa-for-indians',
                    first_publication_date: '2023-08-21T12:50:32+0000',
                    last_publication_date: '2023-10-17T16:06:21+0000',
                    uid: 'israel-work-visa-for-indian',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need travel insurance when applying for an Israel visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, medical insurance is required when you apply for an Israel tourist visa.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What could cause my Israel visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of factors that could cause your visa to be denied, such as wrong documents, invalid information, and insufficient funds.',
              spans: [
                {
                  start: 78,
                  end: 93,
                  type: 'strong',
                },
                {
                  start: 95,
                  end: 114,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 138,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the fastest way to get an Israel visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'On Altys, you can apply for your visa in under 10 minutes. The quicker you apply, the quicker the processing can start.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I obtain an Israel visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to obtain an Israel tourist visa is with tryvisa. When applying through tryvisa, we will collect your passport along with the required documents and submit it on your behalf at the nearest embassy. Once your visa gets approved, we will send you your passport and valid visa.',
              spans: [
                {
                  start: 57,
                  end: 63,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What would the time be for an Israel visa process from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an Israel visa generally takes about 3 weeks but can take longer if there are additional documents required.',
              spans: [
                {
                  start: 61,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What does a tourist visa to Israel cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa the Israel visitor visa fee is ₹ 12,900. ',
              spans: [
                {
                  start: 40,
                  end: 50,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question:
            'What happens if I provide the wrong documents when I apply for my Israel visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you provide the wrong detail on a document or the document itself is wrong, you must contact tryvisa’s support team immediately and provide the correct details. Incorrect documents may cause a delay in the processing time or visa rejections. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I extend my Israel tourist visa as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'You cannot extend your tourist visa; however, you are allowed to apply for a new one while you are in Israel.',
              spans: [
                {
                  start: 4,
                  end: 10,
                  type: 'strong',
                },
                {
                  start: 37,
                  end: 44,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$a127f832-8260-4a53-a2c2-acf5595658ab',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Israel',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  KG: {
    name: 'Kyrgyzstan',
    code: 'KG',
    hero_image_url:
      'https://images.unsplash.com/photo-1606296672103-4783b174ca71?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8a3lyZ3l6c3RhbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Kyrgyzstan E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 14600,
    service_fees: -14600,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'covid_vaccine',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'flight_hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'occupation',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'photo',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_nif',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        currency: 'INR',
        attributes: ['use_surfer'],
        toggle: true,
        key: 'travel_itinerary',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_residence',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 150,
              height: 150,
            },
            alt: 'Kyrgystan Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ed1b2ee7-07a2-4b48-9bf9-e14e4dea809a_Kyrgyzstan+Foreign+Affairs.png?auto=compress,format',
            id: 'ZNSmAREAAB4ApJBV',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2265,
              height: 2049,
            },
            alt: 'Kyrgyzstan Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ad5ac696-047f-4aeb-9e37-7bf929379ab6_Ministry_of_Foreign_Affairs_of_Kyrgyz_Republic_.png?auto=compress,format',
            id: 'ZdRybxEAACsAP0Fa',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 439,
              height: 198,
            },
            alt: 'Air Kyrgyzstan logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/f6b442bb-9255-4b06-8fd3-0c5952a0db76_Air+Kyrgyzstan+logo.png?auto=compress,format',
            id: 'ZdRzvhEAACwAP0d-',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1541,
              height: 1148,
            },
            alt: 'Visit Kyrgyzstan logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/af147300-58e5-4972-9500-5375d0c7f64a_visit+Kyrgyzstan.png?auto=compress,format',
            id: 'ZNSmAhEAACAApJBY',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$b15642d0-1505-4d28-8f82-9e21f5862e83',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'Do Indians need a visa to visit Kyrgyzstan?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Indian citizens must obtain a visa before visiting Kyrgyzstan.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians get a visa on arrival for Kyrgyzstan?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Kyrgyzstan does not offer any visa on arrival for Indians. You must apply for a visa before visiting the country.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the required documents for a Kyrgyzstan e visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The required documents you need are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A scanned copy of your passport ID page.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A scanned copy of your passport's back page.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A recent passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process a Kyrgyzstan e-visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for a Kyrgyzstan e-visa application can vary, but it typically takes around 5 - 7 days. tryvisa guarantees your money back if your visa takes longer than the estimated processing time.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the validity period of a Kyrgyzstan tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Kyrgyzstan visa is valid for up to 60 days for tourism or private purposes without labour or entrepreneurial activity.',
              spans: [],
            },
          ],
        },
        {
          question:
            'Are there any specific health requirements or vaccinations needed for travel to Kyrgyzstan?',
          answer: [
            {
              type: 'paragraph',
              text: 'While there are no mandatory vaccinations for entering Kyrgyzstan, it is advisable to check with a healthcare professional or travel health clinic for any recommended vaccinations based on your health status and the areas you plan to visit. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Kyrgyzstan e-visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your Kyrgyzstan e visa is rejected, you can reapply for your visa once you have reviewed and fixed the reasons for the rejection. The fees are non-refundable when applying through the government or most online portals. Luckily, at tryvisa, you will receive a refund of up to ₹8000 or $100 if your visa is denied. (The refund will exclude the service fees).',
              spans: [
                {
                  start: 222,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 234,
                  end: 239,
                  type: 'strong',
                },
                {
                  start: 260,
                  end: 266,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Kyrgyzstan with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No. Your Kyrgyzstan tourist visa does not allow you to perform any work activity in the country. The Kyrgyzstan visa allows you to visit for tourism or other private purposes, which does not include any labour or entrepreneurial activity.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a  Kyrgyzstan e-visa if I have dual citizenship?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Kyrgyzstan e-visa if you have dual citizenship. However, you must apply with the passport you intend to travel with. Ensure that you provide accurate information and use the appropriate passport details in your visa application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Kyrgyzstan visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your Kyrgyzstan e visa through the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to Kyrgyzstan with a copy of the e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes. It is advisable to carry a printed copy of your e-visa when travelling to Kyrgyzstan. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Kyrgyzstan e-visa is lost or damaged?',
          answer: [
            {
              type: 'paragraph',
              text: 'If the copy of your Kyrgyzstan e-visa is lost or damaged, you can download a new copy from the tryvisa app or online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I pay the visa fees for the Kyrgyzstan e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can pay for the Kyrgyzstan visa online using a credit/debit card. If you apply for your visa to Kyrgyzstan on tryvisa, you can use a credit/debit card or an approved mobile wallet to pay the visa fees.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I travel to multiple cities within Kyrgyzstan with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, with a tourist visa to Kyrgyzstan, you can travel to multiple cities within the country. The tourist visa allows you to explore various destinations and enjoy the cultural, historical, and natural attractions Jordan offers.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: "We're here to answer all your questions",
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$4785ba9d-cd79-40a5-98a5-5c5b4621aaa2',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Kyrgyzstan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  LB: {
    name: 'Lebanon',
    code: 'LB',
    hero_image_url:
      'https://images.unsplash.com/photo-1496823407868-80f47c7453b5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TGViYW5vbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '60 days',
    govt_fees: 8370,
    service_fees: -8370,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 381,
              height: 381,
            },
            alt: 'Logo of Lebanon General Directorate of General Security.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/31dfefc0-6d06-4a8a-9d44-9c1b240b382d_Lebanon+security+website+logo.png?auto=compress,format',
            id: 'ZYPvdhEAACcA8Lex',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 256,
              height: 330,
            },
            alt: 'Lebanon MOFA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/51364003-cb78-4dfe-825d-7a8e90e587f5_Libanon+MOFA.png?auto=compress,format',
            id: 'ZdR3BxEAACwAP1Zx',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 300,
              height: 150,
            },
            alt: 'Middle East Airline Logo for Lebanon.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/18897d64-68ff-4579-afbe-7bee170afee6_Middle-East-Airlines-Logo.gif?auto=compress,format',
            id: 'ZYPzNhEAACcA8Mja',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: '#fff',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 660,
              height: 330,
            },
            alt: 'Lebanon Ministry of Information logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/08792520-4bc4-4ec2-b805-3fcd7247a517_Lebanon+Ministry+of+Information.png?auto=compress,format',
            id: 'ZdSEDhEAAC4AP5PV',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$da913bca-ed70-4348-bb79-47136c353705',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'How do I apply for a Lebanon visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to get a Lebanon visa from India is by applying through tryvisa. Here is how the application process works when applying through tryvisa:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Visit tryvisa: Go to the tryvisa website.',
              spans: [
                {
                  start: 0,
                  end: 12,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Travel Details: Provide your travel details, such as date and destination.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Submit your Documents: Upload your documents onto tryvisa. This includes your passport and photo.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Pay The Visa Fee: Once you have completed the online application, pay the visa fee and submit your application.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'tryvisa Collects Your Documents: tryvisa will collect your documents and submit your application at the correct embassy.',
              spans: [
                {
                  start: 0,
                  end: 30,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'o-list-item',
              text: 'Receive Your Visa: Once your visa gets approved, tryvisa will collect it and send it to you.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to attend an appointment when applying for a Lebanon visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no appointment required when applying for your Lebanon visa through tryvisa. We will submit your documents on your behalf at the embassy.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What documents are required when applying for a Lebanon tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Submitting all the required documents when applying for your visa is important. Here are the documents required when applying for a Lebanon visa:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Application Form: A duly completed application form and the tourist visa checklist must be submitted.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
                {
                  start: 35,
                  end: 51,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/16dfe73e-826d-4eba-904e-820b6675984e/lebanon-visa-application-form-nov-2021.pdf?id=26f80071-2117-4d90-abb4-472a7303356e&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1703289600000&signature=rc-QIgXIlsrXMNxGRI23RVP_Ry_TjQn1xPryP191go8&downloadName=lebanon-visa-application-form-nov-2021.pdf',
                  },
                },
                {
                  start: 60,
                  end: 82,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://file.notion.so/f/f/76525ab9-aa7e-4076-b18e-e66441fd5060/b513fbe9-eaba-43dd-ae55-810fa972dee1/checklist-for-tourist-visa-latest.pdf?id=2056c6bd-c7b8-4d83-b74f-c37fb52a426e&table=block&spaceId=76525ab9-aa7e-4076-b18e-e66441fd5060&expirationTimestamp=1703289600000&signature=bDazMsCJHPiWwXnHb7VES1hK8DGw_7fYLbXHw8vAGMU&downloadName=checklist-for-tourist-visa-latest.pdf',
                  },
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Letter of Intent: This is like a cover letter stating your employment status, address, and the reason for visiting Lebanon.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Authorisation Letter: If you apply through a visa agent, you must submit an authorisation letter stating that the agent is responsible for submitting your application.',
              spans: [
                {
                  start: 0,
                  end: 21,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Passport Copy: You need to submit a copy of your passport. Your passport must be valid for at least six more months from the day of the application.',
              spans: [
                {
                  start: 0,
                  end: 14,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photos: You need 2x passport-sized photos with a white background taken in the last six months.',
              spans: [
                {
                  start: 0,
                  end: 8,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Ticket: Submit your arrival and departure flight ticket for Lebanon.',
              spans: [
                {
                  start: 0,
                  end: 15,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Accommodation Booking: Show proof of where you will stay by providing a hotel reservation.',
              spans: [
                {
                  start: 0,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Bank Statements: To prove you have enough funds for your trip, you must submit your last 3 month’s bank statements.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for my visa over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, when you apply through tryvisa, you can complete the online application over the weekend. However, submitting your application at the embassy will only happen during weekdays.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How do I check my Lebanon visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can easily check your visa status through the tryvisa app:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Visit your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Choose the visa you applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on the visa to see its current status.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'If you have applied through a visa application centre. You can check your status on their website.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How early should I apply for my Lebanon visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa at least 4-5 weeks before travelling to the country is advised. This will allow sufficient time for the processing of your visa.',
              spans: [
                {
                  start: 32,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required when applying for a visa to Lebanon?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory for the Lebanon visa application. However, it is always advised to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the  Lebanon visa fees?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your visa on tryvisa, the Lebanon visa fees are ₹10870.',
              spans: [
                {
                  start: 63,
                  end: 70,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How will I receive my visa once it is approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'Your visa will be delivered to you via courier when applying with tryvisa. If you have applied through the visa application centre, you must either collect it from them or opt for their postal service during the application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can Indians get a visa on arrival in Lebanon?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indian citizens are not eligible for a Lebanon visa on arrival. Indians must have a valid visa before travelling to the country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How does a criminal record impact my visa processing?',
          answer: [
            {
              type: 'paragraph',
              text: 'Having a criminal record will delay the processing of your visa. This is because the authorities will then do a background check to see if there is any risk to national security. Once the authorities are happy, they will continue processing your visa.',
              spans: [
                {
                  start: 30,
                  end: 50,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay In Lebanon with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa for Lebanon allows you to stay in the country for 30 days.',
              spans: [
                {
                  start: 67,
                  end: 74,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the reasons for my visa being rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'Various reasons can cause your visa to be denied. Some of the more common reasons include:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Submitting false documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having sufficient funds for your stay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a passport with a lack of validity.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Having a criminal record with a serious offence.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I get a multiple-entry visa for Lebanon?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, Lebanon allows applying for a single, dual, or multiple-entry visa. Note that on tryvisa, you will receive a single entry visa. If you want a multi-entry visa, you must personally apply at the application centre.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Lebanon with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, the tourist visa for Lebanon can only be used for leisure or sightseeing. You must have an employment visa if you want to work in the country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for multiple visas at once?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for multiple visas at once on tryvisa. The easy online process lets you complete various applications quickly and easily. When doing so, ensure you have all the required details for each applicant.',
              spans: [
                {
                  start: 0,
                  end: 3,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$81e84864-8a7a-4670-ba19-1a0a3602d0bf',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Lebanon',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  LI: {
    name: 'Liechtenstein',
    code: 'LI',
    hero_image_url:
      'https://images.unsplash.com/photo-1512424113276-fa9f6a112384?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TGllY2h0ZW5zdGVpbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Liechtenstein',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  LT: {
    name: 'Lithuania',
    code: 'LT',
    hero_image_url:
      'https://images.unsplash.com/photo-1567023249914-7c83f04de30c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TGl0aHVhbmlhfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Lithuania',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  LV: {
    name: 'Latvia',
    code: 'LV',
    hero_image_url:
      'https://images.unsplash.com/photo-1567669721460-221b82865ee0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TGF0dmlhfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Latvia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  MT: {
    name: 'Malta',
    code: 'MT',
    hero_image_url:
      'https://images.unsplash.com/photo-1571856975545-119703c5225c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8TWFsdGF8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Malta',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  NL: {
    name: 'Netherlands',
    code: 'NL',
    hero_image_url:
      'https://images.unsplash.com/photo-1512470876302-972faa2aa9a4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8TmV0aGVybGFuZHN8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: 16,
    eta_post_checkout_unit: 'days',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 10250,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Netherlands',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: 10250,
    cost_max: 12000,
    cost_min: 9500,
  },
  NO: {
    name: 'Norway',
    code: 'NO',
    hero_image_url:
      'https://images.unsplash.com/photo-1527004013197-933c4bb611b3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Tm9yd2F5fGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Norway',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  NZ: {
    name: 'New Zealand',
    code: 'NZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1558256720-a8ceb13f335d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fE5ldyUyMFplYWxhbmR8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'New Zealand E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Digital',
    entry_length_stay: '180 days',
    entry_validity: '1 years',
    govt_fees: 13100,
    service_fees: -13100,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'National ID (Aadhar or PAN Card)',
        description: '',
        label: 'National ID (Aadhar or PAN Card)',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'national_id',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 2 Years ITR',
        description: '',
        label: 'Last 2 Years ITR',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'last_two_years_itr',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 6 Months Bank Statement',
        description: 'Please provide e-statement only',
        label: 'Last 6 Months Bank Statement',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'last_six_months_bank_statement',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Last 3 Months Salary Slip',
        description: "Please upload last 3 months salary slip if you're employed",
        label: 'Last 3 Months Salary Slip',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'last_three_months_salary_slip',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Leave Letter or Letter of Employment from the Company',
        description:
          "Please upload recent leave letter or letter of employment from the company if you're employed",
        label: 'Leave Letter from the Company or Letter of Employment',
        required: false,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1sgCLeU1nu-mjILZSiqBUZy2xyvsugf0v/edit?usp=sharing&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'leave_employment_letter',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'GST Certificate - Company Bank Statement 6 Months',
        description:
          "Please upload GST Certificate - Company Bank Statement for last 6 Months if you're self employed",
        label: 'GST Certificate - Company Bank Statement 6 Months',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'gst_bank_statement_six_monts',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'COVID Vaccination Certificate',
        description: '',
        label: 'COVID Vaccination Certificate',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'covid_vaccination_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Marriage Certificate (Optional)',
        description: '',
        label: 'Marriage Certificate (Optional)',
        required: false,
        only_b2b: true,
        source_url: '',
        key: 'marriage_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'All Passport Old & New Used Pages Copies/Scanned Copies',
        description: '',
        label: 'All Passport Old & New Used Pages Copies/Scanned Copies',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'old_new_passport_pages',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Covering Letter',
        description: '',
        label: 'Covering Letter (Address to New Zealand High Commission, New Delhi)',
        required: true,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1Y7xPm6-EmQzt-QGNkZdP7kF1BRvYMorx/edit?usp=sharing&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'covering_letter',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Net Worth Certificate (Optional)',
        description: '',
        label: 'Net Worth Certificate (Optional)',
        required: false,
        only_b2b: true,
        source_url:
          'https://docs.google.com/document/d/1rmQYLUztEjeTco-RXm2qJ_6cfZVXOGCz/edit?usp=sharing&ouid=105245786584749491771&rtpof=true&sd=true',
        key: 'net_worth_certificate',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Other Saving & Investment (PPF, Fix Deposit, Shares)',
        description: '',
        label: 'Other Saving & Investment (PPF, Fix Deposit, Shares)',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'other_saving_investment',
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: 'Form 54',
        description: '',
        label: 'Form 54',
        required: true,
        only_b2b: true,
        source_url: '',
        key: 'form_fifty_four',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'covid_vaccine',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'flight_hotel_details',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'identity',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: true,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'indonesia_hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'notary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'pan_card',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        currency: 'INR',
        attributes: ['image_required'],
        only_b2b: false,
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'photo',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_lease_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'shipping_labels',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_bank_statements',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 275,
              height: 183,
            },
            alt: 'New Zealand Tourism Board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/7864ba49-d131-468f-8954-791f05ca1a64_New+zealnd+touriam+board..png?auto=compress,format',
            id: 'ZdXOqxEAAC0ARVy3',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 453,
              height: 551,
            },
            alt: 'New Zealand coat of arms',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e3d6d9a3-4c11-498c-b057-b8ca0afdf2c1_New+Zealand+coat+of+arms..png?auto=compress,format',
            id: 'ZdXQThEAACkARWRh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 744,
              height: 320,
            },
            alt: 'New Zealand airline logo',
            copyright: null,
            url: 'https://images.prismic.io/atlys/8bf380ff-aad2-4d6e-a42e-37f50901ac51_New+Zealand+airline..png?auto=compress,format',
            id: 'ZdXRLREAACgARWie',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$124e3510-0d5d-4a29-9131-47ba5173022a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question:
            'What are the requirements for an New Zealand tourist visa as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Australian tourist visa documents that are required when applying for tryvisa are as follows:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: '2 years ITR statement',
              spans: [],
            },
            {
              type: 'list-item',
              text: '6-month bank statement',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Scanned copies of all your passport pages that have a visa stamp or sticker.\n',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the application process for a New Zealand  visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The process is quite simple. First, go online to tryvisa; from there, gather the required documents, do the application, pay the visa fee, and then wait for your visa to be processed.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for my New Zealand tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'It is recommended to apply well in advance since there are a lot of factors that can delay the processing of your visa. So applying 1-2 months before your travels is advised.',
              spans: [
                {
                  start: 132,
                  end: 162,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Will an interview be required for an New Zealand tourist visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you don’t need to go to an interview to get an New Zealand visa. If there are additional requirements or clarification needed, then you might be asked for an Interview.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if I apply for an New Zealand visa and have a criminal record?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you have a criminal record, you might be asked to provide additional documents for your visa to be processed. Additionally, the visa approval process also depends on the severity of your crime. ',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide a letter of invitation as an Indian tourist?',
          answer: [
            {
              type: 'paragraph',
              text: 'A letter of invitation is not mandatory, but it might be beneficial if you have one.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my New Zealand visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa has been denied, you can reapply for it. Before reapplying, ensure that the reasons that caused the visa rejection are fixed. Unfortunately, you will lose the funds if you have applied through the government or online portals, except through tryvisa. At tryvisa, you are guaranteed a total of ₹8000 or a $100 refund on rejected visas. This refund will exclude the service fees.',
              spans: [
                {
                  start: 265,
                  end: 270,
                  type: 'strong',
                },
                {
                  start: 280,
                  end: 290,
                  type: 'strong',
                },
                {
                  start: 318,
                  end: 324,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What type of New Zealand visa can I get as an Indian citizen?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of different types of visas for Indian citizens. Here at tryvisa, you can apply for your New Zealand visitor visa. There are also other types, such as transit visas, student visas, and work visas.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What is the required bank balance for an New Zealand visa for Indian tourists?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will need to have at least ₹278 000 (subject to change) in your bank account to apply for the New Zealand visa.',
              spans: [
                {
                  start: 32,
                  end: 59,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the fastest way to get an New Zealand visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'On Altys, you can apply for your visa in under 10 minutes. The quicker you apply, the quicker the processing can start.',
              spans: [],
            },
          ],
        },
        {
          question: 'How do I obtain an New Zealand visa from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'You cannot extend your tourist visa; however, you are allowed to apply for a new one while you are in New Zealand.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the quickest way to apply for an ANew Zealand e-visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying through tryvisa is the quickest way to get your New Zealand visitor visa from India. You can apply for your visa in under 10 minutes.',
              spans: [
                {
                  start: 17,
                  end: 22,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What would the time be for an New Zealand visa process from India?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an New Zealand visa generally takes about 45 days but can take longer if there are additional documents required.',
              spans: [
                {
                  start: 66,
                  end: 73,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I apply for an New Zealand visa if I have a one-way flight ticket?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you may apply for an Australian visa even if you only have a one-way flight ticket. You must just provide proof that you will return to your home country.',
              spans: [],
            },
          ],
        },
        {
          question: 'What does a tourist visa to New Zealand cost?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa the New Zealand visitor visa fee is as stated above.',
              spans: [
                {
                  start: 60,
                  end: 61,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay with an New Zealand visa for Indian tourists?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can stay in New Zealand for up to 12 months, depending on the stay period provided on your visa. The Australian officials are the ones deciding how long you can stay.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What happens if I provide the wrong documents when I apply for my New Zealand visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you provide the wrong detail on a document or the document itself is wrong, you must contact tryvisa’s support team immediately and provide the correct details. Incorrect documents may cause a delay in the processing time or visa rejections. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What could cause my New Zealand visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of factors that could cause your visa to be denied, such as wrong documents, invalid information, and insufficient funds.',
              spans: [
                {
                  start: 78,
                  end: 93,
                  type: 'strong',
                },
                {
                  start: 95,
                  end: 114,
                  type: 'strong',
                },
                {
                  start: 120,
                  end: 138,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$a127f832-8260-4a53-a2c2-acf5595658ab',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'New Zealand',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  PL: {
    name: 'Poland',
    code: 'PL',
    hero_image_url:
      'https://images.unsplash.com/photo-1563177978-4c5ffc081b2a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8UG9sYW5kfGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Poland',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  PT: {
    name: 'Portugal',
    code: 'PT',
    hero_image_url:
      'https://images.unsplash.com/photo-1501927023255-9063be98970c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8UG9ydHVnYWx8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Portugal',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  SE: {
    name: 'Sweden',
    code: 'SE',
    hero_image_url:
      'https://images.unsplash.com/photo-1497217968520-7d8d60b7bc25?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8U3dlZGVufGVufDB8fDB8fHww',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Sweden',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  SI: {
    name: 'Slovenia',
    code: 'SI',
    hero_image_url:
      'https://images.unsplash.com/photo-1534754359223-fab5b6eadfdf?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8U2xvdmVuaWF8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Slovenia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  SK: {
    name: 'Slovakia',
    code: 'SK',
    hero_image_url:
      'https://images.unsplash.com/photo-1620283110809-3ff3adfe057b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U2xvdmFraWF8ZW58MHx8MHx8fDA%3D',
    process: 'physical',
    process_name: 'Schengen Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 8,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '180 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appoinment',
        description: 'Our experts will find an appointment date for you within the next 7-21 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 2560,
              height: 675,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/520a17ae-23c4-468d-b9d1-f5bbf00fbd7b_Swiss_new.svg.png?auto=compress,format',
            id: 'ZcoaYxIAACcACtRE',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1556,
              height: 1557,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/1f9e65d3-98c7-4708-b949-6739a3ae9540_lufthansa.png?auto=compress,format',
            id: 'ZcoamBIAACsACtST',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 600,
              height: 600,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/b731fee0-518c-41bf-a594-46c5b8fce2d5_europe.png?auto=compress,format',
            id: 'ZcoazBIAACgACtTh',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$dbdf4183-a604-430f-bf83-1742a0e4139b',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'Slovakia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  TJ: {
    name: 'Tajikistan',
    code: 'TJ',
    hero_image_url:
      'https://images.unsplash.com/photo-1585649640892-f97f19f45724?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8VGFqaWtpc3RhbnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Tajikistan E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 40,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '60 days',
    entry_validity: '60 days',
    govt_fees: 4500,
    service_fees: -4500,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'appointment',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'covid_vaccine',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'fbi_background_check',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'flight_hotel_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'indonesia_hotel_details',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'occupation',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'pan_card',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'shipping_labels',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'travel_itinerary',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 512,
              height: 512,
            },
            alt: 'Tajikistan Ministry of Foreign Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/10517ed0-7f30-4ea1-8e4d-f7e28928087f_mfa_tajikistan.png?auto=compress,format',
            id: 'ZP1V1hIAACMAf8dm',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 499,
            },
            alt: 'Tajikistan National emblem logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/ef19e1bd-d878-4c5c-810b-6438c745d4a7_Tajikistan+national+emblem.png?auto=compress,format',
            id: 'ZdWIyhEAACgARCpm',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 500,
              height: 128,
            },
            alt: 'Tajikistan airline logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c073651c-a8f9-444e-94f1-94e92b1e5616_tajik-air_16c626.png?auto=compress,format',
            id: 'ZP1W5xIAACIAf8w2',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 617,
              height: 404,
            },
            alt: 'Tajikistan tourism logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/aa3be774-89bb-4fa9-b633-5607469ff833_Tajikistan+tourism.png?auto=compress,format',
            id: 'ZdWKcxEAACgARDG9',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$dd0021c7-b606-4602-9a1a-55019387ed6f',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for an Tajikistan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The commonly required documents for an Tajikistan visa application are:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport should be valid for at least six months beyond your planned departure date from Tajikistan. You must also have at least one blank page for the visa stamp.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Photograph: You need to provide a recent passport-sized color photograph with a white background.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance required to apply for an Tajikistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Travel insurance is highly recommended when applying for an Tajikistan visa, though it may not be mandatory.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question:
            'Are there any restrictions on activities I can do on an Tajikistan visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Tajikistan visit visa is primarily for tourism purposes. Any kind of paid work without a proper work visa is prohibited.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'What kind of photos should I submit for the Tajikistan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'You should submit a recent color photograph with a white background for your Tajikistan visa application. On tryvisa, you can use the visa photo tool when you apply. It generates a perfect visa photo for free.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'Can I make a family application for an Tajikistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: "A family application can be made for an Tajikistan visa, On tryvisa, you can select the number of people you're applying for under one application.",
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'What should I do if I make a mistake on my Tajikistan visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you need to correct a mistake on your Tajikistan visa application, please contact our support team as soon as possible to rectify the issue.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'How early should I apply for an Tajikistan visa before my travel date?',
          answer: [
            {
              type: 'paragraph',
              text: 'We recommend applying for your Tajikistan visa at least two weeks before your intended travel date to account for any potential processing delays.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'Do I need to book a flight before applying for an Tajikistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While not mandatory, having a booked flight can increase the chances of your Tajikistan visa application being approved.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'What happens if my Tajikistan visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: "After visa rejection, you can submit a new visa application once you've addressed all reasons for denial. Note that visa fees are non-refundable through government and online portals, except for tryvisa. tryvisa refunds up to ₹8000 or $100 upon visa rejection. (The refund excludes any service fees)",
              spans: [
                {
                  start: 202,
                  end: 207,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I work in Tajikistan with a visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, it is illegal to work in Tajikistan with a visitor visa. You will need a proper work visa.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'Are there any specific health requirements to apply for an Tajikistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are no specific health requirements for most visitors. However, depending on your travel history, you might need to provide a yellow fever vaccination certificate.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check if my Tajikistan visa application was successful?',
          answer: [
            {
              type: 'paragraph',
              text: 'Access your visa dashboard, where you can check the status of your Tajikistan visa application. Click on the icon located at the top right corner of your dashboard to view your visa status.',
              spans: [],
              direction: 'ltr',
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'Can I visit all parts of Tajikistan with a visit visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, an Tajikistan visit visa allows you to travel throughout the country unless specified otherwise.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'How will I receive my Tajikistan visa after the application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After your Tajikistan visa application is approved, the visa will be sent to you via email in PDF format.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
        {
          question: 'How can I check my Tajikistanvisa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can check your Oman visa status online through our website by entering your application reference number.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it mandatory to print my Tajikistan visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'While the visa is linked to your passport electronically, you still need to print out a copy to show at customs once you arrive in Tajikistan. We recommend that you print out not just one but several copies and put them in different bags just in case you lose your luggage.',
              spans: [],
              direction: 'ltr',
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$31062bf8-45b7-4e12-9f78-c42c81d7f3b8',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Tajikistan',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  TZ: {
    name: 'Tanzania',
    code: 'TZ',
    hero_image_url:
      'https://images.unsplash.com/photo-1589177900326-900782f88a55?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8VGFuemFuaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Tanzania E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 90,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist',
    entry_length_stay: '90 days',
    entry_validity: '90 days',
    govt_fees: 4300,
    service_fees: -4300,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          "An e-visa is required to visit, regardless of the duration. The e-visa is checked on departure, and you're required to get an e-visa before your trip. You cannot get an e-visa on arrival.",
      },
    ],
    components_required: [
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: true,
        key: 'identity',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'USD',
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'USD',
        key: 'photo',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'portugal_nif',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'travel_insurance',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format&rect=0,0,356,240&w=356&h=240',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 342,
              height: 316,
            },
            alt: 'Tanzania Ministry of Foreign Affairs',
            copyright: null,
            url: 'https://images.prismic.io/atlys/cd6dd935-35e2-42ff-8da8-6508e0d57126_image+%283%29.png?auto=compress,format',
            id: 'ZK_OLhIAACIAaWxe',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 225,
              height: 225,
            },
            alt: 'Tanzania airline',
            copyright: null,
            url: 'https://images.prismic.io/atlys/18738426-1aea-43f4-980b-f63423b5d627_Tanzania+airlines.+.png?auto=compress,format',
            id: 'ZdTzcBEAAC0AQY1t',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 590,
              height: 423,
            },
            alt: 'Tanzania Tourism Board',
            copyright: null,
            url: 'https://images.prismic.io/atlys/c92f942f-54cf-4ceb-ac37-a465e8152c1e_Tanzania+Tourism+Board..png?auto=compress,format',
            id: 'ZdTyBxEAACgAQYcK',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$fd19fa1d-c046-4b75-bdd3-74aa684a9c59',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need when applying for the Tanzania visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "When applying for a Tanzania visa online, you'll need a valid passport, a recent passport-style photograph, and details about your intended stay.",
              spans: [
                {
                  start: 20,
                  end: 40,
                  type: 'hyperlink',
                  data: {
                    link_type: 'Web',
                    url: 'https://www.atlys.com/en-IN/post/tanzania-evisa-for-indians',
                    target: '_blank',
                  },
                },
              ],
            },
          ],
        },
        {
          question: 'What are the photo requirements for the Tanzania visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The photo for your Tanzania visa application must be a recent passport-style color photo taken against a white background with a clear image of your face.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for a Tanzania visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for a Tanzania visa online through tryvisa. tryvisa simplifies the visa application process, making it efficient and hassle-free. Their system is designed to prevent common errors that can delay your application.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is travel insurance necessary for my Tanzania visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "While travel insurance might not be a requirement for the Tanzania visa application, it's always a good idea to have one to cover any unexpected incidents or health issues during your travel.",
              spans: [],
            },
          ],
        },
        {
          question: 'How can I ensure my Tanzania visa application is successful?',
          answer: [
            {
              type: 'paragraph',
              text: 'To ensure a successful Tanzania visa application, fill out all the required information accurately, upload all the necessary documents, and pay the correct fee. Inaccuracies or incomplete information can lead to delays or rejection of your application.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I receive my approved Tanzania e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once approved, your Tanzania e-visa will be sent to the email you provided during the application process, and you can also download it for the app.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need to provide proof of accommodation for my Tanzania visa application?',
          answer: [
            {
              type: 'paragraph',
              text: "Yes, for your Tanzania visa application, you’ll need to provide proof of accommodation, like a hotel booking confirmation or an invitation letter if you're staying with friends or family.",
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my visa is declined?',
          answer: [
            {
              type: 'paragraph',
              text: "When your visa is denied, you can reapply for it once you have addressed all the reasons for rejection. You will lose your visa funds since the government and online portals do not refund the visa fees, except for tryvisa. At tryvisa, if your visa application is rejected, you'll be refunded up to ₹8000 or $100, excluding the service fee.",
              spans: [
                {
                  start: 224,
                  end: 229,
                  type: 'strong',
                },
                {
                  start: 279,
                  end: 287,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to show proof of onward travel for my Tanzania visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'For your Tanzania visa application, it is typically required to provide proof of onward travel, like a return or onward ticket.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I visit other East African countries with my Tanzania visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'A standard Tanzania visa does not permit travel to other East African countries. If you plan to visit multiple countries in East Africa, consider applying for the East Africa Tourist Visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I correct a mistake in my Tanzania visa online application?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa, you’re able to review your application before submitting it to avoid making any mistakes.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I check my Tanzania visa status online?',
          answer: [
            {
              type: 'paragraph',
              text: 'To check your Tanzania visa status online, log in to your account using your username and password. Next, navigate to the "Applications" tab on the top menu of the online portal. Under the "Applications" tab, you can keep track of the status of your Oman visa application. The status can range from "Submitted" to "In progress" to "Approved."',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I work in Tanzania with a visitor visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, a visitor visa does not permit you to work in Tanzania. You should apply for the appropriate work or business visa if you intend to work.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Tanzania visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for a Tanzania visa online. This is the most convenient method and allows you to complete the process from anywhere.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take for a Tanzania e-visa to be processed?',
          answer: [
            {
              type: 'paragraph',
              text: "The processing time for a Tanzania e-visa is 5 days with tryvisa. It's advisable to apply well in advance of your intended travel date.",
              spans: [],
            },
          ],
        },
        {
          question:
            'What steps does Tanzania take to ensure my data is safe during the visa application process?',
          answer: [
            {
              type: 'paragraph',
              text: 'tryvisa has a secure platform to protect your data during the application process. They comply with all data security laws to ensure your information is safe. tryvisa uses secure payment gateways to process your visa application fees, ensuring that your financial data is well-protected.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I amend my Tanzania visa after it has been issued?',
          answer: [
            {
              type: 'paragraph',
              text: 'Once your Tanzania visa has been issued, it cannot be amended. You should apply for a new visa if there are any significant changes.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need a yellow fever vaccination to apply for a Tanzania visa?',
          answer: [
            {
              type: 'paragraph',
              text: "Tanzania requires proof of yellow fever vaccination if you are traveling from a country with a risk of yellow fever. It's best to check the latest health-related entry requirements before your travel.",
              spans: [],
            },
          ],
        },
        {
          question: 'How much is the Tanzania visa fee?',
          answer: [
            {
              type: 'paragraph',
              text: 'The Tanzania visa fee is INR 4,450 when you apply for the visa through tryvisa.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$619bc944-b8b2-4712-bea8-649e112087d6',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Tanzania',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  US: {
    name: 'USA',
    code: 'US',
    hero_image_url:
      'https://images.unsplash.com/photo-1483653364400-eedcfb9f1f88?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fFVuaXRlZCUyMFN0YXRlc3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Visa Appointment',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: 'days',
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'B1 / B2',
    entry_length_stay: '180 days',
    entry_validity: '10 years',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Express Visa Appointment',
        description: 'Our experts will find an appointment date for you within 15-45 days.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1243,
              height: 1258,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/0ad09f17-a055-4559-9923-cb40d42b49ea_USA_DOT.png?auto=compress,format',
            id: 'ZWcgRREAAB8AivJe',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 200,
              height: 200,
            },
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/atlys/c2fb4bb9-770f-49f2-a6fa-a33bd414177a_America-Airlines.png?auto=compress,format',
            id: 'ZWiXsxEAAB8Akcff',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'black_clp_partners$5f0d7368-2f22-4b34-be3a-781ddcb55b07',
      slice_type: 'black_clp_partners',
      slice_label: null,
    },
    country_name: 'United States',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: true,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  UY: {
    name: 'Uruguay',
    code: 'UY',
    hero_image_url:
      'https://images.unsplash.com/photo-1616959312796-6ed07ba57550?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8VXJ1Z3VheXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'physical',
    process_name: 'Tourist Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 60,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist Visa',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 3500,
    service_fees: -3500,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA Logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1440,
            },
            alt: 'Coat of arms of Uruguay.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d63fd404-97e2-4e37-9c68-d328533dbb2f_Coat_of_arms_of_Uruguay.svg.png?auto=compress,format',
            id: 'ZZam7BEAACAAnesP',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 578,
              height: 432,
            },
            alt: 'Logo of the Ministry of Tourism for Uruguay.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5966c5da-36a7-4d2a-82b8-4059fbce8694_Uruguay+ministry+of+tourism.png?auto=compress,format&rect=26,14,520,389&w=578&h=432',
            id: 'ZZaphREAACEAnfU4',
            edit: {
              x: -28.83710196021163,
              y: -15.5276702862678,
              zoom: 1.1091193061619857,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 717,
              height: 348,
            },
            alt: 'Uruguay Ministry of Exterior logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/2aefad92-9046-454a-9541-ebd262d9b3ba_Uruguay+ministry+of+exteriors.png?auto=compress,format&rect=56,28,595,289&w=717&h=348',
            id: 'ZdWkXREAACoARJ3P',
            edit: {
              x: -67,
              y: -33,
              zoom: 1.205098087489605,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$383b941f-65ce-4acd-8fb6-c174636860af',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What are the required documents for the Uruguay visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'When applying for your Uruguay visa as an Indian, you need the following documents:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport: Your passport must be valid for 6 months after you leave Uruguay.',
              spans: [
                {
                  start: 0,
                  end: 9,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Application Form: An accurately completed application form.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Photo: A recent passport-sized photo.',
              spans: [
                {
                  start: 0,
                  end: 6,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Copy of Passport: 1x photocopy of your passport.',
              spans: [
                {
                  start: 0,
                  end: 17,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Accommodation: Show proof of your accommodation, such as hotel reservations.',
              spans: [
                {
                  start: 0,
                  end: 24,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Flight Tickets: Submit your flight tickets to show your departure date.',
              spans: [
                {
                  start: 0,
                  end: 16,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Proof of Finance: Provide proof of funds that can cover your stay in Uruguay, such as bank statements, proof of salary, and an international credit card.',
              spans: [
                {
                  start: 0,
                  end: 18,
                  type: 'strong',
                },
              ],
            },
            {
              type: 'list-item',
              text: 'Medical Certificate: Provide a medical certificate from an approved medical examiner.',
              spans: [
                {
                  start: 0,
                  end: 21,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get a Uruguay e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, Indians can not get an Uruguay e-visa. Instead, they can apply for a Uruguay visa online through tryvisa. tryvisa will guide you through the application and collect your documents from you to submit them at the embassy on your behalf.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can Indians get a Uruguay visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there is no Uruguay visa on arrival for Indians. You must have an approved visa before travelling to Uruguay.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Do I need to attend an interview when applying for a Uruguay visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Generally, there is no interview needed for the Uruguay visa. However, if the authorities find it necessary to do so with more complex applications, they will request an interview.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance must I apply for my Uruguay visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To allow sufficient processing time, it is advised to apply at least 3-4 weeks before your travelling date.',
              spans: [
                {
                  start: 69,
                  end: 85,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the easiest way for Indians to get a Uruguay visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The easiest way to get a visa for Uruguay is through tryvisa. tryvisa works with government partners to allow you a smooth and hassle-free application. The application steps will be as follows: ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'You complete the online application.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'tryvisa will collect your documents from you via courier.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'tryvisa will verify your documents to ensure they meet the requirements.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'After visa approval, tryvisa will collect your visa from the embassy and courier it back to you.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my visa is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is rejected, you can reapply for your visa. To do so, you must ensure that all your documents are provided and you meet the requirements.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'paragraph',
              text: 'Reapplying for your visa causes you to pay the visa fees again since the government or visa portals will not refund them. Luckily, you can apply through tryvisa, where you are guaranteed a refund of up to ₹8000 or $100 on rejected visas. This refund will not include the service fee.',
              spans: [
                {
                  start: 121,
                  end: 129,
                  type: 'strong',
                },
                {
                  start: 153,
                  end: 159,
                  type: 'strong',
                },
                {
                  start: 174,
                  end: 184,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay in Uruguay with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa for Uruguay allows you to stay for up to 30 days.',
              spans: [
                {
                  start: 58,
                  end: 65,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is travel insurance required for the Uruguay visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, travel insurance is not mandatory when applying for a Uruguay visa. However, it is always advised to have travel insurance when visiting a foreign country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I check my visa status?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can check the status of your visa on the tryvisa app. ',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Download the tryvisa app.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Click on your profile.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Choose the visa type you have applied for.',
              spans: [],
            },
            {
              type: 'o-list-item',
              text: 'Once you click on the visa, you will see its current status.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long does the Uruguay visa take to process?',
          answer: [
            {
              type: 'paragraph',
              text: 'The tourist visa for Uruguay usually takes about 15 days to process. However, this time can be longer if your application gets delayed.',
              spans: [
                {
                  start: 49,
                  end: 56,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I pay for my visa on tryvisa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can pay for your visa on tryvisa using a credit/debit card or an approved mobile wallet.',
              spans: [
                {
                  start: 43,
                  end: 60,
                  type: 'strong',
                },
                {
                  start: 76,
                  end: 89,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can the visa for Urugua be used for multiple entries?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, if you apply through the embassy, you can get a multi-entry visa, allowing you to enter Uruguay multiple times on a single visa.',
              spans: [
                {
                  start: 0,
                  end: 4,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Can I be employed in Uruguay with a tourist visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, you can not work in Uruguay with a tourist visa. You must have a valid employment visa when working in the country.',
              spans: [
                {
                  start: 0,
                  end: 2,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are some reasons that can cause my visa to be rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'There are a bunch of factors that can cause your visa to be rejected, such as:',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Incomplete documents.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Not having enough funds to cover your stay in Uruguay.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Your passport is close to the expiry date.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Lack of proof of intent to return to your home country.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'You are not providing proof of accommodation.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Frequently Asked Questions',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$fe3c289b-bec6-4af3-9d77-6ca495d4e5dd',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Uruguay',
    purpose: 'tourism',
    sticker_visa: 'TRUE',
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  MM: {
    name: 'Myanmar (Burma)',
    code: 'MM',
    hero_image_url:
      'https://images.unsplash.com/photo-1614426840638-c802bdb2b698?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8TXlhbm1hciUyMChCdXJtYSl8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Myanmar E Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: '28 days',
    entry_validity: '90 days',
    govt_fees: 6600,
    service_fees: -6600,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Myanmar E-Visa',
        description:
          'An E-visa is a single entry visa which allows you to stay in Myanmar for upto 28 days',
      },
    ],
    additional_questions: [
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        description: '',
        label: 'Please upload your flight bookings',
        only_b2b: false,
        required: true,
        key: 'flight_details',
        source_url: '',
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        description: '',
        label: 'Please upload your hotel bookings',
        required: true,
        key: 'hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        description: 'Please upload Covid 19 Vaccine',
        label: 'Covid_19 Vaccine',
        key: 'Covid_Vaccine',
        only_b2b: true,
        source_url: '',
        required: true,
      },
      {
        family_enabled: true,
        extra_info: '',
        question_type: 'file',
        description: 'Please Upload Insurance',
        label: 'Insurance',
        required: true,
        source_url: '',
        only_b2b: true,
        key: 'Insurance',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'fbi_background_check',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'notary',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'occupation',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'pan_card',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['image_required', 'validity_required'],
        currency: 'INR',
        only_b2b: false,
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'passport_back',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'photo',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'shipping_labels',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'travel_insurance',
        only_b2b: true,
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'travel_itinerary',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_bank_statements',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
      },
    ],
    country_name: 'Myanmar (Burma)',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  NG: {
    name: 'Nigeria',
    code: 'NG',
    hero_image_url:
      'https://images.unsplash.com/photo-1569706971306-de5d78f6418e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8TmlnZXJpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 57858,
    service_fees: -57858,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Visa',
        description:
          'Nigeria issues a paper visa on your passport. With tryvisa, you can skip an in-person interview. ',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'appointment',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'notary',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        key: 'pan_card',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'passport_back',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'travel_insurance',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_residence',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    country_name: 'Nigeria',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  UG: {
    name: 'Uganda',
    code: 'UG',
    hero_image_url:
      'https://images.unsplash.com/photo-1581918629062-2b6cfdd04060?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8VWdhbmRhfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Rwanda E-Visa',
    supported: false,
    message: null,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '90 days',
    govt_fees: 8250,
    service_fees: -8250,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description:
          "An e-visa is required to visit, regardless of the duration. The e-visa is checked on departure, and you're required to get an e-visa before your trip. You cannot get an e-visa on arrival.",
      },
    ],
    additional_questions: [
      {
        extra_info: '',
        question_type: 'file',
        description: 'A yellow fever vaccination certificate is required for your travel',
        label: 'Upload Yellow Fever vaccination certificate',
        key: 'yellow_fever',
        source_url: '',
        required: false,
      },
      {
        extra_info: '',
        question_type: 'file',
        description:
          'Please upload a letter of invitation from the host, this is required for a visa. If saying in a hotel, please ask your hotel for this.',
        label: 'Letter of invitation',
        source_url: '',
        required: false,
        key: 'letter_of_invitation',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'appointment',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'USD',
        key: 'identity',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'USD',
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'USD',
        key: 'photo',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'portugal_bank_account',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'portugal_lease_details',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'shipping_labels',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        key: 'travel_itinerary',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'USD',
        toggle: false,
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required'],
        currency: 'USD',
        toggle: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'USD',
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 680,
              height: 357,
            },
            alt: 'Uganda Ministry of Internal Affairs logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/e4c4fbde-e1cf-43e3-a59b-f7fa01a2abd2_Uguanda+Ministry+of+Internal+Affairs.png?auto=compress,format',
            id: 'ZdWoqxEAACgARLEB',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 440,
              height: 102,
            },
            alt: 'Uganda airlines logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/5cced4d2-4d13-4507-9113-b67534fbab96_Uganda+airlines+logo.png?auto=compress,format',
            id: 'ZdWqjxEAACwARLmM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 400,
              height: 90,
            },
            alt: 'Uganda Police Force logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/447b4e5a-37af-476b-87fe-1898b83d5386_image+%282%29.png?auto=compress,format',
            id: 'ZK_J_hIAACMAaV0I',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 800,
              height: 857,
            },
            alt: 'Uganda coat of arms logo.',
            copyright: null,
            url: 'https://images.prismic.io/atlys/90fdbaf9-8b80-4dfa-bc29-a6ed4370c0e3_Coat_of_arms_of_Uganda.png?auto=compress,format',
            id: 'ZdWrTREAAC0ARLzi',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
      ],
      primary: {},
      id: 'clp_partners$0e560291-eafb-4e08-811e-e6f9265cb647',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents do I need for the Uganda visa application?',
          answer: [
            {
              type: 'paragraph',
              text: 'The documents you need are as follows:',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Photo of your yellow fever vaccination certificate.',
              spans: [],
            },
            {
              type: 'list-item',
              text: "A letter of invitation. This letter needs to be from your hotel, friends, or family. Depending on where you'll stay.",
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport photo.',
              spans: [],
            },
          ],
        },
        {
          question: 'Is it safe to apply for an Uganda visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, tryvisa uses 256-bit Advanced Encryption Standard to keep your data safe. tryvisa does not sell your data to third-party companies, and we never will. tryvisa only uses your data to ensure that you get your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How early should I apply for an Uganda visa online?',
          answer: [
            {
              type: 'paragraph',
              text: "It's recommended to apply for an Uganda visa online at least two weeks before your planned travel date. This allows sufficient time for processing and addressing any potential issues that may arise.",
              spans: [],
            },
          ],
        },
        {
          question: "What happens if my Uganda e-visa expires while I'm in the country?",
          answer: [
            {
              type: 'paragraph',
              text: "If your Uganda e-visa expires while you're in the country, you should contact the local immigration office immediately. Overstaying your visa can result in fines or other penalties.",
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question:
            'What can I do if I encounter issues during the Uganda e-visa application process?',
          answer: [
            {
              type: 'paragraph',
              text: 'If you encounter issues during the Uganda e-visa application process, contact the customer service of tryvisa. They can provide assistance and guide you through the application process.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'Do children need a separate Uganda e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, every traveler, regardless of age, must have their own Uganda e-visa. This includes infants and children. However, you can apply for this visa easily and without hassle through tryvisa.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I receive my Uganda e-visa after application?',
          answer: [
            {
              type: 'paragraph',
              text: 'After your Uganda e-visa application is approved, the e-visa will be sent to you via email or download from our portal or app. You can then print it out and present it upon arrival in Uganda.',
              spans: [],
            },
          ],
        },
        {
          question: "Can I change the details on my Uganda e-visa after it's been issued?",
          answer: [
            {
              type: 'paragraph',
              text: "No, you cannot change the details on your Uganda e-visa once it's been issued. If there are changes or errors, you'll need to apply for a new e-visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'How long does it take to process an online visa application for Uganda?',
          answer: [
            {
              type: 'paragraph',
              text: 'The processing time for an online visa application for Uganda is 3 business days. tryvisa guarantees your money back if the visa isn’t processed in the stated time.',
              spans: [],
            },
            {
              type: 'paragraph',
              text: '',
              spans: [],
            },
          ],
        },
        {
          question: 'How much does it cost to apply for an Uganda visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'The cost of applying for an Uganda visa online is INR 12450. Please note that this visa fee is nonrefundable.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Uganda e-visa from any country?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you can apply for an Uganda e-visa from any country as long as you have a valid passport and access to the internet.',
              spans: [],
            },
          ],
        },
        {
          question: 'What should I do if my Uganda e-visa is not approved in time for my trip?',
          answer: [
            {
              type: 'paragraph',
              text: "If your Uganda e-visa is not approved in time for your trip, you should contact the tryvisa customer service team immediately. However, make sure you apply for your visa at least two weeks before your travels. Then, you won't have any trouble with a late visa.",
              spans: [],
            },
          ],
        },
        {
          question: 'What is the maximum stay duration with an Uganda e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'For tourist visas, the usual maximum stay is 90 days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What happens if my Uganda visa application is rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa is denied, you can reapply for the visa. Ensure that you meet the requirements and provide the required documents fully. Unfortunately, you will lose the funds since the government and visa portals do not provide a refund, except for tryvisa. When applying through tryvisa, a refund of up to ₹8000 or $100 will be guaranteed. This excludes the service fee.',
              spans: [
                {
                  start: 276,
                  end: 281,
                  type: 'strong',
                },
                {
                  start: 284,
                  end: 291,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'Is an Uganda e-visa valid for both business and leisure travel?',
          answer: [
            {
              type: 'paragraph',
              text: 'No, there are specific types of e-visas for business and leisure travel. Ensure you apply for the correct visa based on your travel purpose.',
              spans: [],
            },
          ],
        },
        {
          question: 'How can I apply for the Uganda visa online?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for an Uganda visa online through the tryvisa online portal.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for an Uganda visa on arrival?',
          answer: [
            {
              type: 'paragraph',
              text: 'As of 2023, Uganda no longer offers visas on arrival. All visitors must apply for an e-visa online prior to their arrival.',
              spans: [],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind ?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$e62c7419-67ac-432c-91ac-0224d795d2ab',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Uganda',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  ZW: {
    name: 'Zimbabwe',
    code: 'ZW',
    hero_image_url:
      'https://images.unsplash.com/photo-1575285272212-d52e915d01c7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8WmltYmFid2V8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'E-Visa',
    supported: false,
    message: 'Visa on Arrival Available',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Tourist',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 700,
    service_fees: -700,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'E-Visa',
        description: 'An e-visa is required to visit Zimbabwe.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'identity',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'indonesia_hotel_details',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'notary',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'occupation',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        only_b2b: false,
        key: 'pan_card',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'passport',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'passport_back',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'photo',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_bank_account',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'shipping_labels',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'travel_insurance',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'us_bank_statements',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
        only_b2b: false,
      },
    ],
    partner_slice: {
      variation: 'default',
      version: 'initial',
      items: [
        {
          logo: {
            dimensions: {
              width: 356,
              height: 240,
            },
            alt: 'IATA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/d5504196-40e8-41ba-a58d-768ccab8807d_iata.png?auto=compress,format',
            id: 'ZIK_pBAAACEAjaQM',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {
            dimensions: {
              width: 1200,
              height: 1036,
            },
            alt: 'Zimbabwe MOFA',
            copyright: null,
            url: 'https://images.prismic.io/atlys/06b9f807-3c59-4fc2-b217-577d2cb13468_Zimbabwe+MOFA.png?auto=compress,format',
            id: 'ZJwslBEAACMAsy48',
            edit: {
              x: 0,
              y: 0,
              zoom: 1,
              background: 'transparent',
            },
          },
        },
        {
          logo: {},
        },
      ],
      primary: {},
      id: 'clp_partners$add610ee-a704-439e-8b66-85700074d59a',
      slice_type: 'clp_partners',
      slice_label: null,
    },
    faqs_slice: {
      variation: 'clpFaq',
      version: 'sktwi1xtmkfgx8626',
      items: [
        {
          question: 'What documents are required for Indians to get a Zimbabwe visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'To get a Zimbabwe visa as an Indian, you will need to provide the following: ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'A valid passport.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Passport-size Photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of accommodation.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Proof of residence in India.',
              spans: [],
            },
          ],
        },
        {
          question: 'Do I need a hard copy of my visa to visit Zimbabwe as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, you need to print out your visa and provide it along with your passport when you go to Zimbabwe.',
              spans: [],
            },
          ],
        },
        {
          question: 'What are the visa photo size requirements?',
          answer: [
            {
              type: 'paragraph',
              text: 'The measurements for the visa photo are 35mmx45mm, and it has to be on a white background. You can use tryvisa’s online photo tool for free. You can also load your photo on the white background in the application process, where tryvisa will edit your photo to the correct measurements.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I know if my Zimbabwean visa gets approved?',
          answer: [
            {
              type: 'paragraph',
              text: 'When your visa for Zimbabwe is approved, tryvisa will notify you via the email and phone number you have registered with.',
              spans: [],
            },
          ],
        },
        {
          question: 'How will I receive my online visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'You will receive your visa through the email you have registered with on tryvisa. From there, you can download your visa and print it out.',
              spans: [],
            },
          ],
        },
        {
          question: 'What do I do if my visa application gets rejected?',
          answer: [
            {
              type: 'paragraph',
              text: 'If your visa gets denied, you can see the reasons for the rejection. You can fix these reasons and reapply for the visa. Unfortunately, you will lose the funds when you have applied through the government or online visa portals, except for tryvisa. At tryvisa, you are guaranteed a refund on visa denials. This refund is up to ₹8000 or $100 but excludes the service fee.',
              spans: [
                {
                  start: 250,
                  end: 255,
                  type: 'strong',
                },
                {
                  start: 265,
                  end: 275,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How can I track my Zimbabwe visa for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'By using tryvisa, you can track your visa and see the status of the process. Go to tryvisa, click on your profile on the home page,  and choose the “Dashboard” option to check your visa status.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for a Zimbabwean visa on behalf of my family?',
          answer: [
            {
              type: 'paragraph',
              text: 'Yes, through tryvisa, you can apply for your visa and your family’s visa. You will just need the required documents to apply on behalf of them.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I apply for my Zimbabwe visa over the weekend?',
          answer: [
            {
              type: 'paragraph',
              text: 'You can apply for your visa over the weekend; tryvisa is available 24/7. Remember that once tryvisa submits the application, the Government authorities will only process your visa application on business days.',
              spans: [],
            },
          ],
        },
        {
          question: 'What can I do to ensure my Zimbabwe visa is successful?',
          answer: [
            {
              type: 'paragraph',
              text: 'Even though you can never be guaranteed to get your visa, at tryvisa, we ensure you fully complete your documents, giving you the best chance of getting your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'Which countries can I visit on a Zimbabwe visa as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'As an Indian, you can only visit Zimbabwe with a Zimbabwean visa. ',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the process of obtaining a Zimbabwe visa for Indian citizens?',
          answer: [
            {
              type: 'paragraph',
              text: 'To obtain your Zimbabwean visa for Indians, go to tryvisa; ',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Choose the country you want to visit.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Select your dates.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Provide your Passport and photo.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Complete the application form and pay visa fees.',
              spans: [],
            },
            {
              type: 'list-item',
              text: 'Then wait for the processing of your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'How long in advance should I apply for my Zimbabwean visa as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'Applying for your visa at least 3 weeks before you travel to Zimbabwe is advised.',
              spans: [],
            },
          ],
        },
        {
          question:
            'What happens if my Zimbabwe visa for Indian nationals expires while I am in Zimbabwe?',
          answer: [
            {
              type: 'paragraph',
              text: 'There could be serious effects if your visa expires while in Zimbabwe. You could get a ban from Zimbabwe for a certain period, and you might be fine for each day you overstay your visa. Apply for an extension at least 1 week before your visa expires is advised.',
              spans: [],
            },
          ],
        },
        {
          question: 'Can I pay for my Zimbabwean visa for Indian nationals online?',
          answer: [
            {
              type: 'paragraph',
              text: 'When you apply for your visa on tryvisa, you can pay the visa fees either with a credit or debit card or by using an approved mobile wallet.',
              spans: [
                {
                  start: 79,
                  end: 85,
                  type: 'strong',
                },
                {
                  start: 89,
                  end: 94,
                  type: 'strong',
                },
                {
                  start: 124,
                  end: 137,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What is the fastest way I can get an e-visa for ZImbabawe as an Indian?',
          answer: [
            {
              type: 'paragraph',
              text: 'On tryvisa, you can apply for your Zimbabwe visa in less than 10 minutes. From there, you just wait for the processing of your visa.',
              spans: [],
            },
          ],
        },
        {
          question: 'What is the Zimbabwe e-visa processing time for Indian nationals?',
          answer: [
            {
              type: 'paragraph',
              text: 'Visa processing times vary, but a Zimbabwean visa takes about 7 days to process.',
              spans: [
                {
                  start: 62,
                  end: 68,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'What are the Zimbabwe visa fees for Indians?',
          answer: [
            {
              type: 'paragraph',
              text: 'The fee for a Zimbabwean visa on tryvisa is ₹2470.',
              spans: [
                {
                  start: 42,
                  end: 48,
                  type: 'strong',
                },
              ],
            },
          ],
        },
        {
          question: 'How long can I stay with a Zimbabwe e-visa?',
          answer: [
            {
              type: 'paragraph',
              text: 'The e-visa is valid for 3 months, and you can stay in the country for 30 days.',
              spans: [
                {
                  start: 69,
                  end: 77,
                  type: 'strong',
                },
              ],
            },
          ],
        },
      ],
      primary: {
        title: [
          {
            type: 'heading2',
            text: 'Any questions in mind?',
            spans: [],
          },
        ],
      },
      id: 'questions_and_answers$758b5f1d-f24b-4bd1-8005-24168dfb20a0',
      slice_type: 'questions_and_answers',
      slice_label: null,
    },
    country_name: 'Zimbabwe',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  DK: {
    name: 'Denmark',
    code: 'DK',
    hero_image_url:
      'https://images.unsplash.com/photo-1552560880-2482cef14240?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8RGVubWFya3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Schengen Visa Application PDF',
    supported: false,
    message:
      'Temporarily suspending support\nWe have temporarily suspended support for this country due to issues with availability of appointments. We wish this weren’t the case, but we are working with the authorities and will open up as soon as the availability improves.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '15 days',
    entry_validity: '30 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Schengen Tourist Visa',
        description:
          'A Schengen tourist visa is required to visit. The visa grants you access to all countries in the European Union. The visa validity depends on your previous Schengen area visits. tryvisa automatically applies for a multiple entry visa.',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'appointment',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'covid_vaccine',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        only_b2b: false,
        key: 'flight_hotel_details',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        key: 'identity',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'india_itr',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'occupation',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        key: 'passport',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'passport_back',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['physical_copy_required'],
        currency: 'INR',
        key: 'photo',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_lease_details',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_nif',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'shipping_labels',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'travel_insurance',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_bank_statements',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    country_name: 'Denmark',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  IS: {
    name: 'Iceland',
    code: 'IS',
    hero_image_url:
      'https://images.unsplash.com/photo-1500043357865-c6b8827edf10?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8SWNlbGFuZHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Schengen Visa Application PDF',
    supported: false,
    message:
      'Temporarily suspending support\nWe have temporarily suspended support for this country due to issues with availability of appointments. We wish this weren’t the case, but we are working with the authorities and will open up as soon as the availability improves.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '15 days',
    entry_validity: '30 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Schengen Tourist Visa',
        description:
          'A Schengen tourist visa is required to visit. The visa grants you access to all countries in the European Union. The visa validity depends on your previous Schengen area visits. tryvisa automatically applies for a multiple entry visa.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'fbi_background_check',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'flight_hotel_details',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'notary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'passport_back',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'shipping_labels',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'travel_insurance',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    country_name: 'Iceland',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  IT: {
    name: 'Italy',
    code: 'IT',
    hero_image_url:
      'https://images.unsplash.com/photo-1520175480921-4edfa2983e0f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8SXRhbHl8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Schengen Visa Application PDF',
    supported: false,
    message:
      'Temporarily suspending support\nWe have temporarily suspended support for this country due to issues with availability of appointments. We wish this weren’t the case, but we are working with the authorities and will open up as soon as the availability improves.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '15 days',
    entry_validity: '30 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Schengen Tourist Visa',
        description:
          'A Schengen tourist visa is required to visit. The visa grants you access to all countries in the European Union. The visa validity depends on your previous Schengen area visits. tryvisa automatically applies for a multiple entry visa.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'appointment',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'covid_vaccine',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'flight_hotel_details',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'india_itr',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'pan_card',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        currency: 'INR',
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        source_url: '',
        key: 'passport',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['physical_copy_required'],
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_bank_account',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'shipping_labels',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'us_uk_schengen_visa',
        only_b2b: false,
      },
    ],
    country_name: 'Italy',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  LU: {
    name: 'Luxembourg',
    code: 'LU',
    hero_image_url:
      'https://images.unsplash.com/photo-1593449044719-ea9688bb8706?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8THV4ZW1ib3VyZ3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Schengen Visa Application PDF',
    supported: false,
    message:
      'Temporarily suspending support\nWe have temporarily suspended support for this country due to issues with availability of appointments. We wish this weren’t the case, but we are working with the authorities and will open up as soon as the availability improves.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_type: 'Tourist',
    entry_length_stay: '15 days',
    entry_validity: '30 days',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Schengen Tourist Visa',
        description:
          'A Schengen tourist visa is required to visit. The visa grants you access to all countries in the European Union. The visa validity depends on your previous Schengen area visits. tryvisa automatically applies for a multiple entry visa.',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'appointment',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'flight_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'india_itr',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'notary',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'pan_card',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        key: 'passport_back',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'portugal_bank_account',
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_lease_details',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'portugal_nif',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'shipping_labels',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        only_b2b: false,
        key: 'travel_insurance',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'travel_itinerary',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    country_name: 'Luxembourg',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  HR: {
    name: 'Croatia',
    code: 'HR',
    hero_image_url:
      'https://images.unsplash.com/photo-1537353825146-9f2f61475590?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Q3JvYXRpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'This country is currently unsupported',
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    service_fees: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'Croatia Visa',
        description:
          'A visa is required to visit Croatia. A Croatia visa does not let you enter the Schengen area',
      },
    ],
    components_required: [
      {
        amount: 1928,
        chargable: true,
        attributes: [],
        currency: 'INR',
        toggle: true,
        key: 'appointment',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        key: 'identity',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'india_itr',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['employment_letter_required'],
        currency: 'INR',
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: ['image_required', 'validity_required'],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['physical_copy_required'],
        currency: 'INR',
        key: 'photo',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'portugal_bank_account',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_nif',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'shipping_labels',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        source_url: '',
        key: 'travel_insurance',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'travel_itinerary',
        source_url: '',
      },
      {
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_bank_statements',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'us_uk_schengen_visa',
        source_url: '',
      },
    ],
    country_name: 'Croatia',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  MW: {
    name: 'Malawi',
    code: 'MW',
    hero_image_url:
      'https://images.unsplash.com/photo-1612286710224-dd9eb9d8349a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TWFsYXdpfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Malawi E-Visa',
    supported: false,
    message:
      'Temporarily suspending support\nWe have temporarily suspended support for this country due to issues with availability of appointments. We wish this weren’t the case, but we are working with the authorities and will open up as soon as the availability improves.',
    entry_type: 'Malawi Evisa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 5000,
    service_fees: -5000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '',
        description: 'Covering letter from the host in Malawi (Must be signed by Host)',
        label: 'Covering letter from the host in Malawi (Must be signed by Host)',
        source_url: '',
        key: 'Covering_letter',
        required: true,
        only_b2b: false,
      },
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'file',
        required_doc: '',
        description: 'Covering letter from Accommodation booking',
        label: 'Covering letter from Accommodation booking',
        only_b2b: false,
        key: 'Accommodation_booking',
        required: true,
        source_url: '',
      },
    ],
    components_required: [
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'appointment',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'covid_vaccine',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        only_b2b: false,
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'flight_hotel_details',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'identity',
        only_b2b: false,
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'india_itr',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'indonesia_hotel_details',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'notary',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'occupation',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'pan_card',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport',
        source_url: '',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        key: 'passport_back',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: true,
        source_url: '',
        key: 'photo',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        key: 'portugal_bank_account',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        source_url: '',
        key: 'portugal_lease_details',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'portugal_nif',
        only_b2b: false,
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        source_url: '',
        key: 'shipping_labels',
        only_b2b: false,
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        only_b2b: false,
        key: 'travel_insurance',
        source_url: '',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        toggle: true,
        attributes: ['use_surfer'],
        currency: 'INR',
        key: 'travel_itinerary',
        only_b2b: false,
        source_url: '',
      },
      {
        only_b2c: false,
        family_enabled: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_bank_statements',
        source_url: '',
        only_b2b: false,
      },
      {
        only_b2c: false,
        family_enabled: true,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        key: 'us_residence',
        source_url: '',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        currency: 'INR',
        toggle: false,
        key: 'us_uk_schengen_visa',
        only_b2b: false,
        source_url: '',
      },
    ],
    country_name: 'Malawi',
    purpose: 'tourism',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  // BY: {
  //   name: 'Belarus',
  //   code: 'BY',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1559387188-d5cebb21de5e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8QmVsYXJ1c3xlbnwwfHwwfHx8MA%3D%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Belarus',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  // BI: {
  //   name: 'Burundi',
  //   code: 'BI',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1672575659699-33e02d8a51a5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8QnVydW5kaXxlbnwwfHwwfHx8MA%3D%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Burundi',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  CV: {
    name: 'Cape Verde',
    code: 'CV',
    hero_image_url:
      'https://images.unsplash.com/photo-1592761855671-33347cb2f7bb?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Q2FwZSUyMFZlcmRlfGVufDB8fDB8fHww',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Cape Verde',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  KM: {
    name: 'Comoros',
    code: 'KM',
    hero_image_url:
      'https://images.unsplash.com/photo-1581412218131-a2b5634c074d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q29tb3Jvc3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Comoros',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  MV: {
    name: 'Maldives',
    code: 'MV',
    hero_image_url:
      'https://images.unsplash.com/photo-1573843981267-be1999ff37cd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8TWFsZGl2ZXN8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: "Nothing Required!\nYou're not required to get any visa pre-departure.",
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    entry_documents: [
      {
        heading: 'Visa on Arrival',
        description: 'Maldives provides a visa on arrival for all tourists for no cost. ',
      },
    ],
    country_name: 'Maldives',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  // DJ: {
  //   name: 'Djibouti',
  //   code: 'DJ',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1552796220-db7b4c3824a2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8RGppYm91dGl8ZW58MHx8MHx8fDA%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Djibouti',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  GW: {
    name: 'Guinea-Bissau',
    code: 'GW',
    hero_image_url:
      'https://images.unsplash.com/photo-1509840825703-6af73fa6c128?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8R3VpbmVhJTIwQmlzc2F1fGVufDB8fDB8fHww',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Guinea-Bissau',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  // LR: {
  //   name: 'Liberia',
  //   code: 'LR',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1586857669661-c0bbe3fccffd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8TGliZXJpYXxlbnwwfHwwfHx8MA%3D%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Liberia',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  MH: {
    name: 'Marshall Islands',
    code: 'MH',
    hero_image_url:
      'https://images.unsplash.com/photo-1553709724-ccfb0930663f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8TWFyc2hhbGwlMjBJc2xhbmRzfGVufDB8fDB8fHww',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Marshall Islands',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  MR: {
    name: 'Mauritania',
    code: 'MR',
    hero_image_url:
      'https://images.unsplash.com/photo-1543318557-a11bce6e033a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8TWF1cml0YW5pYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Mauritania',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  PW: {
    name: 'Palau',
    code: 'PW',
    hero_image_url:
      'https://images.unsplash.com/photo-1569806145835-1413fbfc1af4?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8UGFsYXV8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Palau',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  LC: {
    name: 'St. Lucia',
    code: 'LC',
    hero_image_url:
      'https://images.unsplash.com/photo-1617280325974-f9c5721a4862?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8U3QuJTIwTHVjaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    country_name: 'St. Lucia',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  WS: {
    name: 'Samoa',
    code: 'WS',
    hero_image_url:
      'https://images.unsplash.com/photo-1625224588813-cc8d5055493f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U2Ftb2F8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Samoa',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  SC: {
    name: 'Seychelles',
    code: 'SC',
    hero_image_url:
      'https://images.unsplash.com/photo-1577353716826-9151912dcdd1?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8U2V5Y2hlbGxlc3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Seychelles E-Visa',
    supported: false,
    message: "No Visa Required\nYou don't need a visa!",
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 30,
    eta_pre_travel_unit: 'days',
    entry_type: 'Evisa',
    entry_length_stay: '30 days',
    entry_validity: '30 days',
    govt_fees: 1000,
    service_fees: -1000,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    entry_documents: [
      {
        heading: 'No Visa Required',
        description: 'Indian nationals do not require a visa to visit Seychelles.',
      },
    ],
    components_required: [
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'appointment',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'covid_vaccine',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'fbi_background_check',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'flight_hotel_details',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'identity',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'india_itr',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'indonesia_hotel_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'notary',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'occupation',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'pan_card',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'passport_back',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: true,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'photo',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_bank_account',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_lease_details',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'portugal_nif',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'shipping_labels',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_insurance',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'travel_itinerary',
      },
      {
        family_enabled: false,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_bank_statements',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_residence',
      },
      {
        family_enabled: true,
        only_b2c: false,
        amount: 0,
        chargable: false,
        attributes: [],
        toggle: false,
        currency: 'INR',
        only_b2b: false,
        source_url: '',
        key: 'us_uk_schengen_visa',
      },
    ],
    country_name: 'Seychelles',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    price: '',
    cost_max: 0,
    cost_min: 0,
  },
  // SL: {
  //   name: 'Sierra Leone',
  //   code: 'SL',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1534471109-33fbc90d77d2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8U2llcnJhJTIwTGVvbmV8ZW58MHx8MHx8fDA%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Sierra Leone',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  // SB: {
  //   name: 'Solomon Islands',
  //   code: 'SB',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1612016834422-ab208e8c9f31?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8U29sb21vbiUyMElzbGFuZHN8ZW58MHx8MHx8fDA%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Solomon Islands',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  SO: {
    name: 'Somalia',
    code: 'SO',
    hero_image_url:
      'https://images.unsplash.com/photo-1603999540542-b8c65c6d3e89?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8U29tYWxpYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Somalia',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  // TG: {
  //   name: 'Togo',
  //   code: 'TG',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1580470846411-b0e734c5ffd6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8VG9nb3xlbnwwfHwwfHx8MA%3D%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Togo',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  // TC: {
  //   name: 'Turks & Caicos Islands',
  //   code: 'TC',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1564763623496-579a9824ca25?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fFR1cmtzJTIwJTI2JTIwQ2FpY29zJTIwSXNsYW5kc3xlbnwwfHwwfHx8MA%3D%3D',
  //   process: 'digital',
  //   process_name: 'Visa',
  //   supported: false,
  //   message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  //   eta_post_appointment_duration: 0,
  //   eta_post_appointment_unit: null,
  //   eta_pre_travel_duration: 0,
  //   eta_pre_travel_unit: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   govt_fees: 0,
  //   country_name: 'Turks & Caicos Islands',
  //   purpose: 'visa_on_arrival',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: 0,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  // },
  CK: {
    name: 'Cook Islands',
    code: 'CK',
    hero_image_url:
      'https://images.unsplash.com/photo-1604988162322-d5d678a1d993?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Q29vayUyMElzbGFuZHN8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Cook Islands',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  FM: {
    name: 'Micronesia',
    code: 'FM',
    hero_image_url:
      'https://images.unsplash.com/photo-1553602924-f9b35269e68b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fE1pY3JvbmVzaWF8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Micronesia',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  // GM: {
  //   name: 'Gambia',
  //   code: 'GM',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1553602924-f9b35269e68b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fE1pY3JvbmVzaWF8ZW58MHx8MHx8fDA%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Gambia',
  //   purpose: 'visa_free',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  SN: {
    name: 'Senegal',
    code: 'SN',
    hero_image_url:
      'https://images.unsplash.com/photo-1583495506658-50a3587f792e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fFNlbmVnYWx8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Senegal',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  HT: {
    name: 'Haiti',
    code: 'HT',
    hero_image_url:
      'https://images.unsplash.com/photo-1603767868273-d59d712c8188?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8SGFpdGl8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Haiti',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  BB: {
    name: 'Barbados',
    code: 'BB',
    hero_image_url:
      'https://images.unsplash.com/photo-1626023523650-f9fe5e85cacc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8QmFyYmFkb3N8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    country_name: 'Barbados',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  // MO: {
  //   name: 'Macao SAR China',
  //   code: 'MO',
  //   hero_image_url:
  //     'https://images.unsplash.com/photo-1465447142348-e9952c393450?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fE1hY2FvJTIwU0FSJTIwQ2hpbmF8ZW58MHx8MHx8fDA%3D',
  //   process: 'digital',
  //   supported: false,
  //   message: null,
  //   entry_length_stay: 'undefined undefined',
  //   entry_validity: 'undefined undefined',
  //   country_name: 'Macao SAR China',
  //   purpose: 'visa_free',
  //   sticker_visa: false,
  //   appointment: false,
  //   service_fees: null,
  //   price: '',
  //   cost_max: 0,
  //   cost_min: 0,
  //   compliance_fees: 0,
  //   payment_processing_fees: 0,
  //   process_upkeep_fees: 0,
  //   eta_post_checkout_duration: '',
  //   eta_post_checkout_unit: '',
  // },
  MS: {
    name: 'Montserrat',
    code: 'MS',
    hero_image_url:
      'https://images.unsplash.com/photo-1571037288111-a4d130100fd5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TW9udHNlcnJhdHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Montserrat',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  KN: {
    name: 'St. Kitts & Nevis',
    code: 'KN',
    hero_image_url:
      'https://images.unsplash.com/photo-1639222819309-f5b6e4f5e60a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8U3QuJTIwS2l0dHMlMjAlMjYlMjBOZXZpc3xlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    country_name: 'St. Kitts & Nevis',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  VG: {
    name: 'British Virgin Islands',
    code: 'VG',
    hero_image_url:
      'https://images.unsplash.com/photo-1622581646489-6066b5c697e9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8QnJpdGlzaCUyMFZpcmdpbiUyMElzbGFuZHN8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'British Virgin Islands',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  JM: {
    name: 'Jamaica',
    code: 'JM',
    hero_image_url:
      'https://images.unsplash.com/photo-1605994543054-6ffbabbd8139?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8SmFtYWljYXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    additional_questions: [
      {
        family_enabled: false,
        extra_info: '',
        question_type: 'dropdown',
        options: [
          {
            label: 'Student',
            value: 'student',
          },
          {
            label: 'Service worker',
            value: 'services',
          },
        ],
        description: 'Please include your occupation.',
        default_value: 'services',
        label: 'What is your occupation',
        source_url: '',
        key: 'occupation',
        required: false,
      },
    ],
    country_name: 'Jamaica',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  RE: {
    name: 'Réunion',
    code: 'RE',
    hero_image_url:
      'https://images.unsplash.com/photo-1617972405994-b3932f05d83a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cmV1bmlvbiUyMGlzbGFuZHxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Réunion',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  DM: {
    name: 'Dominica',
    code: 'DM',
    hero_image_url:
      'https://images.unsplash.com/photo-1499598896209-c62806b9ae75?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8RG9taW5pY2F8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Dominica',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  NP: {
    name: 'Nepal',
    code: 'NP',
    hero_image_url:
      'https://images.unsplash.com/photo-1529733905113-027ed85d7e33?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8TmVwYWx8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'E-Visa',
    supported: false,
    message: 'No Visa Required\nIndian nationals do not require a visa to visit Nepal.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 14,
    eta_pre_travel_unit: 'days',
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    entry_documents: [
      {
        heading: 'No Visa',
        description: 'No visa is required to enter.',
      },
    ],
    country_name: 'Nepal',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  SV: {
    name: 'El Salvador',
    code: 'SV',
    hero_image_url:
      'https://images.unsplash.com/photo-1618074951760-70364e5dcedb?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8RWwlMjBTYWx2YWRvcnxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'El Salvador',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  BT: {
    name: 'Bhutan',
    code: 'BT',
    hero_image_url:
      'https://images.unsplash.com/photo-1580649851649-992b28f56e98?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Qmh1dGFufGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Bhutan Tourist Visa',
    supported: false,
    message:
      "You do not require a visa to visit Bhutan\nIf you're going to Bhutan by road, you are required to obtain an 'Entry Permit' on the basis of a valid Travel Document (1. Indian Passport having validity of minimum 6 months; and/or 2. Voter Identity Card, issued by the Election Commission of India) from the Immigration Office of Royal Government of Bhutan at Phuentsholing, located on the Indo-Bhutan border opposite Jaigaon, West Bengal. Similarly, if you're going by by air, you can get the Entry Permit at the Paro International Airport.",
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    country_name: 'Bhutan',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  VC: {
    name: 'St. Vincent & Grenadines',
    code: 'VC',
    hero_image_url:
      'https://images.unsplash.com/photo-1625694100768-181ea98d028a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8U3QuJTIwVmluY2VudCUyMCUyNiUyMEdyZW5hZGluZXN8ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'St. Vincent & Grenadines',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  MU: {
    name: 'Mauritius',
    code: 'MU',
    hero_image_url:
      'https://images.unsplash.com/photo-1571147558584-8490ea37d7a9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8TWF1cml0aXVzfGVufDB8fDB8fHww',
    process: 'digital',
    process_name: 'Visa on Arrival',
    supported: false,
    message:
      'Nothing Required!\nA visa on arrival is issued at no cost. No entry document or visa is required pre-departure.',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    entry_documents: [
      {
        heading: 'Visa on Arrival',
        description: 'All Indians are eligible to get visa on arrival. ',
      },
    ],
    country_name: 'Mauritius',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  TT: {
    name: 'Trinidad & Tobago',
    code: 'TT',
    hero_image_url:
      'https://images.unsplash.com/photo-1607642875704-821b6eb0ba44?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8VHJpbmlkYWQlMjAlMjYlMjBUb2JhZ298ZW58MHx8MHx8fDA%3D',
    process: 'digital',
    process_name: 'Visa',
    supported: false,
    message: 'No Visa Required\nCongrats, you do not need a visa to visit this country!',
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
    eta_post_appointment_duration: 0,
    eta_post_appointment_unit: null,
    eta_pre_travel_duration: 0,
    eta_pre_travel_unit: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    govt_fees: 0,
    country_name: 'Trinidad & Tobago',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: 0,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
  },
  NU: {
    name: 'Niue',
    code: 'NU',
    hero_image_url:
      'https://images.prismic.io/atlys/MjlkZTk3NDQtMjk5Yi00NWVmLWFlNGEtNzg2NTFiMWQwMTI0_c3013c67-6857-45bd-9ed0-a2b020fc69b6_nu1.jpg?auto=compress,format&rect=0,0,2000,2500&w=2000&h=2500',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Niue',
    purpose: 'visa_free',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
  FJ: {
    name: 'Fiji',
    code: 'FJ',
    hero_image_url:
      'https://images.unsplash.com/photo-1530736822234-c8562cb0a710?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8RmlqaXxlbnwwfHwwfHx8MA%3D%3D',
    process: 'digital',
    supported: false,
    message: null,
    entry_length_stay: 'undefined undefined',
    entry_validity: 'undefined undefined',
    country_name: 'Fiji',
    purpose: 'visa_on_arrival',
    sticker_visa: false,
    appointment: false,
    service_fees: null,
    price: '',
    cost_max: 0,
    cost_min: 0,
    compliance_fees: 0,
    payment_processing_fees: 0,
    process_upkeep_fees: 0,
    eta_post_checkout_duration: '',
    eta_post_checkout_unit: '',
  },
};
